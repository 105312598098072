import { useState } from 'react';
import { useFormikContext } from 'formik';
import SingleSelect from '../SingleSelect';

function SingleSelectSituacao(props) {
	const { onChange, field, url } = props;

	const [resetPesquisa, setResetPesquisa] = useState(false);
	const { setFieldValue } = useFormikContext();
	
	function buscarUrlPesquisa(pesquisa, page) {
		const query = `?query=nome=contains="*${pesquisa}*";situacao=="ATIVO"&page=${page}&size=50`;
		return `${url}${query}`;
	}

	function buscarUrlRegistroUnico(id) {
		const query = `?query=id==${id}`;
		return `${url}${query}`;
	}

	function montarLabel(row) {
		return `${row.nome}`;
	}
	
	function handleChange(value) {
		if (onChange) {
			onChange(value);
		} else {
			setFieldValue(field.name, {
				value: value.value,
				label: value.label,
				registro: value.registro,
			});
		}
	}

	return (		
		<SingleSelect
			{...props}
			buscarUrlPesquisa={buscarUrlPesquisa}
			buscarUrlRegistroUnico={buscarUrlRegistroUnico}
			montarLabel={montarLabel}				
			onChange={handleChange}
			resetPesquisa={resetPesquisa}
			setResetPesquisa={setResetPesquisa}
			esconderBotao
		/>		
	);
}

SingleSelectSituacao.defaultProps = {
	label: 'Situação',
	isClearable: false,
};

export default SingleSelectSituacao;
