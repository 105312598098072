import { actionTypes, buscarDadosLoginLocalStorage, dispatchAction, salvarDadosLoginLocalStorage } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
} from 'components';
import { useFormikContext } from 'formik';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { INITIAL_VALUES } from '../../Util/constantes';
import { converterUsuarioParaApi } from '../Functions';
import {
	asyncBloquearCredencial,
	asyncDeleteUsuario,
	asyncDesbloquearCredencial,
	atualizarUsuario,
	registrarNovoUsuario,
} from '../Requests';

function ActionButtons({ history, isModal, hideModal, informacoesPermissoes, props }) {
	const { values, dirty, initialValues, handleSubmit, resetForm } = useFormikContext();

	const PESQUISAURL = '/usuarios';
	const CADASTROURL = '/usuarios/cadastro';
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);

		resetForm({ values: { ...INITIAL_VALUES, setores: [] } });
	}

	function excluir() {
		confirmarExclusao(asyncDeleteRegistro);
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteUsuario(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else if (isModal) {
			fecharModal();
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	function bloquearCredencial() {
		const { email, id } = values;

		confirm('Confirmação', `Tem certeza que deseja bloquear o usuário ${email}?`, async () => {
			await asyncBloquearCredencial(id, () => {
				resetForm({
					values: { ...values, bloqueada: true },
				});
			});
		});
	}

	function desbloquearCredencial() {
		const { email, id } = values;

		confirm('Confirmação', `Tem certeza que deseja desbloquear o usuário ${email}?`, async () => {
			await asyncDesbloquearCredencial(id, () => {
				resetForm({
					values: { ...values, bloqueada: false },
				});
			});
		});
	}

	async function modificarUsuario(dadosForm, novoOnSuccess) {
		if (isModal && !dirty && values.id) {
			fecharModal();
		} else {
			await atualizarUsuario(converterUsuarioParaApi(dadosForm), () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({ values: dadosForm });
				}
			});
		}
	}

	async function criarUsuario(dadosForm, novoOnSuccess) {
		await registrarNovoUsuario(converterUsuarioParaApi(dadosForm), ({ data: usuario }) => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				resetForm({
					values: { ...values, id: usuario.id, bloqueada: false },
				});
				atualizarUrl(history, CADASTROURL, usuario.id, metodosAtualizarUrl.POP);
			}
		});
	}

	function fecharModal() {
		if (values.id) {
			const urlRegistro = `${props.service}usuarios/${values.id}`;
			hideModal(urlRegistro);
		} else {
			hideModal();
		}
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();
		const dadosLocalStorage = buscarDadosLoginLocalStorage();

		if (await validarFormulario(props)) {
			if (values.id) {
				await modificarUsuario({ ...values, email: values.email }, novoOnSuccess);

				if (String(dadosLocalStorage.email) === String(values.email)) {
					salvarDadosLoginLocalStorage({
						...dadosLocalStorage,
						nome: values.nome,
					});
					dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR, true);
					dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR_FILIAL, true);
				}
			} else {
				criarUsuario({ ...values, email: values.email }, novoOnSuccess);
			}
		}
	}

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
			/>
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={salvar} />
			<ButtonNovo
				onClick={onClickNovo}
				hidden={(!dirty && !values.id) || isModal}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				onClick={excluir}
				hidden={Boolean(values.ativa || !values.id)}
				disabled={Boolean(values.ativa)}
				{...informacoesPermissoes}
			/>
			<Button
				color="success"
				icon="fa fa-unlock"
				label="Desbloquear"
				style={{ margin: '5px' }}
				onClick={desbloquearCredencial}
				hidden={Boolean(values.bloqueada === false || !values.id || !informacoesPermissoes.podeEditar)}
				{...informacoesPermissoes}
			/>
			<Button
				color="danger"
				icon="fa fa-lock"
				label="Bloquear"
				style={{ margin: '5px' }}
				onClick={bloquearCredencial}
				hidden={Boolean(values.bloqueada === true || !values.id || !informacoesPermissoes.podeEditar)}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { ActionButtons };
