import { useEffect, useState } from 'react';
import { useFormikContext, withFormik } from 'formik';

import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	estadosCadastro,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import { Form, FormActions, FormContent, Prompt, Tutorial, tutorialStepsSituacao } from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { CADASTRO_URL, SITUACAO_TIPO } from '../Utils/constantes';
import { INITIAL_VALUES, SITUACAO_PARAMETROS_TIPO_ESTOQUE, SITUACAO_PARAMETROS_TIPO_FISCAL } from './Utils/constantes';
import { converterSituacaoParaFormulario } from './Utils/converter';
import { getSituacao } from '../Request';
import { ActionButtons } from './components/ActionButtons';
import { InformacoesPrincipais } from './components/InformacoesPrincipais';
import { ParametrosSituacao } from './components/ParametrosSituacao';
import { SituacoesVinculadas } from './components/SituacoesVinculadas';
import { MessageValidacao } from './components/MessageValidacao';

function SituacoesFormImpl({ history, match }) {
	const { dirty, resetForm } = useFormikContext();
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [buscarSituacoes, setBuscarSituacoes] = useState(false);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_SITUACAO);
	const podeInserir = usuarioPossuiPermissao(recursos.CADASTROS_SITUACOES, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.CADASTROS_SITUACOES, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CADASTROS_SITUACOES, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		estadoCadastro: match.params.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		let id = null;
		if (match?.params) {
			id = match.params.id;
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_SITUACAO, false, null, false);
		}

		if (validarUUID(id)) {
			fetchRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('cadastro-situacao-input-nome')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(idSituacao) {
		await getSituacao(
			idSituacao,
			async ({ data: situacao }) => {
				resetForm({ values: await converterSituacaoParaFormulario(situacao) });

				atualizarUrl(history, `/${CADASTRO_URL}`, situacao.id, metodosAtualizarUrl.POP);
				setIsFirstRender(false);
			},
			() => {
				setIsFirstRender(false);
			}
		);
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsSituacao}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de situação">
				<FormActions className="screen-max-width">
					<ActionButtons informacoesPermissoes={informacoesPermissoes} history={history} setBuscarSituacoes={setBuscarSituacoes} />
				</FormActions>
				<FormContent>
					<MessageValidacao />
					<InformacoesPrincipais informacoesPermissoes={informacoesPermissoes} />
					<SituacoesVinculadas informacoesPermissoes={informacoesPermissoes} buscarSituacoes={buscarSituacoes} setBuscarSituacoes={setBuscarSituacoes} />
					<ParametrosSituacao informacoesPermissoes={informacoesPermissoes} isFirstRender={isFirstRender} />
				</FormContent>
			</Form>
		</>
	);
}

const SituacoesForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const { gerarEstoqueNaNfe } = buscarDadosLoginLocalStorage().filialConectada.parametrosEstoque;
		const { gerarFinanceiroNaNfe } = buscarDadosLoginLocalStorage().filialConectada.parametrosFinancas;
		let errors = {};
		let errorsParametros = {};

		function addError(field, message, object) {
			return { ...object, [field]: message };
		}

		function validarCamposPrincipais() {
			const camposObrigatorios = ['nome', 'tipo', 'situacao', 'icone', 'textoBotao', 'corTexto', 'corFundo'];
			camposObrigatorios.forEach((field) => {
				if (!values[field]) {
					errors = addError(field, mensagensDeValidacao.OBRIGATORIO, errors);
				}
			});

			if (values.situacao === 'INATIVO' && values.situacaoInicial) {
				errors = addError('situacaoInicial', 'Situação inativa não pode ser uma situação inicial', errors);
			}

			if (values.situacaoInicial && values.tipo !== SITUACAO_TIPO.PENDENTE) {
				errors = addError('situacaoInicial', 'Situação inicial deve ser do tipo "Pendente"', errors);
			}
		}

		function validarParametros() {
			const { parametros } = values;

			if (!parametros.tipoEstoqueGerado) {
				errorsParametros = addError('tipoEstoqueGerado', mensagensDeValidacao.OBRIGATORIO, errorsParametros);
			}
			if (parametros.tipoEstoqueGerado !== SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM && gerarEstoqueNaNfe) {
				errorsParametros = addError(
					'tipoEstoqueGerado',
					'Parametrizado para gerar estoque na nota de venda, não é possível configurar situação para movimentar estoque',
					errorsParametros
				);
			}
			if (values.situacaoInicial && parametros.tipoEstoqueGerado !== SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM) {
				errorsParametros = addError(
					'tipoEstoqueGerado',
					'Situação inicial não permite nenhum tipo de geração de estoque',
					errorsParametros
				);
			}
			if (parametros.movimentaFinanceiro && gerarFinanceiroNaNfe) {
				errorsParametros = addError(
					'movimentaFinanceiro',
					'Parametrizado para gerar financeiro na nota de venda, não é possível configurar situação para movimentar financeiro',
					errorsParametros
				);
			}
			if (!parametros.tipoFiscal) {
				errorsParametros = addError('tipoFiscal', mensagensDeValidacao.OBRIGATORIO, errorsParametros);
			}
			if (
				parametros.tipoFiscal === SITUACAO_PARAMETROS_TIPO_FISCAL.PERMITE_GERAR_DOCUMENTO_FISCAL &&
				!parametros.situacaoFinalDocumentoGerado
			) {
				errorsParametros = addError(
					'situacaoFinalDocumentoGerado',
					'Situação que permite gerar fiscal deve vincular situação final no campo "Situação final quando doc. fiscal gerado"',
					errorsParametros
				);
			}
			if (!parametros.permiteFinalizarENovo && parametros.situacaoFinalFinalizado) {
				errorsParametros = addError(
					'situacaoFinalFinalizado',
					'Para informar o campo "Situação final finalizado" é necessário que o parâmetro "Permite finalziar e novo" esteja marcado',
					errorsParametros
				);
			}
			if (parametros.permiteFinalizarENovo && !parametros.situacaoFinalFinalizado) {
				errorsParametros = addError(
					'situacaoFinalFinalizado',
					'Quando o campo "Permite finalizar e novo" for marcado, é necessário informar "Situação final finalizado',
					errorsParametros
				);
			}
		}

		function validarPorTipo() {
			const { parametros } = values;

			const regras = {
				[SITUACAO_TIPO.PENDENTE]: () => {
					if (parametros.tipoEstoqueGerado === SITUACAO_PARAMETROS_TIPO_ESTOQUE.NORMAL) {
						errorsParametros = addError(
							'tipoEstoqueGerado',
							'Situação do tipo pendente não pode baixar estoque',
							errorsParametros
						);
					}
					if (parametros.movimentaFinanceiro) {
						errorsParametros = addError(
							'movimentaFinanceiro',
							'Situação do tipo pendente não pode movimentar financeiro',
							errorsParametros
						);
					}
					if (parametros.tipoFiscal === SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL) {
						errorsParametros = addError(
							'tipoFiscal',
							'Situação do tipo pendente não pode gerar documento fiscal',
							errorsParametros
						);
					}
					if (parametros.permiteDevolucao) {
						errorsParametros = addError(
							'permiteDevolucao',
							'Situação do tipo pendente não pode gerar devoluções',
							errorsParametros
						);
					}
				},
				[SITUACAO_TIPO.CANCELADO]: () => {
					if (parametros.tipoEstoqueGerado !== SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM) {
						errorsParametros = addError(
							'tipoEstoqueGerado',
							'Situação do tipo cancelado não pode baixar estoque',
							errorsParametros
						);
					}
					if (parametros.movimentaFinanceiro) {
						errorsParametros = addError(
							'movimentaFinanceiro',
							'Situação do tipo cancelado não pode movimentar financeiro',
							errorsParametros
						);
					}
					if (parametros.tipoFiscal !== SITUACAO_PARAMETROS_TIPO_FISCAL.NENHUM) {
						errorsParametros = addError(
							'tipoFiscal',
							'Situação do tipo cancelado não pode gerar fiscal',
							errorsParametros
						);
					}
					if (parametros.situacaoFinalDocumentoGerado) {
						errorsParametros = addError(
							'situacaoFinalDocumentoGerado',
							`Situação do tipo cancelado não pode conter "situação final doc. fiscal gerado"`,
							errorsParametros
						);
					}
					if (parametros.permiteDevolucao) {
						errorsParametros = addError(
							'permiteDevolucao',
							`Situação do tipo cancelado não permite devolução`,
							errorsParametros
						);
					}
					if (parametros.permiteDevolucaoCondicional) {
						errorsParametros = addError(
							'permiteDevolucaoCondicional',
							`Situação do tipo cancelado não permite devolução condicional`,
							errorsParametros
						);
					}
					if (parametros.permiteEntregaParcial) {
						errorsParametros = addError(
							'permiteEntregaParcial',
							`Situação do tipo cancelado não permite entrega parcial`,
							errorsParametros
						);
					}
				},
				[SITUACAO_TIPO.FINALIZADO]: () => {
					if (parametros.estornaMovimentacoes) {
						errorsParametros = addError(
							'estornaMovimentacoes',
							'Situação do tipo finalizado não pode estornar movimentações',
							errorsParametros
						);
					}
					if (parametros.permiteDevolucaoCondicional) {
						errorsParametros = addError(
							'permiteDevolucaoCondicional',
							'Situação do tipo finalizado não pode gerar devoluções condicionais',
							errorsParametros
						);
					}
				},
			};

			if (regras[values.tipo]) {
				regras[values.tipo]();
			}
		}

		validarCamposPrincipais();
		validarParametros();
		validarPorTipo();

		if (Object.keys(errorsParametros)?.length > 0) {
			errors.parametros = errorsParametros;
		}

		return errors;
	},

	handleSubmit: () => {},
})(SituacoesFormImpl);

export { SituacoesForm };
