export const CADASTRO_URL = '/vendas/natureza_operacao/cadastro';
export const PESQUISA_URL = '/vendas/natureza_operacao';

export const NATUREZA_OPERACAO_SITUACAO = {
	ATIVO: 'ATIVO',
	INATIVO: 'INATIVO',
};

export const NATUREZA_OPERACAO_OPTIONS = [
	{ value: NATUREZA_OPERACAO_SITUACAO.ATIVO, label: 'Ativa' },
	{ value: NATUREZA_OPERACAO_SITUACAO.INATIVO, label: 'Inativa' },
];

export const INITIAL_VALUES = {
	id: null,
	descricao: null,
	situacao: 'ATIVO',
	favorito: false
};
