import ButtonDetalhesTable from 'components/Button/ButtonDetalhesTable';
import ButtonExcluirTable from 'components/Button/ButtonExcluirTable';
import Col from 'components/Col';
import Divider from 'components/Divider';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { InternalTextArea } from 'components/TextArea/InternalTextArea';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';

function ActionsParcela({
	value,
	values,
	indexParcela,
	showLabels,
	onChangeDescricao,
	handleBlurDescricao,
	onRemoveParcela,
}) {
	const { disabledFields, informacoesPermissoes, isMobile, isTablet } = useContextPagamentos();

	const panelDetalhes = useRef(null);

	const isLastParcela = indexParcela === 0 && values.parcelas.length === 1;
	const isLastParcelaByParcelaMinima = values.parcelas.length <= values.condicaoPagamento?.registro?.parcelaMinima;
	const buttonExcluirDisabled = isLastParcela || disabledFields || isLastParcelaByParcelaMinima;

	function handleClickDescricao(e) {
		panelDetalhes.current.toggle(e);
	}

	function handleChangeDescricao(e) {
		onChangeDescricao(e.target.value, indexParcela);
	}

	function handleClickExcluir() {
		onRemoveParcela(indexParcela);
	}

	function getTitleButtonExcluir() {
		if (isLastParcela) {
			return 'Operação não permitida, a forma de pagamento deve conter pelo menos uma parcela';
		}

		if (isLastParcelaByParcelaMinima) {
			return 'Operação não permitida, número mínimo de parcelas da condição de pagamento foi atingido';
		}

		return 'Excluir parcela';
	}

	return (
		<>
			<Col
				sm="12"
				md="12"
				lg="1"
				xl="1"
				style={{
					display: 'flex',
					justifyContent: isMobile || isTablet ? 'center' : 'start',
					alignItems: showLabels ? 'center' : 'start',
					padding: showLabels ? '9px 0px 0px' : '0px',
				}}
			>
				<ButtonDetalhesTable onClick={handleClickDescricao} title="Descrição do item" {...informacoesPermissoes} />
				<ButtonExcluirTable
					onClick={handleClickExcluir}
					title={getTitleButtonExcluir()}
					disabled={buttonExcluirDisabled}
					{...informacoesPermissoes}
				/>
				<OverlayPanel ref={panelDetalhes} showCloseIcon>
					<InternalTextArea
						helpMessage="Detalhes gerais da parcela"
						type="text"
						label="Detalhes da parcela"
						name="descricao"
						value={value.descricao}
						size={200}
						onChange={handleChangeDescricao}
						onBlur={handleBlurDescricao}
						disabled={disabledFields}
						{...informacoesPermissoes}
					/>
				</OverlayPanel>
			</Col>
			{isMobile || isTablet ? <Divider styleContainer={{ marginTop: '0.5rem' }} /> : null}
		</>
	);
}

export { ActionsParcela };
