import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { colors, formatarMonetario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Button, NenhumRegistroEncontrado } from 'components';
import { estornarEntregaIndividual } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Requests';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function ListaProdutosEntregues({ produtosEntregues, buscarItensEntregues, setEstornouEntregas, isMobile }) {
	const permissaoEntrega = usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.ENTREGAR);

	function renderLabelProduto(row) {
		return `${row.produto?.codigo} - ${row.produto?.nome}` || '-';
	}

	function renderData(row) {
		return <span>{format(parseISO(row.entrega?.data, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</span>;
	}

	function renderValor(valor) {
		const valorFormatado = formatarMonetario(valor);
		return (
			<span title={valorFormatado} style={{ color: colors.cinzaDark }}>
				{valorFormatado}
			</span>
		);
	}

	function renderAcoes(row) {
		return (
			<Button
				style={{ ...styleButton, background: '#e2aa01', border: '#e2aa01' }}
				className="p-button p-button-primary"
				icon="fa fa-reply-all"
				title="Estornar entrega"
				disabled={!permissaoEntrega}
				onClick={() => estornarEntrega(row)}
			/>
		);
	}

	function estornarEntrega(row) {
		estornarEntregaIndividual(row.entrega?.id, () => {
			buscarItensEntregues();
			setEstornouEntregas(true);
		});
	}

	return (
		<DataTable
			className="table"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			value={produtosEntregues}
			style={{
				width: '100%',
				overflowY: length > 6 ? 'scroll' : 'hidden',
				overflowX: 'hidden',
				maxHeight: isMobile ? '36.4rem' : '20.2rem',
			}}
			emptyMessage={<NenhumRegistroEncontrado />}
		>
			<Column
				header="Data"
				field="data"
				body={(row) => renderData(row)}
				style={{
					...styleColumn,
					width: '100px',
				}}
			/>
			<Column
				header="Produto"
				field="produto"
				body={(row) => renderLabelProduto(row)}
				style={{
					...styleColumn,
				}}
			/>
			<Column
				header="Quantidade"
				field="quantidade"
				body={(row) => row.entrega?.quantidade}
				style={{
					...styleColumn,
					width: '100px',
				}}
			/>
			<Column
				header="Valor"
				field="valor"
				body={(row) => renderValor(row.entrega?.valor)}
				style={{
					...styleColumn,
					width: '120px',
				}}
			/>
			<Column
				header="Total item"
				field="subtotal"
				body={(row) => renderValor(row.entrega?.valor)}
				style={{
					...styleColumn,
					width: '120px',
				}}
			/>
			<Column className="step-listagem-acoes" header="Ações" body={renderAcoes} style={{ width: '100px' }} />
		</DataTable>
	);
}

export { ListaProdutosEntregues };
