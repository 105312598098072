/* eslint-disable no-param-reassign */
import { format, parse } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { SITUACAO_PARAMETROS_TIPO_FISCAL } from 'views/cadastros/vendas/Situacoes/Form/Utils/constantes';
import {
	buscarDadosLoginLocalStorage,
	colors,
	formatarMonetario,
	permissoes,
	recursos,
	usePrevious,
	usuarioPossuiPermissao,
} from '../../../../../../Common';
import { Button, If, NenhumRegistroEncontrado, Paginacao, ToastTypes, notify } from '../../../../../../components';
import { moduloUtilizado } from '../../../../../../components/header/Util/constantes';
import { atualizarUrl } from '../../../../../Util';
import { TypeErrors } from '../../Form/Util/constantes';
import {
	asyncAtualizarSituacao,
	asyncBuscarSituacaoCompleta,
	asyncEstornarPedido,
	asyncFinalizarPedido,
	verificarSeExisteProdutosInativosNaVenda,
} from '../../Requests';
import { SITUACAO_DEVOLUCAO, SITUACAO_ENTREGA, Status, colorsStatus as StatusTable, Tipo } from '../../Util/constantes';
import ModalCancelarVendaForm from '../ModalCancelarVenda';
import VendasHistorico from '../VendasHistorico';
import { buscarPermissãoPorTipoSituacao } from '../../Util/funcoes';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

function TabelaVendasOrcamentos(props) {
	const {
		history,
		onPesquisar,
		setSortField,
		setSortOrder,
		isMobile,
		onPrint,
		onGenerateSale,
		onReject,
		registros,
		totalRecords,
		rows,
		page,
		onPageChange,
	} = props;

	const [podeExcluir] = useState(
		isPedidoVenda()
			? usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.EXCLUIR)
			: usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.EXCLUIR)
	);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
	const [visibleCancelModal, setVisibleCancelModal] = useState(false);
	const [etapaCanceladaModalCancelamento, setEtapaCanceladaModalCancelamento] = useState(null);
	const [menu, setMenu] = useState(null);
	const prevProps = usePrevious(props);

	const urlVendaOuOrcamento = isPedidoVenda() ? 'pedidos_venda' : 'orcamentos';
	const vendaOuOrcamento = isPedidoVenda() ? moduloUtilizado.PEDIDO_VENDA : moduloUtilizado.ORCAMENTO;

	function isPedidoVenda() {
		return history.location.pathname === '/pedido_venda';
	}

	useEffect(() => {
		if (prevProps?.registros !== registros) {
			setVisibleCancelModal(false);
		}
	}, [prevProps]);

	function atualizarOrdenacao(event) {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	}

	function renderEmissaoField(element) {
		return <span>{format(parse(element.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</span>;
	}

	function renderNumeroField(element) {
		return <span title={element.numero}>{element.numero}</span>;
	}

	function renderClienteField(element) {
		return (
			<span
				title={element.clienteNome}
				style={{
					maxHeight: '60px',
					overflow: 'hidden',
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{element.clienteNome ? element.clienteNome : '—'}
			</span>
		);
	}

	function renderStatusField(element) {
		let nome = element.situacao?.nome;
		let lightColor = element.situacao?.corFundo;
		let strongColor = element.situacao?.corTexto;
		let title = null;

		if (element.situacao?.tipo === Status.FINALIZADO) {
			if (element.situacaoDevolucao === SITUACAO_DEVOLUCAO.PARCIALMENTE_DEVOLVIDO) {
				nome = 'Parc. devolvido';
				lightColor = StatusTable.PARCIALMENTE_DEVOLVIDO.lightColor;
				strongColor = StatusTable.PARCIALMENTE_DEVOLVIDO.strongColor;
				title = 'Pedido parcialmente devolvido';
			} else if (element.situacaoDevolucao === SITUACAO_DEVOLUCAO.DEVOLVIDO) {
				nome = 'Devolvido';
				lightColor = StatusTable.DEVOLVIDO.lightColor;
				strongColor = StatusTable.DEVOLVIDO.strongColor;
				title = 'Pedido completamente devolvido';
			}
		}
		if (element.situacao?.tipo !== Status.FINALIZADO) {
			if (element.situacaoEntrega === SITUACAO_ENTREGA.PARCIALMENTE_ENTREGUE) {
				nome = 'Parc. entregue';
				title = 'Pedido parcialmente entregue';
			} else if (element.situacaoEntrega === SITUACAO_ENTREGA.ENTREGUE) {
				nome = 'Entregue';
				title = 'Pedido completamente entregue';
			}
		}

		return (
			<span
				style={{
					backgroundColor: lightColor,
					color: strongColor,
					fontWeight: 'bold',
					fontSize: '13px',
					borderRadius: '20px',
					height: '1.5rem',
					width: '320px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0 10px',
				}}
				title={title ? `${element.situacao?.nome} (${title})` : nome}
			>
				<span
					style={{
						display: 'block',
						maxWidth: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{nome}
				</span>
			</span>
		);
	}

	function renderValorField(element) {
		const valor = formatarMonetario(element.totalLiquido);
		return (
			<span title={valor} style={{ color: colors.cinzaDark }}>
				{valor}
			</span>
		);
	}

	function renderValorAReceberField(element) {
		const valor = formatarMonetario(element.valorAReceber);
		if (valor !== formatarMonetario(0)) {
			return (
				<span title={valor} style={{ fontWeight: 'bold', color: colors.cinzaDark }}>
					{valor}
				</span>
			);
		}
		return <span>—</span>;
	}

	function renderAcoesField(element) {
		const titleEditar = 'Editar';
		let titleExcluir = 'Excluir';
		const disableBtnEditar = false;
		let disableBtnExcluir = false;

		if (element?.situacao?.tipo === Status.CANCELADO || element?.situacao?.tipo === Status.FINALIZADO) {
			titleExcluir = 'Você não pode excluir uma venda cancelada';
			disableBtnExcluir = true;
		}

		if (!podeExcluir) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		if (element.nfe && element.nfe.id) {
			titleExcluir = 'Você não pode excluir uma venda com nota fiscal';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => props.onEditItem(element)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => props.onRemoveItem(element)}
				/>
				<Button
					title="Opções"
					className="p-button-secondary step-listagem-acoes"
					icon="fa fa-ellipsis-v"
					style={styleButton}
					aria-controls="popup_menu"
					aria-haspopup
					onClick={(event) => {
						menu.toggle(event);
						if (isPedidoVenda()) {
							asyncBuscarSituacaoCompleta(element.situacao.id, ({ data }) => {
								setRegistroSelecionado({ ...element, situacao: data || element.situacao });
							});
						} else {
							setRegistroSelecionado(element);
						}
					}}
				/>
			</div>
		);
	}

	function renderNotaFiscal(element) {
		if (element.nfe && element.nfe.id) {
			const { numero } = element.nfe;
			const { serie } = element.nfe;

			return (
				<span
					onClick={() => acessarNfe(element.nfe)}
					style={{ fontSize: '12px', color: colors.principal }}
					className="link_to"
					title={
						!numero || numero === 0
							? `Acessar nota fiscal (ainda não transmitida)`
							: `Acessar nota fiscal número ${numero}`
					}
				>
					NF-e {numero || '-'}/{serie || '?'}
				</span>
			);
		} else if (element.nfce && element.nfce.id) {
			const { numero } = element.nfce;
			const { serie } = element.nfce;

			return (
				<span
					onClick={() => acessarNfce(element.nfce.id)}
					style={{ fontSize: '12px', color: colors.principal }}
					className="link_to"
					title={
						!numero || numero === 0
							? `Acessar nota fiscal de consumidor (ainda não transmitida)`
							: `Acessar nota fiscal de consumidor número ${numero}`
					}
				>
					NFC-e {numero || '-'}/{serie || '?'}
				</span>
			);
		}
		return <span>—</span>;
	}

	function renderPedidoVenda(element) {
		if (element.pedido && element.pedido.id) {
			const { numero } = element.pedido;

			return (
				<span
					onClick={() => acessarPedido(element.pedido.id)}
					style={{ fontSize: '12px', color: colors.principal }}
					className="link_to"
					title={`Acessar pedido de venda número ${numero}`}
				>
					Pedido {numero || '-'}
				</span>
			);
		}
		return <span>—</span>;
	}

	function acessarNfe(nfe) {
		atualizarUrl(history, `/nfes/cadastro/${nfe.id}`);
	}

	function acessarNfce(nfeId) {
		atualizarUrl(history, `/nfces/cadastro/${nfeId}`);
	}

	function acessarPedido(vendaId) {
		atualizarUrl(history, `/pedido_venda/cadastro/${vendaId}`);
	}

	function duplicar(vendaId) {
		history.push({
			pathname: `/${isPedidoVenda() ? 'pedido_venda' : 'orcamento'}/cadastro/${vendaId}`,
			state: { vendaId },
		});
	}

	async function gerarNfe(venda) {
		try {
			if (await verificarSeExisteProdutosInativosNaVenda(urlVendaOuOrcamento, venda.id)) {
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}
			atualizarUrl(props.history, `/nfes/importar_pedido/${venda.id}`);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, orçamento contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function gerarNfce(venda) {
		atualizarUrl(history, `/nfces/importar_pedido/${venda.id}`);
	}

	function montarItensMenu() {
		let itens = [];

		if (registroSelecionado) {
			itens.push({
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => duplicar(registroSelecionado.id),
			});
			if (registroSelecionado.situacao?.parametros?.permiteImpressoes) {
				itens.push({
					label: 'Imprimir',
					icon: 'fa fa-print',
					command: () => onPrint(registroSelecionado),
				});
			}
			itens.push({
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => visualizarHistorico(),
			});

			if (registroSelecionado.tipo !== Tipo.NOTA_FISCAL) {
				const acoesDaSituacaoAtual = buscarAcoesDaSituacaoAtual();
				if (acoesDaSituacaoAtual?.length > 0) {
					itens = [...itens, ...acoesDaSituacaoAtual];
				}
			}

			const acoesPermitidasDaSituacaoAtual = buscarAcoesPermitidasDaSituacaoAtual();
			if (acoesPermitidasDaSituacaoAtual?.length > 0) {
				itens = [...itens, ...acoesPermitidasDaSituacaoAtual];
			}
		}
		return itens;
	}

	function buscarAcoesDaSituacaoAtual() {
		const itensOpcoes = [];
		if (registroSelecionado.situacao?.etapasVinculadas?.length > 0) {
			registroSelecionado.situacao?.etapasVinculadas?.forEach((etapa) => {
				if (
					isPedidoVenda()
						? etapa.situacao?.tipoFiscal !== SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL
						: !registroSelecionado.pedido
				) {
					itensOpcoes.push({
						label: etapa.situacao?.textoBotao,
						icon: etapa.situacao?.icone,
						command: () => {
							buscarComandoSituacao(registroSelecionado, etapa);
						},
						disabled: !usuarioPossuiPermissao(
							isPedidoVenda() ? recursos.VENDAS_PEDIDOS : recursos.VENDAS_ORCAMENTOS,
							isPedidoVenda() ? buscarPermissãoPorTipoSituacao(etapa.situacao?.tipo) : permissoes.EDITAR
						),
					});
				}
			});

			if (itensOpcoes.length > 0) {
				itensOpcoes.unshift({
					separator: true,
					visible: true,
				});
			}
		}

		return itensOpcoes;
	}

	function buscarComandoSituacao(registroSelecionado, etapa) {
		if (isPedidoVenda()) {
			if (etapa?.situacao?.tipo === Status.FINALIZADO) {
				finalizarPedido(registroSelecionado, etapa.situacao?.id, null, null);
			} else if (etapa?.situacao?.tipo === Status.CANCELADO) {
				setVisibleCancelModal(true);
				setEtapaCanceladaModalCancelamento(etapa?.situacao);
			} else if (etapa?.situacao?.estornaMovimentacoes) {
				estornarVenda(registroSelecionado.id);
			} else {
				atualizarSituacao(registroSelecionado.id, etapa?.situacao?.id);
			}
		} else if (!registroSelecionado.pedido) {
			if (etapa?.situacao?.tipo === Status.FINALIZADO) {
				onGenerateSale(registroSelecionado);
			} else if (etapa?.situacao?.tipo === Status.CANCELADO) {
				setVisibleCancelModal(true);
				setEtapaCanceladaModalCancelamento(etapa?.situacao);
			} else if (etapa?.situacao?.estornaMovimentacoes) {
				estornarVenda(registroSelecionado.id);
			}
		}
	}

	function buscarAcoesPermitidasDaSituacaoAtual() {
		const itens = [];
		const podeVisualizarNFCe = usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR);
		const podeInserirNFCe = usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.INSERIR);
		const podeVisualizarNFe = usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR);
		const podeInserirNFe = usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.INSERIR);

		if (registroSelecionado.tipo === 'PEDIDO_VENDA') {
			if (podeVisualizarNFCe && registroSelecionado.nfce) {
				itens.push({
					label: 'Acessar NFC-e',
					icon: 'fa fa-link',
					command: () => {
						acessarNfce(registroSelecionado.nfce.id);
					},
				});
			} else if (podeVisualizarNFe && registroSelecionado.nfe) {
				itens.push({
					label: 'Acessar NF-e',
					icon: 'fa fa-link',
					command: () => {
						acessarNfe(registroSelecionado.nfe);
					},
				});
			} else if (
				podeInserirNFe &&
				registroSelecionado.situacao?.tipo !== Status.CANCELADO &&
				registroSelecionado.situacao?.parametros?.tipoFiscal !== SITUACAO_PARAMETROS_TIPO_FISCAL.NENHUM
			) {
				itens.push({
					label: 'Gerar NF-e',
					icon: 'fa fa-file-text-o',
					command: () => {
						gerarNfe(registroSelecionado);
					},
				});
				if (
					podeInserirNFCe &&
					buscarDadosLoginLocalStorage()?.filialConectada?.parametrosFiscalNFCe &&
					buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFCe.serieNfce
				) {
					itens.push({
						label: 'Gerar NFC-e',
						icon: 'fa fa-file-text-o',
						command: () => {
							gerarNfce(registroSelecionado);
						},
					});
				}
			}
		}

		if (itens.length > 0) {
			itens.unshift({
				separator: true,
				visible: true,
			});
		}

		return itens;
	}

	async function finalizarPedido(venda, etapaId) {
		try {
			if (await verificarSeExisteProdutosInativosNaVenda(urlVendaOuOrcamento, venda.id)) {
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}
			asyncFinalizarPedido(venda.id, etapaId, () => {
				onPesquisar();
			});
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, orçamento contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function estornarVenda(vendaId) {
		asyncEstornarPedido(urlVendaOuOrcamento, vendaId, () => {
			onPesquisar();
		});
	}

	function atualizarSituacao(vendaId, situacaoId) {
		asyncAtualizarSituacao(urlVendaOuOrcamento, vendaId, situacaoId, () => {
			onPesquisar();
		});
	}

	function handleSubmit(values, registroSelecionado) {
		onReject({
			...registroSelecionado,
			motivoCancelamento: values,
		});
		setVisibleCancelModal(false);
	}

	function visualizarHistorico() {
		setModalHistoricoVisible(true);
	}

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<Menu model={montarItensMenu()} popup ref={(elemento) => setMenu(elemento)} />
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={props.sortField}
				sortOrder={props.sortOrder}
				onSort={atualizarOrdenacao}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="N°"
					className="step-listagem-order"
					field="numero"
					sortable
					body={renderNumeroField}
					style={{ color: colors.cinzaDark, width: '60px' }}
				/>
				<Column
					header="Data"
					field="emissao"
					sortable
					body={renderEmissaoField}
					style={{ color: colors.cinzaDark, width: '100px' }}
				/>
				<Column
					header="Cliente"
					field="clienteNome"
					sortable
					body={renderClienteField}
					style={{ color: colors.cinzaDark }}
				/>
				{isPedidoVenda() ? (
					<Column
						header="NF-e/NFC-e"
						field="nfe"
						body={renderNotaFiscal}
						style={{
							color: colors.cinzaDark,
							maxWidth: '100px',
						}}
					/>
				) : (
					<Column
						header="Pedido de venda"
						field="pedido"
						body={renderPedidoVenda}
						style={{
							color: colors.cinzaDark,
							maxWidth: '125px',
						}}
					/>
				)}
				<Column
					header="Status"
					field="status"
					sortable
					body={renderStatusField}
					style={{ color: colors.cinzaDark, width: '320px' }}
				/>
				<Column
					header="Valor"
					field="totalLiquido"
					sortable
					body={renderValorField}
					style={{
						color: colors.cinzaDark,
						width: '116px',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						fontWeight: 'bold',
					}}
				/>
				{isPedidoVenda() ? (
					<Column
						header="A receber"
						field="valorAReceber"
						body={renderValorAReceberField}
						style={{
							color: colors.cinzaDark,
							width: '120px',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
					/>
				) : null}
				<Column className="step-listagem-acoes" header="Ações" body={renderAcoesField} style={{ width: '130px' }} />
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
			<If test={modalHistoricoVisible}>
				<VendasHistorico
					status={registroSelecionado && registroSelecionado.situacao?.tipo}
					idVenda={registroSelecionado && registroSelecionado.id}
					visible={modalHistoricoVisible}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					onHide={() => setModalHistoricoVisible(false)}
					vendaOuOrcamento={vendaOuOrcamento}
				/>
			</If>
			<If test={visibleCancelModal}>
				<ModalCancelarVendaForm
					valuesVenda={registroSelecionado}
					visible={visibleCancelModal}
					onHide={() => {
						setVisibleCancelModal(false);
						setEtapaCanceladaModalCancelamento(null);
					}}
					etapaCancelamento={etapaCanceladaModalCancelamento}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					handleSubmitForm={(values) => handleSubmit(values, registroSelecionado)}
					resetFormForm={() => {
						onPesquisar();
					}}
				/>
			</If>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaVendasOrcamentos);
