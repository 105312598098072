import { PagamentosFormPrincipal } from './components/PagamentosFormPrincipal';
import PagamentosProvider  from './Context';

function Pagamentos(props) {
   return (
      <PagamentosProvider>
         <PagamentosFormPrincipal {...props} />
      </PagamentosProvider>
   )
}

export { Pagamentos };