import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';
import NoMatch from 'views/Util/NoMatch';
import Dashboard from '../Dashboard';
import TrocaDeFilial from '../TrocaDeFilial';

export const pathDefaultRoutes = ['/', '*', '/trocar_organizacao_filial'];

function DefaultRoutes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" render={props => <Dashboard {...props} />} validarPermissoes={false} />
      <PrivateRoute exact path="/trocar_organizacao_filial" render={props => <TrocaDeFilial {...props}/>} validarPermissoes={false}/>
      <PrivateRoute path="*" component={NoMatch} />
    </Switch>
  );
}

export default withRouter(DefaultRoutes);
