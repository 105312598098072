import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { buscarDadosLoginLocalStorage, colors, formatarDecimais, formatarMonetarioDecimais } from 'Common';
import { InputDouble, NenhumRegistroEncontrado } from 'components';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

function ListaProdutosEntrega({ isMobile, isTablet, informacoesPermissoes }) {
	const { values, errors, setFieldValue } = useFormikContext();

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const decimaisQtd = filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;
	const hasErrorProduto = errors?.produtos?.length > 0;

	function renderLabelProduto(row) {
		return row.produto ?? ' - ';
	}

	function renderLabelQuantidade(row, options) {
		const color =
			hasErrorProduto && errors?.produtos[options.rowIndex]?.quantidade ? colors.vermelho : colors.cinzaText;
		return (
			<div
				className={`id-quantidade-produto-entrega-listagem-${options.rowIndex}`}
				title={
					hasErrorProduto && errors?.produtos[options.rowIndex]?.quantidade
						? errors?.produtos[options.rowIndex]?.quantidade
						: ''
				}
				style={{
					color: color,
					fontWeight: hasErrorProduto && errors?.produtos[options.rowIndex]?.quantidade ? 'bold' : 'normal',
				}}
			>
				{formatarDecimais(row.quantidade, decimaisQtd)}
			</div>
		);
	}

	function renderLabelValorUnitario(row, options) {
		const color = hasErrorProduto && errors?.produtos[options.rowIndex]?.valor ? colors.vermelho : colors.cinzaText;
		return (
			<div
				className={`id-valor-unitario-produto-entrega-listagem-${options.rowIndex}`}
				title={
					hasErrorProduto && errors?.produtos[options.rowIndex]?.valor ? errors?.produtos[options.rowIndex]?.valor : ''
				}
				style={{ color: color }}
			>
				{formatarMonetarioDecimais(row.valor, decimaisPreco)}
			</div>
		);
	}

	function renderLabelSubtotal(row, options) {
		const color = hasErrorProduto && errors?.produtos[options.rowIndex]?.subtotal ? colors.vermelho : colors.cinzaText;
		return (
			<div
				className={`id-subtotal-produto-entrega-listagem-${options.rowIndex}`}
				title={
					hasErrorProduto && errors?.produtos[options.rowIndex]?.subtotal
						? errors?.produtos[options.rowIndex]?.subtotal
						: ''
				}
				style={{ color: color }}
			>
				{formatarMonetarioDecimais(row.subtotal, decimaisPreco)}
			</div>
		);
	}

	function renderEditQuantidade(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				size={13}
				name="quantidade"
				value={options.value}
				onChange={(event) => options.editorCallback(event.target.value ?? 0)}
				decimalScale={decimaisQtd}
				allowNegative={false}
				errors={errors?.quantidade}
				{...informacoesPermissoes}
			/>
		);
	}

	async function onChangeColumn(e) {
		const produto = e.newRowData;
		if (produto) {
			if (produto.quantidade !== e.rowData.quantidade) {
				produto.subtotal = parseFloat((produto.quantidade * produto.valor).toFixed(2));

				setFieldValue(`produtos[${e.rowIndex}]`, produto);
			}
		}
	}

	return (
		<DataTable
			id="id-produtos-entregues-listagem"
			className="table"
			rowClassName="table-row"
			cellClassName="table-row-cell"
			responsive
			editMode="cell"
			value={values?.produtos}
			style={{
				width: '100%',
				overflowY: values?.length > 6 ? 'scroll' : 'hidden',
				overflowX: 'hidden',
				maxHeight: isMobile ? '36.4rem' : '20.2rem',
			}}
			emptyMessage={<NenhumRegistroEncontrado />}
		>
			<Column
				header="Item"
				field="item"
				body={(row) => row?.item}
				style={{
					...styleColumn,
					width: '40px',
				}}
			/>
			<Column
				header="Produto"
				field="produto"
				body={(row) => renderLabelProduto(row)}
				style={{
					...styleColumn,
				}}
			/>
			<Column
				header="Qtde."
				field="quantidade"
				body={(row, options) => renderLabelQuantidade(row, options)}
				editor={(options) => renderEditQuantidade(options)}
				onCellEditComplete={onChangeColumn}
				style={{
					...styleColumn,
					width: '120px',
				}}
			/>
			<Column
				header="Valor unit."
				field="valor"
				body={(row, options) => renderLabelValorUnitario(row, options)}
				style={{
					...styleColumn,
					width: '140px',
				}}
			/>
			<Column
				header="Total do item"
				field="subtotal"
				body={(row, options) => renderLabelSubtotal(row, options)}
				style={{
					...styleColumn,
					width: '140px',
				}}
			/>
		</DataTable>
	);
}

export { ListaProdutosEntrega };
