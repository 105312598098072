import { useRef } from 'react';
import { useFormikContext } from 'formik';

import {
	alert,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	NormalButton,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import {
	asyncCreatePapel,
	asyncDeletePapel,
	asyncGetPapelComMesmoNome,
	asyncUpdatePapel,
} from 'views/configuracao/Papeis/Requests';
import { possuiPermissoesDePapeis } from 'views/configuracao/Papeis/Util/functions';
import { converterPapelParaApi } from 'views/configuracao/Papeis/Util/papelConverter';
import { Menu } from 'primereact/menu';
import { CADASTROURL, INITIAL_VALUES, PAPEL_TIPO, PESQUISAURL } from '../../../Util/constantes';

function ActionButtons({ history, isModal, asyncSelectRegistro, informacoesPermissoes }) {
	const { dirty, resetForm, initialValues, values, handleSubmit, validateForm, setFieldError, setFieldValue } =
		useFormikContext();
	const menuOpcoes = useRef(null);

	const isNormal = values.tipo === PAPEL_TIPO.NORMAL;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;
	const itensOpcoes = buscaritensOpcoes();

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();

		if ((await validarFormulario({ validateForm, values })) && !(await existePapelComMesmoNome())) {
			if (values.id) {
				await asyncUpdateRegistro(novoOnSuccess);
			} else {
				await asyncCreateRegistro(novoOnSuccess);
			}
		}
	}

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: INITIAL_VALUES });
	}

	function excluir() {
		confirmarExclusao(() => asyncDeleteRegistro());
	}

	function buscaritensOpcoes() {
		const itens = [];
		if (values.id) {
			itens.push({
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => {
					handleClickDuplicar();
				},
			});
		}
		return itens;
	}

	async function existePapelComMesmoNome() {
		const { nome, id } = values;
		let existePapelComMesmoNome = false;
		await asyncGetPapelComMesmoNome(nome, ({ data: papeis }) => {
			papeis.content.forEach((papel) => {
				if (papel.id !== id) {
					existePapelComMesmoNome = true;
					setFieldError('nome', `Já existe um grupo com o nome ${nome}`);
				}
			});
		});
		return existePapelComMesmoNome;
	}

	async function asyncDeleteRegistro() {
		await asyncDeletePapel(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	async function asyncUpdateRegistro(novoOnSuccess) {
		const isAdministrador = values.tipo === PAPEL_TIPO.ADMINISTRADOR;
		if (isAdministrador && !possuiPermissoesDePapeis(values.permissoes)) {
			alert('Atenção', 'Você não pode remover as permissões "Grupos de usuários" do usuário administrador.');
		} else {
			const valuesLocal = converterPapelParaApi(values);
			await asyncUpdatePapel(valuesLocal, () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({ values: valuesLocal });
					asyncSelectRegistro(valuesLocal.id);
				}
			});
		}
	}

	async function asyncCreateRegistro(novoOnSuccess) {
		const valuesLocal = converterPapelParaApi(values);
		await asyncCreatePapel(valuesLocal, ({ data: papel }) => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				asyncSelectRegistro(papel.id);
			}
		});
	}

	function handleClickDuplicar() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: { ...values, id: null } });
		setFieldValue('nome', '');

		setTimeout(() => {
			document.getElementById('PapeisInputNome')?.focus();
		}, 500);
	}

	return (
		<>
			<ButtonCancelar
				onClick={cancelar}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty && !isModal ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar onClick={salvar} disabled={!dirty} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={onClickNovo}
				hidden={(!dirty && !values.id) || isModal}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id}
				onClick={excluir}
				disabled={!isNormal}
				titlePersonalizado={
					!isNormal ? 'Não é possível excluir um papel do tipo administrador ou contabilidade' : 'Excluir o registro'
				}
				{...informacoesPermissoes}
			/>
			<NormalButton
				className="p-button-primary"
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={itensOpcoes.length === 0}
				onClick={(event) => menuOpcoes.current?.toggle(event)}
			/>
			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menuOpcoes} />
		</>
	);
}

export { ActionButtons };
