import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	formatarParaPesquisarTiposEnumerados,
	permissoes,
	recursos,
	replaceCaracteresEspeciais,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	Checkbox,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	notify,
	Paginacao,
	PesquisaAvancada,
	ToastTypes,
	Tutorial,
	tutorialStepsListagens,
} from 'components';

import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { GoBookmark } from 'react-icons/go';
import {
	CADASTRO_URL,
	COLORS,
	OPTIONS_FILTRO_AVANCADO,
	SITUACAO_TIPO_OPTIONS,
	STYLE_BACKGROUND,
} from './Utils/constantes';
import { deleteSituacao, getSituacoes } from './Request';

function SituacoesImpl({ history, isMobile, isTablet }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		page,
		setPage,
		rows,
		setRows,
		sortOrder,
		setSortOrder,
		sortField,
		setSortField,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.CADASTROS_SITUACOES, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CADASTROS_SITUACOES, permissoes.EXCLUIR);

	const sortFieldLocal = sortField?.length > 0 ? sortField : 'nome';

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		const inputSearchSituacoes = document.getElementById('input-search-situacoes');
		setTimeout(() => {
			if (inputSearchSituacoes) {
				inputSearchSituacoes.focus();
			}
		}, 300);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortFieldLocal, filtroAvancado]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function pesquisar() {
		let filtro = getFilter();
		filtro += `${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`;
		const url = construirUrl(
			`${services.GESTOR}/v1/vendas/situacao/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await getSituacoes(
			url,
			({ data }) => {
				setRegistros(data.content);
				setTotalElements(data.page.totalElements);
				setFirstRender(false);
			},
			() => {
				setFirstRender(false);
			}
		);
	}

	function getFilter() {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(replaceCaracteresEspeciais(valorPesquisa));

		let filtroRSQL = String('?query=(')
			.concat(`nome=contains="*${valorTiposEnumerados}*",`)
			.concat(`textoBotao=contains="*${valorTiposEnumerados}*")`);

		if (filtroAvancado) {
			filtroRSQL += `;${filtroAvancado}`;
		}

		return filtroRSQL;
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function handleClickNovo() {
		atualizarUrl(history, `/${CADASTRO_URL}`);
	}

	function onChangeCheckboxExibirInativos(element) {
		setExibirBloqueadas(element.checked);
	}

	function renderNome(row) {
		const styleBadge = {
			borderRadius: '20px',
			fontWeight: 'bold',
			fontSize: '14px',
			display: 'flex',
			height: '1.8rem',
			width: isMobile || isTablet ? 'none' : '100%',
			padding: isMobile || isTablet ? '4px 6px' : 'none',
			alignItems: 'center',
			justifyContent: 'center',
		};

		return (
			<span style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '8px' }}>
				<span
					style={{
						display: 'flex',
						alignItems: 'center',
						width: isMobile || isTablet ? '100%' : row.situacaoInicial ? '100%' : 'calc(100% - 30px)',
					}}
				>
					{Badge(row.corTexto, row.corFundo, row.nome, styleBadge, null, isMobile, row.nome, isMobile ? '200px' : null)}
				</span>
				{row.situacaoInicial ? <GoBookmark size={22} color="#fbc02d" title="situação inicial" /> : null}
			</span>
		);
	}

	function renderTextoBotao(row) {
		return <span title={row.textoBotao}>{row.textoBotao || ''}</span>;
	}

	function renderTipo(row) {
		return SITUACAO_TIPO_OPTIONS.find((situacao) => situacao.value === row.tipo)?.label;
	}

	function renderSituacao(row) {
		const styleDescription = {
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(COLORS.textAtivo, COLORS.bgAtivo, 'Ativo', STYLE_BACKGROUND, styleDescription);
			}
			case 'INATIVO': {
				return Badge(COLORS.textInativo, COLORS.bgInativo, 'Inativo', STYLE_BACKGROUND, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} />
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function handleClickEditar(row) {
		atualizarUrl(history, `/${CADASTRO_URL}`, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteSituacao(
			registro.id,
			() => {
				pesquisarCallback();
				notify('Situação removida com sucesso', ToastTypes.SUCCESS);
			},
			() => {
				notify('Falha ao remover o registro', ToastTypes.ERROR);
			}
		);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{field}
			</span>
		);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header="Situações">
				<FormActions>
					<Button
						className="step-listagem-novo p-button-primary"
						label="Nova situação"
						icon="fa fa-plus"
						title="Inserir uma nova situação"
						onClick={handleClickNovo}
						podeInserir={podeInserir}
						disabled={!podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-situacoes"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={OPTIONS_FILTRO_AVANCADO}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql, () => {})}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<span>
									<Checkbox
										name="exibirBloqueadas"
										inputId="exibirBloqueadas"
										checked={exibirBloqueadas}
										onChange={onChangeCheckboxExibirInativos}
										label="Exibir situações inativas"
									/>
								</span>
							</div>
						}
					>
						<Column field="nome" header="Nome" body={(row) => aplicarEstiloInativa(row, renderNome(row))} sortable />
						<Column
							field="textoBotao"
							header="Texto botao"
							body={(row) => aplicarEstiloInativa(row, renderTextoBotao(row))}
							sortable
						/>
						<Column
							field="tipo"
							header="Tipo"
							body={(row) => aplicarEstiloInativa(row, renderTipo(row))}
							sortable
							style={{ width: '8rem' }}
						/>
						<Column
							field="situacao"
							header="Situação"
							body={(row) => aplicarEstiloInativa(row, renderSituacao(row))}
							style={{ width: '8rem' }}
						/>
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7rem' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
		isTablet: state.dispositivo.isTablet,
	};
}

export const Situacoes = connect(mapStateToProps)(SituacoesImpl);
