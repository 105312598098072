import { gerarUUID } from 'Common';

// to api ---------------------------------------------------------------------------------

function converterToApi(data) {	
	return {
		id: data.id ?? gerarUUID(),
		numero: data.numero,
		situacao: data.situacao,
		emissao: data.dataEmissao,
		prestador: data.prestador,
		erros: data.erros,
		tomador: converterTomatorToApi(data.tomador?.registro),
		operacaoFiscal: {
			id: data.operacaoFiscal?.registro?.id,
		},
		comissaoTotalPercentual: data.comissaoTotalPercentual,
		comissaoTotalValor: data.comissaoTotalValor,
		servicos: converterServicoToApi(data.servicos),
		total: {
			id: data.totalizadores.id ?? gerarUUID(),
			valorBruto: data.totalizadores.valorBruto,
			tipoDesconto: data.totalizadores.tipoDesconto,
			desconto: data.totalizadores.desconto,
			valorLiquido: data.totalizadores.valorLiquido,
			baseIss: data.totalizadores.baseIss,
			valorIss: data.totalizadores.valorIss,
			baseIr: data.totalizadores.baseIr,
			valorIr: data.totalizadores.valorIr,
			baseCsll: data.totalizadores.baseCsll,
			valorCsll: data.totalizadores.valorCsll,
			baseInss: data.totalizadores.baseInss,
			valorInss: data.totalizadores.valorInss,
			basePis: data.totalizadores.basePis,
			valorPis: data.totalizadores.valorPis,
			baseCofins: data.totalizadores.baseCofins,
			valorCofins: data.totalizadores.valorCofins,
		},
		pagamentos: converterPagamentoParaApi(data.pagamentos),
		informacaoComplementar: converterInformacoesComplementaresToApi(data.informacoesComplementares),
	};
}

function converterPagamentoParaApi(pagamentos) {
	const pagamentosToApi = [];

	if (pagamentos.length > 0) {
		pagamentos.forEach((pagamento) => {
			pagamentosToApi.push({
				id: pagamento.id,
				sequencial: pagamento.sequencial,
				condicaoPagamento: converterObjectParaApi(pagamento.condicaoPagamento),
				conta: converterObjectParaApi(pagamento.conta),
				formaPagamento: converterObjectParaApi(pagamento.formaPagamento),
				categorias: converterPagamentoCategoriasParaApi(pagamento.categorias),
				quantidadeParcelas: pagamento.quantidadeParcelas?.value ?? 0,
				valor: pagamento.valor,
				parcelas: converterParcelasParaApi(pagamento.parcelas),
			});
		});
	}

	return pagamentosToApi;
}

function converterPagamentoCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const nfseCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(nfseCategoria);
		});
	}
	return categorias;
}

function converterParcelasParaApi(parcelas) {
	const parcelasToApi = [];

	if (parcelas.length > 0) {
		parcelas.forEach((parcela) => {
			parcelasToApi.push({
				id: parcela.id,
				sequencial: parcela.sequencial,
				vencimento: parcela.vencimento,
				formaPagamento: converterObjectParaApi(parcela.formaPagamento),
				conta: converterObjectParaApi(parcela.conta),
				descricao: parcela.descricao,
				valor: parcela.valor,
			});
		});
	}

	return parcelasToApi;
}

function converterCnaeToApi(data) {
	if (Array.isArray(data)) {
		const cnaeFavorito = data.find((cnae) => cnae.favorito);
		if (cnaeFavorito) {
			return cnaeFavorito.codigo;
		}
		if (data.length > 0) {
			return data[0].codigo;
		}
	} else if (data !== null) {
		return data;
	}
	return null;
}

function converterInformacoesComplementaresToApi(data) {
	return {
		setor: data.setor?.registro?.id
			? {
					id: data.setor?.registro?.id,
				}
			: null,
		vendedor: data.vendedor?.registro?.id
			? {
					id: data.vendedor?.registro?.id,
				}
			: null,
		tabelaPreco: data.tabelaPreco?.registro?.id
			? {
					id: data.tabelaPreco?.registro?.id,
				}
			: null,
		municipio: data.municipio?.registro?.id
			? {
					id: data.municipio?.registro?.id,
				}
			: null,
		servicoInformacaoComplementar: data.informacaoComplementar,
		servicoObservacaoInterna: data.observacaoInterna,
		numeroRps: data.numeroRPS,
		loteRps: data.loteRPS,
		protocolo: data.protocolo,
	};
}

function converterEmailToApi(data) {
	if (Array.isArray(data)) {
		const enderecoFavorito = data.find((email) => email.favorito);
		if (enderecoFavorito) {
			return enderecoFavorito.email;
		}
		if (data.length > 0) {
			return data[0].email;
		}
	} else if (data !== null) {
		return data;
	}
	return null;
}

function converterEnderecoToApi(data) {
	if (Array.isArray(data)) {
		const favoritoEndereco = data.find((endereco) => endereco.favorito);
		if (favoritoEndereco) {
			return {
				id: favoritoEndereco.id || null,
				logradouro: favoritoEndereco.logradouro,
				numero: favoritoEndereco.numero,
				complemento: favoritoEndereco.complemento,
				bairro: favoritoEndereco.bairro,
				cep: favoritoEndereco.cep,
				municipio: {
					id: favoritoEndereco.municipio.value ?? favoritoEndereco.municipio.registro?.id ?? null,
				},
				pais: {
					id: favoritoEndereco.pais.value ?? favoritoEndereco.municipio.registro?.id ?? null,
				},
				fone: favoritoEndereco.fone ?? favoritoEndereco.telefone,
			};
		}
		if (data.length > 0) {
			const firstEndereco = data[0];
			return {
				id: firstEndereco.id || null,
				logradouro: firstEndereco.logradouro,
				numero: firstEndereco.numero,
				complemento: firstEndereco.complemento,
				bairro: firstEndereco.bairro,
				cep: firstEndereco.cep,
				municipio: {
					id: firstEndereco.municipio.value ?? firstEndereco.municipio.registro?.id ?? null,
				},
				pais: {
					id: firstEndereco.pais.value ?? firstEndereco.municipio.registro?.id ?? null,
				},
				fone: firstEndereco.fone ?? firstEndereco.telefone,
			};
		}
	} else if (data !== null) {
		return {
			id: data.id || null,
			logradouro: data.logradouro,
			numero: data.numero,
			complemento: data.complemento,
			bairro: data.bairro,
			cep: data.cep,
			fone: data.telefone,
			municipio: {
				id: data.municipio.value ?? data.municipio.registro?.id ?? null,
			},
			pais: {
				id: data.pais.value ?? data.pais.registro?.id ?? null,
			},
		};
	}
	return null;
}

function converterServicoToApi(data) {
	const servicos = data.map((servico) => {
		if (servico.servico) {
			return {
				id: servico.id,
				item: servico.item,
				servico: {
					id: servico.servico.value ?? null,
				},
				descricao: servico.descricao,
				quantidade: servico.quantidade,
				valor: servico.valor,
				valorOriginal: servico.valorOriginal ?? 0,
				subtotal: servico.subtotal,

				utilizouTabelaPromocional: servico.utilizouTabelaPromocional,
				comissaoPercentual: servico.comissaoPercentual ?? 0,
				comissaoValor: servico.comissaoValor ?? 0,
				descontoMaximoPercentual: servico.descontoMaximoPercentual ?? 0,
				descontoMaximoVendedor: servico.descontoMaximoVendedor ?? 0,
				descontoMaximoTabela: servico.descontoMaximoTabela ?? 0,
				percentualAdicionalTabelaPreco: servico.adicionalTabelaPreco?.percentual,
				tipoPercentualAdicionalTabelaPreco: servico.adicionalTabelaPreco?.tipo,

				operacaoFiscal: {
					id: servico.operacaoFiscal?.value ?? null,
				},
				desconto: servico.desconto,
				unidadeMedida: {
					id: servico.unidadeMedida?.value ?? null,
				},
				codigoServico: {
					id: servico.codigoServico?.value ?? null,
				},
				impostoServico: {
					id: gerarUUID(),
					percentualBaseCalculoIss: servico.tributos?.iss?.percentualBase ?? 0,
					valorBaseCalculoIss: servico.tributos?.iss?.valorBase ?? 0,
					aliquotaIss: servico.tributos?.iss?.percentual ?? 0,
					valorIss: servico.tributos?.iss?.valor ?? 0,

					percentualBaseCalculoIr: servico.tributos?.ir?.percentualBase ?? 0,
					valorBaseCalculoIr: servico.tributos?.ir?.valorBase ?? 0,
					aliquotaIr: servico.tributos?.ir?.percentual ?? 0,
					valorIr: servico.tributos?.ir?.valor ?? 0,

					percentualBaseCalculoCsll: servico.tributos?.csll?.percentualBase ?? 0,
					valorBaseCalculoCsll: servico.tributos?.csll?.valorBase ?? 0,
					aliquotaCsll: servico.tributos?.csll?.percentual ?? 0,
					valorCsll: servico.tributos?.csll?.valor ?? 0,

					percentualBaseCalculoInss: servico.tributos?.inss?.percentualBase ?? 0,
					valorBaseCalculoInss: servico.tributos?.inss?.valorBase ?? 0,
					aliquotaInss: servico.tributos?.inss?.percentual ?? 0,
					valorInss: servico.tributos?.inss?.valor ?? 0,

					cstPis: {
						id: servico.tributos?.pis?.cst?.value ?? null,
					},
					percentualBaseCalculoPis: servico.tributos?.pis?.percentualBase ?? 0,
					valorBaseCalculoPis: servico.tributos?.pis?.valorBase ?? 0,
					aliquotaPis: servico.tributos?.pis?.percentual ?? 0,
					valorPis: servico.tributos?.pis?.valor ?? 0,

					cstCofins: {
						id: servico.tributos?.cofins?.cst?.value ?? null,
					},
					percentualBaseCalculoCofins: servico.tributos?.cofins?.percentualBase ?? 0,
					valorBaseCalculoCofins: servico.tributos?.cofins?.valorBase ?? 0,
					aliquotaCofins: servico.tributos?.cofins?.percentual ?? 0,
					valorCofins: servico.tributos?.cofins?.valor ?? 0,
				},
			};
		}

		return null;
	});

	return servicos.filter((servico) => servico !== null);
}

function converterTomatorToApi(data) {
	return {
		id: data.id,
		idTomador: data.idTomador,
		tipo: data.tipo,
		cpf: data.cpf ?? data.pessoaFisica?.cpf ?? null,
		cnpj: data.cnpj ?? data.pessoaJuridica?.cnpj ?? null,
		idEstrangeiro: data.pessoaJuridica?.idEstrangeiro ?? null,
		nome: data.nome,
		nomeFantasia: data.nomeFantasia ?? null,
		endereco: converterEnderecoToApi(data.enderecos ?? data.endereco ?? null),
		email: converterEmailToApi(data.emails ?? data.email ?? null),
		cnae: converterCnaeToApi(data.cnaes ?? null),
	};
}

// to form-------------------------------------------------------------------------------------------------------------

function converterToForm(data) {
	return {
		id: data.id,
		numero: data.numero,
		situacao: data.situacao,
		dataEmissao: data.emissao,
		prestador: data.prestador,
		tomador: converterTomadorToForm(data.tomador),
		operacaoFiscal: converterOperacaoFiscalToForm(data.operacaoFiscal),
		servicos: converterServicoToForm(data.servicos),
		ordemServico: data.ordemServico ?? null,
		comissaoTotalPercentual: data.comissaoTotalPercentual,
		comissaoTotalValor: data.comissaoTotalValor,
		erros: data.erros,
		totalizadores: {
			id: data.total.id ?? null,
			valorBruto: data.total.valorBruto,
			tipoDesconto: data.total.tipoDesconto,
			desconto: data.total.desconto,
			valorLiquido: data.total.valorLiquido,
			baseIss: data.total.baseIss,
			valorIss: data.total.valorIss,
			baseIr: data.total.baseIr,
			valorIr: data.total.valorIr,
			baseCsll: data.total.baseCsll,
			valorCsll: data.total.valorCsll,
			baseInss: data.total.baseInss,
			valorInss: data.total.valorInss,
			basePis: data.total.basePis,
			valorPis: data.total.valorPis,
			baseCofins: data.total.baseCofins,
			valorCofins: data.total.valorCofins,
		},
		pagamentos: converterPagamentosToForm(data.pagamentos, data.tomador),
		informacoesComplementares: {
			setor: converterSetorToForm(data.informacaoComplementar.setor),
			vendedor: converterVendedorToForm(data.informacaoComplementar.vendedor),
			tabelaPreco: converterTabelaPrecoParaFormulario(data.informacaoComplementar.tabelaPreco),
			municipio: converterMunicipioToForm(data.informacaoComplementar.municipio),
			informacaoComplementar: data.informacaoComplementar.servicoInformacaoComplementar,
			observacaoInterna: data.informacaoComplementar.servicoObservacaoInterna,
			numeroRPS: data.informacaoComplementar.numeroRps,
			loteRPS: data.informacaoComplementar.loteRps,
			protocolo: data.informacaoComplementar.protocolo,
		},
	};
}

function converterPagamentosToForm(pagamentos, tomador) {
	const newPagamentos = [];

	if (pagamentos.length > 0) {
		pagamentos.forEach((pagamento) => {
			newPagamentos.push({
				id: pagamento.id,
				tempKey: gerarUUID(),
				sequencial: pagamento.sequencial,
				condicaoPagamento: converterCondicaoPagamentoToForm(pagamento.condicaoPagamento),
				conta: converterValueParaSelect(pagamento.conta),
				formaPagamento: converterValueParaSelect(pagamento.formaPagamento),
				categorias: converterPagamentoCategoriasParaFormulario(pagamento.categorias),
				quantidadeParcelas:
					pagamento.quantidadeParcelas > 0
						? {
								label: `${pagamento.quantidadeParcelas}x`,
								value: pagamento.quantidadeParcelas,
							}
						: null,
				valor: pagamento.valor ?? 0,
				valorPago: pagamento.valorPago ?? 0,
				parcelas: converterParcelasToForm(pagamento.parcelas, tomador),
			});
		});
	}

	return newPagamentos;
}

function converterPagamentoCategoriasParaFormulario(values){
	const categorias = [];
	if (values) {
		values?.forEach(registro =>{
			const nfseCategoria = {
				categoria: converterCategoriaParaFormulario(registro.categoria),
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(nfseCategoria);
		});
	}
	return categorias;
}

function converterCategoriaParaFormulario(categoriaApi) {
	if (categoriaApi) {
		return {
			label: categoriaApi.nome,
			value: categoriaApi.id,
			registro: categoriaApi,
		};
	}
	return null;
}

function converterCondicaoPagamentoToForm(condicao) {
	if (condicao) {
		return {
			label: condicao.descricao,
			value: condicao.id,
			registro: condicao,
		};
	}
	return null;
}

function converterParcelasToForm(parcelas, tomador) {
	const newParcelas = [];

	if (parcelas.length > 0) {
		parcelas.forEach((parcela, index) => {
			newParcelas.push({
				id: parcela.id ?? null,
				tempKey: gerarUUID(),
				sequencial: parcela.sequencial ?? index + 1,
				temBoleto: parcela.temBoleto ?? false,
				temPix: parcela.temPix ?? false,
				vencimento: parcela.vencimento ?? null,
				formaPagamento: converterValueParaSelect(parcela.formaPagamento),
				conta: converterValueParaSelect(parcela.conta),
				descricao: parcela.descricao ?? null,
				valor: parcela.valor ?? 0,
				financeiroIdentificador: parcela.financeiroIdentificador ?? null,
				financeiroId: parcela.financeiroId ?? null,
				financeiroStatus: parcela.financeiroStatus ?? null,
				valorRestante: parcela.valorRestante ?? 0,
				pessoaNome: tomador.nome,
				pessoaCodigo: tomador.codigo,
				pessoaEmail: converterTomadorEmailToForm(tomador.emails),
			});
		});
	}

	return newParcelas;
}

function converterTomadorEmailToForm(emails) {
	let emailFavorito = '';
	if (emails?.length > 0) {
		emails?.forEach((email) => {
			emailFavorito = email.favorito === true ? `${email.email}` : null;
		});
	}

	if (emailFavorito) {
		return emailFavorito;
	} else if (emails?.length > 0) {
		return emails[0]?.email;
	}
	return null;
}

function converterServicoToForm(data) {
	return data.map((servico) => ({
		id: servico.id,
		item: servico.item,
		servico: {
			label: servico.servico.nome,
			value: servico.servico.id,
			registro: servico.servico,
		},
		descricao: servico.descricao,
		quantidade: servico.quantidade,
		valor: servico.valor,
		valorOriginal: servico.valorOriginal ?? 0,
		subtotal: servico.subtotal,
		operacaoFiscal: converterOperacaoFiscalToForm(servico.operacaoFiscal),
		codigoServico: converterCodigoServicoToForm(servico.codigoServico),
		unidadeMedida: converterUnidadeMedidaToForm(servico.unidadeMedida),
		desconto: servico.desconto,

		utilizouTabelaPromocional: servico.utilizouTabelaPromocional ?? false,
		descontoMaximoPercentual: servico.descontoMaximoPercentual ?? 0,
		descontoMaximoVendedor: servico.descontoMaximoVendedor ?? 0,
		descontoMaximoTabela: servico.descontoMaximoTabela ?? 0,
		comissaoValor: servico.comissaoValor ?? 0,
		comissaoPercentual: servico.comissaoPercentual ?? 0,
		adicionalTabelaPreco: {
			percentual: servico.percentualAdicionalTabelaPreco ?? 0,
			tipo: servico.tipoPercentualAdicionalTabelaPreco,
		},
		tributos: {
			iss: {
				percentualBase: servico.impostoServico.percentualBaseCalculoIss,
				valorBase: servico.impostoServico.valorBaseCalculoIss,
				percentual: servico.impostoServico.aliquotaIss,
				valor: servico.impostoServico.valorIss,
			},
			ir: {
				percentualBase: servico.impostoServico.percentualBaseCalculoIr,
				valorBase: servico.impostoServico.valorBaseCalculoIr,
				percentual: servico.impostoServico.aliquotaIr,
				valor: servico.impostoServico.valorIr,
			},
			csll: {
				percentualBase: servico.impostoServico.percentualBaseCalculoCsll,
				valorBase: servico.impostoServico.valorBaseCalculoCsll,
				percentual: servico.impostoServico.aliquotaCsll,
				valor: servico.impostoServico.valorCsll,
			},
			inss: {
				percentualBase: servico.impostoServico.percentualBaseCalculoInss,
				valorBase: servico.impostoServico.valorBaseCalculoInss,
				percentual: servico.impostoServico.aliquotaInss,
				valor: servico.impostoServico.valorInss,
			},
			pis: {
				cst: converterCstToForm(servico.impostoServico.cstPis),
				percentualBase: servico.impostoServico.percentualBaseCalculoPis,
				valorBase: servico.impostoServico.valorBaseCalculoPis,
				percentual: servico.impostoServico.aliquotaPis,
				valor: servico.impostoServico.valorPis,
			},
			cofins: {
				cst: converterCstToForm(servico.impostoServico.cstCofins),
				percentualBase: servico.impostoServico.percentualBaseCalculoCofins,
				valorBase: servico.impostoServico.valorBaseCalculoCofins,
				percentual: servico.impostoServico.aliquotaCofins,
				valor: servico.impostoServico.valorCofins,
			},
		},
	}));
}

function converterUnidadeMedidaToForm(data) {
	if (data) {
		if (data.value) {
			return { ...data };
		} else {
			return {
				label: `${data.unidade  } - ${  data.descricao}`,
				value: data.id,
				registro: data,
			};
		}
	}
	return null;
}

function converterCodigoServicoToForm(data) {
	if (data) {
		if (data.registro) {
			return {
				label: `${data.registro?.codigo  } - ${  data.registro?.descricao}`,
				value: data.registro?.id,
				registro: data.registro,
			};
		} else {
			return {
				label: `${data.codigo  } - ${  data.descricao}`,
				value: data.id,
				registro: data,
			};
		}
	}
	return null;
}

function converterOperacaoFiscalToForm(data) {
	if (data) {
		return {
			label: data.descricao,
			value: data.id,
			registro: data,
		};
	}
	return null;
}

function converterTomadorToForm(data) {
	if (data) {
		return {
			label: `${data?.nome} - ${data?.cpf ?? data?.cnpj}`,
			value: data?.idTomador ?? data?.id ?? null,
			registro: {
				id: data.id ?? null,
				idTomador: data.idTomador ?? null,
				tipo: data.tipo ?? null,
				cpf: data?.cpf ?? null,
				cnpj: data?.cnpj ?? null,
				idEstrangeiro: data?.idEstrangeiro ?? null,
				nome: data.nome ?? null,
				nomeFantasia: data.nomeFantasia ?? null,
				endereco: converterEnderecoToForm(
					data.endereco ?? data.enderecos ?? null,
					converterTelefoneToForm(data.telefone ?? data.telefones ?? data.fone)
				),
				email: converterEmailToForm(data.email ?? data.emails ?? null),
				cnae: converterCnaeToForm(data.cnae ?? data.cnaes ?? null),
			},
		};
	}
	return null;
}

function converterMunicipioToForm(data) {
	if (data) {
		if (data.value) {
			return data;
		} else {
			return {
				label: `${data?.nome} - ${data.estadoSigla ?? data.estado?.sigla}`,
				value: data.id,
				registro: data,
			};
		}
	}
	return null;
}

function converterPaisToForm(data) {
	if (data) {
		if (data.value) {
			return data;
		} else {
			return {
				label: data.nome,
				value: data.id,
				registro: data,
			};
		}
	}
	return null;
}

function converterSetorToForm(data) {
	if (data) {
		return { label: data.nome, value: data.id, registro: data };
	}
	return null;
}

function converterVendedorToForm(data) {
	if (data) {
		return { label: `${data.codigo} - ${data.nome}`, value: data.id, registro: data };
	}
	return null;
}

function converterTabelaPrecoParaFormulario(data) {
	if (data) {
		return { label: `${data.codigo} - ${data.nome}`, value: data.id, registro: data };
	}
	return null;
}

function converterCstToForm(data) {
	if (data) {
		return {
			label: `${data.codigo  } - ${  data.descricao}`,
			value: data.id,
			registro: data,
		};
	}

	return null;
}

function converterTelefoneToForm(data) {
	if (Array.isArray(data)) {
		const telefoneFavorito = data.find((telefone) => telefone.favorito);
		if (telefoneFavorito) {
			return telefoneFavorito.numero;
		}
		if (data.length > 0) {
			return data[0].numero;
		}
	} else if (data !== null) {
		return data;
	}
	return null;
}

function converterCnaeToForm(data) {
	if (Array.isArray(data)) {
		const cnaeFavorito = data.find((cnae) => cnae.favorito);
		if (cnaeFavorito) {
			return `${cnaeFavorito.codigo  } - ${  cnaeFavorito.descricao}`;
		}
		if (data.length > 0) {
			return `${data[0].codigo  } - ${  data[0].descricao}`;
		}
	} else if (data !== null) {
		return data;
	}
	return null;
}

function converterEmailToForm(data) {
	if (Array.isArray(data)) {
		const emailFavorito = data.find((email) => email.favorito);
		if (emailFavorito) {
			return emailFavorito.email;
		}
		if (data.length > 0) {
			return data[0].email;
		}
	} else if (data !== null) {
		return data;
	}
	return null;
}

function converterEnderecoToForm(data, telefone) {
	if (Array.isArray(data)) {
		const enderecoFavorito = data.find((endereco) => endereco.favorito);
		if (enderecoFavorito) {
			return {
				id: enderecoFavorito.id ?? null,
				cep: enderecoFavorito.cep ?? null,
				logradouro: enderecoFavorito.logradouro ?? null,
				numero: enderecoFavorito.numero ?? null,
				complemento: enderecoFavorito.complemento ?? null,
				bairro: enderecoFavorito.bairro ?? null,
				municipio: converterMunicipioToForm(enderecoFavorito.municipio ?? null),
				pais: converterPaisToForm(enderecoFavorito.pais ?? null),
				telefone:
					telefone ??
					converterTelefoneToForm(enderecoFavorito.telefone ?? enderecoFavorito.telefone ?? enderecoFavorito.fone),
			};
		}
		if (data.length > 0) {
			const endereco = data[0];
			return {
				id: endereco.id ?? null,
				cep: endereco.cep ?? null,
				logradouro: endereco.logradouro ?? null,
				numero: endereco.numero ?? null,
				complemento: endereco.complemento ?? null,
				bairro: endereco.bairro ?? null,
				municipio: converterMunicipioToForm(endereco.municipio ?? null),
				pais: converterPaisToForm(endereco.pais ?? null),
				telefone: telefone ?? converterTelefoneToForm(endereco.telefone ?? endereco.telefone ?? endereco.fone),
			};
		}
	} else if (data !== null) {
		return {
			id: data.id ?? null,
			cep: data.cep ?? null,
			logradouro: data.logradouro ?? null,
			numero: data.numero ?? null,
			complemento: data.complemento ?? null,
			bairro: data.bairro ?? null,
			municipio: converterMunicipioToForm(data.municipio ?? null),
			pais: converterPaisToForm(data.pais ?? null),
			telefone: telefone ?? converterTelefoneToForm(data.telefone ?? data.telefone ?? data.fone),
		};
	}
	return null;
}

function converterTomadorToModalForm(data) {
	return {
		id: data.id ?? null,
		idTomador: data.idTomador ?? null,
		tipo: data.tipo ?? null,
		cpf: data.pessoaFisica?.cpf ?? null,
		cnpj: data.pessoaJuridica?.cnpj ?? null,
		idEstrangeiro: data.idEstrangeiro ?? null,
		nome: data.nome ?? null,
		nomeFantasia: data.nomeFantasia ?? null,
		endereco: converterEnderecoToForm(
			data.endereco ?? data.enderecos ?? null,
			converterTelefoneToForm(data.telefone ?? data.telefones ?? null)
		),
		email: converterEmailToForm(data.email ?? data.emails ?? null),
		cnae: converterCnaeToForm(data.cnae ?? data.cnaes ?? null),
	};
}

function converterSelectServicoToForm(data) {
	return {
		id: gerarUUID(),
		item: 1,
		servico: {
			label: data.label,
			value: data.value,
			registro: data.registro,
		},
		quantidade: 1,
		valor: 0,
		subtotal: 0,
		operacaoFiscal: null,
		desconto: 0,
		unidadeMedida: converterUnidadeMedidaToForm(data.registro.unidadeMedida),
		codigoServico: converterCodigoServicoToForm(data.registro.codigoServico),

		iss: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		ir: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		csll: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		inss: {
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		pis: {
			id: null,
			cst: {
				label: '07 - Operação Isenta da Contribuição',
				value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
				registro: {
					id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
					codigo: '07',
					descricao: 'Operação Isenta da Contribuição',
				},
			},
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
		cofins: {
			id: null,
			cst: {
				label: '07 - Operação Isenta da Contribuição',
				value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
				registro: {
					id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
					codigo: '07',
					descricao: 'Operação Isenta da Contribuição',
				},
			},
			percentualBase: 0,
			valorBase: 0,
			percentual: 0,
			valor: 0,
		},
	};
}

// funções adicionais-------------------------------------------------------------------------------------------------------------

function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}

function converterObjectParaApi(value) {
	return value
		? {
				id: value.value ?? value.id,
			}
		: null;
}

export {
	converterToApi,
	converterToForm,
	converterSelectServicoToForm,
	converterTomadorToForm,
	converterPaisToForm,
	converterMunicipioToForm,
	converterTomadorToModalForm,
};
