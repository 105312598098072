import { colors } from 'Common';
import { tipoCampos } from '../../../../../components/PesquisaAvancada/Util/constantes';

export const OptionsFiltroAvancadoOrcamento = [
	{ label: 'Número', name: 'numero', type: tipoCampos.INTEGER },
	{
		label: 'Status',
		name: 'status',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Finalizada', value: 'FINALIZADO' },
			{ label: 'Cancelada', value: 'CANCELADO' },
			{ label: 'Expirada', value: 'EXPIRADO' },
		],
	},
	{ label: 'Valor', name: 'totalLiquido', type: tipoCampos.DECIMAL },
];

export const OptionsFiltroAvancadoPedido = [
	{ label: 'Número', name: 'numero', type: tipoCampos.INTEGER },
	{
		label: 'Situação (tipo)',
		name: 'situacao.tipo',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Pendente', value: 'PENDENTE' },
			{ label: 'Finalizada', value: 'FINALIZADO' },
			{ label: 'Cancelada', value: 'CANCELADO' },
		],
	},
	{ label: 'Valor', name: 'totalLiquido', type: tipoCampos.DECIMAL },
];

export const ColorsCard = {
	ORCAMENTO: {
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	PEDIDO_VENDA: {
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	CANCELADO: {
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
	TOTAL: {
		lightColor: '#e0e0e0',
		strongColor: '#000000de',
	},
};

export const colorsStatus = {
	PENDENTE: {
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	FINALIZADO: {
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	PARCIALMENTE_DEVOLVIDO: {
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	DEVOLVIDO: {
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	CANCELADO: {
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
	EXPIRADO: {
		lightColor: '#e0e0e0',
		strongColor: '#000000de',
	},
};

export const Status = {
	PENDENTE: 'PENDENTE',
	FINALIZADO: 'FINALIZADO',
	CANCELADO: 'CANCELADO',
	EXPIRADO: 'EXPIRADO',
};

export const SITUACAO_NOME = {
	PENDENTE: 'Pendente',
	FINALIZADO: 'Finalizado',
	PARCIALMENTE_DEVOLVIDO: 'Parcialmente devolvido',
	PARCIALMENTE_ENTREGUE: 'Parcialmente entregue',
	DEVOLVIDO: 'Devolvido',
	ENTREGUE: 'Entregue',
	CANCELADO: 'Cancelado',
	EXPIRADO: 'Expirado',
};

export const SITUACAO_DEVOLUCAO = {
	NAO_DEVOLVIDO: 'NAO_DEVOLVIDO',
	PARCIALMENTE_DEVOLVIDO: 'PARCIALMENTE_DEVOLVIDO',
	DEVOLVIDO: 'DEVOLVIDO',
};

export const SITUACAO_ENTREGA = {
	NAO_ENTREGUE: 'NAO_ENTREGUE',
	PARCIALMENTE_ENTREGUE: 'PARCIALMENTE_ENTREGUE',
	ENTREGUE: 'ENTREGUE',
};

export const Tipo = {
	PEDIDO_VENDA: 'PEDIDO_VENDA',
	ORCAMENTO: 'ORCAMENTO',
	NOTA_FISCAL: 'NOTA_FISCAL',
};

export const Operacao = {
	INSERIR: 'INSERIR',
	EDITAR: 'EDITAR',
	EXCLUIR: 'EXCLUIR',
};

export const PESQUISAURL = '/orcamento';
