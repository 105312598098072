import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TabPanel } from 'primereact/tabview';

import {
	mensagensDeValidacao,
	recursos,
	permissoes,
	estadosCadastro,
	validarUUID,
	usuarioPossuiPermissao,
} from 'Common';
import { Prompt, FormActions, FormContent, Form, TabView } from 'components';

import { atualizarUrl, metodosAtualizarUrl } from '../../../Util';
import { asyncGetAdmPlano } from './Requests';
import { CADASTROURL, INITIAL_VALUES } from '../Util/constantes';
import { converterEstruturaParaTree, converterPlanoParaFormulario } from '../Util/converter';
import { asyncBuscarEstruturaRecursos } from '../Requests';
import { ActionButtons } from './components/ActionButtons';
import { CamposPrincipais } from './components/PlanosCamposPrincipais';
import { PlanosLimites } from './components/PlanosLimites';
import { PlanosRecursos } from './components/PlanosRecursos';

function AdministracaoPlanosFormImpl({ isModal, match, history, hideModal }) {
	const { values, dirty, setFieldValue, resetForm } = useFormikContext();
	const [tabViewIndex, setTabViewIndex] = useState(0);
	const [estruturaTree, setEstruturaTree] = useState([]);
	const [fieldCheckboxes, setFieldCheckboxes] = useState({
		usuarios: false,
		armazenamento: false,
		cadastrosPessoas: false,
		cadastrosProdutos: false,
		documentosMensais: false,
		contasFinanceiras: false,
		orcamentosMensais: false,
		pedidosMensais: false,
		nfesMensais: false,
		nfcesMensais: false,
		comprasMensais: false,
		ordensMensais: false,
		nfsesMensais: false,
		mdfesMensais: false,
		ctesMensais: false,
		boletosMensais: false,
	});

	const podeInserir = usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.EXCLUIR);
	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};
	const planoIlimitado = values.ilimitado === true;

	useEffect(() => {
		carregarEstruturaRecurso();
		setTimeout(() => {
			document.getElementById('PlanosInputFieldDescricao')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		loadCheckboxes();
	}, [values]);

	useEffect(() => {
		fetchData();
	}, [estruturaTree]);

	function fetchData() {
		const id = match && match.params.id;
		const fetchData = async () => {
			if (validarUUID(id)) {
				await asyncSelectRegistro(id);
			}
		};
		fetchData();
	}

	async function asyncSelectRegistro(idPlano) {
		await asyncGetAdmPlano(idPlano, ({ data: plano }) => {
			resetForm({ values: converterPlanoParaFormulario(plano, estruturaTree) });
			if (!isModal) {
				atualizarUrl(history, CADASTROURL, plano.id, metodosAtualizarUrl.POP);
			}
		});
	}

	async function carregarEstruturaRecurso() {
		await asyncBuscarEstruturaRecursos(({ data: recursos }) => {
			const estrutura = converterEstruturaParaTree(recursos);
			setEstruturaTree(estrutura);
		});
	}

	function handleIlimitado(current) {
		const campos = {
			usuarios: false,
			armazenamento: false,
			cadastrosPessoas: false,
			cadastrosProdutos: false,
			documentosMensais: false,
			contasFinanceiras: false,
			orcamentosMensais: false,
			pedidosMensais: false,
			nfesMensais: false,
			nfcesMensais: false,
			comprasMensais: false,
			ordensMensais: false,
			nfsesMensais: false,
			mdfesMensais: false,
			ctesMensais: false,
			boletosMensais: false,
		};

		Object.keys(campos).forEach((key) => {
			if (current === true) {
				if (key === 'armazenamento') {
					setFieldValue([key], Number.parseInt('99999999999999999', 10));
					changeCheckboxesFunction(key, true);
				} else {
					setFieldValue([key], 999999999);
					changeCheckboxesFunction(key, true);
				}
			} else {
				setFieldValue([key], 0);
				changeCheckboxesFunction(key, false);
			}
		});
	}

	function changeCheckboxesFunction(field, value) {
		setFieldCheckboxes((prevState) => ({
			...prevState,
			[field]: value,
		}));
	}

	function loadCheckboxes() {
		if (values.usuarios >= 999999999) {
			changeCheckboxesFunction('usuarios', true);
		}
		if (values.cadastrosPessoas >= 999999999) {
			changeCheckboxesFunction('cadastrosPessoas', true);
		}
		if (values.cadastrosProdutos >= 999999999) {
			changeCheckboxesFunction('cadastrosProdutos', true);
		}
		if (values.documentosMensais >= 999999999) {
			changeCheckboxesFunction('documentosMensais', true);
		}
		if (values.contasFinanceiras >= 999999999) {
			changeCheckboxesFunction('contasFinanceiras', true);
		}
		if (values.orcamentosMensais >= 999999999) {
			changeCheckboxesFunction('orcamentosMensais', true);
		}
		if (values.pedidosMensais >= 999999999) {
			changeCheckboxesFunction('pedidosMensais', true);
		}
		if (values.nfesMensais >= 999999999) {
			changeCheckboxesFunction('nfesMensais', true);
		}
		if (values.nfcesMensais >= 999999999) {
			changeCheckboxesFunction('nfcesMensais', true);
		}
		if (values.comprasMensais >= 999999999) {
			changeCheckboxesFunction('comprasMensais', true);
		}

		if (values.ordensMensais >= 999999999) {
			changeCheckboxesFunction('ordensMensais', true);
		}
		if (values.nfsesMensais >= 999999999) {
			changeCheckboxesFunction('nfsesMensais', true);
		}
		if (values.mdfesMensais >= 999999999) {
			changeCheckboxesFunction('mdfesMensais', true);
		}
		if (values.ctesMensais >= 999999999) {
			changeCheckboxesFunction('ctesMensais', true);
		}
		if (values.boletosMensais >= 999999999) {
			changeCheckboxesFunction('boletosMensais', true);
		}
		if (values.armazenamento >= Number.parseInt('99999999999999999', 10)) {
			changeCheckboxesFunction('armazenamento', true);
		}
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Form header="Cadastro de plano" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						hideModal={hideModal}
						history={history}
						isModal={isModal}
						handleIlimitado={handleIlimitado}
						estruturaTree={estruturaTree}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<CamposPrincipais handleIlimitado={handleIlimitado} planoIlimitado={planoIlimitado} />
					<TabView
						className="tab-view-plano"
						activeIndex={tabViewIndex}
						onTabChange={(event) => {
							setTabViewIndex(event.index);
						}}
						{...informacoesPermissoes}
					>
						<TabPanel header="Limites">
							<PlanosLimites
								planoIlimitado={planoIlimitado}
								fieldCheckboxes={fieldCheckboxes}
								changeCheckboxesFunction={changeCheckboxesFunction}
								informacoesPermissoes={informacoesPermissoes}
							/>
						</TabPanel>
						<TabPanel header="Recursos">
							<PlanosRecursos estruturaTree={estruturaTree} informacoesPermissoes={informacoesPermissoes} />
						</TabPanel>
					</TabView>
				</FormContent>
			</Form>
		</>
	);
}

const AdministracaoPlanosFormFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		armazenamento: Yup.number().nullable('abc').required(mensagensDeValidacao.OBRIGATORIO),
		usuarios: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cadastrosPessoas: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		cadastrosProdutos: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		documentosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		contasFinanceiras: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		orcamentosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		pedidosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nfesMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		nfcesMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		comprasMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		boletosMensais: Yup.number().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		aplicacao: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(AdministracaoPlanosFormImpl);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const AdministracaoPlanosForm = connect(mapStateToProps)(AdministracaoPlanosFormFormik);
