import { createContext, useContext, useState } from "react";
import { copiarObjeto } from "Common";
import { useGenerateParcelas } from "components";
import If from "components/If";
import Message from "components/Message";
import { ratearValoresCategorias } from "components/select/SingleSelectCategoria";
import { CONDICAO_PAGAMENTO_MOVIMENTACAO, CONDICAO_PAGAMENTO_TIPO } from "views/cadastros/financas/CondicaoPagamento/Util/constantes";
import { divideValorPorQuantidade } from "../Util/functions";
import { FINANCEIRO_TIPO } from "../Util/constantes";

const ContextPagamentos = createContext();

export default function PagamentosProvider(props) {
   const { children } = props;

   const [ idOrigem, setIdOrigem ] = useState(0);
   const [ valuesPagamentos, setValuesPagamentos ] = useState([]);
   const [ valorTotalPagamentos, setValorTotalPagamentos ] = useState(0);
   const [ quantidadeDePagamentos, setQuantidadeDePagamentos ] = useState(0);
   const [ urls, setUrls ] = useState({});
   const [ funcaoSetValues, setFuncaoSetValues ] = useState(null);
   const [ funcaoSetFieldValue, setFuncaoSetFieldValue ] = useState(null);
   const [ errorsFormulario, setErrorsFormulario ] = useState([]);
   const [ parcelasContext, setParcelasContext ] = useState([]);
   const [ quantidadeParcelasContext, setQuantidadeParcelasContext ] = useState({});
   const [ isFormModal, setIsFormModal ] = useState(false);
   const [ isNfce, setIsNfce] = useState(false);
   const [ favoritosContext, setFavoritosContext ] = useState({});
   const [ dataBaseParcela, setDataBaseParcela ] = useState("");
   const [ disabledFields, setDisabledFields ] = useState(false);
   const [ informacoesPermissoes, setInformacoesPermissoes ] = useState({});
   const [ classNameTabView, setClassNameTabView ] = useState('');
   const [ color, setColor ] = useState('');
   const [ hideTabFinanceiro, setHideTabFinanceiro ] = useState(false);
   const [ financeiroTipo, setFinanceiroTipo ] = useState('');
   const [ stylePagamento, setStylePagamento ] = useState('');
   const [ disabledValorRecebido, setDisabledValorRecebido ] = useState(false);
   const [ isMobile, setIsMobile ] = useState(false);
   const [ isTablet, setIsTablet ] = useState(false);
   const [ isLessHd, setIsLessHd ] = useState(false);
   const [ isFullScreen, setIsFullScreen ] = useState(false);

   const [generateParcelas] = useGenerateParcelas({
      dataBaseParcela,
      favoritosContext,
   });

   const indicacaoMovimento =
      financeiroTipo === FINANCEIRO_TIPO.CONTA_PAGAR
         ? CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA
         : CONDICAO_PAGAMENTO_MOVIMENTACAO.SAIDA;

   function handleIdOrigem(idOrigem) {
      setIdOrigem(idOrigem);
   }

   function handleValuesPagamentos(valuesPagamentos) {
      setValuesPagamentos(valuesPagamentos);
   }

   function handleValorTotalPagamentos(valorTotalPagamentos) {
      setValorTotalPagamentos(valorTotalPagamentos);
   }

   function handleQuantidadeDePagamentos(quantidadeDePagamentos) {
      setQuantidadeDePagamentos(quantidadeDePagamentos);
   }

   function handleUrls(urls) {
      setUrls(urls);
   }

   function funcaoSetValuesVerificada(valor) {
      funcaoSetValues ? funcaoSetValues(valor) : () => {}
   }

   function handleFuncaoSetValues(funcaoSetValues) {
      setFuncaoSetValues(funcaoSetValues);
   }

   function funcaoSetFieldValueVerificada(campo, valor) {
      funcaoSetFieldValue ? funcaoSetFieldValue(campo, valor) : () => {}
   }

   function handleFuncaoSetFieldValue(funcaoSetFieldValue) {
      setFuncaoSetFieldValue(funcaoSetFieldValue);
   }

   function handleErrorsFormulario(errorsFormulario) {
      setErrorsFormulario(errorsFormulario);
   }

   function handleParcelasContext(parcelasContext) {
      setParcelasContext(parcelasContext);
   }

   function handleQuantidadeParcelasContext(quantidadeParcelasContext) {
      setQuantidadeParcelasContext(quantidadeParcelasContext);
   }

   function handleIsFormModal(isFormModal) {
      setIsFormModal(isFormModal);
   }

   function handleIsNfce(isNfce) {
      setIsNfce(isNfce);
   }

   function handleFavoritosContext(favoritosContext) {
      setFavoritosContext(favoritosContext);
   }

   function handleDataBaseParcela(dataBaseParcela) {
      setDataBaseParcela(dataBaseParcela);
   }

   function handleDisabledFields(disabledFields) {
      setDisabledFields(disabledFields);
   }

   function handleInformacoesPermissoes(informacoesPermissoes) {
      setInformacoesPermissoes(informacoesPermissoes);
   }

   function handleClassNameTabView(classNameTabView) {
      setClassNameTabView(classNameTabView);
   }

   function handleColor(color) {
      setColor(color);
   }

   function handleHideTabFinanceiro(hideTabFinanceiro) {
      setHideTabFinanceiro(hideTabFinanceiro);
   }

   function handleFinanceiroTipo(financeiroTipo) {
      setFinanceiroTipo(financeiroTipo);
   }

   function handleStylePagamento(stylePagamento) {
      setStylePagamento(stylePagamento);
   }

   function handleDisabledValorRecebido(disabledValorRecebido) {
      setDisabledValorRecebido(disabledValorRecebido);
   }

   function handleIsMobile(isMobile) {
      setIsMobile(isMobile);
   }

   function handleIsTablet(isTablet) {
      setIsTablet(isTablet);
   }

   function handleIsLessHd(isLessHd) {
      setIsLessHd(isLessHd);
   }

   function handleIsFullScreen(isFullScreen) {
      setIsFullScreen(isFullScreen);
   }

   function createTextMessage() {
		const message =
			errorsFormulario[0]?.semFormaPagamento ??
			errorsFormulario[0]?.vencimentoParcela ??
			errorsFormulario[0]?.valorParcela ??
			errorsFormulario[0]?.formaPagamentoParcela ??
			errorsFormulario[0]?.contaParcela ??
			errorsFormulario[0]?.valorTotal;
		return <span>{message}</span>;
	}

	function renderMessage() {
		return (
			<If test={errorsFormulario?.length > 0 && errorsFormulario[0]?.showMessage}>
				<Message severity="warn" text={createTextMessage()} />
			</If>
		);
	}

   function onExcluirPagamento(index) {
      if (quantidadeDePagamentos > 1) {
         let newPagamentos = copiarObjeto(valuesPagamentos);
         const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(valorTotalPagamentos, quantidadeDePagamentos - 1);

         newPagamentos.splice(index, 1);

         if (index !== quantidadeDePagamentos - 1) {
            newPagamentos = newPagamentos.map((e, i) => {
               const valor = i === newPagamentos.length - 1 ? valorUltimaQuantidade : valorPorQuantidade;

               return {
                  ...e,
                  sequencial: i + 1,
                  valor,
                  valorRecebido: valor,
                  categorias: ratearValoresCategorias(e.categorias, valor, -1, true),
                  parcelas: getParcelas(e, valor),
               };
            });
         } else {
            newPagamentos = newPagamentos.map((e, i) => {
               const valor = i === newPagamentos.length - 1 ? valorUltimaQuantidade : valorPorQuantidade;

               return {
                  ...e,
                  valor,
                  valorRecebido: valor,
                  categorias: ratearValoresCategorias(e.categorias, valor, -1, true),
                  parcelas: getParcelas(e, valor),
               };
            });
         }

         funcaoSetValuesVerificada(newPagamentos);
      }
   }

   function getParcelas(e, valorPagamento) {
      const isAPrazo = e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
      return isAPrazo
         ? generateParcelas({
               qtdParcelas: e.quantidadeParcelas?.value,
               parcelas: e.parcelas,
               valorPagamento: valorPagamento,
            })
         : [];
   }
      
   return (
      <ContextPagamentos.Provider
         value={{
            idOrigem,
            handleIdOrigem,
            valuesPagamentos,
            handleValuesPagamentos,
            valorTotalPagamentos,
            handleValorTotalPagamentos,
            quantidadeDePagamentos,
            handleQuantidadeDePagamentos,
            urls,
            handleUrls,
            funcaoSetValuesVerificada,
            handleFuncaoSetValues,
            funcaoSetFieldValueVerificada,
            handleFuncaoSetFieldValue,
            errorsFormulario,
            handleErrorsFormulario,
            parcelasContext,
            handleParcelasContext,
            quantidadeParcelasContext,
            handleQuantidadeParcelasContext,
            isFormModal,
            handleIsFormModal,
            isNfce,
            handleIsNfce,
            favoritosContext,
            handleFavoritosContext,
            dataBaseParcela,
            handleDataBaseParcela,
            disabledFields,
            handleDisabledFields,
            informacoesPermissoes,
            handleInformacoesPermissoes,
            classNameTabView,
            handleClassNameTabView,
            color,
            handleColor,
            hideTabFinanceiro,
            handleHideTabFinanceiro,
            financeiroTipo,
            handleFinanceiroTipo,
            stylePagamento,
            handleStylePagamento,
            disabledValorRecebido,
            handleDisabledValorRecebido,
            isMobile,
            handleIsMobile,
            isTablet,
            handleIsTablet,
            isLessHd,
            handleIsLessHd,
            isFullScreen,
            handleIsFullScreen,
            indicacaoMovimento,
            renderMessage,
            onExcluirPagamento,
         }}
      >
         {children || null}
      </ContextPagamentos.Provider>
   )
}

export const useContextPagamentos = () => useContext(ContextPagamentos);