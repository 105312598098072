import { copiarObjeto, generateOptionsQtdParcelas, parseFloatNumber } from 'Common';
import { Col, Dropdown, If, InputMoney, NormalButton, SingleSelectCategoria, confirm } from 'components';
import { useGenerateParcelas } from 'components/Pagamentos/Hooks/useGenerateParcelas';
import { sumRestoParcelas, updateSequencial, validateValorNegativo } from 'components/Pagamentos/Util/functions';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { ModalParcelas } from './components/ModalParcelas';
import { TabViewParcelas } from './components/TabViewParcelas';

function FieldsAPrazo({
	value,
	indexPagamento,
	isFirstAndUniqueParcelaAPrazo,
	handleChangeCategoria,
	handleChangeValor,
	onBlurValor,
	onChangePagamentoField,
	setRecalcularTodosOsProdutosComTabelaPreco,
	setRecalcularTodosOsServicosComTabelaPreco,
}) {
	const { errors } = useFormikContext();

	const { 
		quantidadeDePagamentos,
		urls,
		funcaoSetFieldValueVerificada,
		parcelasContext,
		quantidadeParcelasContext,
		isFormModal,
		handleIsFormModal,
		favoritosContext,
		dataBaseParcela,
		disabledFields,
		informacoesPermissoes,
	} = useContextPagamentos();

	const [optionsQuantidadeParcelas, setOptionsQuantidadeParcelas] = useState([]);
	const [visibleModalParcelas, setVisibleModalParcelas] = useState(false);
	const [firstRenderFieldsAPrazo, setFirstRenderFieldsAPrazo] = useState(true);
	const [replicarFormaPagamento, setReplicarFormaPagamento] = useState(true);
	const [replicarContaPagamento, setReplicarContaPagamento] = useState(true);
	const [dadosParcelasReplicar, setDadosParcelasReplicar] = useState({
		parcelas: null,
		campoAlterado: null,
		indexInicial: null,
	});

	const [generateParcelas] = useGenerateParcelas({
		qtdParcelas: value.quantidadeParcelas?.value,
		parcelas: parcelasContext,
		valorPagamento: value.valor,
		dataBaseParcela,
		favoritos: favoritosContext,
	});

	const calculateVencimentoParcelas = useCallback((vencimento, indexParcela) => {
		let parcelasTemp = copiarObjeto(parcelasContext);
		if (indexParcela === 0 && isValid(parseISO(vencimento))) {
			parcelasTemp = parcelasTemp.map((parcela, i) => ({
				...parcela,
				vencimento: formatISO(addMonths(parseISO(vencimento), i)),
			}));
			return parcelasTemp;
		} else {
			parcelasTemp[indexParcela] = { ...parcelasTemp[indexParcela], vencimento };
			return parcelasTemp;
		}
	});

	const calculateValorParcelas = useCallback((valor, indexParcela) => {
		
		let parcelasTemp = copiarObjeto(parcelasContext);
		
		parcelasTemp[indexParcela] = {
			...parcelasTemp[indexParcela],
			valor,
		};

		const valorParcelasAnteriores = sumRestoParcelas(parcelasTemp, indexParcela);
		const valorASerRatiado = parseFloatNumber(value.valor - (valorParcelasAnteriores + valor));
		const quantidadeParcelasRestantes = parcelasTemp.length - 1 - indexParcela;
		const valorRatiadoParaCadaParcelaRestante = parseFloatNumber(valorASerRatiado / quantidadeParcelasRestantes);

		let somaDasParcelas = 0;

		parcelasTemp = parcelasTemp.map((parcela, i) => {
			let newParcela = copiarObjeto(parcela);
			let { valor } = newParcela;

			if (i > indexParcela) {
				somaDasParcelas += valorRatiadoParaCadaParcelaRestante;
				if (i === parcelasContext.length - 1) {
					valor = validateValorNegativo(
						parseFloatNumber(valorRatiadoParaCadaParcelaRestante + (value.valor - somaDasParcelas))
					);
				} else {
					valor = validateValorNegativo(valorRatiadoParaCadaParcelaRestante);
				}
			} else {
				somaDasParcelas += parcela.valor;
			}

			newParcela = {
				...newParcela,
				valor,
			};
			return newParcela;
		});

		return parcelasTemp;
	});

	const changeFormaPagamentoParcelas = useCallback((formaPagamento, indexParcela) => {
		const parcelasTemp = copiarObjeto(parcelasContext);
		const { conta } = formaPagamento.registro;
		if (formaPagamento) {
			parcelasTemp[indexParcela] = {
				...parcelasTemp[indexParcela],
				formaPagamento,
				conta: conta ? { value: conta.id, label: conta.nome, registro: conta } : parcelasTemp[indexParcela].conta,
			};
		}

		if (replicarFormaPagamento && parcelasTemp.length > 1 && indexParcela < parcelasTemp.length - 1) {
			confirm(
				'Confirmação',
				'Deseja replicar a forma de pagamento para todas as parcelas abaixo da alterada?',
				() => {
					setDadosParcelasReplicar({
						parcelas: parcelasTemp,
						campoAlterado: 'formaPagamento',
						indexInicial: indexParcela,
					});
				},
				() => {
					setReplicarFormaPagamento(false);
				},
				'Sim',
				'Não'
			);
		}

		return parcelasTemp;
	});

	const changeContaParcelas = useCallback((conta, indexParcela) => {
		const parcelasTemp = copiarObjeto(parcelasContext);
		if (conta) {
			parcelasTemp[indexParcela] = { ...parcelasTemp[indexParcela], conta: conta };
		}

		if (replicarContaPagamento && parcelasTemp.length > 1 && indexParcela < parcelasTemp.length - 1) {
			confirm(
				'Confirmação',
				'Deseja replicar a conta de pagamento para todas as parcelas abaixo da alterada?',
				() => {
					setDadosParcelasReplicar({ parcelas: parcelasTemp, campoAlterado: 'conta', indexInicial: indexParcela });
				},
				() => {
					setReplicarContaPagamento(false);
				},
				'Sim',
				'Não'
			);
		}

		return parcelasTemp;
	});

	const removeParcela = useCallback((indexParcela) => {
		let newParcelas = copiarObjeto(parcelasContext);
		newParcelas.splice(indexParcela, 1);

		if (indexParcela !== parcelasContext.length - 1) {
			newParcelas = updateSequencial(newParcelas);
		}
	
		const newQuantidadeParcelas = optionsQuantidadeParcelas.find((e) => e.value === quantidadeParcelasContext.value - 1);

		return {
			parcelas: generateParcelas({ qtdParcelas: newQuantidadeParcelas.value, parcelas: newParcelas }),
			quantidadeParcelas: newQuantidadeParcelas, 
		};
	});

	useEffect(() => {
		if (dadosParcelasReplicar?.parcelas?.length > 0) {
			const parcelasTemp = copiarObjeto(dadosParcelasReplicar.parcelas);

			for (let i = dadosParcelasReplicar.indexInicial + 1; i < parcelasTemp.length; i++) {
				parcelasTemp[i] = {
					...parcelasTemp[i],
					formaPagamento: parcelasTemp[dadosParcelasReplicar.indexInicial].formaPagamento,
					conta: parcelasTemp[dadosParcelasReplicar.indexInicial].conta,
				};
			}

			onChangeParcelas(parcelasTemp, true);
			setDadosParcelasReplicar({ parcela: null, campoAlterado: null, indexInicial: null });
		}
	}, [dadosParcelasReplicar]);

	useEffect(() => {
		if (dadosParcelasReplicar?.parcelas?.length > 0 && dadosParcelasReplicar.campoAlterado === 'conta') {
			const parcelasTemp = copiarObjeto(dadosParcelasReplicar.parcelas);

			for (let i = dadosParcelasReplicar.indexInicial + 1; i < parcelasTemp.length; i++) {
				parcelasTemp[i] = {
					...parcelasTemp[i],
					conta: parcelasTemp[dadosParcelasReplicar.indexInicial].conta,
				};
			}

			onChangeParcelas(parcelasTemp, true);
			setDadosParcelasReplicar({ parcela: null, campoAlterado: null, indexInicial: null });
		}
	}, [dadosParcelasReplicar]);

	useEffect(() => {
		generateOptionsQuantidadeParcelas();
	}, [value.condicaoPagamento]);

	useEffect(() => {
		if (!firstRenderFieldsAPrazo) {
			const parcelas = generateParcelas({ qtdParcelas: value.quantidadeParcelas.value, valorPagamento: value.valor });
			funcaoSetFieldValueVerificada(`[${indexPagamento}].parcelas`, parcelas);
			onChangePagamentoField(indexPagamento, 'parcelas', parcelas);
		} else {
			setFirstRenderFieldsAPrazo(false);
		}
	}, [value.valor]);

	useEffect(() => {
		handleIsFormModal(visibleModalParcelas);
	}, [visibleModalParcelas]);

	function generateOptionsQuantidadeParcelas() {
		const { parcelaMinima, parcelaMaxima } = value.condicaoPagamento.registro;
		setOptionsQuantidadeParcelas(generateOptionsQtdParcelas(parcelaMinima, parcelaMaxima));
	}

	function handleClickParcelas() {
		setVisibleModalParcelas(true);
	}

	function onHideModalParcelas() {
		setVisibleModalParcelas(false);
	}

	function onChangeParcelas(parcelas) {	
		if (isFormModal) {
			funcaoSetFieldValueVerificada(`parcelas`, parcelas);
		}
		else {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].parcelas`, parcelas);
			onChangePagamentoField(indexPagamento, 'parcelas', parcelas);
		}
	}

	function handleChangeQuantidadeParcelas(e) {
		if (isFormModal) {
			funcaoSetFieldValueVerificada('quantidadeParcelas', e);
		}	
		else {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].quantidadeParcelas`, e);
			onChangePagamentoField(indexPagamento, 'quantidadeParcelas', e);
		}
		
		onChangeParcelas(generateParcelas({ qtdParcelas: e.value }));

		if (typeof setRecalcularTodosOsProdutosComTabelaPreco === 'function') {
			setRecalcularTodosOsProdutosComTabelaPreco(true);
		}
		if (typeof setRecalcularTodosOsServicosComTabelaPreco === 'function') {
			setRecalcularTodosOsServicosComTabelaPreco(true);
		}
	}

	function onChangeVencimentoParcelas(value, indexParcela) {		
		onChangeParcelas(calculateVencimentoParcelas(value, indexParcela));
	}

	function onChangeValorParcelas(value, indexParcela) {		
		if (isFormModal) {
			funcaoSetFieldValueVerificada(`parcelas[${indexParcela}].valor`, value);
		}
		else {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].parcelas[${indexParcela}].valor`, value);
		}
	}

	function onBlurValorParcelas(value, indexParcela) {
		onChangeParcelas(calculateValorParcelas(value, indexParcela));
	}

	function onChangeFormaPagamento(value, indexParcela) {
		onChangeParcelas(changeFormaPagamentoParcelas(value, indexParcela));
	}

	function onChangeConta(value, indexParcela) {
		onChangeParcelas(changeContaParcelas(value, indexParcela));
	}

	function onChangeDescricao(descricao, indexParcela) {
		if (isFormModal) {
			funcaoSetFieldValueVerificada(`parcelas[${indexParcela}].descricao`, descricao);
		}
		else {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].parcelas[${indexParcela}].descricao`, descricao);
		}
	}

	function onBlurDescricaoParcelas() {
		onChangeParcelas(parcelasContext);
	}

	function onRemoveParcela(indexParcela) {
		const { parcelas, quantidadeParcelas } = removeParcela(indexParcela);
		
		if (isFormModal) {
			funcaoSetFieldValueVerificada(`quantidadeParcelas`, quantidadeParcelas);
		}
		else {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].quantidadeParcelas`, quantidadeParcelas);
			onChangePagamentoField(indexPagamento, 'quantidadeParcelas', quantidadeParcelas);
		}
		onChangeParcelas(parcelas);
	}

	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="2"
				xl="2"
				label="Qtd. parcelas"
				name="quantidadeParcelas"
				helpMessage="Quantidade de parcelas"
				placeholder="Pesquisar"
				component={Dropdown}
				value={value.quantidadeParcelas}
				onChange={handleChangeQuantidadeParcelas}
				options={optionsQuantidadeParcelas}
				disabled={disabledFields}
				obrigatorio
				showClear={false}
				touched
				errors={errors[indexPagamento]?.quantidadeParcelas}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				label="Categoria(s)"
				name="categorias"
				helpMessage="Categoria(s)"
				placeholder="Pesquisar"
				component={SingleSelectCategoria}
				value={value.categorias}
				onChange={handleChangeCategoria}
				url={urls.categoria}
				disabled={disabledFields}
				obrigatorio
				isClearable={false}
				touched
				errors={errors[indexPagamento]?.categorias}
				useFormErrors={false}
				withList
				valueTotalList={value.valor}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md={isFirstAndUniqueParcelaAPrazo ? '6' : '3'}
				lg="2"
				xl="2"
				label="Valor"
				name="valor"
				helpMessage="Valor"
				component={InputMoney}
				value={value.valor}
				onChange={handleChangeValor}
				onBlur={onBlurValor}
				allowNegative={false}
				size={13}
				prefix="R$ "
				placeholder="R$ 0,00"
				obrigatorio
				disabled={disabledFields || quantidadeDePagamentos === 1}
				touched
				errors={errors[indexPagamento]?.valor}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<If test={!isFirstAndUniqueParcelaAPrazo}>
				<Col
					sm="12"
					md="3"
					lg="2"
					xl="2"
					style={{
						padding: '0px 4px',
						minHeight: '80px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Field
						style={{ width: '100%', height: '32px' }}
						styleContentButton={{ width: '100%', padding: '18px 8px 8px' }}
						className="p-button-primary"
						type="button"
						component={NormalButton}
						label="Parcelas"
						icon="fa fa-credit-card"
						name="parcelas"
						obrigatorio
						touched
						allowNegative={false}
						onClick={handleClickParcelas}
						{...informacoesPermissoes}
					/>
				</Col>
			</If>
			<If test={isFirstAndUniqueParcelaAPrazo}>
				<TabViewParcelas
					values={value}
					indexPagamento={indexPagamento}
					optionsQuantidadeParcelas={optionsQuantidadeParcelas}
					calculateValorParcelas={calculateValorParcelas}
					onChangeQuantidadeParcelas={handleChangeQuantidadeParcelas}
					onChangeVencimentoParcelas={onChangeVencimentoParcelas}
					onChangeValorParcelas={onChangeValorParcelas}
					onBlurValorParcelas={onBlurValorParcelas}
					onChangeFormaPagamento={onChangeFormaPagamento}
					onChangeConta={onChangeConta}
					onChangeDescricao={onChangeDescricao}
					onBlurDescricaoParcelas={onBlurDescricaoParcelas}
					onRemoveParcela={onRemoveParcela}
				/>
			</If>
			<If test={visibleModalParcelas}>
				<ModalParcelas
					visible={visibleModalParcelas}
					onHide={onHideModalParcelas}
					values={value}
					indexPagamento={indexPagamento}
					optionsQuantidadeParcelas={optionsQuantidadeParcelas}
					calculateValorParcelas={calculateValorParcelas}
					onChangeQuantidadeParcelas={handleChangeQuantidadeParcelas}
					onChangeVencimentoParcelas={onChangeVencimentoParcelas}
					onChangeValorParcelas={onChangeValorParcelas}
					onBlurValorParcelas={onBlurValorParcelas}
					onChangeFormaPagamento={onChangeFormaPagamento}
					onChangeConta={onChangeConta}
					onChangeDescricao={onChangeDescricao}
					onBlurDescricaoParcelas={onBlurDescricaoParcelas}
					onRemoveParcela={onRemoveParcela}
					onChangePagamentoField={onChangePagamentoField}
				/>
			</If>
		</>
	);
}

export { FieldsAPrazo };
