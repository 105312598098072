import { Button, Tree } from 'components';
import { useFormikContext } from 'formik';

function PlanosRecursos({ estruturaTree, informacoesPermissoes }) {
	const { setFieldValue, values, validateForm } = useFormikContext();

	function marcarTodosOsRecursos() {
		const resultado = {};
		for (let i = 0; i < estruturaTree.length; i++) {
			const recurso = estruturaTree[i];

			resultado[recurso.key] = { ...recurso, checked: true, partialChecked: false };
		}
		setFieldValue('recursos', resultado);
	}

	function desmarcarTodosOsRecursos() {
		setFieldValue('recursos', []);
	}

	return (
		<>
			<Button
				label="Marcar todos"
				type="button"
				color="success"
				icon="fa fa-check"
				style={{ margin: '5px' }}
				title="Marca todos os recursos"
				onClick={() => marcarTodosOsRecursos()}
			/>
			<Button
				label="Desmarcar todos"
				type="button"
				color="danger"
				icon="fa fa-close"
				title="Desmarca todos os recursos"
				style={{ margin: '5px' }}
				onClick={() => desmarcarTodosOsRecursos()}
			/>
			<Tree
				className="step-papeis-three"
				style={{ border: 0, padding: 0 }}
				value={estruturaTree}
				selectionKeys={values.recursos}
				onSelectionChange={(e) => {
					setFieldValue('recursos', e.value);
					validateForm();
				}}
				selection={values.recursos}
				sortMode="single"
				selectionMode="checkbox"
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { PlanosRecursos };
