import { Modal, Form, FormActions, FormContent } from 'components';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { withFormik } from 'formik';

import { ActionButtons } from './components/ActionButtons';
import { ListaProdutosDevolucaoCondicional } from './components/ListaProdutosDevolucaoCondicional';
import { converterProdutosPedidoParaProdutosModalDevolucaoCondicional } from '../../Form/Util/PedidovendasOrcamentosConverter';

function ModalDevolucaoCondicionalImpl({
	visible,
	onHide,
	isMobile,
	isTablet,
	produtosPedido,
	setFieldValuesPedido,
	valuesFormPedido,
 	atualizarTotalizadoresProdutosPedido,
 	utilizaTabelaPreco,
}) {
	const podeEditar = usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.EDITAR);

	const informacoesPermissoes = {
		podeEditar: podeEditar,
	};

	return (
		<Modal header="Devolução condicional" visible={visible} onHide={onHide}>
			<Form>
				<FormActions>
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						onHide={onHide}
						produtosPedido={produtosPedido}
						setFieldValuesPedido={setFieldValuesPedido}
						valuesFormPedido={valuesFormPedido}
						atualizarTotalizadoresProdutosPedido={atualizarTotalizadoresProdutosPedido}
					/>
				</FormActions>
				<FormContent>
					<ListaProdutosDevolucaoCondicional
						utilizaTabelaPreco={utilizaTabelaPreco}
						valuesFormPedido={valuesFormPedido}
						isMobile={isMobile}
						isTablet={isTablet}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalDevolucaoCondicional = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return converterProdutosPedidoParaProdutosModalDevolucaoCondicional(props.produtosPedido);
	},

	validate(props) {
		const errors = {};
		const errorsProdutos = [];

		if (props?.length > 0) {
			props?.forEach((produto, index) => {
				if (produto.quantidade > produto.quantidadeInicial) {
					errorsProdutos[index] = {
						...errorsProdutos[index],
						quantidade: 'Quantidade a ser devolvida não pode ser maior que a quantidade inicial',
					};
				}

				if (produto.quantidade < 0) {
					errorsProdutos[index] = {
						...errorsProdutos[index],
						quantidade: 'Quantidade não pode ser menor que 0',
					};
				}
			});
		}

		if (errorsProdutos.length > 0) {
			errors.produtos = errorsProdutos;
		}

		return errors;
	},
})(ModalDevolucaoCondicionalImpl);

export { ModalDevolucaoCondicional };
