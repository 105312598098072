import Col from 'components/Col';
import { FieldsParcela } from './components/FieldsParcela';

function Parcelas({
	values,
	calculateValorParcelas,
	onChangeVencimentoParcelas,
	onChangeValorParcelas,
	onBlurValorParcelas,
	onChangeFormaPagamento,
	onChangeConta,
	onChangeDescricao,
	onBlurDescricaoParcelas,
	onRemoveParcela,
	indexPagamento,
}) {
	return (
		<Col
			style={{maxHeight: '314.68px', overflowY: 'auto'}}
		>
			{values.parcelas.length > 0 &&
				values.parcelas.map((parcela, indexParcela) => (
					<FieldsParcela
						key={parcela.tempKey}
						value={parcela}
						values={values}
						indexParcela={indexParcela}
						calculateValorParcelas={calculateValorParcelas}
						onChangeVencimentoParcelas={onChangeVencimentoParcelas}
						onChangeValorParcelas={onChangeValorParcelas}
						onBlurValorParcelas={onBlurValorParcelas}
						onChangeFormaPagamento={onChangeFormaPagamento}
						onChangeConta={onChangeConta}
						onChangeDescricao={onChangeDescricao}
						onBlurDescricaoParcelas={onBlurDescricaoParcelas}
						onRemoveParcela={onRemoveParcela}
						indexPagamento={indexPagamento}
					/>
				))}
		</Col>
	);
}

export { Parcelas };
