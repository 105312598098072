import { rateioParcelas } from 'components/body/Vendas/components/Parcelas/Util/functions';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { copiarObjeto, copiarParaAreaTransferencia, gerarUUID, validarUUID } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	NormalButton,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';
import { atualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../../Util';
import {
	asyncCreateNfe,
	asyncDeleteNfe,
	asyncTransmitirNFe,
	asyncUpdateNfe,
	imprimirEspelhoDANFE,
	imprimirXMLRejeicaoNfe,
} from '../../../Requests';
import { Operacao, Status } from '../../../Util/constantes';
import { montarMensagemToast } from '../../../Util/funcoes';
import { converterNFeParaApi } from '../../Util/NFeConverter';
import { Condicoes, TypeErrors } from '../../Util/constantes';
import { useFormikContext } from 'formik';
import { NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';

export default function ActionButtons(props) {
	const {
		dadosBase,
		duplicarVenda,
		asyncSelectRegistro,
		novo,
		setTabPagamentoSelecionada,
		setModalHistoricoVisible,
		setModalCancelamento,
		setModalHistoricoCorrecoes,
		setModalCartaCorrecao,
		setModalPedidoImportacao,
		informacoesPermissoes,
		existeParcelaRecebida,
		setAtualizarImpostosProdutos,
		totalizadores,
		podeEditar,
		setIsFirstRender,
		setExibirLoadingTransmissao,
		desabilitarBotaoTransmitir,
		credencial,
		erroProdutoInativo,
		organizacaoPodeTransmitirNfe,
		atualizarParcelas,
		history,
		match,
		setAtualizarTotal,
	} = props;

	const { values, dirty, initialValues, resetForm, handleSubmit, validateForm } = useFormikContext();

	const menuOpcoes = useRef(null);
	const lastLocation = useLastLocation();

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const itensOpcoes = buscaritensOpcoes();
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;

	function buscaritensOpcoes() {
		const notaFiscalTransmitida = values.status === Status.TRANSMITIDA;
		const notaFiscalCorrigida = values.status === Status.CORRIGIDA;
		const notaFiscalCancelada = values.status === Status.CANCELADA;
		const notaFiscalRejeitada = values.status === Status.REJEITADA;
		const notaFiscalNaoEnviada = values.status === Status.NAO_ENVIADA;

		let res = [];

		const duplicar = {
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => duplicarVenda(values.id),
		};

		const historico = {
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setModalHistoricoVisible(true),
		};

		const recalcularNotaFiscal = {
			label: 'Recalcular nota',
			icon: 'fa fa-calculator',
			command: () => {
				recalcularNota();
			},
		};

		const ImportarPedido = {
			label: 'Importar pedido de venda',
			icon: 'pi pi-download',
			command: () => setModalPedidoImportacao(true),
		};

		const espelhoNotaFiscal = {
			label: 'Visualizar espelho',
			icon: 'fa fa-file-text-o',
			disabled: !organizacaoPodeTransmitirNfe(),
			command: () => {
				visualizarEspelho();
			},
		};

		const xmlRejeicao = {
			label: 'Baixar XML rejeição',
			icon: 'pi pi-download',
			disabled: !organizacaoPodeTransmitirNfe(),
			command: () => {
				baixarXmlRejeicao();
			},
		};

		const copiarChaveNFE = {
			label: 'Copiar chave da NF-e',
			icon: 'fa fa-copy',
			command: () => {
				copiarParaAreaTransferencia(values.chave || '', () => {
					notify('Chave de acesso copiada para área de transferência', ToastTypes.SUCCESS);
				});
			},
		};

		const corrigirNotaFiscal = {
			label: 'Emitir carta de correção',
			icon: 'fa fa-edit',
			command: () => setModalCartaCorrecao(true),
		};

		const corrigirNovamente = {
			label: 'Emitir nova carta de correção',
			icon: 'fa fa-edit',
			command: () => setModalCartaCorrecao(true),
		};

		const verCorrecoes = {
			label: 'Ver correções',
			icon: 'fa fa-eye',
			command: () => setModalHistoricoCorrecoes(true),
		};

		const cancelamento = {
			label: values.status === 'CANCELADA' ? 'Motivo do cancelamento' : 'Cancelar NF-e',
			icon: values.status === 'CANCELADA' ? 'fa fa-eye' : 'fa fa-times',
			command: () =>
				values.pedidoId
					? confirm(
							'Confirmação',
							'Nota fiscal com um pedido de venda vínculado, ao cancelá-lo, o pedido também será inutilizado. Deseja continuar?',
							() => setModalCancelamento(true)
						)
					: setModalCancelamento(true),
		};

		if (notaFiscalRejeitada || notaFiscalNaoEnviada) {
			res.push(recalcularNotaFiscal);
		}

		if (values.id) {
			res.push(duplicar);
			res.push(historico);
		}

		if (!dirty) {
			res.push({
				separator: true,
				visible: true,
			});
		}

		if (values.id && notaFiscalTransmitida) {
			res.push(copiarChaveNFE);
			if (podeEditar) {
				res.push(corrigirNotaFiscal);
				res.push(cancelamento);
			}
		}

		if (values.id && notaFiscalCorrigida) {
			res.push(copiarChaveNFE);
			res.push(verCorrecoes);
			if (podeEditar) {
				res.push(corrigirNovamente);
				res.push(cancelamento);
			}
		}

		if (values.id && notaFiscalCancelada) {
			res.push(copiarChaveNFE);
			res.push(cancelamento);
		}

		if (!dirty && notaFiscalNaoEnviada) {
			res.push(ImportarPedido);
		}

		if (values.id && (notaFiscalRejeitada || notaFiscalNaoEnviada)) {
			res.push(espelhoNotaFiscal);
			if (notaFiscalRejeitada) {
				res.push(xmlRejeicao);
			}
		}

		return res;
	}

	function cancelar() {
		const { condicao } = dadosBase;

		if (dirty) {
			if (validarUUID(match.params.id)) {
				resetForm({ values: copiarObjeto(initialValues) });
			} else {
				novo({ isCancelarNFe: true });
			}

			setAtualizarTotal(true);
		} else {
			voltarParaAPesquisa(history, '/nfes');
		}

		if (condicao === Condicoes.A_VISTA) {
			setTabPagamentoSelecionada(0);
		}
	}

	async function calcularTotalParcelas() {
		let totalParcelas = 0;
		await values.parcelas?.forEach((parcela) => {
			totalParcelas += parcela?.valor;
		});

		return totalParcelas;
	}

	async function recalcularParcelas() {
		let parcelasTemp = [];

		const rateio = rateioParcelas(totalizadores.totalLiquido, values.quantidadeParcelas);

		for (let i = 0; i < values.quantidadeParcelas; i++) {
			let oldParcela = values.parcelas && values.parcelas[i];
			let valorParcela = rateio.valorPorParcela;

			if (values.quantidadeParcelas - 1 === i) {
				valorParcela = rateio.valorUltimaParcela;
			}

			let idParcela = oldParcela ? oldParcela.id : null;
			let vencimento = formatISO(
				isValid(new Date(oldParcela?.vencimento))
					? parseISO(oldParcela.vencimento)
					: addMonths(parseISO(values.dataEmissao), i + 1)
			);
			let descricao = oldParcela ? oldParcela.descricao : '';
			let forma = oldParcela ? oldParcela.forma : 'DINHEIRO';

			parcelasTemp.push({
				id: idParcela,
				vencimento: vencimento,
				valor: valorParcela,
				descricao: descricao,
				forma: forma,
			});
		}

		await atualizarParcelas(parcelasTemp);
		return parcelasTemp;
	}

	async function salvar(e, novoOnSuccess, vendaAtualizada) {
		handleSubmit();

		const valuesNFe = { ...values, totalizadores: totalizadores };
		if (values.condicao === Condicoes.A_PRAZO && (await calcularTotalParcelas()) !== totalizadores.totalLiquido) {
			valuesNFe.parcelas = await recalcularParcelas();
		}

		if (valuesNFe.produtos?.length > 0) {
			valuesNFe.produtos = valuesNFe.produtos.filter((produto) => produto.produto);

			if (valuesNFe.produtos?.length === 0) {
				valuesNFe.produtos = [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }];
			}

			for (let i = 0; i < valuesNFe.produtos.length; i++) {
				valuesNFe.produtos[i].item = i + 1;
			}
		}

		const comissaoNFe = calcularComissaoNFe(valuesNFe);
		valuesNFe.comissaoTotalPercentual = comissaoNFe.percentual;
		valuesNFe.comissaoTotalValor = comissaoNFe.valor;

		if (await validarFormulario({ validateForm, values: valuesNFe })) {
			if (valuesNFe.id) {
				updateVenda({
					...valuesNFe,
					totalizadores: totalizadores,
					motivoCancelamento: vendaAtualizada?.motivoCancelamento,
				}, novoOnSuccess);
			} else {
				createVenda({ ...valuesNFe, totalizadores: totalizadores }, novoOnSuccess);
			}
		}
	}

	function calcularComissaoNFe(nfe) {
		const comissao = { percentual: 0, valor: 0 };

		if (nfe.produtos?.length > 0) {
			const percentualTotal =
				nfe.produtos.reduce((total, item) => total + item.comissaoPercentual || 0, 0) / nfe.produtos.length;

			comissao.percentual = parseFloat(percentualTotal.toFixed(2));
			comissao.valor = parseFloat((nfe.totalizadores.totalLiquido * (percentualTotal / 100)).toFixed(2));
		}

		return comissao;
	}

	function updateVenda(values, novoOnSuccess) {
		if (values) {
			const mensagem = montarMensagemToast(dadosBase.status, Operacao.EDITAR);
			asyncUpdateNfe(converterNFeParaApi(values), mensagem, ({ data: nfe }) => {				
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({
						values: {
							...values,
							id: nfe.id,
						},
					});
				}
			});
		}
	}

	function createVenda(values, novoOnSuccess) {
		if (values) {
			const mensagem = montarMensagemToast(dadosBase.status, Operacao.INSERIR);

			asyncCreateNfe(converterNFeParaApi(values), mensagem, ({ data: nfe }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					atualizarUrl(history, `/nfes/cadastro`, nfe.id);
					resetForm({
						values: {
							...values,
							id: nfe.id,
						},
					});
				}
			});
		}
	}

	function recalcularNota() {
		setAtualizarImpostosProdutos(true);
	}

	function visualizarEspelho() {
		imprimirEspelhoDANFE(values.id, ({ data: file }) => {
			let arquivo = new Blob([file], { type: 'application/pdf' });
			let arquivoURL = URL.createObjectURL(arquivo);
			let danfe = window.open(arquivoURL);
			danfe.onload = function () {
				setTimeout(() => {
					danfe.document.title = 'Espelho NF-e';
				}, 250);
			};
		});
	}

	async function baixarXmlRejeicao() {
		await imprimirXMLRejeicaoNfe(
			values.id,
			({ data: xml }) => {
				let blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
				saveAs(blob, `${values.chave ?? values.id}.xml`);
			},
			({ response }) => {
				notify(response.data.details[0], ToastTypes.ERROR, 8);
			}
		);
	}

	function excluir() {
		const mensagem = montarMensagemToast(dadosBase.status, Operacao.EXCLUIR);
		confirm('Atenção', 'Tem certeza que deseja excluir?', () => {
			asyncDeleteNfe(values.id, mensagem, () => {
				voltarParaAPesquisa(history, '/nfes');
			});
		});
	}

	function getTitleButtonTransmitir() {
		if (credencial?.filialConectada?.parametrosFiscalNFe) {
			if (!credencial?.filialConectada?.parametrosFiscalCertificado) {
				return 'Filial sem certificado digital configurado para transmissão de NF-e';
			} else if (!credencial?.filialConectada?.parametrosFiscalNFe.serieNfe) {
				return 'Série da nota fiscal não configurada';
			}
		}
		return '';
	}

	async function transmitirNfe() {
		try {
			const produtosInativos = [];
			values.produtos?.map((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
			});
			if (produtosInativos?.length > 0) {
				erroProdutoInativo(produtosInativos);
				throw new Error('Stopping the function!');
			}
			setIsFirstRender(true);
			setExibirLoadingTransmissao(true);
			asyncTransmitirNFe(
				values.id,
				() => {
					setExibirLoadingTransmissao(false);
					asyncSelectRegistro(values.id);
				},
				() => {
					setExibirLoadingTransmissao(false);
					asyncSelectRegistro(values.id);
				}
			);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, NF-e contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	return (
		<>
			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup={true} ref={menuOpcoes} />
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty || existeParcelaRecebida} onClick={salvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={onClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id} onClick={excluir} {...informacoesPermissoes} />
			<Button
				className="p-button-success"
				label="Transmitir NF-e"
				icon="fa fa-send"
				title={getTitleButtonTransmitir()}
				onClick={transmitirNfe}
				disabled={desabilitarBotaoTransmitir}
				style={{ margin: '5px' }}
				hidden={values.status !== Status.NAO_ENVIADA && values.status !== Status.REJEITADA}
				{...informacoesPermissoes}
			/>
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={itensOpcoes.length === 0}
				onClick={(event) => menuOpcoes.current.toggle(event)}
			/>
		</>
	);
}
