export const PAPEL_TIPO = {
	ADMINISTRADOR: 'ADMINISTRADOR',
	CONTABILIDADE: 'CONTABILIDADE',
	NORMAL: 'NORMAL',
};

export const INITIAL_VALUES = {
	id: null,
	nome: null,
	permissoes: {},
};

export const CADASTROURL = '/papeis/cadastro';
export const PESQUISAURL = '/papeis';
