function converterItensEntreguesParaForm(itensEntreguesApi) {
	if (itensEntreguesApi.length > 0) {
		return itensEntreguesApi?.map((item) => ({
			produto: {
				codigo: item.produtoCodigo,
				nome: item.produtoNome,
			},
			entrega: {
				id: item.entregaId,
				data: item.entregaData,
				quantidade: item.entregaQuantidade,
				valor: item.entregaValor,
				subtotal: item.entregaSubtotal,
				idVendaItem: item.vendaItemId,
				idMovimentacao: item.movimentacaoId,
			},
		}));
	}
	return [];
}

export { converterItensEntreguesParaForm };
