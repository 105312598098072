import { Field, useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Checkbox,
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Prompt,
	Tutorial,
	tutorialStepsNaturezaOperacao,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { readNaturezaOperacao } from '../Requests';
import { converterNaturezaOperacaoParaFormulario } from '../Util/NaturezaOperacaoConverter';
import { CADASTRO_URL, INITIAL_VALUES, NATUREZA_OPERACAO_OPTIONS } from '../Util/constantes';
import { ActionButtons } from './components/ActionButtons';

function NaturezaOperacaoFormImpl({ isMobile, history, isModal, match, hideModal }) {
	const { values, dirty, resetForm, errors, setFieldValue } = useFormikContext();
	const podeInserir = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.EXCLUIR);

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NATUREZA_OPERACAO)
	);

	const informacoesPermissoes = {
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		let id = null;
		if (match?.params) {
			id = match.params.id;
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NATUREZA_OPERACAO, false, null, false);
		}

		if (validarUUID(id) && !isModal) {
			fetchRegistro(id);
		}

		setTimeout(() => {
			document.getElementById('natureza-operacao-input-descricao')?.focus();
		}, 300);
	}, []);

	async function fetchRegistro(id) {
		await readNaturezaOperacao(id, ({ data: naturezaOperacaoApi }) => {
			resetForm({ values: converterNaturezaOperacaoParaFormulario(naturezaOperacaoApi) });
			if (!isModal) {
				atualizarUrl(history, CADASTRO_URL, naturezaOperacaoApi.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsNaturezaOperacao}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form
				className="card-default screen-max-width"
				header={isMobile ? 'Cadastro natureza de operação' : 'Cadastro de natureza de operação'}
				isModal={isModal}
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						isModal={isModal}
						history={history}
						hideModal={hideModal}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							component={Checkbox}
							label="Natureza de operação favorita"
							title="Marque esta opção caso esta natureza de operação seja a favorita"
							name="favorito"
							onChange={() => setFieldValue('favorito', !values.favorito)}
							errors={errors.favorito}
							touched
							checked={values.favorito}
							{...informacoesPermissoes}
						/>
						<Field
							id="natureza-operacao-input-descricao"
							sm="12"
							md="10"
							lg="10"
							xl="10"
							component={InputField}
							label="Descrição"
							obrigatorio
							name="descricao"
							size={100}
							onChange={(e) => setFieldValue('descricao', e.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="2"
							lg="2"
							xl="2"
							component={Dropdown}
							label="Situação"
							name="situacao"
							onChange={(event) => setFieldValue('situacao', event.value)}
							options={NATUREZA_OPERACAO_OPTIONS}
							showClear={false}
							disabled={isModal}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const NaturezaOperacaoFormFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (!values.descricao) {
			errors.descricao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.situacao) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.situacao === 'INATIVO' && values.favorito) {
			errors.favorito = 'Natureza de operação inativa não pode ser favorita';
		}

		return errors;
	},

	handleSubmit: () => {},
})(NaturezaOperacaoFormImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const NaturezaOperacaoForm = connect(mapStateToProps)(NaturezaOperacaoFormFormik);
