import { colors, formatarMonetario } from 'Common';
import { If, InputMoney, SingleSelectCategoria, SingleSelectConta, SingleSelectFormaPagamento } from 'components';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { Field, useFormikContext } from 'formik';

function FieldsAVista({
	value,
	handleChangeFormaPagamento,
	handleChangeConta,
	handleChangeCategoria,
	handleChangeValor,
	onBlurValor,
	indexPagamento,
	handleChangeValorRecebido,
}) {
	const { errors } = useFormikContext();

	const { 
		quantidadeDePagamentos, 
		urls, 
		isNfce, 
		disabledFields,
		informacoesPermissoes,
		disabledValorRecebido,
		isMobile,
		isTablet,
	} = useContextPagamentos();

	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				name="formaPagamento"
				label="Forma de pagamento"
				helpMessage="Forma de pagamento"
				component={SingleSelectFormaPagamento}
				value={value.formaPagamento}
				onChange={handleChangeFormaPagamento}
				url={urls.formaPagamento}
				disabled={disabledFields}
				obrigatorio
				isClearable={false}
				colStyle={{ paddingBottom: '20px' }}
				touched
				errors={errors[indexPagamento]?.formaPagamento}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md={isNfce ? '6' : '4'}
				lg={isNfce ? '3' : '2'}
				xl={isNfce ? '3' : '2'}
				name="conta"
				label="Conta"
				helpMessage="Conta"
				placeholder="Pesquisar"
				component={SingleSelectConta}
				value={value.conta}
				onChange={handleChangeConta}
				url={urls.conta}
				disabled={disabledFields}
				obrigatorio
				isClearable={false}
				touched
				errors={errors[indexPagamento]?.conta}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md={isNfce ? '6' : '5'}
				lg={isNfce ? '3' : '2'}
				xl={isNfce ? '3' : '2'}
				label="Categoria(s)"
				name="categorias"
				helpMessage="Categoria(s)"
				placeholder="Pesquisar"
				component={SingleSelectCategoria}
				value={value.categorias}
				onChange={handleChangeCategoria}
				url={urls.categoria}
				disabled={disabledFields}
				obrigatorio
				isClearable={false}
				touched
				errors={errors[indexPagamento]?.categorias}
				useFormErrors={false}
				withList
				valueTotalList={value.valor}
				{...informacoesPermissoes}
				values={value}
			/>
			<Field
				sm="12"
				md={isNfce ? '6' : '3'}
				lg={isNfce ? '3' : '2'}
				xl={isNfce ? '3' : '2'}
				label="Valor"
				name="valor"
				helpMessage="Valor"
				component={InputMoney}
				value={value.valor}
				onChange={handleChangeValor}
				onBlur={onBlurValor}
				allowNegative={false}
				size={13}
				prefix="R$ "
				placeholder="R$ 0,00"
				obrigatorio
				colStyle={{ paddingRight: '12px' }}
				disabled={disabledFields || quantidadeDePagamentos === 1}
				touched
				errors={errors[indexPagamento]?.valor}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<If test={isNfce}>
				<Field
					sm="12"
					md="6"
					lg="3"
					xl="3"
					component={InputMoney}
					value={value.valorRecebido}
					onChange={handleChangeValorRecebido}
					label="Valor recebido"
					name="valorRecebido"
					disabled={disabledValorRecebido}
					allowNegative={false}
					size={13}
					prefix="R$ "
					placeholder="R$ 0,00"
					touched
					useFormErrors={false}
					errors={errors[indexPagamento]?.valorRecebido}
					{...informacoesPermissoes}
				/>
				<div
					style={{
						fontSize: '24px',
						fontWeight: 'bold',
						color: value?.valorTroco < 0 ? colors.vermelho : colors.verde,
						display: 'flex',
						alignItems: 'center',
						marginTop:
							isMobile ||
							isTablet ||
							(errors?.pagamentos?.length > 0 &&
								(errors?.pagamentos[indexPagamento]?.valorRecebido || errors?.pagamentos[indexPagamento]?.valor))
								? '0px'
								: '18px',
						marginLeft: isMobile || isTablet ? '0px' : '8px',
					}}
					title={
						value?.valorTroco < 0
						? 'Valor que o Cliente deve para fechar o pagamento'
						: 'Valor a ser devolvido para o cliente'
					}
				>
					Troco: {formatarMonetario(value?.valorTroco)}
				</div>
			</If>
		</>
	);
}

export { FieldsAVista };
