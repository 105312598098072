import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar, notify, ToastTypes } from 'components';
import { useFormikContext } from 'formik';
import { validarFormulario } from 'views/Util';
import { converterProdutosModalDevolucaoCondicionalParaProdutosPedido } from '../../../../Form/Util/PedidovendasOrcamentosConverter';
import { copiarObjeto } from '../../../../../../../../Common';

function ActionButtons({
	informacoesPermissoes,
	onHide,
	atualizarTotalizadoresProdutosPedido,
	setFieldValuesPedido,
	valuesFormPedido,
}) {
	const { dirty, handleReset, validateForm, values } = useFormikContext();

	function cancelar() {
		dirty ? handleReset() : onHide();
	}

	async function devolverProdutos() {
		if (
			await validarFormulario({
				validateForm,
				values: values.produtos,
			})
		) {

			const produtosModal = values.produtos;

			const produtosModalParaPedido = produtosModal.map((produtoModal, index) => {
				const produtoOriginalPedido = copiarObjeto(valuesFormPedido.produtos[produtoModal.item - 1]);
				produtoOriginalPedido.item = index + 1;
				if (produtoModal.foiAlterado) {
					return converterProdutosModalDevolucaoCondicionalParaProdutosPedido(produtoModal, produtoOriginalPedido);
				}
				return produtoOriginalPedido;
			})

			setFieldValuesPedido(`produtos`, produtosModalParaPedido);

			atualizarTotalizadoresProdutosPedido();

			onHide();
		} else {
			notify('Ocorreu um erro na devolução dos produtos', ToastTypes.ERROR);
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar onClick={devolverProdutos} disabled={!dirty} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
