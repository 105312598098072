import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { isValid, parseISO, format, formatISO, startOfMonth } from 'date-fns';
import { withFormik, Field } from 'formik';
import { Dropdown, Grid, InputDate, MultipleSelectSetores, SingleSelectPessoa, ModalRelatorio } from 'components';
import { mensagensDeValidacao, services } from 'Common';
import { helpMessage, opcoesCusto } from './util/constantes';
import { asyncBaixarRelatorioORcamentosPorPeriodo } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Requests';
import { validarFormulario } from 'views/Util';
import SingleSelectSituacao from 'components/select/SingleSelectSituacao';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	situacao: null,
	cliente: null,
	setores: null,
	custo: 'NAO_EXIBIR_CUSTO',
};

function OrcamentosPorPeriodo(props) {
	const { visible, onHide, values, handleSubmit, validateForm, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		try {
			let dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
			let dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
			let setores = '';

			if (values.setores) {
				setores = values.setores.map((item) => `'${item.value}'`).join(',');
			}

			if (await validarFormulario(props)) {
				await asyncBaixarRelatorioORcamentosPorPeriodo(
					dataInicialFormatada,
					dataFinalFormatada,
					values.situacao?.value,
					values.cliente?.value,
					setores,
					values.custo,
					({ data: pdf }) => {
						let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
						let arquivoURL = URL.createObjectURL(arquivoPDF);
						let relatorio = window.open(arquivoURL);
						if (relatorio) {
							relatorio.onload = () => {
								setTimeout(() => {
									relatorio.document.title = 'Relatório Orçamentos por período';
								}, 250);
							};
						}
						onHide();
					}
				);
			}
		} catch (e) {
			console.error(e);
		}
	}

	const parametros = (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data inicial"
				name="dataInicial"
				obrigatorio
				helpMessage={helpMessage.dataInicial}
				value={values.dataInicial}
				touched={true}
				id="InputDataInicial"
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={InputDate}
				label="Data final"
				name="dataFinal"
				obrigatorio
				helpMessage={helpMessage.dataFinal}
				value={values.dataFinal}
				touched={true}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				label="Cliente"
				name="cliente"
				component={SingleSelectPessoa}
				esconderBotao={true}
				value={values?.cliente}
				helpMessage={helpMessage.cliente}
				onChange={(e) => setFieldValue('cliente', e)}
				showClear={true}
			/>			
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={SingleSelectSituacao}
				url={`${services.GESTOR}/v1/orcamentos/relacoes/situacoes`}				
				label="Situação"
				name="situacao"
				onChange={(value) => setFieldValue('situacao', value)}
				value={values?.situacao}
				helpMessage={helpMessage.situacao}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Custo a utilizar"
				name="custo"
				onChange={(e) => setFieldValue('custo', e.value)}
				options={opcoesCusto}
				value={values.custo}
				showClear={false}
			/>
			<Field
				sm="12"
				md="6"
				lg="6"
				xl="6"
				component={MultipleSelectSetores}
				label="Setor(es)"
				name="setores"
				obrigatorio
				url={`${services.GESTOR}/v1/orcamentos/relacoes/setores`}
				onChange={(e) => setFieldValue('setores', e)}
				value={values.setores}
				showClear={false}
				utilizaFavorito={true}
			/>
		</Grid>
	);

	return (
		<ModalRelatorio
			header={'Relatório orçamentos por período'}
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={Object.keys(props.errors).length === 0}
		/>
	);
}

const ModalOrcamentosPorPeriodo = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		setores: Yup.array().min(1, mensagensDeValidacao.OBRIGATORIO).nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(OrcamentosPorPeriodo);

export default ModalOrcamentosPorPeriodo;
