import { formatarMonetarioDecimais } from 'Common';
import { Divider } from 'components';
import { Tooltip } from 'primereact/tooltip';

const styleDiv = { display: 'flex', justifyContent: 'space-between' };
const stylePrimeiraSpan = { marginLeft: '4px', marginRight: '16px' };
const styleSpanSomaSubtracao = {
   display: 'flex',
   justifyContent: 'space-between',
   marginLeft: '8px',
   maxWidth: '25px',
   minWidth: '25px',
}

function TooltipTotal({target, disabled, valores}) {
   return (
      <Tooltip
         target={target}
         position='top'
         showDelay={500}
         disabled={disabled}
      >
         <div>
            <div style={styleDiv}>
               <span style={stylePrimeiraSpan}>{`Total dos produtos: `}</span>
               <span>{formatarMonetarioDecimais(valores.produtos, 2)}</span>
            </div>
            <div style={styleDiv}>
               <span style={{ display: 'flex' }}>
                  <span style={styleSpanSomaSubtracao}>
                     <span>(</span>
                     <span>+</span>
                     <span>)</span>
                  </span>
                  <span style={stylePrimeiraSpan}>{`Acessórias: `}</span>
               </span>
               <span>{formatarMonetarioDecimais(valores.acessorias, 2)}</span>
            </div>
            <div style={styleDiv}>
               <span style={{ display: 'flex' }}>
                  <span style={styleSpanSomaSubtracao}>
                     <span>(</span>
                     <span>-</span>
                     <span>)</span>
                  </span>
                  <span style={stylePrimeiraSpan}>{`Desconto: `}</span>
               </span>
               <span>{formatarMonetarioDecimais(valores.desconto, 2)}</span>
            </div>
            <Divider styleContainer={{ margin: '2px 0px' }} />
            <div style={{ ...styleDiv, fontWeight: 'bold' }}>
               <span>{`Total: `}</span>
               <span>{formatarMonetarioDecimais(valores.total, 2)}</span>
            </div>
         </div>
      </Tooltip>
   )
}

export { TooltipTotal };