import { formatISO } from 'date-fns';
import { Field } from 'formik';
import { Status } from '../../../../../views/fiscal/vendas/PedidoVendasOrcamentos/Util/constantes';
import { InputDate, If } from '../../../../index';
import { moduloUtilizado as modulos } from '../../../Util/constantes';

export default function DataPorModulo(props) {
	const { moduloUtilizado, helpForm, values, informacoesPermissoes, setFieldValue, errors } = props;

	function onChangeDataValidade(e) {
		if (Date.parse(e.target.value) >= Date.parse(formatISO(new Date()).substring(0, 10))) {
			props.setFieldValue('status', Status.PENDENTE);
		} else {
			props.setFieldValue('status', Status.EXPIRADO);
		}
		props.setFieldValue('validade', e.target.value);
	}

	return (
		<>
			<Field
				sm="12"
				md="3"
				lg={moduloUtilizado === modulos.ORCAMENTO ? '3' : '2'}
				xl={moduloUtilizado === modulos.ORCAMENTO ? '3' : '2'}
				component={InputDate}
				name="dataEmissao"
				label={`${moduloUtilizado === modulos.NOTA_FISCAL ? 'Data de emissão' : 'Data de criação'}`}
				helpMessage={helpForm.emissao}
				value={values?.dataEmissao}
				onChange={(e) => props.setFieldValue('dataEmissao', e.target.value)}
				errors={errors.dataEmissao}
				{...informacoesPermissoes}
			/>
			<If test={moduloUtilizado === modulos.NOTA_FISCAL}>
				<Field
					sm="12"
					md="3"
					lg="2"
					xl="2"
					component={InputDate}
					name="dataSaida"
					label="Data de saída"
					helpMessage={helpForm.saida}
					value={values?.dataSaida}
					onChange={(e) => setFieldValue('dataSaida', e.target.value)}
					errors={errors.dataSaida}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={moduloUtilizado === modulos.PEDIDO_VENDA}>
				<Field
					sm="12"
					md="3"
					lg="2"
					xl="2"
					component={InputDate}
					label="Previsão de entrega"
					name="previsaoEntrega"
					helpMessage={helpForm.previsaoEntrega}
					touched={true}
					onChange={(e) => setFieldValue('previsaoEntrega', e.target.value)}
					value={values?.previsaoEntrega}
					errors={errors.previsaoEntrega}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={moduloUtilizado === modulos.ORCAMENTO}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputDate}
					label="Validade do orçamento"
					name="validade"
					disabled={props.existeParcelaRecebida}
					helpMessage={helpForm.validade}
					onChange={(e) => onChangeDataValidade(e)}
					touched
					value={values?.validade}
					errors={errors.validade}
					{...informacoesPermissoes}
				/>
			</If>
		</>
	);
}
