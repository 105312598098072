import { permissoes } from 'Common';
import { Operacao, Status, Tipo } from './constantes';

export function montarMensagemToast(tipo, operacao) {
	switch (tipo) {
		case Tipo.PEDIDO_VENDA: {
			if (operacao === Operacao.EDITAR) {
				return 'Pedido de venda alterado com sucesso';
			} else if (operacao === Operacao.EXCLUIR) {
				return 'Pedido de venda excluído com sucesso';
			} else if (operacao === Operacao.INSERIR) {
				return 'Pedido de venda incluído com sucesso';
			}
			break;
		}
		case Tipo.ORCAMENTO: {
			if (operacao === Operacao.EDITAR) {
				return 'Orçamento alterado com sucesso';
			} else if (operacao === Operacao.EXCLUIR) {
				return 'Orçamento excluído com sucesso';
			} else if (operacao === Operacao.INSERIR) {
				return 'Orçamento incluído com sucesso';
			}
			break;
		}
		default:
			break;
	}
}

export function buscarPermissãoPorTipoSituacao(tipo) {
	switch (tipo) {
		case Status.PENDENTE:
			return permissoes.EDITAR;
		case Status.FINALIZADO:
			return permissoes.FINALIZAR;
		case Status.CANCELADO:
			return permissoes.CANCELAR;
		case Status.EXPIRADO:
			return permissoes.EDITAR;
		default:
			return permissoes.EDITAR;
	}
}
