import { Field, useFormikContext } from 'formik';

import { Dropdown, Card, Grid, Subtitle } from 'components';

import { useEffect, useState } from 'react';
import {
	BOOLEAN_OPTIONS,
	HELP_MESSAGE,
	SITUACAO_PARAMETROS_TIPO_ESTOQUE,
	SITUACAO_PARAMETROS_TIPO_ESTOQUE_OPTIONS,
	SITUACAO_PARAMETROS_TIPO_FISCAL,
	SITUACAO_PARAMETROS_TIPO_FISCAL_OPTIONS,
} from '../../Utils/constantes';
import { SITUACAO_TIPO } from '../../../Utils/constantes';

function ParametrosSituacao({ informacoesPermissoes, isFirstRender }) {
	const { values, setFieldValue } = useFormikContext();
	const [situacoesFinaisQueGeramFiscal, setSituacoesFinaisQueGeramFiscal] = useState([]);
	const [situacoesFinaisParaCancelamento, setSituacoesFinaisParaCancelamento] = useState([]);
	const [situacoesFinaisParaFinalizado, setSituacoesFinaisParaFinalizado] = useState([]);

	useEffect(() => {
		const listaSituacoesFinaisQueGeramFiscal = values.etapasVinculadas.filter(
			(etapa) =>
				etapa.registro?.tipo === SITUACAO_TIPO.FINALIZADO &&
				etapa.registro?.tipoFiscal === SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL
		);
		setSituacoesFinaisQueGeramFiscal(listaSituacoesFinaisQueGeramFiscal || []);
		if (values.parametros?.situacaoFinalDocumentoGerado) {
			const listaVerificaSituacaoDocumentoGeradoAindaEstaVinculado = new Set(
				listaSituacoesFinaisQueGeramFiscal.map((situacao) => situacao.value)
			);

			if (
				!isFirstRender &&
				!listaVerificaSituacaoDocumentoGeradoAindaEstaVinculado.has(values.parametros.situacaoFinalDocumentoGerado)
			) {
				setFieldValue('parametros.situacaoFinalDocumentoGerado', null);
			}
		}

		const listaSituacoesFinaisParaCancelamento = values.etapasVinculadas.filter(
			(etapa) => etapa.registro?.tipo === SITUACAO_TIPO.CANCELADO
		);
		setSituacoesFinaisParaCancelamento(listaSituacoesFinaisParaCancelamento || []);
		if (values.parametros?.situacaoFinalCancelamento) {
			const listaVerificaSituacaoCancelamentoAindaEstaVinculado = new Set(
				listaSituacoesFinaisParaCancelamento.map((situacao) => situacao.value)
			);

			if (
				!isFirstRender &&
				!listaVerificaSituacaoCancelamentoAindaEstaVinculado.has(values.parametros.situacaoFinalCancelamento)
			) {
				setFieldValue('parametros.situacaoFinalCancelamento', null);
			}
		}

		const listaSituacoesFinaisParaFinalizado = values.etapasVinculadas.filter(
			(etapa) => etapa.registro?.tipo === SITUACAO_TIPO.FINALIZADO
		);
		setSituacoesFinaisParaFinalizado(listaSituacoesFinaisParaFinalizado || []);
		if (values.parametros?.situacaoFinalFinalizado) {
			const listaVerificaSituacaoFinalizadoAindaEstaVinculado = new Set(
				listaSituacoesFinaisParaFinalizado.map((situacao) => situacao.value)
			);

			if (
				!isFirstRender &&
				!listaVerificaSituacaoFinalizadoAindaEstaVinculado.has(values.parametros.situacaoFinalFinalizado)
			) {
				setFieldValue('parametros.situacaoFinalFinalizado', null);
			}
		}
	}, [values.etapasVinculadas]);

	useEffect(() => {
		if (
			!isFirstRender &&
			values.parametros?.tipoFiscal === SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL &&
			values.parametros?.situacaoFinalDocumentoGerado
		) {
			setFieldValue('parametros.situacaoFinalDocumentoGerado', null);
		}
	}, [values.parametros?.tipoFiscal]);

	useEffect(() => {
		if (!isFirstRender && !values.parametros?.permiteFinalizarENovo) {
			setFieldValue('parametros.situacaoFinalFinalizado', null);
		}
	}, [values.parametros?.permiteFinalizarENovo]);

	function verificarDisabledCampos(campo) {
		const camposBloqueadosSeEstornarMovimentacoes = ['tipoEstoqueGerado', 'movimentaFinanceiro'];
		const camposBloqueadosSeFinalizarENovoDesativado = ['situacaoFinalFinalizado'];
		const camposBloqueadosQuandoGeraDocumentoFiscal = ['situacaoFinalDocumentoGerado'];
		const camposBloqueadosQuandoSituacaoInicial = ['tipoEstoqueGerado'];
		const camposDesativadosPorTipo = {
			[SITUACAO_TIPO.PENDENTE]: ['estornaMovimentacoes', 'movimentaFinanceiro', 'permiteDevolucao'],
			[SITUACAO_TIPO.CANCELADO]: [
				'estornaMovimentacoes',
				'tipoEstoqueGerado',
				'movimentaFinanceiro',
				'tipoFiscal',
				'situacaoFinalDocumentoGerado',
				'permiteDevolucao',
				'permiteDevolucaoCondicional',
				'permiteEntregaParcial',
			],
			[SITUACAO_TIPO.FINALIZADO]: ['estornaMovimentacoes', 'permiteDevolucaoCondicional', 'permiteEntregaParcial'],
		};

		const disabledPorTipo =
			values.tipo in camposDesativadosPorTipo && camposDesativadosPorTipo[values.tipo].includes(campo);
		const disabledQuandoEstornaMovimentacoes =
			values.estornaMovimentacoes && camposBloqueadosSeEstornarMovimentacoes.includes(campo);
		const disabledQuandoGeraDocumentoFiscal =
			values.parametros.tipoFiscal === SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL &&
			camposBloqueadosQuandoGeraDocumentoFiscal.includes(campo);
		const disabledQuandoFinalizarENovoDesativado =
			!values.parametros.permiteFinalizarENovo && camposBloqueadosSeFinalizarENovoDesativado.includes(campo);
		const disabledQuandoSituacaoInicial =
			values.situacaoInicial && camposBloqueadosQuandoSituacaoInicial.includes(campo);

		return (
			disabledPorTipo ||
			disabledQuandoEstornaMovimentacoes ||
			disabledQuandoFinalizarENovoDesativado ||
			disabledQuandoGeraDocumentoFiscal ||
			disabledQuandoSituacaoInicial
		);
	}

	function onChangeEstornaMovimentacoes(event) {
		setFieldValue('parametros.estornaMovimentacoes', event.value);

		if (event.value) {
			setFieldValue(
				'parametros.tipoEstoqueGerado',
				SITUACAO_PARAMETROS_TIPO_ESTOQUE_OPTIONS.find(
					(opcao) => opcao.value === SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM
				).value
			);
			setFieldValue('parametros.movimentaFinanceiro', false);
		}
	}

	return (
		<Card style={{ marginTop: '1rem' }} className="step-cadastro-situacao-parametros">
			<Grid>
				<Subtitle subtitle="Comportamento da situação" />
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Tipo de estoque gerado"
					name="parametros.tipoEstoqueGerado"
					onChange={(event) => setFieldValue('parametros.tipoEstoqueGerado', event.value)}
					options={SITUACAO_PARAMETROS_TIPO_ESTOQUE_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('tipoEstoqueGerado')}
					title={HELP_MESSAGE.parametros.tipoEstoqueGerado}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Estorna movimentações"
					name="parametros.estornaMovimentacoes"
					onChange={onChangeEstornaMovimentacoes}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('estornaMovimentacoes')}
					title={HELP_MESSAGE.parametros.estornaMovimentacoes}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Movimenta financeiro"
					name="parametros.movimentaFinanceiro"
					onChange={(event) => setFieldValue('parametros.movimentaFinanceiro', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('movimentaFinanceiro')}
					title={HELP_MESSAGE.parametros.movimentaFinanceiro}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Tipo fiscal"
					name="parametros.tipoFiscal"
					onChange={(event) => setFieldValue('parametros.tipoFiscal', event.value)}
					options={SITUACAO_PARAMETROS_TIPO_FISCAL_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('tipoFiscal')}
					title={HELP_MESSAGE.parametros.tipoFiscal}
					{...informacoesPermissoes}
				/>
			</Grid>

			<Grid style={{ marginTop: '8px' }}>
				<Subtitle subtitle="Permissões" />
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Permite devoluções"
					name="parametros.permiteDevolucao"
					onChange={(event) => setFieldValue('parametros.permiteDevolucao', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('permiteDevolucao')}
					title={HELP_MESSAGE.parametros.permiteDevolucao}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Permite devoluções condicionais"
					name="parametros.permiteDevolucaoCondicional"
					onChange={(event) => setFieldValue('parametros.permiteDevolucaoCondicional', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('permiteDevolucaoCondicional')}
					title={HELP_MESSAGE.parametros.permiteDevolucaoCondicional}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Permite entrega parcial"
					name="parametros.permiteEntregaParcial"
					onChange={(event) => setFieldValue('parametros.permiteEntregaParcial', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('permiteEntregaParcial')}
					title={HELP_MESSAGE.parametros.permiteEntregaParcial}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Permite gerar assinatura"
					name="parametros.permiteGerarAssinatura"
					onChange={(event) => setFieldValue('parametros.permiteGerarAssinatura', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('permiteGerarAssinatura')}
					title={HELP_MESSAGE.parametros.permiteGerarAssinatura}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Permite impressões"
					name="parametros.permiteImpressoes"
					onChange={(event) => setFieldValue('parametros.permiteImpressoes', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('permiteImpressoes')}
					title={HELP_MESSAGE.parametros.permiteImpressoes}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Permite compartilhamento"
					name="parametros.permiteCompartilhamento"
					onChange={(event) => setFieldValue('parametros.permiteCompartilhamento', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('permiteCompartilhamento')}
					title={HELP_MESSAGE.parametros.permiteCompartilhamento}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="3"
					component={Dropdown}
					label="Permite finalizar e novo"
					name="parametros.permiteFinalizarENovo"
					onChange={(event) => setFieldValue('parametros.permiteFinalizarENovo', event.value)}
					options={BOOLEAN_OPTIONS}
					showClear={false}
					disabled={verificarDisabledCampos('permiteFinalizarENovo')}
					title={HELP_MESSAGE.parametros.permiteFinalizarENovo}
					{...informacoesPermissoes}
				/>
			</Grid>

			<Grid style={{ marginTop: '8px' }}>
				<Subtitle subtitle="Situações finais vinculadas" />
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Situação final quando doc. fiscal gerado"
					name="parametros.situacaoFinalDocumentoGerado"
					onChange={(event) => setFieldValue('parametros.situacaoFinalDocumentoGerado', event.value)}
					options={situacoesFinaisQueGeramFiscal}
					showClear={false}
					disabled={verificarDisabledCampos('situacaoFinalDocumentoGerado')}
					title={HELP_MESSAGE.parametros.situacaoFinalDocumentoGerado}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Situação final cancelamento"
					name="parametros.situacaoFinalCancelamento"
					onChange={(event) => setFieldValue('parametros.situacaoFinalCancelamento', event.value)}
					options={situacoesFinaisParaCancelamento}
					showClear={false}
					disabled={verificarDisabledCampos('situacaoFinalCancelamento')}
					title={HELP_MESSAGE.parametros.situacaoFinalCancelamento}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="6"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Situação final finalizado"
					name="parametros.situacaoFinalFinalizado"
					onChange={(event) => setFieldValue('parametros.situacaoFinalFinalizado', event.value)}
					options={situacoesFinaisParaFinalizado}
					showClear={false}
					disabled={verificarDisabledCampos('situacaoFinalFinalizado')}
					title={HELP_MESSAGE.parametros.situacaoFinalFinalizado}
					{...informacoesPermissoes}
				/>
			</Grid>
		</Card>
	);
}

export { ParametrosSituacao };
