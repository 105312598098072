export const OPTIONS_MUNICIPIO_PRESTACAO = [
	{ label: 'Prestador', value: 'PRESTADOR' },
	{ label: 'Tomador', value: 'TOMADOR' },
];

export const MUNICIPIO_PRESTACAO = {
	PRESTADOR: 'PRESTADOR',
	TOMADOR: 'TOMADOR',
};

const TIPO_ALERTA_VIGENCIA = {
	SETOR: 'SETOR',
	USUARIO: 'USUARIO',
};

const TITULO_IMPRESSO_ASSINATURA = {
	COMPROVANTE: 'COMPROVANTE',
	MANIFESTO: 'MANIFESTO',
};

export const TIPO_ALERTA_VIGENCIA_OPTIONS = [
	{ label: 'Setor', value: 'SETOR' },
	{ label: 'Usuário', value: 'USUARIO' },
];

export const TITULO_IMPRESSO_ASSINATURA_OPTIONS = [
	{ label: 'Comprovante', value: 'COMPROVANTE' },
	{ label: 'Manifesto', value: 'MANIFESTO' },
];

export const TIPO_EMISSAO_CTE = {
	NORMAL: 'NORMAL',
	REGIME_ESPECIAL_NFF: 'REGIME_ESPECIAL_NFF',
	EPEC_SVC: 'EPEC_SVC',
	CONTINGENCIA_FS_DA: 'CONTINGENCIA_FS_DA',
	CONTINGENCIA_SVC_RS: 'CONTINGENCIA_SVC_RS',
	CONTINGENCIA_SVC_SP: 'CONTINGENCIA_SVC_SP',
};

export const TIPO_EMISSAO_CTE_OPTIONS = [
	{
		label: 'Normal',
		value: TIPO_EMISSAO_CTE.NORMAL,
	},
	{
		label: 'Regime Especial NFF',
		value: TIPO_EMISSAO_CTE.REGIME_ESPECIAL_NFF,
	},
	{
		label: 'EPEC pela SVC',
		value: TIPO_EMISSAO_CTE.EPEC_SVC,
	},
	{
		label: 'Contingência FSDA',
		value: TIPO_EMISSAO_CTE.CONTINGENCIA_FS_DA,
	},
	{
		label: 'Contingência SVC-RS',
		value: TIPO_EMISSAO_CTE.CONTINGENCIA_SVC_RS,
	},
	{
		label: 'Contingência SVC-SP',
		value: TIPO_EMISSAO_CTE.CONTINGENCIA_SVC_SP,
	},
];

export const TIPO_IMPRESSAO_DACTE = {
	NORMAL_RETRATO: 'NORMAL_RETRATO',
	NORMAL_PAISAGEM: 'NORMAL_PAISAGEM',
};

export const TIPO_IMPRESSAO_DACTE_OPTIONS = [
	{
		label: 'Normal / Modo retrato',
		value: TIPO_IMPRESSAO_DACTE.NORMAL_RETRATO,
	},
	{
		label: 'Normal / Modo paisagem',
		value: TIPO_IMPRESSAO_DACTE.NORMAL_PAISAGEM,
	},
];

export const CTE_TIPO_TOMADOR_PADRAO = {
	REMETENTE: 'REMETENTE',
	DESTINATARIO: 'DESTINATARIO',
};

export const CTE_TIPO_TOMADOR_PADRAO_OPTIONS = [
	{
		value: CTE_TIPO_TOMADOR_PADRAO.REMETENTE,
		label: 'Remetente',
	},
	{
		value: CTE_TIPO_TOMADOR_PADRAO.DESTINATARIO,
		label: 'Destinatário',
	},
];

export const INITIAL_VALUES = {
	nome: null,
	nomeFantasia: null,
	razaoSocial: null,
	email: null,
	tipo: null,
	cnpj: null,
	telefone: null,
	inscricaoMunicipal: null,
	inscricaoEstadual: null,
	inscricaoEstadualSubstitutoTributario: null,
	cnae: null,
	regimeTributario: 'SIMPLES_NACIONAL',
	logradouro: null,
	numero: null,
	bairro: null,
	cep: null,
	municipio: null,
	pais: null,
	complemento: null,
	modulos: [],
	emiteNfe: false,
	emiteNfce: false,
	emiteNfse: false,
	emiteMdfe: false,
	emiteCte: false,
	alterarNumeroInicialESerieNfce: false,
	alterarNumeroInicialESerieNfceNfe: false,
	alterarNumeroInicialESerieNfceNfse: false,
	alterarNumeroInicialESerieMdfe: false,
	alterarNumeroInicialESerieCTe: false,

	// NFC-e
	bibliotecaNfce: 'MICROSYS',
	tipoImpressaoDanfeNfce: 'NORMAL_RETRATO',
	serieNfce: null,
	numeroInicialNfce: null,
	envioAutomaticoEmailNfce: true,
	codigoSegurancaContribuinteNfce: null,
	tokenSegurancaContribuinteNfce: null,
	operacaoFiscalNfce: null,

	// NF-e
	bibliotecaNfe: 'MICROSYS',
	serieNfe: null,
	tipoImpressaoDanfeNfe: 'NORMAL_RETRATO',
	impressaoFcpDanfeNfe: true,
	impressaoPartilhaDanfeNfe: true,
	numeroInicialNfe: null,
	envioAutomaticoEmailNfe: true,
	tipoEmissaoNfe: 'NORMAL',

	// NFS-e
	integracaoNfse: null,
	serieNfse: null,
	numeroInicialNfse: null,
	numeroInicialRps: null,
	loteInicialRps: null,
	envioAutomaticoEmailNfse: true,

	// MDF-e
	serieMdfe: null,
	numeroInicialMdfe: null,

	// CT-e
	tipoEmissaoCte: TIPO_EMISSAO_CTE.NORMAL,
	tipoImpressaoDacte: TIPO_IMPRESSAO_DACTE.NORMAL_RETRATO,
	serieCte: null,
	numeroInicialCte: null,

	logotipoUrl: null,
	certificado: null,
	certificadoAlterado: false,
	certificadoSenha: null,
	certificadoConfirmacaoSenha: null,
	percentualAliquotaSimples: null,
	mesVigenciaAliquotaSimples: null,

	urlServidorNfse: null,
	usuarioNfse: null,
	senhaNfse: null,

	parametrizacao: {
		financas: {
			enviarEmailAutomatico: null,
			mensagemEmailContasPagar: null,
			mensagemEmailContasReceber: null,
			taxaCategoria: null,
		},
		estoque: {
			gerarEstoqueNaNfe: null,
			permitirEstoqueNegativo: null,
		},
		documentos: {
			tipoGestaoDocumento: 'CONTRATO',
			emailAlertaVigencia: false,
			diasAlertaVigencia: 0,
			tipoAlertaVigencia: TIPO_ALERTA_VIGENCIA.SETOR,
			corpoEmail: null,
			signatariosPadrao: [],
			confirmacaoPadrao: [],
			assinaturaTituloImpresso: TITULO_IMPRESSO_ASSINATURA.COMPROVANTE,
		},
		cadastros: {
			decimaisQtd: null,
			decimaisPreco: null,
		},
		vendas: {
			codigoNaNfe: { label: 'Código', value: 'CODIGO' },
			codigoNaNfce: { label: 'Código de barras', value: 'CODIGO_BARRAS' },
			tipoImpressaoVenda: { label: 'A4', value: 'A4' },
			observacaoPadraoNfe: null,
			utilizaTabelaPreco: false,
			UtilizaTabelaPrecoPorRegiao: false,
			utilizaTabelaPrecoPromocional: false,
			utilizaTabelaPrecoDescontoQuantidade: false,
			utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao: false,
			utilizaTabelaPrecoParaServico: false,
			tabelaPrecoPadrao: null,
		},
		servicos: {
			padraoMunicipioPrestacao: MUNICIPIO_PRESTACAO.PRESTADOR,
		},
		transportes: {
			cteRntrc: null,
			cteCst: null,
			cteCfopIntraestadual: null,
			cteCfopInterestadual: null,
			cteLocalInicio: null,
			cteLocalTermino: null,
			cteTomadorPadrao: CTE_TIPO_TOMADOR_PADRAO.DESTINATARIO,
			cteRemetente: null,
			cteDestinatario: null,
			ctePercentualBaseIcms: 100,
			ctePercentualIcms: 0,
			cteImportarNfeTodasFiliais: false,
			mdfeImportarNfeTodasFiliais: false,
			mdfeImportarCteTodasFiliais: false,
		},
		controladoria: { usaGestaoFechamento: false },
		geral: {
			pesquisaInteracao: false,
		},
	},
};

export const TABS = {
	INFORMACOES_EMPRESA: 0,
	MODULOS: 1,
	PARAMETRIZACAO: 2,
	CONFIGURACOES_FISCAIS: 3,
	SEGURANCA: 4,
};

export const TIPO_OPERACAO = {
	ENTRADA: 'ENTRADA',
	SAIDA: 'SAIDA',
};

export const OPTIONS_REGIME_TRIBUTARIO = [
	{ label: 'Microempreendedor individual(MEI)', value: 'MEI' },
	{ label: 'Simples nacional', value: 'SIMPLES_NACIONAL' },
	{
		label: 'Simples Nacional, excesso sublimite de receita bruta',
		value: 'SIMPLES_NACIONAL_EXCESSO',
	},
	{ label: 'Regime normal', value: 'NORMAL' },
];
