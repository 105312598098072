import { colors } from 'Common';
import { tipoCampos } from 'components';

export const CADASTRO_URL = 'vendas/situacoes/cadastro';
export const PESQUISA_URL = 'vendas/situacoes';

export const COLORS = {
	bgAtivo: colors.verdeLight,
	textAtivo: colors.verde,
	bgInativo: colors.vermelhoLight,
	textInativo: colors.vermelho,
};

export const STYLE_BACKGROUND = {
	borderRadius: '20px',
	padding: '0.2rem 1.1rem',
};

export const SITUACAO_TIPO = {
	PENDENTE: 'PENDENTE',
	FINALIZADO: 'FINALIZADO',
	CANCELADO: 'CANCELADO',
};

export const SITUACAO_TIPO_OPTIONS = [
	{ label: 'Pendente', value: SITUACAO_TIPO.PENDENTE },
	{ label: 'Finalizado', value: SITUACAO_TIPO.FINALIZADO },
	{ label: 'Cancelado', value: SITUACAO_TIPO.CANCELADO },
];

export const SITUACAO = {
	ATIVO: 'ATIVO',
	INATIVO: 'INATIVO',
};

export const SITUACAO_OPTIONS = [
	{ label: 'Ativo', value: SITUACAO.ATIVO },
	{ label: 'Inativo', value: SITUACAO.INATIVO },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{ label: 'Texto botão', name: 'textoBotao', type: tipoCampos.STRING },
	{
		label: 'Tipo',
		name: 'tipo',
		type: tipoCampos.SELECT,
		optionSelect: SITUACAO_TIPO_OPTIONS,
	},
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: SITUACAO_OPTIONS,
	},
];
