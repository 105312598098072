import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { TabView } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { CLASSNAME_TABVIEW } from '../../Util/constantes';
import { TabServicos } from './components/TabServicos';
import { TabInformacaoCarga } from './components/TabInformacaoCarga';
import { TabImpostos } from './components/TabImpostos';

function TabsCargas({ totalizadores, setTotalizadores }) {
	const { activeTabCargas, setActiveTabCargas } = useContextCTe();
	const { values } = useFormikContext();

	return (
		<TabView
			className={`tab-view ${CLASSNAME_TABVIEW[values.situacao]}`}
			activeIndex={activeTabCargas}
			onTabChange={({ index }) => setActiveTabCargas(index)}
			renderActiveOnly={false}
		>
			<TabPanel header="Serviço">
				<TabServicos />
			</TabPanel>
			<TabPanel header="Informação de carga">
				<TabInformacaoCarga totalizadores={totalizadores} setTotalizadores={setTotalizadores} />
			</TabPanel>
			<TabPanel header="Impostos">
				<TabImpostos />
			</TabPanel>
		</TabView>
	);
}

export { TabsCargas };
