import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { Dropdown, DropdownMultiSelect, Card, Grid } from 'components';
import { services } from 'Common';

import { getSituacoesVinculadas } from '../../../Request';
import { converterSituacoesParaSelect } from '../../Utils/converter';
import { HELP_MESSAGE } from '../../Utils/constantes';

function SituacoesVinculadas({ informacoesPermissoes, buscarSituacoes, setBuscarSituacoes }) {
	const { values, setFieldValue } = useFormikContext();
	const [etapas, setEtapas] = useState([]);
	const [etapasIniciais, setEtapasIniciais] = useState([]);

	useEffect(() => {
		getSituacoes();
	}, [values.id]);

	useEffect(() => {
		if (buscarSituacoes) {
			getSituacoes();
			setBuscarSituacoes(false);
		}
	}, [buscarSituacoes]);

	function onChangeEtapasVinculadas(etapasSelecionadas) {
		const valoresSelecionados = new Set(etapasSelecionadas.map((etapa) => etapa.value));
		const etapasNaoSelecionadas = etapasIniciais.filter((etapa) => !valoresSelecionados.has(etapa.value));

		setEtapas(etapasNaoSelecionadas);
		setFieldValue('etapasVinculadas', etapasSelecionadas);

		if (values.etapaFavorita && !valoresSelecionados.has(values.etapaFavorita.value)) {
			setFieldValue('etapaFavorita', null);
		}
	}

	function getSituacoes() {
		getSituacoesVinculadas(`${services.GESTOR}/v1/vendas/situacao/relacoes/situacoes`, (situacoes) => {
			const etapasParaVincular = converterSituacoesParaSelect(situacoes.data.content).filter(
				(etapa) => etapa.value !== values.id
			);
			setEtapasIniciais(etapasParaVincular);
			setEtapas(etapasParaVincular);
		});
	}

	return (
		<Card style={{ marginTop: '1rem' }} className="step-cadastro-situacao-etapas">
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					obrigatorio
					component={DropdownMultiSelect}
					label="Etapas vínculadas"
					name="etapasVinculadas"
					value={values.etapasVinculadas}
					onChange={(event) => onChangeEtapasVinculadas(event)}
					options={etapas}
					showClear={false}
					title={HELP_MESSAGE.etapasVinculadas}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Dropdown}
					label="Etapas favorita"
					name="etapaFavorita"
					value={values.etapaFavorita}
					onChange={(event) => setFieldValue('etapaFavorita', event.value ? event : null)}
					options={values.etapasVinculadas}
					showClear
					title={HELP_MESSAGE.etapaFavorita}
					{...informacoesPermissoes}
				/>
			</Grid>
		</Card>
	);
}

export { SituacoesVinculadas };
