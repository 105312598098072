import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	keyFilterConsultaRsql,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Button,
	Col,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Tree,
	Tutorial,
	tutorialStepsPapeis,
} from 'components';
import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { atualizarUrl, metodosAtualizarUrl } from '../../../Util';
import { asyncBuscarEstruturaPapeis, asyncGetPapel } from '../Requests';
import { CADASTROURL, INITIAL_VALUES } from '../Util/constantes';
import { permissoesForamMarcadas } from '../Util/functions';
import { converterEstruturaParaTree, converterPapelParaFormulario } from '../Util/papelConverter';
import { helpFieldText } from './Help';
import { ActionButtons } from './components/ActionButtons';

function PapeisFormView({ isModal, history, ...props }) {
	const { values, validateForm, setFieldValue, resetForm, errors } = useFormikContext();
	const [deveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PAPEIS_PERMISSOES)
	);

	const [estruturaTree, setEstruturaTree] = useState([]);
	const [tutorialVisible, setTutorialVisible] = useState(false);

	const podeInserir = usuarioPossuiPermissao(recursos.PAPEIS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.PAPEIS, permissoes.EXCLUIR);
	const informacoesPermissoes = {
		estadoCadastro: buscarEstadoCadastro(),
		podeInserir,
		podeEditar,
		podeExcluir,
	};
	const { id } = props.match.params;

	useEffect(() => {
		asyncBuscarEstruturaPapeis(({ data: papeisPermissoes }) => {
			const estrutura = converterEstruturaParaTree(papeisPermissoes.recursos);
			setEstruturaTree(estrutura);
		});

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PAPEIS_PERMISSOES, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('PapeisInputNome')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		if (validarUUID(id) && !isModal && estruturaTree.length > 0) {
			asyncSelectRegistro(id);
		}
	}, [estruturaTree]);

	function buscarEstadoCadastro() {
		return values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;
	}

	async function asyncSelectRegistro(papelId) {
		await asyncGetPapel(papelId, ({ data: papel }) => {
			resetForm({
				values: converterPapelParaFormulario(papel, estruturaTree),
			});
			atualizarUrl(history, CADASTROURL, papelId, metodosAtualizarUrl.POP);
		});
	}

	function marcarTodosAsPermissoes() {
		const resultado = {};
		for (let i = 0; i < estruturaTree.length; i++) {
			const recurso = estruturaTree[i];
			resultado[recurso.key] = { checked: true, partialChecked: false };

			recurso.children?.forEach((item) => {
				resultado[item.key] = { checked: true, partialChecked: false };
			});
		}
		setFieldValue('permissoes', resultado);
	}

	function desmarcarTodosAsPermissoes() {
		setFieldValue('permissoes', []);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsPapeis}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>

			<Form header="Cadastro de grupo de usuário" isModal={isModal} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons
						history={history}
						isModal={isModal}
						asyncSelectRegistro={asyncSelectRegistro}
						informacoesPermissoes={informacoesPermissoes}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							className="step-papeis-nome"
							label="Nome "
							name="nome"
							obrigatorio
							component={InputField}
							value={values.nome}
							keyfilter={keyFilterConsultaRsql}
							helpMessage={helpFieldText.nome}
							id="PapeisInputNome"
							{...informacoesPermissoes}
						/>
					</Grid>
					<Grid style={{ padding: '8px 4px' }}>
						<Button
							label="Marcar todos"
							type="button"
							color="success"
							icon="fa fa-check"
							style={{ margin: '5px' }}
							title="Marca todos os recursos"
							onClick={() => marcarTodosAsPermissoes()}
						/>
						<Button
							label="Desmarcar todos"
							type="button"
							color="danger"
							icon="fa fa-close"
							title="Desmarca todos os recursos"
							style={{ margin: '5px' }}
							onClick={() => desmarcarTodosAsPermissoes()}
						/>
					</Grid>
					<Grid>
						<Col>
							<p title={helpFieldText.permissoes}>
								Permissões <b style={{ fontSize: '18px', lineHeight: '5px' }}> *</b>
							</p>
							<Tree
								className="step-papeis-three"
								style={{ border: 0, padding: 0, width: 'auto' }}
								value={estruturaTree}
								selectionKeys={values.permissoes}
								onSelectionChange={(e) => {
									setFieldValue('permissoes', e.value);
									validateForm();
								}}
								selection={values.permissoes}
								sortMode="single"
								selectionMode="checkbox"
								errors={errors.permissoes}
								{...informacoesPermissoes}
							/>
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const PapeisForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		permissoes: Yup.object()
			.nullable()
			.test('Permissoes marcadas', 'Selecione ao menos uma permissão para este grupo', (val) =>
				permissoesForamMarcadas(val)
			),
	}),

	handleSubmit: () => {},
})(PapeisFormView);

export default PapeisForm;
