import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { recursos, permissoes, usuarioPossuiPermissao, construirUrl, services, formatarMonetario } from 'Common';

import {
	ButtonNovo,
	Grid,
	ButtonEditarTable,
	ButtonExcluirTable,
	InputSearch,
	confirm,
	Form,
	FormActions,
	FormContent,
	NenhumRegistroEncontrado,
	Paginacao,
} from 'components';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from '../../Util';
import { CADASTROURL } from './Util/constantes';
import { asyncDeletePlano, asyncGetPesquisaPlanos } from './Requests';

function AdministracaoPlanos({ history, isMobile }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
	} = useContextPesquisa();
	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [firstRender, setFirstRender] = useState(true);

	const podeInserir = usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EXCLUIR);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		setTimeout(() => {
			document.getElementById('PlanosInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField]);

	function buscarFiltro() {
		return `?query=(descricao=contains="*${valorPesquisa}*",tipo=contains="*${valorPesquisa}*",valor=contains="*${valorPesquisa}*")`;
	}

	function pesquisar() {
		const filtro = buscarFiltro();
		const url = construirUrl(
			`${services.GESTOR}/v1/administracao/planos/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetPesquisaPlanos(url, ({ data: planos }) => {
			setRegistros(planos.content);
			setTotalElements(planos.page.totalElements);
			setFirstRender(false);
		});
	}

	function onPesquisar() {
		pesquisar();
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function onEditar(row) {
		atualizarUrl(history, '/administracao_planos/cadastro', row.id);
	}

	function onExcluir(row) {
		confirm('Confirmação', 'Deseja excluir o registro?', () => {
			asyncExcluirRegistro(row);
		});
	}

	function asyncExcluirRegistro(row) {
		asyncDeletePlano(row.id, () => {
			pesquisar();
		});
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable disabled={false} podeExcluir={podeExcluir} onClick={() => onExcluir(row)} />
			</div>
		);
	}

	function renderDescricao(row) {
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{row.descricao}
			</span>
		);
	}

	function renderValor(row) {
		return formatarMonetario(row.valor);
	}

	function renderAplicacao(row) {
		return row.aplicacao === 'DOCS_DIGITAIS'
			? 'Docs Digitais'
			: row.aplicacao === 'MSYS_GESTOR'
				? 'Msys Gestor'
				: 'Gestor MEI';
	}

	function renderTipo(row) {
		const label = row.tipo === 'NORMAL' ? 'Normal' : row.tipo === 'GRATUITO' ? 'Gratuito' : 'Exclusivo';

		return label;
	}

	return (
		<Form header="Planos">
			<FormActions>
				<ButtonNovo label="Novo plano" onClick={() => history.push(CADASTROURL)} podeInserir={podeInserir} />
			</FormActions>
			<FormContent>
				<Grid justifyCenter>
					<InputSearch
						onPesquisar={() => onPesquisar()}
						value={valorPesquisa}
						onChange={(value) => setValorPesquisa(value)}
						id="PlanosInputSearch"
					/>
				</Grid>
				<DataTable
					className="table"
					responsive
					rowClassName="table-row"
					cellClassName="table-row-cell"
					value={registros}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={(row) => onSort(row)}
					emptyMessage={<NenhumRegistroEncontrado />}
				>
					<Column field="descricao" header="Descrição" body={(row) => renderDescricao(row)} sortable />
					<Column field="tipo" header="Tipo" body={(row) => renderTipo(row)} sortable />
					<Column field="valor" header="Valor" sortable body={(row) => renderValor(row)} />
					<Column field="aplicacao" header="Aplicação" sortable body={(row) => renderAplicacao(row)} />
					<Column body={(row) => renderOpcoes(row)} header="Ações" style={{ width: '7em' }} />
				</DataTable>
				<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(row) => onPageChange(row)} />
			</FormContent>
		</Form>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(AdministracaoPlanos);
