import { Field, useFormikContext } from 'formik';

import { Checkbox, Dropdown, Grid, InputField, InputMoney } from 'components';

import { PLANOS_APLICACAO_OPTIONS, PLANOS_TIPO_OPTIONS } from 'views/administracao/Planos/Util/constantes';
import { helpPlanosForm } from '../../Help';

function CamposPrincipais({ planoIlimitado, handleIlimitado, informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="O plano é ilimitado"
				name="ilimitado"
				helpMessage={helpPlanosForm.ilimitado}
				onChange={(e) => {
					setFieldValue('ilimitado', e.checked);
					setFieldValue('tipo', values.tipo);
					setFieldValue('descricao', values.descricao);
					setFieldValue('valor', values.valor);
					handleIlimitado(e.checked);
				}}
				checked={values.ilimitado === true}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={Checkbox}
				label="Suporte via chat"
				name="suporteChat"
				disabled={planoIlimitado}
				helpMessage={helpPlanosForm.suporteChat}
				onChange={(e) => setFieldValue('suporteChat', e.checked)}
				checked={values.suporteChat === true}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="5"
				lg="5"
				xl="5"
				component={InputField}
				label="Descrição"
				name="descricao"
				size={128}
				obrigatorio
				helpMessage={helpPlanosForm.descricao}
				id="PlanosInputFieldDescricao"
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Tipo"
				name="tipo"
				showClear={false}
				onChange={(e) => setFieldValue('tipo', e.value)}
				options={PLANOS_TIPO_OPTIONS}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="3"
				xl="3"
				component={Dropdown}
				label="Aplicação"
				name="aplicacao"
				showClear={false}
				onChange={(e) => setFieldValue('aplicacao', e.value)}
				options={PLANOS_APLICACAO_OPTIONS}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="2"
				component={InputMoney}
				label="Valor"
				name="valor"
				obrigatorio
				size={15}
				min={0}
				helpMessage={helpPlanosForm.valor}
				onChange={(e) => setFieldValue('valor', e.target.value)}
				value={values.valor}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { CamposPrincipais };
