import { Field, useFormikContext, withFormik } from 'formik';

import { services } from 'Common';
import { Checkbox, Dropdown, Grid, If, ModalRelatorio, MultiSelectProdutos, SingleSelectMarca, SingleSelectProdutoGrupo } from 'components';

import { asyncImprimirProdutosEstoqueMinimo } from 'views/cadastros/produtos/Produtos/Requests';
import { OPCOES_CODIGO, OPCOES_ORDENACAO } from './util/constantes';

const initialValue = {
	codigo: OPCOES_CODIGO.find((option) => option.value === 'CODIGO'),
	ordenacao: OPCOES_ORDENACAO.find((option) => option.value === 'CODIGO'),
	produtos: null,
	filtrarProdutos: false,
	grupo: null,
	marca: null,
	considerarEstoqueMinimoZerado: true,
	considerarReservasNoSaldo: false,
};

function ModalProdutoEstoqueMinimoImpl({ visible, onHide }) {
	const { values, isValid, setFieldValue, handleSubmit, validateForm } = useFormikContext();

	async function print() {
		handleSubmit();
		await validateForm();
		if (isValid) {
			await asyncImprimirProdutosEstoqueMinimo(
				values.codigo?.value ?? '',
				values.ordenacao?.value ?? '',
				createListProdutos(),
				values.grupo == null ? '' : values.grupo.value,
				values.marca == null ? '' : values.marca.value,				
				values.considerarEstoqueMinimoZerado,
				values.considerarReservasNoSaldo,
				({ data: pdf }) => {
					const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
					const arquivoURL = URL.createObjectURL(arquivoPDF);
					const report = window.open(arquivoURL);
					if (report) {
						report.onload = () => {
							setTimeout(() => {
								report.document.title = `Relatório produtos abaixo do estoque mínimo`;
							}, 250);
						};
					}
					onHide();
				}
			);
		}
	}

	function createListProdutos() {
		let result = '';
		if (values.produtos) {
			for (let i = 0; i < values.produtos.length; i++) {
				if (result !== '') {
					result += ',';
				}
				result += values.produtos[i].value;
			}
		}
		return result;
	}

	const params = (
		<>
			<Grid>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					label="Imprimir relatório de produtos específicos"
					name="filtrarProdutos"
					onChange={() => {
						values.produtos = [];
						setFieldValue('filtrarProdutos', !values.filtrarProdutos);
					}}
					checked={values.filtrarProdutos}
				/>
			</Grid>
			<Grid>
				<If test={values.filtrarProdutos}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="produtos"
						label="Produtos"
						autofocus
						isMulti
						component={MultiSelectProdutos}
						value={values.produtos}
						onChange={(e) => setFieldValue('produtos', e)}
						url={`${services.GESTOR}/v1/estoque/movimentacoes/relacoes/produtos`}
						parametros={';tipo!="SERVICO";controlarEstoque==true'}
					/>
				</If>
				<If test={!values.filtrarProdutos}>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectProdutoGrupo}
						label="Grupo"
						name="grupo"						
						url={`${services.GESTOR}/v1/grupos`}
						value={values.grupo}
						onChange={(e) => setFieldValue('grupo', e)}
						showClear={false}
						disabled={values.filtrarProdutos}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectMarca}
						label="Marca"
						name="marca"						
						url={`${services.GESTOR}/v1/produtos/relacoes/marcas`}
						value={values.marca}
						onChange={(e) => setFieldValue('marca', e)}
						showClear={false}
						disabled={values.filtrarProdutos}
						esconderBotao
					/>					
				</If>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					label="Código a utilizar"
					name="codigo"
					onChange={(e) => setFieldValue('codigo', e)}
					options={OPCOES_CODIGO}
					value={values.codigo}
					showClear={false}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					label="Ordenar por"
					name="ordenacao"
					onChange={(e) => setFieldValue('ordenacao', e)}
					options={OPCOES_ORDENACAO}
					value={values.ordenacao}
					showClear={false}
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"			
					component={Checkbox}
					label="Considerar produto(s) com estoque mínimo zerado"
					name="considerarEstoqueMinimoZerado"
					onChange={() => {
						setFieldValue('considerarEstoqueMinimoZerado', !values.considerarEstoqueMinimoZerado);
					}}
					checked={values.considerarEstoqueMinimoZerado}
				/>			
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"			
					component={Checkbox}
					label="Exibir saldo disponível (considerar reservas)"
					name="considerarReservasNoSaldo"
					onChange={() => {
						setFieldValue('considerarReservasNoSaldo', !values.considerarReservasNoSaldo);
					}}
					checked={values.considerarReservasNoSaldo}
				/>			
			</Grid>
		</>
	);

	return (
		<ModalRelatorio
			header="Relatório produtos abaixo do estoque mínimo"
			visible={visible}
			onHide={onHide}
			onImprimir={print}
			parametros={params}
			isValid={isValid}
		/>
	);
}

const ModalProdutoEstoqueMinimo = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	handleSubmit: () => {},
})(ModalProdutoEstoqueMinimoImpl);

export { ModalProdutoEstoqueMinimo };
