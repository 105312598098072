import { useFormikContext } from 'formik';

import { ButtonCancelar, ButtonSalvar, estadosBotaoCancelar, notify, ToastTypes } from 'components';

import { entregarProdutosDoPedidoDeVenda } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Requests';
import { validarFormulario } from 'views/Util';
import { converterProdutosEntregarParaApi } from 'views/fiscal/vendas/PedidoVendasOrcamentos/Form/Util/PedidovendasOrcamentosConverter';

function ActionButtons({ vendaId, informacoesPermissoes, onHide, asyncSelectRegistro }) {
	const { dirty, handleReset, validateForm, values } = useFormikContext();

	function cancelar() {
		if (dirty) {
			handleReset();
		} else {
			onHide();
		}
	}

	async function entregarProdutos() {
		const produtosEntregues = values?.produtos?.filter((produto) => produto?.quantidade > 0);
		if (
			await validarFormulario({
				validateForm,
				values: { ...values, produtos: produtosEntregues },
			})
		) {
			entregarProdutosDoPedidoDeVenda(
				vendaId,
				converterProdutosEntregarParaApi(produtosEntregues),
				() => {
					notify('Entrega realizada com sucesso', ToastTypes.SUCCESS);
					asyncSelectRegistro(vendaId);
					onHide();
				},
				({ response }) => {
					const erro = response && response?.data?.details?.length > 0 ? response?.data?.details[0] : null;

					notify(erro || `Ocorreu um erro ao entregar o(s) item(s)`, ToastTypes.ERROR);
				}
			);
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar onClick={entregarProdutos} {...informacoesPermissoes} />
		</>
	);
}

export { ActionButtons };
