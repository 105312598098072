import { useState } from 'react';
import { useFormikContext } from 'formik';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';

import ModalNaturezaOperacao from 'views/cadastros/vendas/NaturezaOperacao/Modal';
import SingleSelect from '../SingleSelect';

function SingleSelectNaturezaOperacao(props) {
	const { onChange, field, url } = props;
	
	const [visible, setVisible] = useState(false);	
	const [resetPesquisa, setResetPesquisa] = useState(false);
	const { setFieldValue } = useFormikContext();

	const podeInserir = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.INSERIR);
	const podeVisualizar = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.VISUALIZAR);
	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		const query = `?query=descricao=contains="*${pesquisa}*";situacao=="ATIVO"&page=${page}&size=50`;
		return `${url}${query}`;
	}

	function buscarUrlRegistroUnico(id) {
		const query = `?query=id==${id}`;
		return `${url}${query}`;
	}

	function montarLabel(row) {
		return `${row.descricao}`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: { ...novoRegistro},
			});
			setResetPesquisa(true);
		}
	}

	function esconderModal() {
		setVisible(false);
	}

	function handleChange(value) {
		if (onChange) {
			onChange(value);
		} else {
			setFieldValue(field.name, {
				value: value.value,
				label: value.label,
				registro: value.registro,
			});
		}
	}

	return (
		<>
			<ModalNaturezaOperacao visible={visible} onHide={onHide} />
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				onChange={handleChange}
				disabledButton={!podeInserirEVisualizar}
				titleBotaoNovo={
					podeInserirEVisualizar
						? 'Adicionar nova natureza de operação'
						: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectNaturezaOperacao.defaultProps = {
	label: 'Natureza de operação',
	isClearable: false,
};

export default SingleSelectNaturezaOperacao;
