export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é seu cadastro de situações, nele você pode adicionar as situações que serão utilizadas no pedido de venda.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-cadastro-situacao-informacoes-principais',
		content:
			'Nesta área são configurados as principais informações visuais da situação. Como por exemplo, nome, ícone, cor, textos, etc.',
		placement: 'top',
	},
	{
		target: '.step-cadastro-situacao-etapas',
		content: 'Na área de etapas são configurados as situações vínculadas a situação atual.',
		placement: 'top',
	},
	{
		target: '.step-cadastro-situacao-parametros',
		content:
			'Por fim, na área de parâmetros são configurados o comportamento de cada situação. Como por exemplo, se a situação gera estoque ou financeiro, se permite devoluções, entrega parcial, se permite impressões, entre outras parametrizações',
		placement: 'top',
	},
];
