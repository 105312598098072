import { ButtonCancelar, estadosBotaoCancelar } from 'components';

function ActionButtons({ onHide, vendaId, estornouEntregas, asyncSelectRegistro }) {
	return (
		<ButtonCancelar
			estadoBotao={estadosBotaoCancelar.VOLTAR}
			onClick={() => {
				if (estornouEntregas) {
					asyncSelectRegistro(vendaId);
				}
				onHide();
			}}
		/>
	);
}

export { ActionButtons };
