import { formatarMonetario } from 'Common';
import { Button, Col, If } from 'components';
import { useContextNFCe } from '../../../../../Context';
import { defineColorStatus } from '../../../../../Util/function';
import ModalFinalizacao from '../../../ModalFinalizacao';
import ModalRecebimentoComPix from '../../../ModalRecebimentoPorPix';
import { TooltipTotal } from '../../../TooltipTotal';

function TotalFinalizacao(props) {
	const {
		values,
		isMobile,
		isTablet,
		isLessHd,
		dirty,
		novaNFCe,
		decimaisQtd,
		decimaisPreco,
		isFullScreen,
		resetFormPrincipal,
		setAtualizarTotal,
		disabled,
		imprimirNFCe,
	} = props;

	const {
		modalFinalizacaoVisible,
		setModalFinalizacaoVisible,
		modalRecebimentoPixVisible,
		setModalRecebimentoPixVisible,
	} = useContextNFCe();

	return (
		<>
			<div
				style={{
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					flexDirection: isLessHd || isTablet || isMobile ? 'column-reverse' : 'row',
				}}
			>
				<TooltipTotal
					target='.id-componente-total-nfce'
					valores={{
						produtos: values.totalizadores.totalProdutos,
						acessorias: values.totalizadores.totalAcessorias,
						desconto: values.totalizadores.totalDesconto,
						total: values.totalizadores.totalLiquido,
					}}
				/>
				<Col
					sm={5}
					md={12}
					lg={12}
					xl={5}
					style={{
						paddingRight: '0',
						paddingLeft: isMobile ? '0px' : '8px',
						display: isLessHd || isTablet || isMobile ? 'flex' : 'block',
						justifyContent: isMobile ? 'center' : 'flex-end',
					}}
				>
					<Button
						label={values.id ? 'Pagamentos [F4]' : 'Finalizar [F4]'}
						icon="fa fa-check"
						className="p-button-success step-nfce-finalizacao"
						style={{
							width:
								isLessHd || isTablet || isMobile ? document.getElementById('id-cliente-nfce')?.offsetWidth : '100%',
							fontWeight: '500',
						}}
						onClick={() => setModalFinalizacaoVisible(true)}
						disabled={!values.produtos.length > 0}
					/>
				</Col>
				<Col
					sm={7}
					md={12}
					lg={12}
					xl={7}
					style={
						isLessHd || isTablet || isMobile
							? { display: 'flex', justifyContent: 'flex-end', paddingTop: '0px', paddingRight: '0px' }
							: { display: 'flex', justifyContent: 'flex-end'}
					}
				>
					<span
						className="id-componente-total-nfce"
						style={{
							fontSize: isTablet || isMobile ? '30px' : '36px',
							fontWeight: 'bold',
							color: defineColorStatus(values.status),
						}}
					>
						Total: {formatarMonetario(values?.totalizadores?.totalLiquido)}
					</span>
				</Col>
			</div>
			<If test={modalFinalizacaoVisible}>
				<ModalFinalizacao
					values={values}
					visible={modalFinalizacaoVisible}
					onHide={() => setModalFinalizacaoVisible(false)}
					isMobile={isMobile}
					isTablet={isTablet}
					dirty={dirty}
					novaNFCe={novaNFCe}
					decimaisQtd={decimaisQtd}
					decimaisPreco={decimaisPreco}
					isFullScreen={isFullScreen}
					resetFormPrincipal={resetFormPrincipal}
					setAtualizarTotal={setAtualizarTotal}
					disabled={disabled}
					imprimirNFCe={imprimirNFCe}
				/>
			</If>

			<If test={modalRecebimentoPixVisible}>
				<ModalRecebimentoComPix
					values={values}
					visible={modalRecebimentoPixVisible}
					onHide={() => setModalRecebimentoPixVisible(false)}
					isMobile={isMobile}
					isTablet={isTablet}
					dirty={dirty}
					novaNFCe={novaNFCe}
					decimaisQtd={decimaisQtd}
					decimaisPreco={decimaisPreco}
					isFullScreen={isFullScreen}
					resetFormPrincipal={resetFormPrincipal}
					setAtualizarTotal={setAtualizarTotal}
					disabled={disabled}
					imprimirNFCe={imprimirNFCe}
				/>
			</If>
		</>
	);
}

export default TotalFinalizacao;
