import { colors } from 'Common';
import { SITUACAO, SITUACAO_TIPO } from '../../Utils/constantes';

export const STYLE_EXEMPLO_BADGE__TEXTO_SITUACAO = {
	padding: '11px 0px',
};

export const STYLE_EXEMPLO_BADGE_BACKGROUND_SITUACAO = {
	borderRadius: '20px',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	itemContent: 'center',
};

export const CORES_INICIAL_SITUACAO_POR_TIPO = {
	[SITUACAO_TIPO.PENDENTE]: { texto: colors.azul, fundo: colors.azulLight },
	[SITUACAO_TIPO.FINALIZADO]: { texto: colors.verde, fundo: colors.verdeLight },
	[SITUACAO_TIPO.CANCELADO]: { texto: colors.vermelho, fundo: colors.vermelhoLight },
};

export const ICONE_INICIAL_SITUACAO_POR_TIPO = {
	[SITUACAO_TIPO.PENDENTE]: 'fa fa-reply',
	[SITUACAO_TIPO.FINALIZADO]: 'fa fa-check',
	[SITUACAO_TIPO.CANCELADO]: 'fa fa-times',
};

export const SITUACAO_PARAMETROS_TIPO_ESTOQUE = {
	NENHUM: 'NENHUM',
	RESERVA: 'RESERVA',
	NORMAL: 'NORMAL',
};

export const SITUACAO_PARAMETROS_TIPO_ESTOQUE_OPTIONS = [
	{ label: 'Nenhum', value: SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM },
	{ label: 'Reserva', value: SITUACAO_PARAMETROS_TIPO_ESTOQUE.RESERVA },
	{ label: 'Normal', value: SITUACAO_PARAMETROS_TIPO_ESTOQUE.NORMAL },
];

export const SITUACAO_PARAMETROS_TIPO_FISCAL = {
	NENHUM: 'NENHUM',
	PERMITE_GERAR_DOCUMENTO_FISCAL: 'PERMITE_GERAR_DOCUMENTO_FISCAL',
	GERA_DOCUMENTO_FISCAL: 'GERA_DOCUMENTO_FISCAL',
};

export const SITUACAO_PARAMETROS_TIPO_FISCAL_OPTIONS = [
	{ label: 'Nenhum', value: SITUACAO_PARAMETROS_TIPO_FISCAL.NENHUM },
	{ label: 'Permite gerar documento fiscal', value: SITUACAO_PARAMETROS_TIPO_FISCAL.PERMITE_GERAR_DOCUMENTO_FISCAL },
	{ label: 'Gera documento fiscal', value: SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL },
];

export const BOOLEAN_OPTIONS = [
	{ label: 'Sim', value: true },
	{ label: 'Não', value: false },
];

export const INITIAL_VALUES = {
	id: null,
	nome: null,
	tipo: SITUACAO_TIPO.PENDENTE,
	situacao: SITUACAO.ATIVO,
	situacaoInicial: false,
	icone: ICONE_INICIAL_SITUACAO_POR_TIPO[SITUACAO_TIPO.PENDENTE],
	textoBotao: null,
	textoBotaoAuxiliar: null,
	corTexto: CORES_INICIAL_SITUACAO_POR_TIPO[SITUACAO_TIPO.PENDENTE].texto,
	corFundo: CORES_INICIAL_SITUACAO_POR_TIPO[SITUACAO_TIPO.PENDENTE].fundo,
	etapasVinculadas: [],
	etapaFavorita: null,
	parametros: {
		tipoEstoqueGerado: SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM,
		estornaMovimentacoes: false,
		movimentaFinanceiro: false,
		tipoFiscal: SITUACAO_PARAMETROS_TIPO_FISCAL.PERMITE_GERAR_DOCUMENTO_FISCAL,
		situacaoFinalDocumentoGerado: null,
		situacaoFinalCancelamento: null,
		situacaoFinalFinalizado: null,
		permiteFinalizarENovo: false,
		permiteDevolucao: false,
		permiteDevolucaoCondicional: false,
		permiteEntregaParcial: false,
		permiteGerarAssinatura: false,
		permiteImpressoes: false,
		permiteCompartilhamento: false,
		permiteGerarPedidoVenda: false,
	},
};

const ICONES_LABELS = [
	'fa fa-archive',
	'fa fa-ban',
	'fa fa-bars',
	'fa fa-bell',
	'fa fa-bookmark',
	'fa fa-calendar-check-o',
	'fa fa-cart-plus',
	'fa fa-check',
	'fa fa-times',
	'fa fa-credit-card-alt',
	'fa fa-exchange',
	'fa fa-exclamation',
	'fa fa-eye',
	'fa fa-exclamation-triangle',
	'fa fa-folder',
	'fa fa-share',
	'fa fa-reply',
	'fa fa-reply-all',
	'fa fa-print',
	'fa fa-search',
	'fa fa-sitemap',
	'fa fa-trash',
	'fa fa-thumbs-up',
	'fa fa-thumbs-down',
	'fa fa-file-text',
	'fa fa-check-square',
	'fa fa-plus-square',
	'fa fa-bar-chart',
	'fa fa-usd',
	'fa fa-university',
];

export const ICONE_SITUACAO_OPTIONS = ICONES_LABELS.map((icone) => ({
	label: (
		<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<i className={icone} />
		</span>
	),
	value: icone,
}));

export const HELP_MESSAGE = {
	nome: 'Nome da situação. Essa informação será apresentada nos cards de situação',
	tipo: 'O Campo "tipo" define o status absoluto. Várias validações são baseadas nesse campo',
	situacao: 'Indica se a situação está ativa ou inativa para uso',
	situacaoInicial: 'Define a situação padrão para iniciar uma venda',
	icone: 'O ícone informado será exibido nos botões de ação das situações',
	textoBotao: 'O texto informado será apresentado nos botões de ação das situações',
	textoBotaoAuxiliar:
		'O texto informado será exibido como uma mensagem complementar ao passar o mouse sobre o botão da situação',
	corTexto: 'Cor do texto apresentada no fundo dos cards',
	corFundo: 'Cor de fundo do "nome" apresentado nos cards',
	etapasVinculadas: 'Informe as situações para as quais a situação atual pode ser encaminhada',
	etapaFavorita:
		'Quando há etapas vinculadas, é possível definir uma etapa favorita. A etapa favorita terá destaque nas vendas',
	parametros: {
		tipoEstoqueGerado: `Este campo indica o tipo de estoque gerado:
    - Nenhum: Nada ocorre em relação ao estoque
    - Reserva: Quando a venda é encaminhada para a situação com esse parâmetro marcado, o estoque é reservado
    - Normal: Quando a venda é encaminhada para a situação com esse parâmetro marcado, o estoque é gerado normalmente`,

		estornaMovimentacoes:
			'Indica se a situação estornará as movimentações de estoque e financeiro. Campo controlado pelo tipo da situação',

		movimentaFinanceiro: 'Indica se a situação gerará movimentações financeiras',

		tipoFiscal: `Este campo indica o tipo fiscal gerado:
    - Nenhum: Nada ocorre em relação aos documentos fiscais
    - Permite gerar documento fiscal: A situação permite a geração de documentos fiscais, como NF-e ou NFC-e, habilitando o botão "Gerar NF-e/Gerar Fiscal"
    - Gera documento fiscal: Quando a venda é encaminhada para a situação com esse parâmetro marcado, o documento fiscal é gerado`,

		situacaoFinalDocumentoGerado: `Apresenta as situações com tipo "Finalizado", marcadas em "Etapas vinculadas", onde o parâmetro "Tipo fiscal" é igual a "Gera documento fiscal"
    Este campo é obrigatório quando "Tipo fiscal" estiver marcado como "Permite gerar documento fiscal"`,

		situacaoFinalCancelamento: `Apresenta as situações com tipo "Cancelado".
    A situação indicada neste campo será utilizada quando um processo de cancelamento automático for acionado, por exemplo:
    - Ao cancelar uma NF-e vinculada a uma venda, a venda também será cancelada, utilizando a situação indicada neste campo`,

		situacaoFinalFinalizado: `Apresenta as situações com tipo "Finalizado".
    A situação indicada neste campo será utilizada quando um processo de finalização automática for acionado, por exemplo:
    - Ao utilizar a ação "Finalizar e novo", a situação de finalização para a qual a venda será encaminhada é a destacada neste parâmetro`,

		permiteFinalizarENovo: 'Controla a liberação do botão "Finalizar e novo" nas vendas',

		permiteDevolucao: `Nas vendas, quando marcado como "Sim", permite a realização de devoluções.
    Só é permitido quando o "Tipo" for "Finalizado"`,

		permiteDevolucaoCondicional: `Nas vendas, quando marcado como "Sim", permite a realização de devoluções condicionais, alterando a quantidade dos itens.
    Só é permitido quando o "Tipo" for "Pendente"`,

		permiteEntregaParcial: `Nas vendas, quando marcado como "Sim", permite a realização de entregas. A entrega reserva o estoque dos itens entregues`,

		permiteGerarAssinatura: `Nas vendas, quando marcado como "Sim", permite a geração de assinaturas`,

		permiteImpressoes: `Nas vendas, quando marcado como "Sim", permite a geração de impressões`,

		permiteCompartilhamento: `Nas vendas, quando marcado como "Sim", permite o compartilhamento da venda`,
	},
};
