import { If, Message } from 'components';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

function MessageValidacao() {
	const { errors } = useFormikContext();
	const [exibirMessages, setExibirMessages] = useState(false);

	useEffect(() => {
		if (errors.parametros && Object.keys(errors.parametros)?.length > 0) {
			setExibirMessages(true);
		} else {
			setExibirMessages(false);
		}
	}, [errors]);

	function montarMensagemValidacoes() {
		return (
			<div>
				{errors.parametros && Object.keys(errors.parametros)?.length > 0
					? Object.keys(errors.parametros).map((erro) => <div key={erro}>{errors.parametros[erro]}</div>)
					: null}
			</div>
		);
	}

	return (
		<If test={exibirMessages}>
			<Message severity="warn" text={montarMensagemValidacoes()} />
		</If>
	);
}

export { MessageValidacao };
