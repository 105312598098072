import { useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import { useFormikContext } from 'formik';

import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	If,
	ModalHistorico,
	NormalButton,
	notify,
	ToastTypes,
} from 'components';

import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { atualizarSituacao, deleteSituacao, getSituacao, salvarSituacao } from '../../../Request';
import { CADASTRO_URL, PESQUISA_URL } from '../../../Utils/constantes';
import { INITIAL_VALUES } from '../../Utils/constantes';
import { converterSituacaoParaApi, converterSituacaoParaFormulario } from '../../Utils/converter';

function ActionButtons({ informacoesPermissoes, history, setBuscarSituacoes }) {
	const { values, validateForm, dirty, resetForm, handleReset, handleSubmit, setValues } = useFormikContext();

	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);

	const menuOpcoes = useRef(null);
	const itensOpcoes = getItensOpcoes();

	const estadoBotaoCancelar = dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;

	function cancelar() {
		if (dirty) {
			handleReset();
			setBuscarSituacoes(true);
		} else {
			voltarParaAPesquisa(history, `/${PESQUISA_URL}`);
		}
	}

	async function salvar(callback) {
		handleSubmit();
		if (await validarFormulario({ validateForm, values })) {
			if (values.id) {
				asyncUpdateRegistro(values, callback);
			} else {
				asyncCreateRegistro(values, callback);
			}
		}
	}

	async function asyncCreateRegistro(dadosFormulario, callback) {
		await salvarSituacao(
			converterSituacaoParaApi(dadosFormulario),
			({ data: response }) => {
				if (typeof callback === 'function') {
					callback();
				} else {
					resetForm({ values: { ...values, id: response.id } });
				}
				notify('Situação salva com sucesso', ToastTypes.SUCCESS);
			},
			(error) => {
				notify('Ocorreu um erro ao salvar a situação', ToastTypes.ERROR);
				console.warn('error: ', error);
			}
		);
	}

	async function asyncUpdateRegistro(dadosFormulario, callback) {
		await atualizarSituacao(
			values.id,
			converterSituacaoParaApi(dadosFormulario),
			() => {
				if (typeof callback === 'function') {
					callback();
				} else {
					resetForm({ values: { ...values } });
				}
				notify('Situação atualizada com sucesso', ToastTypes.SUCCESS);
			},
			(error) => {
				notify('Ocorreu um erro ao atualizar a situação', ToastTypes.ERROR);
				console.warn('error: ', error);
			}
		);
	}

	function onClickNovo() {
		return dirty ? salvar(novo) : novo();
	}

	function novo() {
		atualizarUrl(history, `/${CADASTRO_URL}`, null, metodosAtualizarUrl.POP);
		resetForm({ values: INITIAL_VALUES });
	}

	function handleClickDeletar() {
		confirmarExclusao(() => excluir());
	}

	async function excluir() {
		if (values.id) {
			await deleteSituacao(values.id, () => {
				voltarParaAPesquisa(history, `/${PESQUISA_URL}`);
				notify('Situação removída com sucesso', ToastTypes.SUCCESS);
			});
		}
	}

	function getItensOpcoes() {
		const itens = [
			{
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => duplicarSituacao(values.id),
				visible: !dirty && values.id,
			},
			{
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => setVisibleModalHistorico(true),
				visible: true,
			},
		];

		return itens.filter((element) => element.visible || element.separator);
	}

	async function duplicarSituacao(id) {
		await getSituacao(id, async ({ data: situacao }) => {
			atualizarUrl(history, `/${CADASTRO_URL}`, null, metodosAtualizarUrl.POP);
			const situacaoConverter = { ...(await converterSituacaoParaFormulario(situacao)), id: null };

			setValues(situacaoConverter);
		});
	}

	return (
		<>
			<Menu model={itensOpcoes} popup ref={menuOpcoes} />
			<ButtonCancelar onClick={cancelar} estadoBotao={estadoBotaoCancelar} />
			<ButtonSalvar onClick={() => salvar()} disabled={!dirty} />
			<ButtonNovo
				onClick={onClickNovo}
				estadoBotao={estadoBotaoNovo}
				hidden={!values.id && !dirty}
				disabled={!informacoesPermissoes.podeInserir}
			/>
			<ButtonExcluir
				onClick={handleClickDeletar}
				hidden={!values.id}
				disabled={!informacoesPermissoes.podeExcluir || dirty}
			/>
			<NormalButton
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={dirty || !values.id}
				onClick={(event) => menuOpcoes.current.toggle(event)}
				{...informacoesPermissoes}
			/>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico do cadastro de situação"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="vendas/situacao"
					id={values.id}
				/>
			</If>
		</>
	);
}

export { ActionButtons };
