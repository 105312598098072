import { colors, formatarMonetario, mensagensDeValidacao } from 'Common';
import Button from 'components/Button';
import ButtonCancelar, { estadosBotaoCancelar } from 'components/Button/ButtonCancelar';
import Divider from 'components/Divider';
import Form from 'components/Form';
import FormActions from 'components/FormActions';
import FormContent from 'components/FormContent';
import Grid from 'components/Grid';
import Modal from 'components/Modal';
import Dropdown from 'components/select/Dropdown';
import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect } from 'react';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { validarFormulario } from 'views/Util';
import { isValid } from 'date-fns';
import { TabViewParcelas } from '../TabViewParcelas';

function ModalParcelasImpl({
	values,
	visible,
	onHide,
	indexPagamento,
	optionsQuantidadeParcelas,
	calculateValorParcelas,
	onChangeQuantidadeParcelas,
	onChangeVencimentoParcelas,
	onChangeValorParcelas,
	onBlurValorParcelas,
	onChangeFormaPagamento,
	onChangeConta,
	onChangeDescricao,
	onBlurDescricaoParcelas,
	onRemoveParcela,
	onChangePagamentoField,
}) {
	const { 
		errors, setFieldValue, dirty, resetForm, initialValues, validateForm } = useFormikContext();

	const { 
		handleFuncaoSetFieldValue,
		handleErrorsFormulario,
		handleParcelasContext,
		handleQuantidadeParcelasContext,
		disabledFields,
		informacoesPermissoes,
		color,
		isMobile,
		isTablet,
		isLessHd,
		renderMessage,
	} = useContextPagamentos();

	useEffect(() => {
		handleFuncaoSetFieldValue(() => setFieldValue);
	}, []);

	useEffect(() => {
		handleParcelasContext(values.parcelas);
	}, [values.parcelas]);

	useEffect(() => {
		handleQuantidadeParcelasContext(values.quantidadeParcelas);
	}, [values.quantidadeParcelas]);

	useEffect(() => {
		handleErrorsFormulario(errors);
	}, [errors]);

	function handleClickCancelar() {
		resetForm({ values: initialValues });
		if(!dirty) {
			onHide();
		}
	}

	async function handleClickSalvar() {
		if (
			await validarFormulario({
				validateForm,
				values: {
					...values,
					indexPagamento,
				},
			})
		) {
			onChangePagamentoField(indexPagamento, 'parcelas', values.parcelas);
			onChangePagamentoField(indexPagamento, 'quantidadeParcelas', values.quantidadeParcelas);
			onHide();
		}
	}
	
	function handleChangeQuantidadeParcelas(e) {
		onChangeQuantidadeParcelas(e, true);
	}

	return (
		<Modal
			header="Parcelas"
			visible={visible}
			onHide={onHide}
			closeOnEsc={false}
			styleModal={{
				maxWidth: isMobile || isTablet || isLessHd ? '98%' : '65%',
			}}
		>
			<Form>
				<FormActions>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							paddingBottom: '10px',
							flexWrap: 'wrap',
						}}
					>
						<ButtonCancelar
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={handleClickCancelar}
						/>
						<Button
							className="p-button-success"
							label="Salvar"
							icon="fa fa-check"
							onClick={handleClickSalvar}
							disabled={disabledFields || !dirty}
						/>
					</div>
				</FormActions>
				{renderMessage()}
				<FormContent>
					<Grid>
						<Field
							sm="6"
							md="4"
							lg="4"
							xl="4"
							component={Dropdown}
							label="Qtd. parcelas"
							name="quantidadeParcelas"
							helpMessage="Quantidade de parcelas que serão geradas"
							obrigatorio
							value={values.quantidadeParcelas}
							showClear={false}
							options={optionsQuantidadeParcelas}
							onChange={handleChangeQuantidadeParcelas}
							disabled={disabledFields}
							touched
							errors={errors[indexPagamento]?.quantidadeParcelas}
							useFormErrors={false}
							{...informacoesPermissoes}
						/>
						<div
							style={{
								fontSize: '24px',
								fontWeight: 'bold',
								color: color,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								paddingLeft: isMobile || isTablet ? '12px' : '0px',
								marginTop: isMobile || isTablet ? '0px' : '28px',
								marginLeft: isMobile || isTablet ? '0px' : '8px',
							}}
						>
							Total a prazo: {formatarMonetario(values.valor)}
							{errors.valor && (
								<p
									style={{
										fontSize: '12px',
										color: colors.vermelho,
										margin: '0px',
										justifyContent: 'flex-end',
										display: 'flex',
									}}
								>
									{errors.valor}
								</p>
							)}
						</div>
						<Divider styleContainer={{ marginTop: '0px', marginBottom: '0px' }} />
						<TabViewParcelas
							values={values}
							calculateValorParcelas={calculateValorParcelas}
							onChangeVencimentoParcelas={onChangeVencimentoParcelas}
							onChangeValorParcelas={onChangeValorParcelas}
							onChangeFormaPagamento={onChangeFormaPagamento}
							onBlurValorParcelas={onBlurValorParcelas}
							onChangeConta={onChangeConta}
							onChangeDescricao={onChangeDescricao}
							onBlurDescricaoParcelas={onBlurDescricaoParcelas}
							onRemoveParcela={onRemoveParcela}
							indexPagamento={indexPagamento}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalParcelasFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues({ values }) {
		return {
			condicaoPagamento: values.condicaoPagamento,
			parcelas: values.parcelas,
			quantidadeParcelas: values.quantidadeParcelas,
			valor: values.valor
		}
	},

	validate(values) {
		const errors = [];
		const errorsParcelas = [];
		const { indexPagamento } = values;
		let valorTotalDasParcelas = 0;

		if (!values.quantidadeParcelas) {
			errors[indexPagamento] = {
				...errors[indexPagamento],
				quantidadeParcelas: mensagensDeValidacao.OBRIGATORIO,
			};
		}

		values.parcelas.forEach((parcela, indexParcela) => {
			
			if (!parcela.vencimento || (parcela.vencimento && !isValid(new Date(parcela.vencimento)))) {
				errorsParcelas[indexParcela] = {
					...errorsParcelas[indexParcela],
					vencimento: mensagensDeValidacao.DATA_INVALIDA,
				}
			}
			
			if (!parcela.valor) {
				errorsParcelas[indexParcela] = {
					...errorsParcelas[indexParcela],
					valor: mensagensDeValidacao.OBRIGATORIO,
				}
			}

			if (!parcela.formaPagamento) {
				errorsParcelas[indexParcela] = {
					...errorsParcelas[indexParcela],
					formaPagamento: mensagensDeValidacao.OBRIGATORIO,
				}
			}

			if (!parcela.conta) {
				errorsParcelas[indexParcela] = {
					...errorsParcelas[indexParcela],
					conta: mensagensDeValidacao.OBRIGATORIO,
				}
			}

			valorTotalDasParcelas += parcela.valor;
			
		})

		if (values.valor !== Number(valorTotalDasParcelas.toFixed(2))) {
			errors[0] = {
				...errors[0],
				valorTotal: `Valor total das parcelas é diferente do valor da forma de pagamento`,
				showMessage: true,
			};
		}

		if (errorsParcelas.length > 0) {
			errors[indexPagamento] = {
				...errors[indexPagamento],
				parcelas: errorsParcelas,
			};
		}

		return errors.length !== 0 ? errors : {};
	},

	handleSubmit: () => {},
})(ModalParcelasImpl);

export const ModalParcelas = ModalParcelasFormik;
