import { montarLabelPessoa } from 'components/select/SingleSelectPessoa/Util';
import { getYear } from 'date-fns';

export function converterConfiguracoesGeraisParaFormulario(
	values,
	marcarCheckboxEmiteNfe,
	marcarCheckboxEmiteNfce,
	marcarCheckboxEmiteNfse,
	marcarCheckboxEmiteMdfe,
	marcarCheckboxEmiteCte,
	marcarCheckBoxUsaGestaoFechamentoControladoria
) {
	return {
		razaoSocial: values.razaoSocial ?? null,
		nome: values.nome ?? null,
		nomeApresentacao: values.nomeApresentacao ?? null,
		tipo: values.tipo ?? null,
		cnpj: values.cnpj ?? null,
		cpf: values.cpf ?? null,
		email: values.email ?? null,
		telefone: values.telefone ?? null,
		inscricaoEstadual: values.inscricaoEstadual ?? null,
		regimeTributario: values.regimeTributario ?? null,
		modulos: values.modulos.sort(),
		logradouro: values.endereco?.logradouro ?? null,
		numero: values.endereco?.numero ?? null,
		bairro: values.endereco?.bairro ?? null,
		cep: values.endereco?.cep ?? null,
		complemento: values.endereco?.complemento ?? null,
		municipio: values.endereco ? converterMunicipioParaFormulario(values.endereco.municipio) : null,
		pais: values.endereco ? converterPaisParaFormulario(values.endereco.pais) : null,
		logotipoUrl: values.logotipoUrl ?? null,
		certificado: converterCertificadoParaFormulario(values.parametrosFiscalCertificado),
		certificadoSenha: null,
		certificadoConfirmacaoSenha: null,
		certificadoVencimento: values.certificado != null ? values.parametrosFiscalCertificado.vencimento : null,
		cnae: values.cnae ?? null,
		inscricaoEstadualSubstitutoTributario: values.inscricaoEstadualSubstitutoTributario ?? null,
		inscricaoMunicipal: values.inscricaoMunicipal ?? null,
		suframa: values.suframa ?? null,

		plugnotasStatus: values.plugnotasStatus ?? null,
		emiteNfe: marcarCheckboxEmiteNfe === true ? marcarCheckboxEmiteNfe : values.emiteNfe,
		bibliotecaNfe: values.parametrosFiscalNFe?.bibliotecaNfe ?? null,
		serieNfe: values.parametrosFiscalNFe?.serieNfe ?? null,
		numeroInicialNfe: values.parametrosFiscalNFe?.numeroInicialNfe ?? null,
		alterarNumeroInicialESerieNfe: values.parametrosFiscalNFe?.serieNfe ? false : true,
		tipoImpressaoDanfeNfe: values.parametrosFiscalNFe?.tipoImpressaoDanfeNfe ?? null,
		impressaoPartilhaDanfeNfe: values.parametrosFiscalNFe?.impressaoPartilhaDanfeNfe ?? null,
		impressaoFcpDanfeNfe: values.parametrosFiscalNFe?.impressaoFcpDanfeNfe ?? null,
		envioAutomaticoEmailNfe: values.parametrosFiscalNFe?.envioAutomaticoEmailNfe ?? null,
		ultimoNsu: values.parametrosFiscalNFe?.ultimoNsu ?? null,
		tipoEmissaoNfe: values.parametrosFiscalNFe?.tipoEmissaoNfe ?? null,

		emiteNfce: marcarCheckboxEmiteNfce === true ? marcarCheckboxEmiteNfce : values.emiteNfce,
		serieNfce: values.parametrosFiscalNFCe?.serieNfce ?? null,
		numeroInicialNfce: values.parametrosFiscalNFCe?.numeroInicialNfce ?? null,
		codigoSegurancaContribuinteNfce: values.parametrosFiscalNFCe?.codigoSegurancaContribuinteNfce ?? null,
		tokenSegurancaContribuinteNfce: values.parametrosFiscalNFCe?.tokenSegurancaContribuinteNfce ?? null,
		alterarNumeroInicialESerieNfce: values.parametrosFiscalNFCe?.serieNfce ? false : true,
		tipoImpressaoDanfeNfce: values.parametrosFiscalNFCe?.tipoImpressaoDanfeNfce ?? null,
		envioAutomaticoEmailNfce: values.parametrosFiscalNFCe?.envioAutomaticoEmailNfce ?? null,
		bibliotecaNfce: values.parametrosFiscalNFCe?.bibliotecaNfce ?? null,
		operacaoFiscalNfce: values.parametrosFiscalNFCe?.operacaoFiscal
			? converterOperacaoFiscalParaFormulario(values.parametrosFiscalNFCe?.operacaoFiscal)
			: null,

		emiteNfse: marcarCheckboxEmiteNfse === true ? marcarCheckboxEmiteNfse : values.emiteNfse,
		alterarNumeroInicialESerieNfse: values.parametrosFiscalNFSe?.serie ? false : true,
		integracaoNfse: values.parametrosFiscalNFSe?.integracao ?? null,
		serieNfse: values.parametrosFiscalNFSe?.serie ?? null,
		numeroInicialNfse: values.parametrosFiscalNFSe?.numeroInicial ?? null,
		numeroInicialRps: values.parametrosFiscalNFSe?.numeroRps ?? null,
		loteInicialRps: values.parametrosFiscalNFSe?.loteRps ?? null,
		urlServidorNfse: values.parametrosFiscalNFSe?.urlServidor ?? null,
		usuarioNfse: values.parametrosFiscalNFSe?.usuario ?? null,
		senhaNfse: values.parametrosFiscalNFSe?.senha ?? null,
		envioAutomaticoEmailNfse: values.parametrosFiscalNFSe?.envioAutomaticoEmailNfse,

		emiteMdfe: marcarCheckboxEmiteMdfe === true ? marcarCheckboxEmiteMdfe : values.emiteMdfe,
		alterarNumeroInicialESerieMdfe: values.parametrosFiscalMDFe?.serie ? false : true,
		serieMdfe: values.parametrosFiscalMDFe?.serie ?? null,
		numeroInicialMdfe: values.parametrosFiscalMDFe?.numeroInicial ?? null,

		emiteCte: marcarCheckboxEmiteCte === true ? marcarCheckboxEmiteCte : values.emiteCte,
		tipoEmissaoCte: values.parametrosFiscalCTe?.tipoEmissaoCte,
		tipoImpressaoDacte: values.parametrosFiscalCTe?.tipoImpressaoDacte,
		alterarNumeroInicialESerieCte: values.parametrosFiscalCTe?.serie ? false : true,
		serieCte: values.parametrosFiscalCTe?.serie ?? null,
		numeroInicialCte: values.parametrosFiscalCTe?.numeroInicial ?? null,

		parametrizacao: {
			financas: {
				enviarEmailAutomatico: values.parametrosFinancas?.enviarEmailAutomatico ?? null,
				mensagemEmailContasPagar: values.parametrosFinancas?.mensagemEmailContasPagar ?? null,
				mensagemEmailContasReceber: values.parametrosFinancas?.mensagemEmailContasReceber ?? null,
				gerarFinanceiroNaNfe: values.parametrosFinancas?.gerarFinanceiroNaNfe ?? null,
				gerarFinanceiroNaNfse: values.parametrosFinancas?.gerarFinanceiroNaNfse ?? null,
				plugBoletosToken: values.plugBoletosToken ?? null,
				visualizarReceberTodasFiliais: values.parametrosFinancas?.visualizarReceberTodasFiliais ?? null,
				visualizarPagarTodasFiliais: values.parametrosFinancas?.visualizarPagarTodasFiliais ?? null,
				permitirTransferenciaEntreFiliais: values.parametrosFinancas?.permitirTransferenciaEntreFiliais ?? null,
				taxaCategoria: converterTaxaCategoriaParaFormulario(values.parametrosFinancas?.taxaCategoria),
			},
			estoque: {
				gerarEstoqueNaNfe: values.parametrosEstoque?.gerarEstoqueNaNfe ?? null,
				permitirEstoqueNegativo: values.parametrosEstoque?.permitirEstoqueNegativo ?? null,
				tipoProdutosParticipaInventario: values.parametrosEstoque?.tipoProdutosParticipaInventario ?? null,
			},
			vendas: {
				codigoNaNfe: values.parametrosVendas.codigoNaNfe,
				codigoNaNfce: values.parametrosVendas.codigoNaNfce,
				campoBarras: values.parametrosVendas.campoBarras,
				iniciaisBarras: values.parametrosVendas.iniciaisBarras,
				posicaoBarras: values.parametrosVendas.posicaoBarras,
				tamanhoBarras: values.parametrosVendas.tamanhoBarras,
				decimaisBarras: values.parametrosVendas.decimaisBarras,
				informacaoBarras: values.parametrosVendas.informacaoBarras,
				tipoImpressaoVenda: values.parametrosVendas.tipoImpressaoVenda,
				observacaoPadraoNfe: values.parametrosVendas.observacaoPadraoNfe,
				utilizaTabelaPreco: values.parametrosVendas.utilizaTabelaPreco,
				utilizaTabelaPrecoPorRegiao: values.parametrosVendas.utilizaTabelaPrecoPorRegiao,
				utilizaTabelaPrecoPromocional: values.parametrosVendas.utilizaTabelaPrecoPromocional,
				utilizaTabelaPrecoDescontoQuantidade: values.parametrosVendas.utilizaTabelaPrecoDescontoQuantidade,
				utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao:
					values.parametrosVendas.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao,
				utilizaTabelaPrecoParaServico: values.parametrosVendas.utilizaTabelaPrecoParaServico,
				tabelaPrecoPadrao: converterTabelaPrecoParaFormulario(values.parametrosVendas?.tabelaPrecoPadrao),
			},
			servicos: {
				padraoMunicipioPrestacao: values.parametrosServicos?.padraoMunicipioPrestacao,
			},
			transportes: {
				cteRntrc: values.parametrosTransportes?.cteRntrc,
				cteCst: converterCstParaFormulario(values.parametrosTransportes?.cteCst),
				cteCfopIntraestadual: converterCfopParaFormulario(values.parametrosTransportes?.cteCfopIntraestadual),
				cteCfopInterestadual: converterCfopParaFormulario(values.parametrosTransportes?.cteCfopInterestadual),
				cteLocalInicio: converterMunicipioParaFormulario(values.parametrosTransportes?.cteLocalInicio),
				cteLocalTermino: converterMunicipioParaFormulario(values.parametrosTransportes?.cteLocalTermino),
				cteTomadorPadrao: values.parametrosTransportes?.cteTomadorPadrao,
				cteRemetente: converterPessoaParaFormulario(values.parametrosTransportes?.cteRemetente),
				cteDestinatario: converterPessoaParaFormulario(values.parametrosTransportes?.cteDestinatario),
				ctePercentualBaseIcms: values.parametrosTransportes?.ctePercentualBaseIcms,
				ctePercentualIcms: values.parametrosTransportes?.ctePercentualIcms,
				cteImportarNfeTodasFiliais: values.parametrosTransportes?.cteImportarNfeTodasFiliais,
				mdfeImportarNfeTodasFiliais: values.parametrosTransportes?.mdfeImportarNfeTodasFiliais,
				mdfeImportarCteTodasFiliais: values.parametrosTransportes?.mdfeImportarCteTodasFiliais,
			},
			controladoria: {
				usaGestaoFechamento:
					marcarCheckBoxUsaGestaoFechamentoControladoria || values.parametrosControladoria?.usaGestaoFechamento,
			},
			documentos: {
				id: values.documentos?.id ?? null,
				tipoGestaoDocumento: values.parametrosDocumentos?.tipoGestaoDocumento,
				corpoEmailDocumentos: values.parametrosDocumentos?.corpoEmail ?? null,
				emailAlertaVigencia: values.parametrosDocumentos?.emailAlertaVigencia,
				diasAlertaVigencia: values.parametrosDocumentos?.diasAlertaVigencia,
				tipoAlertaVigencia: values.parametrosDocumentos?.tipoAlertaVigencia,
				signatarioPadrao: converterSignatarioPadraoParaForm(values.parametrosDocumentos?.signatarios ?? null),
				confirmacaoPadrao: converterConfirmacaoPadraoParaForm(values.parametrosDocumentos?.confirmacoes ?? null),
				assinaturaTituloImpresso: values.parametrosDocumentos?.assinaturaTituloImpresso
			},
			cadastros: {
				decimaisQtd: values.parametrosCadastros?.decimaisQtd,
				decimaisPreco: values.parametrosCadastros?.decimaisPreco,
				utilizaPrecoCusto: values.parametrosCadastros?.utilizaPrecoCusto,
			},
			geral: {
				pesquisaInteracao: values.parametrosGerais?.pesquisaInteracao,
			},
		},
	};
}

export function converterConfiguracoesGeraisParaApi(values) {
	return {
		...values,
		pais: null,
		endereco: {
			logradouro: values.logradouro,
			numero: values.numero,
			bairro: values.bairro,
			cep: values.cep,
			municipio: values.municipio ? { id: values.municipio.value } : null,
			pais: values.pais ? { id: values.pais.value } : null,
			complemento: values.complemento,
		},
		parametrosFiscalNFe: {
			tipoImpressaoDanfeNfe: values.tipoImpressaoDanfeNfe,
			impressaoFcpDanfeNfe: values.impressaoFcpDanfeNfe,
			impressaoPartilhaDanfeNfe: values.impressaoPartilhaDanfeNfe,
			serieNfe: values.emiteNfe ? values.serieNfe : null,
			numeroInicialNfe: values.numeroInicialNfe,
			envioAutomaticoEmailNfe: values.envioAutomaticoEmailNfe,
			alterarNumeroInicialESerie: values.alterarNumeroInicialESerieNfe,
			bibliotecaNfe: values.bibliotecaNfe,
			ultimoNsu: values.ultimoNsu,
			tipoEmissaoNfe: values.tipoEmissaoNfe,
		},
		parametrosFiscalNFCe: {
			envioAutomaticoEmailNfce: values.envioAutomaticoEmailNfce,
			tipoImpressaoDanfeNfce: values.tipoImpressaoDanfeNfce,
			tokenSegurancaContribuinteNfce: values.tokenSegurancaContribuinteNfce,
			codigoSegurancaContribuinteNfce: values.codigoSegurancaContribuinteNfce,
			serieNfce: values.emiteNfce ? values.serieNfce : null,
			numeroInicialNfce: values.numeroInicialNfce,
			alterarNumeroInicialESerie: values.alterarNumeroInicialESerieNfce,
			bibliotecaNfce: values.bibliotecaNfce,
			operacaoFiscalNfce: values.operacaoFiscalNfce?.value ?? null,
		},
		parametrosFiscalNFSe: {
			integracao: values.integracaoNfse,
			serie: values.serieNfse,
			numeroInicial: values.numeroInicialNfse,
			numeroRps: values.numeroInicialRps,
			loteRps: values.loteInicialRps,
			urlServidor: values.urlServidorNfse,
			usuario: values.usuarioNfse,
			senha: values.senhaNfse,
			envioAutomaticoEmailNfse: values.envioAutomaticoEmailNfse,
		},
		parametrosFiscalMDFe: {
			serie: values.serieMdfe,
			numeroInicial: values.numeroInicialMdfe,
			alterarNumeroInicialESerie: values.alterarNumeroInicialESerieMdfe,
		},
		parametrosFiscalCTe: {
			serie: values.serieCte,
			numeroInicial: values.numeroInicialCte,
			alterarNumeroInicialESerie: values.alterarNumeroInicialESerieCte,
			tipoEmissaoCte: values.tipoEmissaoCte,
			tipoImpressaoDacte: values.tipoImpressaoDacte,
		},
		parametrosFinancas: {
			enviarEmailAutomatico: values.parametrizacao.financas.enviarEmailAutomatico,
			gerarFinanceiroNaNfe: values.parametrizacao.financas.gerarFinanceiroNaNfe,
			gerarFinanceiroNaNfse: values.parametrizacao.financas.gerarFinanceiroNaNfse,
			mensagemEmailContasPagar: values.parametrizacao.financas.mensagemEmailContasPagar,
			mensagemEmailContasReceber: values.parametrizacao.financas.mensagemEmailContasReceber,
			visualizarReceberTodasFiliais: values.parametrizacao.financas.visualizarReceberTodasFiliais,
			visualizarPagarTodasFiliais: values.parametrizacao.financas.visualizarPagarTodasFiliais,
			permitirTransferenciaEntreFiliais: values.parametrizacao.financas.permitirTransferenciaEntreFiliais,
			taxaCategoria: values.parametrizacao.financas?.taxaCategoria
				? { id: values.parametrizacao.financas?.taxaCategoria?.value }
				: null,
		},
		parametrosEstoque: {
			gerarEstoqueNaNfe: values.parametrizacao.estoque.gerarEstoqueNaNfe,
			permitirEstoqueNegativo: values.parametrizacao.estoque.permitirEstoqueNegativo,
			tipoProdutosParticipaInventario: values.parametrizacao.estoque.tipoProdutosParticipaInventario,
		},
		parametrosVendas: {
			codigoNaNfe: values.parametrizacao.vendas.codigoNaNfe,
			codigoNaNfce: values.parametrizacao.vendas.codigoNaNfce,
			campoBarras: values.parametrizacao.vendas.campoBarras,
			iniciaisBarras: values.parametrizacao.vendas.iniciaisBarras,
			posicaoBarras: values.parametrizacao.vendas.posicaoBarras,
			tamanhoBarras: values.parametrizacao.vendas.tamanhoBarras,
			decimaisBarras: values.parametrizacao.vendas.decimaisBarras,
			informacaoBarras: values.parametrizacao.vendas.informacaoBarras,
			tipoImpressaoVenda: values.parametrizacao.vendas.tipoImpressaoVenda,
			observacaoPadraoNfe: values.parametrizacao.vendas.observacaoPadraoNfe,
			utilizaTabelaPreco: values.parametrizacao.vendas.utilizaTabelaPreco,
			utilizaTabelaPrecoPorRegiao: values.parametrizacao.vendas.utilizaTabelaPrecoPorRegiao,
			utilizaTabelaPrecoPromocional: values.parametrizacao.vendas.utilizaTabelaPrecoPromocional,
			utilizaTabelaPrecoDescontoQuantidade: values.parametrizacao.vendas.utilizaTabelaPrecoDescontoQuantidade,
			utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao:
				values.parametrizacao.vendas.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao,
			utilizaTabelaPrecoParaServico: values.parametrizacao.vendas.utilizaTabelaPrecoParaServico,
			tabelaPrecoPadrao: values.parametrizacao.vendas?.tabelaPrecoPadrao
				? { id: values.parametrizacao.vendas?.tabelaPrecoPadrao?.value }
				: null,
		},
		parametrosServicos: {
			padraoMunicipioPrestacao: values.parametrizacao.servicos.padraoMunicipioPrestacao,
		},
		parametrosTransportes: {
			cteRntrc: values.parametrizacao.transportes.cteRntrc,
			cteCst: values.parametrizacao.transportes?.cteCst
				? { id: values.parametrizacao.transportes?.cteCst?.value }
				: null,
			cteCfopIntraestadual: values.parametrizacao.transportes?.cteCfopIntraestadual
				? { id: values.parametrizacao.transportes?.cteCfopIntraestadual?.value }
				: null,
			cteCfopInterestadual: values.parametrizacao.transportes?.cteCfopInterestadual
				? { id: values.parametrizacao.transportes?.cteCfopInterestadual?.value }
				: null,

			cteLocalInicio: values.parametrizacao.transportes?.cteLocalInicio
				? { id: values.parametrizacao.transportes?.cteLocalInicio?.value }
				: null,
			cteLocalTermino: values.parametrizacao.transportes?.cteLocalTermino
				? { id: values.parametrizacao.transportes?.cteLocalTermino?.value }
				: null,
			cteTomadorPadrao: values.parametrizacao.transportes?.cteTomadorPadrao,
			cteRemetente: values.parametrizacao.transportes?.cteRemetente
				? { id: values.parametrizacao.transportes?.cteRemetente?.value }
				: null,
			cteDestinatario: values.parametrizacao.transportes?.cteDestinatario
				? { id: values.parametrizacao.transportes?.cteDestinatario?.value }
				: null,
			ctePercentualBaseIcms: values.parametrizacao.transportes?.ctePercentualBaseIcms,
			ctePercentualIcms: values.parametrizacao.transportes?.ctePercentualIcms,
			cteImportarNfeTodasFiliais: values.parametrizacao.transportes?.cteImportarNfeTodasFiliais,
			mdfeImportarNfeTodasFiliais: values.parametrizacao.transportes?.mdfeImportarNfeTodasFiliais,
			mdfeImportarCteTodasFiliais: values.parametrizacao.transportes?.mdfeImportarCteTodasFiliais,
		},
		parametrosControladoria: {
			usaGestaoFechamento: values.parametrizacao.controladoria?.usaGestaoFechamento,
		},
		parametrosDocumentos: {
			tipoGestaoDocumento: values.parametrizacao.documentos?.tipoGestaoDocumento,
			corpoEmail: values.parametrizacao.documentos?.corpoEmailDocumentos ?? null,
			emailAlertaVigencia: values.parametrizacao.documentos?.emailAlertaVigencia,
			diasAlertaVigencia: values.parametrizacao.documentos?.diasAlertaVigencia,
			tipoAlertaVigencia: values.parametrizacao.documentos?.tipoAlertaVigencia,
			signatarios: converterSignatarioPadraoParaApi(values.parametrizacao.documentos.signatarioPadrao),
			confirmacoes: converterConfirmacaoPadraoParaApi(values.parametrizacao.documentos.confirmacaoPadrao),
			assinaturaTituloImpresso: values.parametrizacao.documentos?.assinaturaTituloImpresso,
		},
		parametrosCadastros: {
			decimaisQtd: values.parametrizacao.cadastros.decimaisQtd,
			decimaisPreco: values.parametrizacao.cadastros.decimaisPreco,
			utilizaPrecoCusto: values.parametrizacao.cadastros.utilizaPrecoCusto,
		},
		parametrosGerais: {
			pesquisaInteracao: values.parametrizacao.geral.pesquisaInteracao,
		},
	};
}

function converterSignatarioPadraoParaForm(signatariosPadrao) {
	if (!signatariosPadrao) {
		return [];
	}
	return signatariosPadrao.map((signatario) => {
		return {
			id: signatario.id,
			pessoa: signatario.pessoa ? { label: montarLabelPessoa(signatario.pessoa), value: signatario.pessoa.id } : null,
			email: signatario.email,
			nome: signatario.nome,
			papel: signatario.papel,
			assinatura: signatario.assinatura,
			confirmacoes: signatario.confirmacoes,
			telefone: signatario.telefone,
			linkAssinatura: signatario.linkAssinatura,
		};
	});
}

function converterConfirmacaoPadraoParaForm(confirmacoesPadrao) {
	if (!confirmacoesPadrao) {
		return [];
	}
	return confirmacoesPadrao.map((confirmacao) => {
		return {
			id: confirmacao.id,
			pergunta: confirmacao.pergunta,
			respostaTipo: confirmacao.respostaTipo,
			respostaEsperada: confirmacao.respostaEsperada,
		};
	});
}

function converterSignatarioPadraoParaApi(signatariosPadrao) {
	if (!signatariosPadrao) {
		return [];
	}
	return signatariosPadrao.map((signatario) => {
		return {
			id: signatario.id,
			pessoa: signatario.pessoa
				? {
						id: signatario.pessoa.value,
					}
				: null,
			email: signatario.email ? signatario.email : signatario.pessoaEmail.registro.email,
			nome: signatario.pessoa && signatario.pessoa.registro ? signatario.pessoa.registro.nome : signatario.nome,
			papel: signatario.papel,
			assinatura: signatario.assinatura,
			telefone: signatario.telefone ? signatario.telefone : signatario.pessoaTelefone.registro.telefone,
		};
	});
}

function converterConfirmacaoPadraoParaApi(confirmacoesPadrao) {
	if (!confirmacoesPadrao) {
		return [];
	}
	return confirmacoesPadrao.map((confirmacao) => {
		return {
			id: confirmacao.id,
			pergunta: confirmacao.pergunta,
			respostaTipo: confirmacao.respostaTipo,
			respostaEsperada: confirmacao.respostaEsperada,
		};
	});
}

export function converterAliquotaSimplesParaApi(values) {
	return {
		...values,
		percentualAliquota: values.aliquotaSimples,
		anoVigencia: getYear(new Date()),
	};
}

function converterMunicipioParaFormulario(municipio) {
	if (municipio) {
		return {
			label: municipio.nome + ' - ' + municipio.estadoSigla,
			value: municipio.id,
		};
	}
	return null;
}

function converterTabelaPrecoParaFormulario(tabelaPreco) {
	if (tabelaPreco) {
		return {
			label: tabelaPreco.nome,
			value: tabelaPreco.id,
			registro: tabelaPreco,
		};
	}
	return null;
}

function converterCstParaFormulario(cst) {
	if (cst) {
		return {
			label: `${cst.codigo} - ${cst.descricao}`,
			value: cst.id,
			registro: cst,
		};
	}
	return null;
}

function converterCfopParaFormulario(cfop) {
	if (cfop) {
		return {
			label: `${cfop.codigo} - ${cfop.descricao}`,
			value: cfop.id,
			registro: cfop,
		};
	}
	return null;
}

function converterPessoaParaFormulario(pessoa) {
	if (pessoa) {
		return {
			label: `${pessoa.codigo} - ${pessoa.nome}`,
			value: pessoa.id,
			registro: pessoa,
		};
	}
	return null;
}

function converterTaxaCategoriaParaFormulario(taxaCategoria) {
	if (taxaCategoria) {
		return {
			label: taxaCategoria.nome,
			value: taxaCategoria.id,
			registro: taxaCategoria,
		};
	}
	return null;
}

function converterCertificadoParaFormulario(certificadoApi) {
	if (!certificadoApi) {
		return null;
	}
	return {
		name: certificadoApi.nome,
		vencimento: certificadoApi.vencimento,
	};
}

function converterPaisParaFormulario(pais) {
	if (pais) {
		return {
			label: pais.nome,
			value: pais.id,
		};
	}
	return null;
}

function converterOperacaoFiscalParaFormulario(operacaoFiscal) {
	if (operacaoFiscal) {
		return {
			label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
			registro: operacaoFiscal,
			value: operacaoFiscal.id,
		};
	}
	return null;
}

function converterUsuarioParaFormulario(usuario) {
	if (usuario) {
		return {
			label: usuario.nome,
			registro: usuario,
			value: usuario.id,
		};
	}
	return null;
}

function converterUsuarioParaApi(usuario) {
	if (usuario) {
		return {
			id: usuario.value,
			nome: usuario.label,
		};
	}
	return null;
}
