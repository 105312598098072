import { asyncSalvarConfiguracaoUsuario } from './Requests';
import {
	formatarConfiguracaoUsuarioParaApi,
	formatarConfiguracaoUsuarioParaAplicacao,
	adicionarConfiguracaoNosDadosLogin,
} from './Util/functions';
import { buscarDadosLoginLocalStorage, salvarDadosLoginLocalStorage } from '../Autenticacao';

export const configuracoesUsuario = {
	EXIBIR_TUTORIAL_LISTAGENS: 'WEB_EXIBIR_TUTORIAL_LISTAGENS',
	EXIBIR_TUTORIAL_PAPEIS_PERMISSOES: 'WEB_EXIBIR_TUTORIAL_PAPEIS_PERMISSOES',
	EXIBIR_TUTORIAL_PLANOS: 'WEB_EXIBIR_TUTORIAL_PLANOS',
	EXIBIR_TUTORIAL_CABECALHO_RODAPE: 'WEB_EXIBIR_TUTORIAL_CABECALHO_RODAPE',
	EXIBIR_TUTORIAL_CONFIRMACOES: 'WEB_EXIBIR_TUTORIAL_CONFIRMACOES',
	EXIBIR_TUTORIAL_MODELOS_DOCUMENTO: 'WEB_EXIBIR_TUTORIAL_MODELOS_DOCUMENTO',
	EXIBIR_TUTORIAL_DOCUMENTO: 'WEB_EXIBIR_TUTORIAL_DOCUMENTO',
	EXIBIR_TUTORIAL_ORCAMENTO: 'WEB_EXIBIR_TUTORIAL_ORCAMENTO',
	EXIBIR_TUTORIAL_PEDIDO_VENDA: 'WEB_EXIBIR_TUTORIAL_PEDIDO_VENDA',
	EXIBIR_TUTORIAL_NOTA_FISCAL: 'WEB_EXIBIR_TUTORIAL_NOTA_FISCAL',
	EXIBIR_TUTORIAL_PRODUTO: 'WEB_EXIBIR_TUTORIAL_PRODUTO',
	EXIBIR_TUTORIAL_NFCE: 'WEB_EXIBIR_TUTORIAL_NFCE',
	EXIBIR_TUTORIAL_GESTAO_DOCUMENTOS: 'WEB_EXIBIR_TUTORIAL_GESTAO_DOCUMENTOS',
	EXIBIR_TUTORIAL_PESSOA: 'WEB_EXIBIR_TUTORIAL_PESSOA',
	EXIBIR_TUTORIAL_OPERACOES_FISCAIS: 'WEB_EXIBIR_TUTORIAL_OPERACOES_FISCAIS',
	EXIBIR_TUTORIAL_TRIBUTACAO_FEDERAL: 'WEB_EXIBIR_TUTORIAL_TRIBUTACAO_FEDERAL',
	EXIBIR_TUTORIAL_TRIBUTACAO_ESTADUAL: 'WEB_EXIBIR_TUTORIAL_TRIBUTACAO_ESTADUAL',
	EXIBIR_TUTORIAL_TRIBUTACAO_MUNICIPAL: 'WEB_EXIBIR_TUTORIAL_TRIBUTACAO_MUNICIPAL',
	EXIBIR_TUTORIAL_NCM: 'WEB_EXIBIR_TUTORIAL_NCM',
	EXIBIR_TUTORIAL_NOTA_ENTRADA: 'WEB_EXIBIR_TUTORIAL_NOTA_ENTRADA',
	EXIBIR_TUTORIAL_MDE: 'WEB_EXIBIR_TUTORIAL_MDE',
	EXIBIR_TUTORIAL_MARCA: 'WEB_EXIBIR_TUTORIAL_MARCA',
	EXIBIR_TUTORIAL_MODELO_SERVICO: 'WEB_EXIBIR_TUTORIAL_MODELO_SERVICO',
	EXIBIR_TUTORIAL_OBJETO_DE_SERVICO: 'WEB_EXIBIR_TUTORIAL_OBJETO_DE_SERVICO',
	EXIBIR_TUTORIAL_CADASTRO_CODIGO_SERVICO: 'WEB_EXIBIR_TUTORIAL_CADASTRO_CODIGO_SERVICO',
	EXIBIR_TUTORIAL_NFSE: 'WEB_EXIBIR_TUTORIAL_NFSE',
	EXIBIR_TUTORIAL_TECNICO: 'EXIBIR_TUTORIAL_TECNICO',
	EXIBIR_TUTORIAL_SETOR: 'EXIBIR_TUTORIAL_SETOR',
	EXIBIR_TUTORIAL_ORDEM_SERVICO: 'WEB_EXIBIR_TUTORIAL_ORDEM_SERVICO',
	EXIBIR_TUTORIAL_ETIQUETA_PRODUTOS: 'WEB_EXIBIR_TUTORIAL_ETIQUETA_PRODUTOS',
	EXIBIR_TUTORIAL_ETIQUETA_CADASTRO: 'WEB_EXIBIR_TUTORIAL_ETIQUETA_CADASTRO',
	EXIBIR_TUTORIAL_COMPONENTE_VALOR_PRESTACAO: 'WEB_EXIBIR_TUTORIAL_COMPONENTE_VALOR_PRESTACAO',
	EXIBIR_TUTORIAL_MERCADORIA_TRANSPORTE: 'WEB_EXIBIR_TUTORIAL_MERCADORIA_TRANSPORTE',
	EXIBIR_TUTORIAL_CONDUTOR: 'WEB_EXIBIR_TUTORIAL_CONDUTOR',
	EXIBIR_TUTORIAL_VEICULO: 'WEB_EXIBIR_TUTORIAL_VEICULO',
	EXIBIR_TUTORIAL_SEGURADORA: 'WEB_EXIBIR_TUTORIAL_SEGURADORA',
	EXIBIR_TUTORIAL_CTE: 'WEB_EXIBIR_TUTORIAL_CTE',
	EXIBIR_TUTORIAL_MDFE: 'WEB_EXIBIR_TUTORIAL_MDFE',
	EXIBIR_TUTORIAL_TABELA_PRECO: 'WEB_EXIBIR_TUTORIAL_TABELA_PRECO',
	EXIBIR_TUTORIAL_VENDEDOR: 'WEB_EXIBIR_TUTORIAL_VENDEDOR',
	EXIBIR_TUTORIAL_REGIAO: 'WEB_EXIBIR_TUTORIAL_REGIAO',
	EXIBIR_TUTORIAL_FORMA_PAGAMENTO: 'WEB_EXIBIR_TUTORIAL_FORMA_PAGAMENTO',
	EXIBIR_TUTORIAL_CONDICAO_PAGAMENTO: 'WEB_EXIBIR_TUTORIAL_CONDICAO_PAGAMENTO',
	EXIBIR_TUTORIAL_SITUACAO: 'WEB_EXIBIR_TUTORIAL_SITUACAO',
	EXIBIR_TUTORIAL_NATUREZA_OPERACAO: 'WEB_EXIBIR_TUTORIAL_NATUREZA_OPERACAO',
};

export async function salvarConfiguracaoUsuario(chave, valor, onSuccess, dispararAcoes) {
	const valorFormatado = formatarConfiguracaoUsuarioParaApi(valor);

	await asyncSalvarConfiguracaoUsuario(
		chave,
		valorFormatado,
		async (e) => {
			salvarConfiguracaoLocalStorage(chave, valor);
			onSuccess && onSuccess(e);
		},
		dispararAcoes
	);
}

export function buscarConfiguracaoUsuario(chave) {
	const dadosLogin = buscarDadosLoginLocalStorage();

	for (const configuracao of dadosLogin.configuracoes) {
		if (configuracao.chave === chave) {
			return formatarConfiguracaoUsuarioParaAplicacao(configuracao.valor);
		}
	}
	return null;
}

function salvarConfiguracaoLocalStorage(chave, valor) {
	let dadosLogin = buscarDadosLoginLocalStorage();
	dadosLogin = adicionarConfiguracaoNosDadosLogin({ chave, valor }, dadosLogin);
	salvarDadosLoginLocalStorage(dadosLogin);
}
