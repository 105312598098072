function converterNaturezaOperacaoParaFormulario(value) {
	return {
		id: value.id,
		descricao: value.descricao,
		situacao: value.situacao,
		favorito: value.favorito,
	};
}

function converterNaturezaOperacaoParaApi(value) {
	return {
		id: value.id ? value.id : null,
		descricao: value.descricao,
		situacao: value.situacao,
		favorito: value.favorito,
	};
}

export { converterNaturezaOperacaoParaFormulario, converterNaturezaOperacaoParaApi };
