import { useEffect } from 'react';
import { useFormikContext, withFormik } from 'formik';

import { Form, FormActions, FormContent, Modal } from 'components';
import { estadosCadastro, getSafeValue, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { getVendaItensEntregues } from '../../Requests';

import { converterProdutosEntregarParaForm } from '../../Form/Util/PedidovendasOrcamentosConverter';
import { ActionButtons } from './components/ActionButtons';
import { ListaProdutosEntrega } from './components/ListaProdutosEntrega';

function ModalEntregarItensImpl({ vendaId, visible, onHide, isMobile, isTablet, asyncSelectRegistro }) {
	const { values, resetForm } = useFormikContext();

	const podeInserir = usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.EXCLUIR);

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
	};

	useEffect(() => {
		getVendaItensEntregues(vendaId, ({ data }) => {
			if (data?.length > 0) {
				atualizarProdutos(data);
			}
		});
	}, []);

	function atualizarProdutos(data) {
		const produtos = values?.produtos
			?.map((produto) => {
				const itens = data?.filter((item) => item?.vendaItemId === produto?.id) || [];
				const totalEntregue = itens.reduce((total, item) => total + (item.entregaQuantidade || 0), 0);

				if (totalEntregue > 0) {
					const quantidade = produto.quantidade - totalEntregue;
					const unitario = produto.subtotal / produto.quantidade;
					const subtotal = getSafeValue(quantidade * unitario);

					return {
						...produto,
						quantidade: quantidade,
						quantidadeInicial: quantidade,
						valor: unitario,
						subtotal: subtotal,
					};
				}

				return { ...produto, quantidadeInicial: produto.quantidade };
			})
			?.filter((produto) => produto.quantidade > 0);

		resetForm({ values: { ...values, produtos: produtos }, initialValues: { ...values, produtos: produtos } });
	}

	return (
		<Modal header="Entrega de itens" visible={visible} onHide={onHide}>
			<Form>
				<FormActions>
					<ActionButtons
						vendaId={vendaId}
						informacoesPermissoes={informacoesPermissoes}
						onHide={onHide}
						asyncSelectRegistro={asyncSelectRegistro}
					/>
				</FormActions>
				<FormContent>
					<ListaProdutosEntrega isMobile={isMobile} isTablet={isTablet} informacoesPermissoes={informacoesPermissoes} />
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalEntregarItens = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return { produtos: converterProdutosEntregarParaForm(props.produtos) };
	},

	validate(props) {
		const errors = {};
		const errorsProdutos = [];

		if (props.produtos?.length > 0) {
			props.produtos?.forEach((produto, index) => {
				if (produto.quantidade > produto.quantidadeInicial) {
					errorsProdutos[index] = {
						...errorsProdutos[index],
						quantidade: 'Quantidade não pode ser maior que a quantidade disponível',
					};
				}

				if (produto.quantidade < 0) {
					errorsProdutos[index] = {
						...errorsProdutos[index],
						quantidade: 'Quantidade não pode ser menor que 0',
					};
				}
			});
		}

		if (errorsProdutos.length > 0) {
			errors.produtos = errorsProdutos;
		}

		return errors;
	},
})(ModalEntregarItensImpl);

export { ModalEntregarItens };
