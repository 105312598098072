import { useEffect, useState } from 'react';
import { useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';
import { Form, FormActions, FormContent, If, Prompt, Tutorial, tutorialStepsCondicaoPagamento } from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import {
	CADASTRO_URL,
	CONDICAO_PAGAMENTO_MOVIMENTACAO,
	CONDICAO_PAGAMENTO_TAXA_TIPO,
	CONDICAO_PAGAMENTO_TIPO,
	INITIAL_VALUES,
} from '../Util/constantes';
import { converterCondicaoPagamentoParaFormulario } from '../Util/condicoesPagamentoConverter';
import { readCondicaoPagamento } from '../Requests';
import { ActionButtons } from './components/ActionButtons';
import { CamposPrincipais } from './components/CamposPrincipais';
import { Compensacao } from './components/Compensacao';
import { Taxas } from './components/Taxas';

function CondicaoPagamentoFormImpl({ isModal, hideModal, history, match, isMobile, isTablet}) {
	const { values, dirty, resetForm } = useFormikContext();	
	const idCondicaoPagamento = match?.params?.id

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CONDICAO_PAGAMENTO);
	const isEntrada = values.movimentacao === CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA;

	useEffect(() => {
		if (idCondicaoPagamento) {
			if (deveExibirTutorial !== false) {
				setTutorialVisible(true);
				salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_CONDICAO_PAGAMENTO, false, null, false);
			}

			if (validarUUID(idCondicaoPagamento) && !isModal) {
				fetchRegistro(idCondicaoPagamento);
			}
		}
		setTimeout(() => {
			document.getElementById('condicao-pagamento-descricao')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(idCondicao) {
		await readCondicaoPagamento(idCondicao, ({ data: condicao }) => {
			resetForm({ values: converterCondicaoPagamentoParaFormulario(condicao) });
			if (!isModal) {
				atualizarUrl(history, CADASTRO_URL, condicao.id, metodosAtualizarUrl.POP);
			}
		});
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsCondicaoPagamento}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de condição de pagamento" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons isModal={isModal} hideModal={hideModal} isEntrada={isEntrada} history={history} match={match} />
				</FormActions>
				<FormContent>
					<CamposPrincipais isEntrada={isEntrada} />
					<If test={values.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO}>
						<Compensacao isEntrada={isEntrada} match={match}/>
					</If>

					<If test={values.tipo !== CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO}>
						<Taxas isMobile={isMobile} isTablet={isTablet} match={match} />
					</If>
				</FormContent>
			</Form>
		</>
	);
}

const CondicaoPagamentoForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};
		const errorsTaxas = [];

		if (values.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
			if (values.parcelaMaxima - values.parcelaMinima < 0) {
				errors.parcelaMaxima = 'A parcela máxima não pode ser menor que a mínima';
			}

			if (!values.parcelaPadrao) {
				errors.parcelaPadrao = mensagensDeValidacao.OBRIGATORIO;
			} else {
				if (values.parcelaPadrao > values.parcelaMaxima) {
					errors.parcelaPadrao = 'A parcela padrão não pode ser maior que a máxima';
				}
				if (values.parcelaPadrao < values.parcelaMinima) {
					errors.parcelaPadrao = 'A parcela padrão não pode ser menor que a mínima';
				}
			}
		}

		if (values.taxas.length > 0) {
			values.taxas.forEach((item, index) => {
				if (values.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
					if (item.valorInicial === null || item.valorInicial === undefined) {
						errorsTaxas[index] = {
							...errorsTaxas[index],
							valorInicial: mensagensDeValidacao.OBRIGATORIO,
						};
					}

					if (item.valorFinal === null || item.valorFinal === undefined) {
						errorsTaxas[index] = {
							...errorsTaxas[index],
							valorFinal: mensagensDeValidacao.OBRIGATORIO,
						};
					}
				}

				if (values.taxaTipoAplicado === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL) {
					if (item.percentualAplicado === null || item.percentualAplicado === undefined) {
						errorsTaxas[index] = {
							...errorsTaxas[index],
							percentualAplicado: mensagensDeValidacao.OBRIGATORIO,
						};
					}
				} else if (values.taxaTipoAplicado === CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR) {
					if (item.valorAplicado === null || item.valorAplicado === undefined) {
						errorsTaxas[index] = {
							...errorsTaxas[index],
							valorAplicado: mensagensDeValidacao.OBRIGATORIO,
						};
					}
				}

				if (item.valorFinal && item.valorFinal < item.valorInicial) {
					errorsTaxas[index] = {
						...errorsTaxas[index],
						valorFinal: 'Valor Final menor que inicial',
					};
				}

				if (index > 0) {
					const valorAnterior = values.taxas[index - 1];

					if (item.valorInicial && item.valorInicial <= valorAnterior.valorFinal) {
						errorsTaxas[index] = {
							...errorsTaxas[index],
							valorInicial: 'Valor menor que valor final anterior',
						};
					}
				}

				if (item.valorInicial && item.valorInicial < values.parcelaMinima) {
					errorsTaxas[index] = {
						...errorsTaxas[index],
						valorInicial: 'Valor inicial menor que Min. parcelas',
					};
				}
				if (
					values.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO &&
					item.valorFinal &&
					item.valorFinal > values.parcelaMaxima
				) {
					errorsTaxas[index] = {
						...errorsTaxas[index],
						valorFinal: 'Valor final maior que Max. parcelas',
					};
				}
			});
		}

		if (errorsTaxas?.length > 0) {
			errors.taxas = errorsTaxas;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.max(60, 'O campo não pode ter mais de 60 caracteres.'),
	}),

	handleSubmit: () => {},
})(CondicaoPagamentoFormImpl);

export { CondicaoPagamentoForm };
