import { Field, useFormikContext } from 'formik';

import { keyFilterConsultaRsql } from 'Common';
import { Badge, Col, ColorPicker, Dropdown, Grid, InputField } from 'components';

import { SITUACAO_OPTIONS, SITUACAO_TIPO, SITUACAO_TIPO_OPTIONS } from '../../../Utils/constantes';
import {
	BOOLEAN_OPTIONS,
	CORES_INICIAL_SITUACAO_POR_TIPO,
	HELP_MESSAGE,
	ICONE_INICIAL_SITUACAO_POR_TIPO,
	ICONE_SITUACAO_OPTIONS,
	SITUACAO_PARAMETROS_TIPO_ESTOQUE,
	SITUACAO_PARAMETROS_TIPO_FISCAL,
	STYLE_EXEMPLO_BADGE__TEXTO_SITUACAO,
	STYLE_EXEMPLO_BADGE_BACKGROUND_SITUACAO,
} from '../../Utils/constantes';

function InformacoesPrincipais({ informacoesPermissoes }) {
	const { values, setFieldValue } = useFormikContext();

	function onChangeTipo(event) {
		const tipo = event.value;

		if (values.tipo !== tipo) {
			setFieldValue('tipo', tipo);
			setFieldValue('corTexto', CORES_INICIAL_SITUACAO_POR_TIPO[tipo].texto);
			setFieldValue('corFundo', CORES_INICIAL_SITUACAO_POR_TIPO[tipo].fundo);
			setFieldValue('icone', ICONE_INICIAL_SITUACAO_POR_TIPO[tipo]);

			const parametros = { ...values.parametros };
			switch (tipo) {
				case SITUACAO_TIPO.PENDENTE:
					parametros.movimentaFinanceiro = false;
					parametros.permiteDevolucao = false;
					if (values.situacaoInicial) {
						parametros.estornaMovimentacoes = true;
					} else {
						parametros.estornaMovimentacoes = false;
					}
					break;

				case SITUACAO_TIPO.CANCELADO:
					parametros.tipoEstoqueGerado = SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM;
					parametros.movimentaFinanceiro = false;
					parametros.tipoFiscal = SITUACAO_PARAMETROS_TIPO_FISCAL.NENHUM;
					parametros.situacaoFinalDocumentoGerado = null;
					parametros.permiteDevolucao = false;
					parametros.permiteDevolucaoCondicional = false;
					parametros.permiteEntregaParcial = false;
					parametros.estornaMovimentacoes = true;
					break;

				case SITUACAO_TIPO.FINALIZADO:
					parametros.estornaMovimentacoes = false;
					parametros.permiteDevolucaoCondicional = false;
					parametros.estornaMovimentacoes = false;
					break;

				default:
					break;
			}

			setFieldValue('parametros', parametros);
		}
	}

	function handleChangeSituacaoInicial(event) {
		setFieldValue('situacaoInicial', event.value);

		if (event.value) {
			setFieldValue('parametros.estornaMovimentacoes', true);
			setFieldValue('parametros.tipoEstoqueGerado', SITUACAO_PARAMETROS_TIPO_ESTOQUE.NENHUM);
		} else {
			setFieldValue('parametros.estornaMovimentacoes', false);
		}
	}

	return (
		<Grid className="step-cadastro-situacao-informacoes-principais">
			<Field
				id="cadastro-situacao-input-nome"
				sm="12"
				md="12"
				lg="4"
				xl="5"
				component={InputField}
				label="Nome"
				obrigatorio
				keyfilter={keyFilterConsultaRsql}
				name="nome"
				size={60}
				onChange={(e) => setFieldValue('nome', e.target.value)}
				title={HELP_MESSAGE.nome}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Tipo"
				name="tipo"
				onChange={(event) => onChangeTipo(event)}
				options={SITUACAO_TIPO_OPTIONS}
				showClear={false}
				title={HELP_MESSAGE.tipo}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Situação"
				name="situacao"
				onChange={(event) => setFieldValue('situacao', event.value)}
				options={SITUACAO_OPTIONS}
				showClear={false}
				title={HELP_MESSAGE.situacao}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Situação inicial"
				name="situacaoInicial"
				onChange={handleChangeSituacaoInicial}
				options={BOOLEAN_OPTIONS}
				showClear={false}
				title={HELP_MESSAGE.situacaoInicial}
				disabled={values.tipo !== SITUACAO_TIPO.PENDENTE}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="2"
				lg="2"
				xl="1"
				component={Dropdown}
				label="Ícone"
				name="icone"
				value={values.icone}
				obrigatorio
				onChange={(event) => setFieldValue('icone', event.value)}
				options={ICONE_SITUACAO_OPTIONS}
				showClear={false}
				title={HELP_MESSAGE.icone}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="4"
				xl="4"
				component={InputField}
				label="Texto botão"
				obrigatorio
				keyfilter={keyFilterConsultaRsql}
				name="textoBotao"
				size={120}
				onChange={(e) => setFieldValue('textoBotao', e.target.value)}
				title={HELP_MESSAGE.textoBotao}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="12"
				lg="8"
				xl="8"
				component={InputField}
				label="Texto auxiliar botão"
				keyfilter={keyFilterConsultaRsql}
				name="textoBotaoAuxiliar"
				size={240}
				onChange={(e) => setFieldValue('textoBotaoAuxiliar', e.target.value)}
				title={HELP_MESSAGE.textoBotaoAuxiliar}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="2"
				xl="2"
				component={ColorPicker}
				label="Cor do texto"
				name="corTexto"
				value={values.corTexto}
				onBlur={(e) => setFieldValue('corTexto', e.target.value)}
				title={HELP_MESSAGE.corTexto}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="2"
				xl="2"
				component={ColorPicker}
				label="Cor do fundo"
				name="corFundo"
				value={values.corFundo}
				onBlur={(e) => setFieldValue('corFundo', e.target.value)}
				title={HELP_MESSAGE.corFundo}
				{...informacoesPermissoes}
			/>
			<Col sm="12" md="12" lg="8" xl="8" style={{ display: 'flex', flexDirection: 'column' }}>
				<div>Exemplo:</div>
				{Badge(
					values.corTexto,
					values.corFundo,
					values.nome || 'Nome de exemplo',
					STYLE_EXEMPLO_BADGE_BACKGROUND_SITUACAO,
					STYLE_EXEMPLO_BADGE__TEXTO_SITUACAO
				)}
			</Col>
		</Grid>
	);
}

export { InformacoesPrincipais };
