async function converterSituacaoParaFormulario(situacaoApi) {
	if (situacaoApi) {
		return {
			id: situacaoApi.id,
			nome: situacaoApi.nome,
			tipo: situacaoApi.tipo,
			situacao: situacaoApi.situacao,
			situacaoInicial: situacaoApi.situacaoInicial,
			icone: situacaoApi.icone,
			textoBotao: situacaoApi.textoBotao,
			textoBotaoAuxiliar: situacaoApi.textoBotaoAuxiliar,
			corTexto: situacaoApi.corTexto,
			corFundo: situacaoApi.corFundo,
			etapasVinculadas: await converterEtapasVinculadasParaFormulario(situacaoApi.etapasVinculadas),
			etapaFavorita: converterEtapaFavoritaParaFormulario(situacaoApi.etapasVinculadas),
			parametros: {
				tipoEstoqueGerado: situacaoApi.parametros.tipoEstoqueGerado,
				estornaMovimentacoes: situacaoApi.parametros.estornaMovimentacoes,
				movimentaFinanceiro: situacaoApi.parametros.movimentaFinanceiro,
				tipoFiscal: situacaoApi.parametros.tipoFiscal,
				situacaoFinalDocumentoGerado: situacaoApi.parametros.situacaoFinalDocumentoGerado?.id || null,
				situacaoFinalCancelamento: situacaoApi.parametros.situacaoFinalCancelamento?.id || null,
				situacaoFinalFinalizado: situacaoApi.parametros.situacaoFinalFinalizado?.id || null,
				permiteFinalizarENovo: situacaoApi.parametros.permiteFinalizarENovo,
				permiteDevolucao: situacaoApi.parametros.permiteDevolucao,
				permiteDevolucaoCondicional: situacaoApi.parametros.permiteDevolucaoCondicional,
				permiteEntregaParcial: situacaoApi.parametros.permiteEntregaParcial,
				permiteGerarAssinatura: situacaoApi.parametros.permiteGerarAssinatura,
				permiteImpressoes: situacaoApi.parametros.permiteImpressoes,
				permiteCompartilhamento: situacaoApi.parametros.permiteCompartilhamento,
				permiteGerarPedidoVenda: situacaoApi.parametros.permiteGerarPedidoVenda,
			},
		};
	}

	return null;
}

function converterEtapasVinculadasParaFormulario(etapasVinculadas) {
	if (etapasVinculadas?.length > 0) {
		return etapasVinculadas?.map((etapa) => ({
			label: etapa.situacao?.nome,
			registro: etapa.situacao,
			value: etapa.situacao?.id,
		}));
	}

	return [];
}

function converterEtapaFavoritaParaFormulario(etapasVinculadas) {
	const etapaFavorita = etapasVinculadas.find((etapa) => etapa.favorito);
	if (etapaFavorita) {
		return {
			label: etapaFavorita.situacao?.nome,
			registro: etapaFavorita.situacao,
			value: etapaFavorita.situacao?.id,
		};
	}

	return null;
}

function converterSituacaoParaApi(situacaoForm) {
	if (situacaoForm) {
		return {
			id: situacaoForm.id || null,
			nome: situacaoForm.nome,
			tipo: situacaoForm.tipo,
			situacao: situacaoForm.situacao,
			situacaoInicial: situacaoForm.situacaoInicial,
			icone: situacaoForm.icone,
			textoBotao: situacaoForm.textoBotao,
			textoBotaoAuxiliar: situacaoForm.textoBotaoAuxiliar,
			corTexto: situacaoForm.corTexto,
			corFundo: situacaoForm.corFundo,
			etapasVinculadas: converterEtapasVinculadasParaApi(situacaoForm.etapasVinculadas, situacaoForm.etapaFavorita),
			parametros: {
				tipoEstoqueGerado: situacaoForm.parametros.tipoEstoqueGerado,
				estornaMovimentacoes: situacaoForm.parametros.estornaMovimentacoes,
				movimentaFinanceiro: situacaoForm.parametros.movimentaFinanceiro,
				tipoFiscal: situacaoForm.parametros.tipoFiscal,
				situacaoFinalDocumentoGerado: situacaoForm.parametros.situacaoFinalDocumentoGerado
					? { id: situacaoForm.parametros.situacaoFinalDocumentoGerado }
					: null,
				situacaoFinalCancelamento: situacaoForm.parametros.situacaoFinalCancelamento
					? { id: situacaoForm.parametros.situacaoFinalCancelamento }
					: null,
				situacaoFinalFinalizado: situacaoForm.parametros.situacaoFinalFinalizado
					? { id: situacaoForm.parametros.situacaoFinalFinalizado }
					: null,
				permiteFinalizarENovo: situacaoForm.parametros.permiteFinalizarENovo,
				permiteDevolucao: situacaoForm.parametros.permiteDevolucao,
				permiteDevolucaoCondicional: situacaoForm.parametros.permiteDevolucaoCondicional,
				permiteEntregaParcial: situacaoForm.parametros.permiteEntregaParcial,
				permiteGerarAssinatura: situacaoForm.parametros.permiteGerarAssinatura,
				permiteImpressoes: situacaoForm.parametros.permiteImpressoes,
				permiteCompartilhamento: situacaoForm.parametros.permiteCompartilhamento,
				permiteGerarPedidoVenda: situacaoForm.parametros.permiteGerarPedidoVenda,
			},
		};
	}

	return null;
}

function converterEtapasVinculadasParaApi(etapasVinculadas, etapaFavorita) {
	if (etapasVinculadas?.length > 0) {
		return etapasVinculadas?.map((etapa) => ({
			id: null,
			situacao: { id: etapa.value },
			favorito: etapa.value === etapaFavorita?.value,
		}));
	}

	return [];
}

function converterSituacoesParaSelect(situacoes) {
	if (situacoes) {
		return situacoes.map((situacao) => ({ label: situacao.nome, registro: situacao, value: situacao.id }));
	}

	return [];
}

export { converterSituacaoParaFormulario, converterSituacaoParaApi, converterSituacoesParaSelect };
