import { connect } from 'react-redux';
import GraficoSemPermissaoDashboard from './Images/GraficoSemPermissaoDashboard.svg';

function TrocaDeFilial() {
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: '20%',
				}}
			>
				<div>
					<img src={GraficoSemPermissaoDashboard} alt="Nenhuma informação a ser exibida" width="130" height="82" />
				</div>
				<div style={{ textAlign: 'center' }}>
					<span style={{ fontWeight: '600' }}>{'Carregando informações da filial...'}</span>
				</div>
			</div>
		</>
	);	
}

export default TrocaDeFilial;
