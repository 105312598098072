import { useEffect, useRef } from 'react';
import { ButtonExcluirTable, Divider, If, SingleSelectCondicaoPagamento } from 'components';
import Grid from 'components/Grid';
import { useGenerateParcelas } from 'components/Pagamentos/Hooks/useGenerateParcelas';
import { FINANCEIRO_TIPO } from 'components/Pagamentos/Util/constantes';
import { convertObjectToSelectValue, divideValorPorQuantidade } from 'components/Pagamentos/Util/functions';
import { Field, useFormikContext } from 'formik';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import './Styles/index.css';
import { copiarObjeto } from 'Common';
import ButtonDetalhesTable from 'components/Button/ButtonDetalhesTable';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { FieldsAPrazo } from './components/FieldsAPrazo';
import { FieldsAVista } from './components/FieldsAVista';

function Pagamento({
	value,
	indexPagamento,
	onChangePagamento,
	onChangePagamentoField,
	setRecalcularValoresPagamentos,
	setRecalcularTodosOsProdutosComTabelaPreco,
	setRecalcularTodosOsServicosComTabelaPreco,
	setModalDetalhesPixVisible,
	setIndexPagamentoPix,
}) {
	const { 
		valorTotalPagamentos,
		quantidadeDePagamentos,
		urls,
		funcaoSetFieldValueVerificada,
		handleParcelasContext,
		quantidadeParcelasContext,
		handleQuantidadeParcelasContext,
		handleIsFormModal,
		isNfce,
		favoritosContext,
		handleFavoritosContext,
		dataBaseParcela,
		informacoesPermissoes,
		disabledFields,
		financeiroTipo,
		stylePagamento,
		isMobile,
		indicacaoMovimento,
		onExcluirPagamento,
	} = useContextPagamentos();

	const { values, errors } = useFormikContext();

	const prevValor = useRef(null);

	const isAVista = value.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA;
	const isAPrazo = value.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
	const hasMoreOnePagamento = quantidadeDePagamentos > 1;
	const isFirstAndUniqueParcelaAPrazo = quantidadeDePagamentos === 1 && indexPagamento === 0 && isAPrazo;

	useEffect(() => {
		if (value.condicaoPagamento?.registro?.formaPagamento) {
			const newFavoritos = copiarObjeto(favoritosContext);
			const formaPagamento = value.condicaoPagamento?.registro?.formaPagamento;

			newFavoritos.formaPagamento = convertObjectToSelectValue(formaPagamento);

			if (formaPagamento.conta) {
				newFavoritos.conta = convertObjectToSelectValue(formaPagamento.conta);
			}
			
			handleFavoritosContext(newFavoritos);
		}
		handleIsFormModal(false);
	}, []);

	useEffect(() => {
		if (quantidadeDePagamentos === 1 || value.condicaoPagamento?.registro?.tipo === 'A_PRAZO') {
			handleParcelasContext(value.parcelas);
		}
	}, [value.parcelas]);

	useEffect(() => {
		handleQuantidadeParcelasContext(value.quantidadeParcelas);
	}, [value.quantidadeParcelas]);

	const [generateParcelas] = useGenerateParcelas({
		qtdParcelas: quantidadeParcelasContext?.value,
		parcelas: value.parcelas,
		valorPagamento: value.valor,
		dataBaseParcela,
		favoritos: favoritosContext,
	});

	function handleChangeCondicaoPagamento(e) {
		const pagamento = {
			...value,
			condicaoPagamento: e,
		};

		const { tipo, formaPagamento } = e.registro;
		const isPrevSemPagamento = value.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO;
		const categoriaConvertida =
			(formaPagamento?.categoriaReceita || formaPagamento?.categoriaDespesa) &&
			financeiroTipo === FINANCEIRO_TIPO.CONTA_RECEBER
				? convertObjectToSelectValue(formaPagamento?.categoriaReceita)
				: convertObjectToSelectValue(formaPagamento?.categoriaDespesa);

		if (e && tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
			pagamento.quantidadeParcelas = 0;
			pagamento.formaPagamento = formaPagamento
				? convertObjectToSelectValue(formaPagamento)
				: value.formaPagamento ?? favoritosContext.formaPagamento;
			if (pagamento.categorias?.length === 1) {
				pagamento.categorias = [
					{
						categoria: categoriaConvertida || (value.categoria ?? favoritosContext.categoria),
						percentual: 100,
						valor: value.valor,
					},
				];
			}
			pagamento.parcelas = [];
			pagamento.conta = formaPagamento?.conta
				? convertObjectToSelectValue(formaPagamento.conta)
				: value.conta ?? favoritosContext.conta;
		}

		if (e && tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
			const qtdParcelas = e.registro.parcelaPadrao ?? e.registro.parcelaMinima ?? 1;

			pagamento.formaPagamento = null;
			pagamento.quantidadeParcelas = {
				label: `${qtdParcelas}x`,
				value: qtdParcelas,
			};
			if (pagamento.categorias?.length === 1) {
				pagamento.categorias = [
					{
						categoria: categoriaConvertida || (value.categoria ?? favoritosContext.categoria),
						percentual: 100,
						valor: value.valor,
					},
				];
			}
			pagamento.parcelas = generateParcelas({ qtdParcelas }).map((parcela) => ({
				...parcela,
				formaPagamento: formaPagamento
					? convertObjectToSelectValue(formaPagamento)
					: parcela.formaPagamento ?? favoritosContext.categoria,
				conta: formaPagamento?.conta ? convertObjectToSelectValue(formaPagamento.conta) : parcela.conta,
			}));
		}

		if (e && tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO) {
			pagamento.formaPagamento = null;
			pagamento.conta = null;
			pagamento.categorias = [];
			pagamento.quantidadeParcelas = null;
			pagamento.valor = 0;
			pagamento.parcelas = [];
		}

		if (e && isPrevSemPagamento && tipo !== CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO) {
			if (tipo === CONDICAO_PAGAMENTO_TIPO.A_VISTA) {
				pagamento.valor = valorTotalPagamentos;
				pagamento.categorias = [
					{ categoria: favoritosContext.categoria, percentual: 100, valor: valorTotalPagamentos },
				];
				pagamento.conta = favoritosContext.conta;
			}
			if (tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO) {
				const qtdParcelas = e.registro.parcelaPadrao ?? e.registro.parcelaMinima ?? 1;
				const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(
					valorTotalPagamentos,
					qtdParcelas
				);
				pagamento.categorias = [
					{ categoria: favoritosContext.categoria, percentual: 100, valor: valorTotalPagamentos },
				];
				pagamento.valor = valorTotalPagamentos;
				pagamento.parcelas?.forEach((_parcela, index) => {
					if (index === pagamento.parcelas.length - 1) {
						pagamento.parcelas[index].valor = valorUltimaQuantidade;
					} else {
						pagamento.parcelas[index].valor = valorPorQuantidade;
					}
				});
			}
		}

		if (formaPagamento) {
			const newFavoritos = copiarObjeto(favoritosContext);

			newFavoritos.formaPagamento = convertObjectToSelectValue(formaPagamento);

			if (formaPagamento.conta) {
				newFavoritos.conta = convertObjectToSelectValue(formaPagamento.conta);
			}

			handleFavoritosContext(newFavoritos);
		}

		funcaoSetFieldValueVerificada(`[${indexPagamento}]`, pagamento);
		onChangePagamento(indexPagamento, pagamento);
		if (typeof setRecalcularTodosOsProdutosComTabelaPreco === 'function') {
			setRecalcularTodosOsProdutosComTabelaPreco(true);
		}
		if (typeof setRecalcularTodosOsServicosComTabelaPreco === 'function') {
			setRecalcularTodosOsServicosComTabelaPreco(true);
		}
	}

	function handleChangeFormaPagamento(e) {
		const pagamento = {
			...value,
			formaPagamento: e,
			parcelas: [],
		};

		if (e && e.registro?.conta) {
			pagamento.conta = {
				label: e.registro.conta.nome,
				value: e.registro.conta.id,
				registro: e.registro.conta,
			};

			if (value.parcelas.length > 0) {
				value.parcelas.forEach((e) => {
					pagamento.parcelas.push({
						...e,
						conta: pagamento.conta ? pagamento.conta : e.conta,
					});
				});
			}
		}

		if (value.categorias?.length === 1) {
			if (e && e.registro?.categoriaReceita && financeiroTipo === FINANCEIRO_TIPO.CONTA_RECEBER) {
				pagamento.categorias = [
					{
						categoria: {
							label: e.registro.categoriaReceita.nome,
							value: e.registro.categoriaReceita.id,
							registro: e.registro.categoriaReceita,
						},
						percentual: 100,
						valor: value.valor,
					},
				];
			}
			if (e && e.registro?.categoriaDespesa && financeiroTipo === FINANCEIRO_TIPO.CONTA_PAGAR) {
				pagamento.categorias = [
					{
						categoria: {
							label: e.registro.categoriaDespesa.nome,
							value: e.registro.categoriaDespesa.id,
							registro: e.registro.categoriaDespesa,
						},
						percentual: 100,
						valor: value.valor,
					},
				];
			}
		}

		funcaoSetFieldValueVerificada(`[${indexPagamento}]`, pagamento);
		onChangePagamento(indexPagamento, pagamento);
	}

	function handleChangeConta(e) {
		funcaoSetFieldValueVerificada(`[${indexPagamento}].conta`, e);
		onChangePagamentoField(indexPagamento, 'conta', e);
	}

	function handleChangeCategoria(e) {
		funcaoSetFieldValueVerificada(`[${indexPagamento}].categorias`, e);
		onChangePagamentoField(indexPagamento, 'categorias', e);
	}

	function handleChangeValor(e) {
		funcaoSetFieldValueVerificada(`[${indexPagamento}].valor`, e.target.value);
		funcaoSetFieldValueVerificada(`[${indexPagamento}].valorTroco`, values[indexPagamento].valorRecebido - e.target.value);
		onChangePagamentoField(indexPagamento, 'valor', e.target.value);
		onChangePagamentoField(indexPagamento, 'valorTroco', values[indexPagamento].valorRecebido - e.target.value);

		prevValor.current = value.valor;
	}

	function handleChangeValorRecebido(e) {
		if(!e.target.value) {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].valorRecebido`, 0);
			onChangePagamentoField(indexPagamento, 'valorRecebido', 0);
		} else {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].valorRecebido`, e.target.value);
			onChangePagamentoField(indexPagamento, 'valorRecebido', e.target.value);
		}
		if (e.target.value >= 0) {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].valorTroco`, e.target.value - values[indexPagamento].valor);
			onChangePagamentoField(indexPagamento, 'valorTroco', e.target.value - values[indexPagamento].valor);
		} 
	}

	function onBlurValor() {
		if (prevValor.current !== value.valor) {
			funcaoSetFieldValueVerificada(`[${indexPagamento}].alterouValor`, true);
			onChangePagamentoField(indexPagamento, 'alterouValor', true);
			setRecalcularValoresPagamentos(true);
		}
	}

	function handleClickExcluir() {
		onExcluirPagamento(indexPagamento);
	}

	function hasCondicaoAPrazo() {
		let result = false;

		if (quantidadeDePagamentos > 0) {
			values.forEach((e, i) => {
				if (e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO && i !== indexPagamento) {
					result = true;
				}
			});
		}

		return result;
	}

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				borderBottom: quantidadeDePagamentos > 1 ? '1px solid #ddd' : 'none',
				backgroundColor: indexPagamento % 2 === 0 ? null : '#f1f1f1',
			}}
			className={hasMoreOnePagamento ? 'pagamento-list-hover' : ''}
		>
			<div
				style={{
					padding: hasMoreOnePagamento ? '0px 6px 0px 4px' : '0px 8px',
					display: 'flex',
					alignItems: 'center',
					fontSize: '16px',
					fontWeight: 'bold',
					...stylePagamento,
				}}
			>
				{hasMoreOnePagamento ? `${value.sequencial}ª ` : ''}
			</div>
			<Grid style={{ display: 'flex', width: '100%' }}>
				<Field
					sm="12"
					md={isAPrazo ? '6' : '6'}
					lg={isAPrazo ? '3' : '3'}
					xl={isAPrazo ? '3' : '3'}
					name="condicaoPagamento"
					label="Condição de pagamento"
					helpMessage="Condição de pagamento"
					component={SingleSelectCondicaoPagamento}
					value={value.condicaoPagamento}
					onChange={handleChangeCondicaoPagamento}
					url={urls.condicaoPagamento}
					disabled={disabledFields}
					obrigatorio
					isClearable={false}
					indicacaoMovimento={indicacaoMovimento}
					hideSemPagamento={hasMoreOnePagamento}
					hideAPrazo={hasCondicaoAPrazo()}
					touched
					errors={errors[indexPagamento]?.condicaoPagamento}
					useFormErrors={false}
					{...informacoesPermissoes}
				/>
				<If test={isAVista}>
					<FieldsAVista
						value={value}
						handleChangeFormaPagamento={handleChangeFormaPagamento}
						handleChangeConta={handleChangeConta}
						handleChangeCategoria={handleChangeCategoria}
						handleChangeValor={handleChangeValor}
						indexPagamento={indexPagamento}
						onBlurValor={onBlurValor}
						handleChangeValorRecebido={handleChangeValorRecebido}
					/>
				</If>
				<If test={isAPrazo}>
					<FieldsAPrazo
						value={value}
						handleChangeFormaPagamento={handleChangeFormaPagamento}
						handleChangeConta={handleChangeConta}
						handleChangeCategoria={handleChangeCategoria}
						handleChangeValor={handleChangeValor}
						indexPagamento={indexPagamento}
						isFirstAndUniqueParcelaAPrazo={isFirstAndUniqueParcelaAPrazo}
						onChangePagamentoField={onChangePagamentoField}
						onBlurValor={onBlurValor}
						setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
						setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
					/>
				</If>
				{isMobile && <Divider styleContainer={{ marginTop: '0px' }} />}
			</Grid>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: hasMoreOnePagamento ? '0px' : '0px 8px',
				}}
			>
				<If test={isNfce}>
					<ButtonDetalhesTable
						icon="pi pi-qrcode"
						color="success"
						onClick={() => {
							setModalDetalhesPixVisible(true);
							setIndexPagamentoPix(indexPagamento);
						}}
						title={
							values[indexPagamento].lancamento && values[indexPagamento].lancamento.pixId
								? 'Visualizar detalhes recebimento pix'
								: 'Sem detalhes para exibir'
						}
						disabled={!(values[indexPagamento].lancamento && values[indexPagamento].lancamento.pixId)}
						{...informacoesPermissoes}
					/>
				</If>
				<If test={hasMoreOnePagamento}>
					<ButtonExcluirTable
						onClick={handleClickExcluir}
						title="Excluir item"
						disabled={(indexPagamento === 0 && quantidadeDePagamentos === 1) || disabledFields}
						{...informacoesPermissoes}
					/>
				</If>
			</div>
		</div>
	);
}

export { Pagamento };
