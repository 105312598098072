import { del, get, post, put, services } from 'Common';

export async function getSituacoes(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function getSituacoesVinculadas(url, onSuccess, onError) {
	return await get(`${url}?query=(situacao=="ATIVO")&page=0&size=500&sort=nome,asc`, null, onSuccess, onError, true);
}

export async function deleteSituacao(id, onSuccess, onError) {
	return await del(`${services.GESTOR}/v1/vendas/situacao/${id}`, null, onSuccess, onError);
}

export async function salvarSituacao(situacao, onSuccess, onError) {
	return await post(`${services.GESTOR}/v1/vendas/situacao`, situacao, null, onSuccess, onError);
}

export async function atualizarSituacao(id, situacao, onSuccess, onError) {
	return await put(`${services.GESTOR}/v1/vendas/situacao/${id}`, situacao, null, onSuccess, onError);
}

export async function getSituacao(id, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/vendas/situacao/${id}`, null, onSuccess, onError);
}
