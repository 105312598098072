import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { divideValorPorQuantidade } from 'components/Pagamentos/Util/functions';
import ModalMultiCategoria from 'views/cadastros/financas/Categorias/components/ModalMultiCategoria';
import ModalCategoria from '../../../views/cadastros/financas/Categorias/components/ModalCategoria';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import If from '../../If';

const colourStyles = {
	option: (styles, { data }) => ({
		...styles,
		color: data.registro.aparencia,
		fontWeight: 'bold',
		backgroundColor: 'transparent',
		whiteSpace: 'nowrap',
	}),
	singleValue: (styles, { data }) => ({
		...styles,
		color: data.registro?.aparencia,
		fontWeight: 'bold',
	}),
};

export const tiposCategoria = {
	DESPESA: 'DESPESA',
	RECEITA: 'RECEITA',
};

export function validateTotalCategorias(listaCategorias, valueTotalList) {
	let totalSoma = 0;
	if (listaCategorias) {
		listaCategorias.forEach((registro) => {
			totalSoma += parseFloat(registro.valor.toFixed(2));
		});
		if (parseFloat(totalSoma.toFixed(2)) === parseFloat(valueTotalList.toFixed(2))) {
			return true;
		}
	}
	return false;
}

export function ratearValoresCategorias(values, valorTotalCategorias, indexInicial = -1, manterPercentuais = false) {
	let updatedValues = values ?? [];
	if (indexInicial < updatedValues.length - 1) {
		let valorTotal = valorTotalCategorias;
		let countRegistros = updatedValues.length;
		let percentualRestante = 100;
		if (indexInicial > -1) {
			let valorJaLancado = 0;
			updatedValues.forEach((item, index) => {
				if (index <= indexInicial) {
					valorJaLancado += item.valor;
					countRegistros -= 1;
					percentualRestante -= item.percentual;
				}
			});
			valorTotal -= valorJaLancado;
		}
		if (manterPercentuais) {
			let valorUltimaCategoria = valorTotal;
			updatedValues = updatedValues.map((item, index, array) => {
				const isUltimaCategoria = index === array.length - 1;
				if (isUltimaCategoria) {
					return { ...item, valor: parseFloat(valorUltimaCategoria.toFixed(2)) };
				}

				const novoValorCategoria = parseFloat(((valorTotal * item.percentual) / 100).toFixed(2));
				valorUltimaCategoria -= novoValorCategoria;

				return { ...item, valor: novoValorCategoria };
			});
		} else if (updatedValues?.length && valorTotal > 0) {
			const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(valorTotal, countRegistros);
			updatedValues = updatedValues.map((item, index) => {
				if (index > indexInicial) {
					return {
						...item,
						valor: valorPorQuantidade,
						percentual: parseFloat(((valorPorQuantidade * percentualRestante) / valorTotal).toFixed(4)),
					};
				} else {
					return item;
				}
			});
			updatedValues[updatedValues.length - 1].valor = valorUltimaQuantidade;
			updatedValues[updatedValues.length - 1].percentual = parseFloat(
				((valorUltimaQuantidade * 100) / valorTotal).toFixed(4)
			);

			let somaPercentuais = 0;
			updatedValues.forEach((item) => {
				somaPercentuais += item.percentual;
			});
			const diferencaPercentuais = 100 - somaPercentuais;
			if (diferencaPercentuais !== 0) {
				updatedValues[updatedValues.length - 1].percentual =
					updatedValues[updatedValues.length - 1].percentual + diferencaPercentuais;
			}
		} else {
			updatedValues = updatedValues.map((item, index) => {
				if (index > indexInicial) {
					return {
						...item,
						valor: 0,
						percentual: 0,
					};
				} else {
					return item;
				}
			});
		}
	}
	return updatedValues;
}

export default function SingleSelectCategoria(props) {
	const {
		url,
		tipoCategoria,
		disabledButton,
		esconderBotao,
		withList,
		valueTotalList = 0,
		value,
		onChange,
		disabled,
	} = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.INSERIR));
	const [podeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR)
	);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	const changeToOnlyList = Array.isArray(value) && value.length > 1;

	useEffect(() => {
		if (withList && value?.length === 1) {
			onChange([{ categoria: value[0].categoria, percentual: 100, valor: valueTotalList }]);
		}
	}, [valueTotalList]);

	function buscarUrlPesquisa(pesquisa, page) {
		switch (tipoCategoria) {
			case tiposCategoria.DESPESA: {
				return `${url}?query=nome=contains="*${pesquisa}*";ativa=="true";despesa=="true"&page=${page}&size=50&sort=nome,asc`;
			}
			case tiposCategoria.RECEITA: {
				return `${url}?query=nome=contains="*${pesquisa}*";ativa=="true";receita=="true"&page=${page}&size=50&sort=nome,asc`;
			}
			default: {
				return `${url}?query=nome=contains="*${pesquisa}*";ativa=="true"&page=${page}&size=50&sort=nome,asc`;
			}
		}
	}

	function buscarUrlRegistroUnico(id) {
		return `${url}?query=id==${id}`;
	}

	function montarLabel(row) {
		return row.nome;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function onSaveMultiCategorias(listaCategorias) {
		onChange(listaCategorias);
		esconderModal();
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({ label: novoRegistro.nome, value: novoRegistro.id, registro: novoRegistro });
			setResetPesquisa(true);
		}
	}

	function esconderModal() {
		setVisible(false);
	}

	function onChangeCategoria(event) {
		if (withList) {
			const newValue = event ? [{ categoria: event, percentual: 100, valor: valueTotalList }] : [];

			onChange(newValue);
			return;
		}

		onChange(event);
	}

	return (
		<>
			<If test={visible}>
				{withList ? (
					<ModalMultiCategoria
						tipoCategoria={tipoCategoria}
						visible={visible}
						onHide={onHide}
						url={url}
						styleModal={{ maxHeight: '31rem' }}
						onSave={onSaveMultiCategorias}
						values={value}
						valueTotalList={valueTotalList}
						disabledFields={disabled}
					/>
				) : (
					<ModalCategoria tipoCategoria={tipoCategoria} visible={visible} insertJustOne onHide={onHide} />
				)}
			</If>
			<SingleSelect
				{...props}
				style={colourStyles}
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				esconderBotao={esconderBotao}
				disabledButton={!withList && (!podeInserirEVisualizar || disabledButton)}
				botaoIcon={withList ? 'fa fa-list' : 'fa fa-plus'}
				titleBotaoNovo={
					withList
						? 'Rateio por categorias'
						: podeInserirEVisualizar && !disabledButton
							? 'Adicionar nova categoria'
							: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				value={changeToOnlyList ? null : Array.isArray(value) && value.length ? value[0]?.categoria : value}
				disabled={disabled || changeToOnlyList}
				placeholder={
					changeToOnlyList
						? `${value?.length} ${props?.placeholder === 'Pesquisar' ? 'categorias' : 'categorias selecionadas'}`
						: props.placeholder
				}
				onChange={onChangeCategoria}
				disabledOnlySelect
			/>
		</>
	);
}

SingleSelectCategoria.defaultProps = {
	label: 'Categoria',
	disabledButton: false,
	url: '',
};

SingleSelectCategoria.propTypes = {
	// Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`
	url: propTypes.string,
	label: propTypes.string,
	disabledButton: propTypes.bool,
};
