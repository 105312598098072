import { colors } from 'Common';
import { colorsStatus } from '../../Util/constantes';

export function validarValorNegativo(valor) {
	return valor < 0 ? 0.0 : valor;
}

export function converterSetorParaFormulario(setor) {
	if (setor) {
		return {
			label: setor.nome,
			registro: setor,
			value: setor.id,
		};
	}
	return null;
}

export function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}
