import { useRef } from 'react';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';

import { NormalButton, ButtonCancelar, ButtonSalvar, estadosBotaoCancelar } from 'components';

import { validarFormulario } from 'views/Util';
import { montarECalcularTributacaoProduto } from 'components/Produtos/Util/util';
import { StatusNota, StatusPedidoEOrcamento, moduloUtilizado } from '../../../../Util/constantes';

function ActionButtons({
	indexProduto,
	setFieldValueProduto,
	onChangeProduto,
	onHide,
	informacoesPermissoes,
	disabled,
	modulo,
	status,
	pessoa,
}) {
	const { dirty, values, setValues, handleReset, handleSubmit, validateForm } = useFormikContext();
	const menuOpcoes = useRef(null);
	const itensOpcoes = buscaritensOpcoes();

	function cancelar() {
		dirty ? handleReset() : onHide();
	}

	async function salvar() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			setFieldValueProduto(`produtos[ ${indexProduto}]`, values);
			onChangeProduto(indexProduto, values);
			onHide();
		}
	}

	async function calcularImpostos() {
		if (pessoa && values.operacaoFiscal) {
			setValues(await montarECalcularTributacaoProduto(values, pessoa));
		}
	}

	function buscaritensOpcoes() {
		const itens = [];
		if (
			modulo === moduloUtilizado.NOTA_FISCAL &&
			!(status !== StatusNota.REJEITADA && status !== StatusNota.NAO_ENVIADA)
		) {
			itens.push({
				label: 'Recalcular produto',
				icon: 'fa fa-calculator',
				command: () => {
					calcularImpostos();
				},
			});
		} else if (
			modulo === moduloUtilizado.PEDIDO_VENDA &&
			!(status === StatusPedidoEOrcamento.CANCELADO || status === StatusPedidoEOrcamento.FINALIZADO)
		) {
			itens.push({
				label: 'Recalcular produto',
				icon: 'fa fa-calculator',
				command: () => {
					calcularImpostos();
				},
			});
		}

		return itens;
	}

	return (
		<>
			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menuOpcoes} />
			<ButtonCancelar
				onClick={cancelar}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar onClick={salvar} disabled={!dirty || disabled} {...informacoesPermissoes} />
			<NormalButton
				className="p-button-primary"
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={itensOpcoes.length === 0}
				onClick={(event) => menuOpcoes.current?.toggle(event)}
			/>
		</>
	);
}

export { ActionButtons };
