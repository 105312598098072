import { buscarDadosLoginLocalStorage } from "Common";
import { If } from "components";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useContextNFSe } from "../../../Context"
import { ErrorsParametrosFiscais } from "./Components/ErrorsParametrosFiscais";
import { ErrorsTransmissao } from "./Components/ErrorsTransmissao";

function ErrorsMessage({ history }){
   const {
      setShowMessageParametrosFiscais,
      showMessageParametrosFiscais,
      errosTransmissao,
      showMessageTransmissao,
      setShowMessageTransmissao,
   } = useContextNFSe();

   const { values } = useFormikContext();

   const [mensagemParametroFiscal, setMensagemParametroFiscal] = useState(null);

   const dadosLocais = buscarDadosLoginLocalStorage();

   useEffect(() => {
      handleParametrosFiscais();
      handleErrosTransmissao();
   }, [errosTransmissao, values.errors]);

   function handleParametrosFiscais() {
      if (dadosLocais?.filialConectada?.parametrosFiscalNFSe) {
         if (!dadosLocais?.filialConectada?.parametrosFiscalCertificado) {
            setMensagemParametroFiscal('Filial sem certificado digital configurado para transmissão de NFS-e.');
            setShowMessageParametrosFiscais(true);
         } else if (
            !dadosLocais.filialConectada.parametrosFiscalNFSe?.numeroRps ||
            !dadosLocais.filialConectada.parametrosFiscalNFSe?.numeroInicial ||
            !dadosLocais.filialConectada.parametrosFiscalNFSe?.loteRps
         ) {
            setMensagemParametroFiscal('Número de RPS, lote ou número inicial inválido para transmissão de NFS-e.');
            setShowMessageParametrosFiscais(true);
         }
      }
   }

   function handleErrosTransmissao() {
      if (Object.keys(errosTransmissao).length > 0) {
         setShowMessageTransmissao(true);
         Object.keys(errosTransmissao).forEach(() => {});
      }

      if (values.erros.length > 0) {
         setShowMessageTransmissao(true);
      }

      if (!Object.keys(errosTransmissao).length > 0 && !values.erros.length > 0) {
         setShowMessageTransmissao(false);
      }
   }

   return (
      <>
         <If test={showMessageTransmissao}>
            <ErrorsTransmissao />
         </If>
         <If test={showMessageParametrosFiscais}>
            <ErrorsParametrosFiscais mensagemParametroFiscal={mensagemParametroFiscal} history={history} />
         </If>
      </>
   )
}

export { ErrorsMessage };