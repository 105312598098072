import { formatarMonetario, mensagensDeValidacao } from 'Common';
import { Divider, SingleSelectFormaPagamento, TextArea } from 'components';
import Col from 'components/Col';
import Form from 'components/Form';
import FormActions from 'components/FormActions';
import FormContent from 'components/FormContent';
import Grid from 'components/Grid';
import AutoProgressBar from 'components/Loading/AutoProgressBar';
import Modal from 'components/Modal';
import { INITIAL_VALUES_FINANCEIRO } from 'components/Pagamentos/Util/constantes';
import InputDate from 'components/input/InputDate';
import InputMoney from 'components/input/InputMoney';
import InputSelectPercentualOrValor, { tipos } from 'components/input/InputSelectPercentualOrValor';
import SingleSelectConta from 'components/select/SingleSelectConta';
import { isValid, parseISO } from 'date-fns';
import { Field, useFormikContext, withFormik } from 'formik';
import { useState } from 'react';
import { connect } from 'react-redux';
import { aplicarPercentual } from 'views/Util';
import * as Yup from 'yup';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { ActionButtons } from './components/ActionButtons';

const PARTE_STYLE = {
	fontSize: '13px',
	color: '#000000',
	margin: '0px 0px',
};

const TOTAL_STYLE = {
	fontSize: '20px',
	fontWeight: 'bold',
	color: '#000000',
	margin: '10px 0px',
};

function ModalRecebimentoImpl({ visible, onHide, onHideRecebimentoPix, isMobile }) {
	const { urls, informacoesPermissoes } = useContextPagamentos();

	const { values, errors, setFieldValue } = useFormikContext();

	const [tipoDesconto, setTipoDesconto] = useState(tipos.VALOR);
	const [percentualDesconto, setPercentualDesconto] = useState(0);
	const [tipoJuros, setTipoJuros] = useState(tipos.VALOR);
	const [percentualJuros, setPercentualJuros] = useState(0);
	const [tipoMulta, setTipoMulta] = useState(tipos.VALOR);
	const [percentualMulta, setPercentualMulta] = useState(0);

	const total = values.valor + values.juros + values.multa - values.desconto;

	function handleChangeValor(e) {
		const valor = e.target.value;
		setFieldValue('valor', valor);

		if (tipoDesconto === tipos.PERCENTUAL) {
			setFieldValue('desconto', aplicarPercentual(valor, percentualDesconto));
		}

		if (tipoJuros === tipos.PERCENTUAL) {
			setFieldValue('juros', aplicarPercentual(valor, percentualJuros));
		}

		if (tipoMulta === tipos.PERCENTUAL) {
			setFieldValue('multa', aplicarPercentual(valor, percentualMulta));
		}
	}

	function handleChangeData(e) {
		setFieldValue('data', e.target.value);
	}

	function handleChangeFormaPagamento(e) {
		setFieldValue('formaPagamento', e);
	}

	function handleChangeConta(e) {
		setFieldValue('conta', e);
	}

	function handleChangeDesconto(tipo, value, percentual) {
		setTipoDesconto(tipo);
		setPercentualDesconto(percentual);
		setFieldValue('desconto', value);
	}

	function handleChangeJuros(tipo, value, percentual) {
		setTipoJuros(tipo);
		setPercentualJuros(percentual);
		setFieldValue('juros', value);
	}

	function handleChangeMulta(tipo, value, percentual) {
		setTipoMulta(tipo);
		setPercentualMulta(percentual);
		setFieldValue('multa', value);
	}

	function handleChangeObservacao(e) {
		setFieldValue('observacao', e.target.value);
	}

	return (
		<Modal
			header="Confirmar recebimento"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: isMobile ? '90%' : '55%' }}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ActionButtons onHide={onHide} onHideRecebimentoPix={onHideRecebimentoPix} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="3"
							id="valorRecebimento"
							component={InputMoney}
							label="Valor"
							name="valor"
							obrigatorio
							value={values.valor}
							onChange={handleChangeValor}
							size={15}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="3"
							component={InputDate}
							label="Data do recebimento"
							name="data"
							obrigatorio
							onChange={handleChangeData}
							value={values.data}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="3"
							name="formaPagamento"
							label="Forma pagamento"
							obrigatorio
							component={SingleSelectFormaPagamento}
							value={values.formaPagamento}
							onChange={handleChangeFormaPagamento}
							url={urls.formaPagamento}
							isClearable={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="3"
							name="conta"
							label="Conta"
							obrigatorio
							component={SingleSelectConta}
							value={values.conta}
							onChange={handleChangeConta}
							url={urls.conta}
							isClearable={false}
							{...informacoesPermissoes}
						/>
						<Divider label="Informações complementares" />
						<Grid col="12">
							<Col sm="12" md="6" lg="4" xl="4">
								<Field
									component={InputSelectPercentualOrValor}
									label="Descontos"
									name="desconto"
									value={values.desconto}
									valueBase={values.valor}
									onChange={handleChangeDesconto}
									errors={errors.desconto}
									size={15}
									{...informacoesPermissoes}
								/>
							</Col>
							<Col sm="12" md="6" lg="4" xl="4">
								<Field
									component={InputSelectPercentualOrValor}
									label="Juros"
									name="juros"
									value={values.juros}
									valueBase={values.valor}
									onChange={handleChangeJuros}
									size={15}
									{...informacoesPermissoes}
								/>
							</Col>
							<Col sm="12" md="6" lg="4" xl="4">
								<Field
									component={InputSelectPercentualOrValor}
									label="Multa"
									name="multa"
									value={values.multa}
									valueBase={values.valor}
									onChange={handleChangeMulta}
									size={15}
									{...informacoesPermissoes}
								/>
							</Col>
							<Col>
								<Field
									component={TextArea}
									placeholder="Escreva sua observação aqui"
									label="Observação"
									name="observacao"
									maxLength={4096}
									onChange={handleChangeObservacao}
									{...informacoesPermissoes}
								/>
							</Col>
						</Grid>
						<Col>
							<p style={PARTE_STYLE}>{`Descontos: ${formatarMonetario(values.desconto)}`}</p>
							<p style={PARTE_STYLE}>{`Juros: ${formatarMonetario(values.juros)}`}</p>
							<p style={PARTE_STYLE}>{`Multa: ${formatarMonetario(values.multa)}`}</p>
							<p style={TOTAL_STYLE}>{`Total: ${formatarMonetario(total)}`}</p>
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalRecebimentoWithFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues({ parcela, idOrigem, favoritos }) {
		if (parcela) {
			return {
				...INITIAL_VALUES_FINANCEIRO,
				idOrigem,
				valor: Number(parcela.valorRestante),
				id: parcela.id,
				financeiroId: parcela.financeiroId,
				formaPagamento: parcela.formaPagamento ?? favoritos.formaPagamento ?? null,
				conta: parcela.conta ?? favoritos.conta ?? null,
			};
		}
		return INITIAL_VALUES_FINANCEIRO;
	},

	validate(values) {
		const errors = {};

		if (values.valor <= 0) {
			errors.valor = 'O valor deve ser maior que zero';
		}

		if (values.valor + values.juros + values.multa - values.desconto <= 0) {
			errors.desconto = 'O desconto não pode ser maior ou igual o valor total';
		}

		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		valor: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		conta: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		formaPagamento: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalRecebimentoImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const ModalRecebimento = connect(mapStateToProps)(ModalRecebimentoWithFormik);
