import { useEffect, useState } from 'react';

import { Form, FormActions, FormContent, Modal } from 'components';

import { ActionButtons } from './components/ActionButtons';
import { ListaProdutosEntregues } from './components/ListaProdutosEntregues';
import { getVendaItensEntregues } from '../../Requests';
import { converterItensEntreguesParaForm } from './Util/converter';

function ModalItensEntregues({ visible, onHide, isMobile, vendaId, asyncSelectRegistro }) {
	const [produtosEntregues, setProdutosEntregues] = useState([]);
	const [estornouEntregas, setEstornouEntregas] = useState(false);

	useEffect(() => {
		buscarItensEntregues();
	}, []);

	function buscarItensEntregues() {
		getVendaItensEntregues(vendaId, ({ data }) => {
			if (data?.length > 0) {
				const itensEntregues = converterItensEntreguesParaForm(data);
				setProdutosEntregues(itensEntregues);
			} else {
				setProdutosEntregues();
			}
		});
	}

	return (
		<Modal header="Itens devolvidos" visible={visible} onHide={onHide} closeOnEsc={false} showCloseIcon={false}>
			<Form>
				<FormActions>
					<ActionButtons
						onHide={onHide}
						vendaId={vendaId}
						estornouEntregas={estornouEntregas}
						asyncSelectRegistro={asyncSelectRegistro}
					/>
				</FormActions>
				<FormContent>
					<ListaProdutosEntregues
						produtosEntregues={produtosEntregues}
						buscarItensEntregues={buscarItensEntregues}
						isMobile={isMobile}
						setEstornouEntregas={setEstornouEntregas}
					/>
				</FormContent>
			</Form>
		</Modal>
	);
}

export { ModalItensEntregues };
