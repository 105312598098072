import { useState, useEffect } from 'react';
import {
	buscarItemLocalStorage,
	buscarMascaraTelefone,
	DADOSLOCALSTORAGE,
	mensagensDeValidacao,
	replaceCaracteresEspeciais,
	services,
} from 'Common';
import {
	Button,
	ButtonCancelar,
	estadosBotaoCancelar,
	FormActions,
	FormContent,
	Grid,
	InputMask,
	Modal,
} from 'components';
import { Field, withFormik } from 'formik';

function ModalCompartilharPorWhatsappImpl({
	values,
	idVenda,
	handleSubmit,
	validateForm,
	isValid,
	visible,
	onHide,
	setFieldValue,
	urlVendaOuOrcamento,
	indetificacaoOrcamento,
	cliente,
	isVenda,
	numero,
	...props
}) {
	const [organizacao] = useState(buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao);

	useEffect(() => {
		cliente?.registro?.telefones?.forEach((telefone) => {
			if (telefone.favorito === true) {
				setFieldValue('telefone', telefone.numero);
			} else if (cliente?.registro.telefones?.length > 0) {
				setFieldValue('telefone', cliente?.registro.telefones[0]?.numero);
			} else {
				setFieldValue('telefone', null);
			}
		});
	}, []);

	async function compartilharPorWhatsapp() {
		await handleSubmit();
		await validateForm();

		if (isValid) {
			enviarPorWhatsapp();
		}
	}

	function enviarPorWhatsapp() {
		const nomeCliente = cliente?.registro.nome;

		if (Object.keys(props.errors).length === 0 && !!values.telefone) {
			const texto = `Olá ${nomeCliente}, segue as informações do ${
				isVenda ? 'pedido de venda' : 'orçamento de venda'
			} Nº${numero}. Ficamos a inteira disposição para mais informações.

      %0D%0A%0D%0APdf: ${services.GESTOR}/v1/${urlVendaOuOrcamento}/${idVenda}/organizacoes/${organizacao.id}/imprimir
      `;

			window.open(`https://web.whatsapp.com/send?phone=55${values.telefone}&text=${replaceCaracteresEspeciais(texto)}`);

			onHide();
		}
	}

	return (
		<Modal header="Enviar por WhatsApp" visible={visible} onHide={onHide} width="500px">
			<FormActions style={{ margin: '8px 0' }}>
				<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
				<Button
					label="Enviar por WhatsApp"
					icon="fa fa-send"
					onClick={compartilharPorWhatsapp}
					style={{ margin: '5px' }}
				/>
			</FormActions>
			<FormContent>
				<Grid>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={InputMask}
						mask={buscarMascaraTelefone(values.telefone)}
						placeholder="(  )      -    "
						label="Número de telefone"
						value={values.telefone}
						name="telefone"
						onChange={(e) => setFieldValue('telefone', e.target.value)}
						obrigatorio
					/>
				</Grid>
			</FormContent>
		</Modal>
	);
}

const ModalCompartilharPorWhatsapp = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues: (props) => ({
		telefone: props.telefone,
	}),

	validate(values) {
		const errors = {};

		if (!values.telefone) {
			errors.telefone = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalCompartilharPorWhatsappImpl);

export default ModalCompartilharPorWhatsapp;
