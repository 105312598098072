import { Message } from "components";
import { useFormikContext } from "formik";
import { useContextNFSe } from "views/fiscal/servicos/NFSe/Context";

function ErrorsTransmissao() {
   const { values } = useFormikContext();
   const { errosTransmissao } = useContextNFSe();

   function montarMensagemTransmissao() {
      const mensagens = [];

      if (values.erros.length > 0) {
         values.erros.forEach((error) => {
            mensagens.push(<p key={error.id}>{error.mensagem}</p>);
         });
      }

      if (Object.keys(errosTransmissao).length > 0) {
         Object.keys(errosTransmissao).forEach((key) => {
            const errorMessage = `Validação interna - ${errosTransmissao[key]}`;
            mensagens.push(<p key={key}>{errorMessage}</p>);
         })
      }

      return mensagens;
   }
   
   return <Message severity="warn" text={montarMensagemTransmissao()} />
}

export { ErrorsTransmissao };