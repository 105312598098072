import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	buscarConfiguracaoUsuario,
	colors,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Checkbox,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	Tutorial,
	tutorialStepsListagens,
} from 'components';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { GoBookmark } from 'react-icons/go';

import { deleteNaturezaOperacao, readNaturezasOperacao } from './Requests';
import { CADASTRO_URL } from './Util/constantes';
import { COLORS, STYLE_BACKGROUND } from '../Situacoes/Utils/constantes';

function NaturezaOperacaoImpl({ isMobile, history }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		setSortField,
		sortField,
		setSortOrder,
		sortOrder,
		page,
		setPage,
		rows,
		setRows,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [tutorialVisible, setTutorialVisible] = useState(false);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CADASTROS_NATUREZA_OPERACAO, permissoes.EXCLUIR);
	const sortFieldLocal = sortField?.length > 0 ? sortField : 'descricao';

	const fetchCallback = useCallback(() => {
		if (!isFirstRender) {
			fetch();
		}
	});

	useEffect(() => {
		fetch();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		const inputSearchComponente = document.getElementById('input-search-natureza');
		setTimeout(() => {
			if (inputSearchComponente) {
				inputSearchComponente.focus();
			}
		}, 300);
	}, []);

	useEffect(() => {
		fetchCallback();
	}, [exibirBloqueadas, page, rows, sortOrder, sortFieldLocal]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function fetch() {
		let filtro = getFilter();
		filtro += `${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`;
		const url = construirUrl(
			`${services.GESTOR}/v1/vendas/natureza_operacao/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await readNaturezasOperacao(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.page.totalElements);
			setIsFirstRender(false);
		});
	}

	function getFilter() {
		const filter = String('?query=(')
			.concat(`descricao=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);
		return filter;
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function renderDescricao(row) {
		return (
			<span style={{ display: 'flex', alignItems: 'center' }}>
				{row.descricao}
				{row.favorito ? (
					<span>
						<GoBookmark size={22} color="#fbc02d" title="Natureza de operação favorita" />
					</span>
				) : null}
			</span>
		);
	}

	function renderFieldSituacao(row) {
		const styleDescription = {
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(COLORS.textAtivo, COLORS.bgAtivo, 'Ativo', STYLE_BACKGROUND, styleDescription);
			}
			case 'INATIVO': {
				return Badge(COLORS.textInativo, COLORS.bgInativo, 'Inativo', STYLE_BACKGROUND, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function handleClickEditar(row) {
		atualizarUrl(history, CADASTRO_URL, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteNaturezaOperacao(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function onChangeCheckboxExibirInativos(element) {
		setExibirBloqueadas(element.checked);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Naturezas de operação">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label={isMobile ? 'Nova natureza' : 'Nova natureza de operação'}
						onClick={() => history.push(CADASTRO_URL)}
						podeInserir={podeInserir}
						style={{ height: '32px' }}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-natureza_operacao"
							className="step-listagem-input-search"
							onPesquisar={() => fetch()}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
					</Grid>
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<span>
									<Checkbox
										name="exibirBloqueadas"
										inputId="exibirBloqueadas"
										checked={exibirBloqueadas}
										onChange={onChangeCheckboxExibirInativos}
										label={
											isMobile ? 'Exibir inativos' : 'Exibir naturezas de operação inativas'
										}
									/>
								</span>
							</div>
						}
					>
						<Column 
							field="descricao" 
							header="Descrição" 
							body={(row) => renderDescricao(row)} 
							style={{ width: '80%' }} 
							sortable 
						/>
						<Column
							header="Situação"
							field="situacao"
							sortable
							body={renderFieldSituacao}
							style={{ color: colors.cinzaDark, width: '20%' }}
						/>
						<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const NaturezaOperacao = connect(mapStateToProps)(NaturezaOperacaoImpl);
