import { Message } from "components";

function ErrorsParametrosFiscais({ mensagemParametroFiscal, history }) {
   function montarMensagemParametroFiscal() {
      return (
         <span>
            {`${mensagemParametroFiscal}`}
            <b
               style={{ cursor: 'pointer' }}
               onClick={() => {
                  history.push({
                     pathname: '/configuracoes_gerais',
                     state: { configurarSerieNfse: true },
                  })
               }}
            >
               Cliquei aqui para configurar
            </b>
         </span>
      );
   }

   return <Message severity="error" text={montarMensagemParametroFiscal()} />;
}

export { ErrorsParametrosFiscais };