import { Checkbox, Dropdown, Fieldset, Grid, If, InputDouble, InputField } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { helpConfiguracoesForm } from 'views/configuracao/ConfiguracoesGerais/Help';

function TabConfiguracoesNfse() {
	const { values, setFieldValue, informacoesPermissoes } = useFormikContext();
	const { alterarNumeroInicialESerieNfse, setAlterarNumeroInicialESerieNfse } = useContextConfiguracoesGerais();

	return (
		<If test={values.emiteNfse}>
			<Fieldset legend="Configurações NFS-e" className="fieldset">
				<Grid>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						name="integracaoNfse"
						label="Integração"
						component={Dropdown}
						onChange={(event) => setFieldValue('integracaoNfse', event.value ? event.value : null)}
						obrigatorio
						showClear={false}
						value={values.integracaoNfse}
						options={[
							{ label: 'Betha', value: 'BETHA' },
							{ label: 'IPM v1.0', value: 'IPM_V1' },
							{ label: 'IPM v2.0', value: 'IPM_V2' },
							{ label: 'Sigcorp', value: 'SIGCORP' },
						]}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Alterar número e série da NFS-e"
						name="alterarNumeroInicialESerieNfse"
						onChange={() => {
							setAlterarNumeroInicialESerieNfse(!alterarNumeroInicialESerieNfse);
							setFieldValue('alterarNumeroInicialESerieNfse', alterarNumeroInicialESerieNfse);
						}}
						checked={alterarNumeroInicialESerieNfse}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Série da NFS-e"
						name="serieNfse"
						obrigatorio
						disabled={!alterarNumeroInicialESerieNfse}
						value={values.serieNfse}
						onChange={(e) => setFieldValue('serieNfse', e.target.value)}
						size={30}
						component={InputField}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Número inicial da NFS-e"
						name="numeroInicialNfse"
						value={values.numeroInicialNfse}
						onChange={(e) => setFieldValue('numeroInicialNfse', e.target.value)}
						size={9}
						thousandSeparator=""
						component={InputDouble}
						disabled={!alterarNumeroInicialESerieNfse}
						decimalScale={0}
						helpMessage={helpConfiguracoesForm.numeroInicialNfse}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="RPS inicial da NFS-e"
						name="numeroInicialRps"
						value={values.numeroInicialRps}
						onChange={(e) => setFieldValue('numeroInicialRps', e.target.value)}
						size={9}
						thousandSeparator=""
						component={InputDouble}
						disabled={!alterarNumeroInicialESerieNfse}
						decimalScale={0}
						obrigatorio
						helpMessage={helpConfiguracoesForm.numeroInicialRps}
						{...informacoesPermissoes}
					/>
					<Field
						sm="12"
						md="3"
						lg="3"
						xl="3"
						label="Lote inicial da NFS-e"
						name="loteInicialRps"
						value={values.loteInicialRps}
						onChange={(e) => setFieldValue('loteInicialRps', e.target.value)}
						size={9}
						thousandSeparator=""
						component={InputDouble}
						disabled={!alterarNumeroInicialESerieNfse}
						decimalScale={0}
						obrigatorio
						helpMessage={helpConfiguracoesForm.loteInicialNfse}
						{...informacoesPermissoes}
					/>
					
					<If test={values.integracaoNfse !== 'BETHA'}>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							label="Url servidor"
							name="urlServidorNfse"
							value={values.urlServidorNfse}
							onChange={(e) => setFieldValue('urlServidorNfse', e.target.value)}
							size={200}
							component={InputField}
							{...informacoesPermissoes}
						/>
					</If>	
					<If test={values.integracaoNfse === 'IPM_V1' || values.integracaoNfse === 'IPM_V2'}>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Usuário"
							name="usuarioNfse"
							value={values.usuarioNfse}
							onChange={(e) => setFieldValue('usuarioNfse', e.target.value)}
							size={30}
							component={InputField}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="3"
							lg="3"
							xl="3"
							label="Senha"
							name="senhaNfse"
							type="password"
							value={values.senhaNfse}
							onChange={(e) => setFieldValue('senhaNfse', e.target.value)}
							size={30}
							component={InputField}
							{...informacoesPermissoes}
						/>
					</If>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={Checkbox}
						label="Enviar e-mails automaticamente para destinatário ao emitir uma NFS-e"
						name="envioAutomaticoEmailNfse"
						onChange={(e) => setFieldValue('envioAutomaticoEmailNfse', e.checked)}
						checked={values.envioAutomaticoEmailNfse}
						{...informacoesPermissoes}
					/>
				</Grid>
			</Fieldset>
		</If>
	);
}

export { TabConfiguracoesNfse };
