import { If, Modal } from 'components';
import { NaturezaOperacaoForm } from '../Form';

export default function ModalNaturezaOperacao({ visible, onHide, history }) {
	return (
		<Modal
			className={visible ? 'modalDeUmAsyncSelect' : undefined}
			header="Cadastro de natureza de operação"
			visible={visible}
			onHide={onHide}
			styleModal={{ maxWidth: '1200px' }}
		>
			<If test={visible}>
				<NaturezaOperacaoForm hideModal={onHide} isModal history={history} />
			</If>
		</Modal>
	);
}
