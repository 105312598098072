import { copiarObjeto } from 'Common';

export function converterEstruturaParaTree(jsonEstrututra) {
	let newArray = [];
	for (let i = 0; i < jsonEstrututra.length; i++) {
		const recurso = jsonEstrututra[i];

		newArray[i] = {
			id: recurso.id,
			label: recurso.descricao,
			key: recurso.identificacao,
			children: [],
		};
	}
	return newArray.sort((a, b) => a.label.localeCompare(b.label));
}

export function converterPlanoParaApi(plano, estruturaTree) {
	let recursos = [];
	let recursosSelecionados = Object.keys(plano.recursos);
	for (let i = 0; i < recursosSelecionados.length; i++) {
		const recurso = estruturaTree.filter((e) => e.key === recursosSelecionados[i])[0];
		if (recurso.id) {
			recursos.push({ id: recurso.id });
		}
	}
	return { ...copiarObjeto(plano), recursos: recursos };
}

export function converterPlanoParaFormulario(plano, estruturaTree) {
	return { ...plano, recursos: converterRecursosParaFormulario(estruturaTree, plano.recursos) };
}

export function converterRecursosParaFormulario(opcoesTree, recursos) {
	const recursosSelecionados = montarArrayDeRecurosDoPlanoSelecionado(recursos);
	return converterRecursosParaTreeTable(recursosSelecionados, opcoesTree);
}

function montarArrayDeRecurosDoPlanoSelecionado(recursos) {
	let arrayRecursosDoPlano = [];

	for (let i = 0; i < recursos.length; i++) {
		const recurso = recursos[i];
		arrayRecursosDoPlano.push(`${recurso.identificacao}`);
	}
	return arrayRecursosDoPlano;
}

function converterRecursosParaTreeTable(recursosSelecionados, opcoesTree) {
	let resultado = {};
	for (let i = 0; i < opcoesTree.length; i++) {
		const recurso = opcoesTree[i];
		if (recursosSelecionados.includes(recurso.key)) {
			resultado[recurso.key] = { ...recurso, checked: true, partialChecked: false };
		}
	}
	return resultado;
}
