import { Checkbox, Dropdown, Grid, If, InputDouble, SingleSelectTabelaPreco, Subtitle, TextArea } from 'components';
import { Field, useFormikContext } from 'formik';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { services } from 'Common';
import { gerarOptionsDecimais } from '../../Utils/functions';

function TabVendas() {
	const { podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, setFieldValue } = useFormikContext();

	function onChangeUtilizaTabelaPreco(e) {
		setFieldValue('parametrizacao.vendas.utilizaTabelaPreco', e.checked);

		if (!e.checked) {
			setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoPorRegiao', false);
			setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoDescontoQuantidade', false);
			setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao', false);
			setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoParaServico', false);
			setFieldValue('parametrizacao.vendas.tabelaPrecoPadrao', null);
		}
	}

	return (
		<Grid>
			<Subtitle subtitle="Vendas" />
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Código enviado na NF-e"
				name="codigoNaNfe"
				value={values.parametrizacao.vendas?.codigoNaNfe}
				onChange={(e) => setFieldValue('parametrizacao.vendas.codigoNaNfe', e.value)}
				options={[
					{ label: 'Código', value: 'CODIGO' },
					{ label: 'Código de barras', value: 'CODIGO_BARRAS' },
					{ label: 'Referência', value: 'REFERENCIA' },
				]}
				obrigatorio
				showClear={false}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Código enviado na NFC-e"
				name="codigoNaNfce"
				value={values.parametrizacao.vendas?.codigoNaNfce}
				onChange={(e) => setFieldValue('parametrizacao.vendas.codigoNaNfce', e.value)}
				options={[
					{ label: 'Código', value: 'CODIGO' },
					{ label: 'Código de barras', value: 'CODIGO_BARRAS' },
					{ label: 'Referência', value: 'REFERENCIA' },
				]}
				obrigatorio
				showClear={false}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				sm="12"
				md="4"
				lg="4"
				xl="4"
				component={Dropdown}
				label="Tipo de impressão de orçamento/venda"
				name="tipoImpressaoVenda"
				value={values.parametrizacao.vendas?.tipoImpressaoVenda}
				onChange={(e) => setFieldValue('parametrizacao.vendas.tipoImpressaoVenda', e.value)}
				options={[
					{ label: 'A4', value: 'A4' },
					{ label: 'A5', value: 'A5' },
					{ label: '80mm', value: 'OITENTA_MM' },
				]}
				obrigatorio
				showClear={false}
				disabled={!podeEditarEmpresa}
			/>

			<Field
				sm="12"
				md="12"
				lg="12"
				xl="12"
				component={TextArea}
				label="Observação padrão para NF-e"
				name="observacaoPadraoNfe"
				value={values.parametrizacao.vendas?.observacaoPadraoNfe}
				onChange={(e) => setFieldValue('parametrizacao.vendas.observacaoPadraoNfe', e.target.value)}
				showClear={false}
				disabled={!podeEditarEmpresa}
			/>

			<Subtitle subtitle="Tabela de preço" />
			<Grid style={{ width: '100%', padding: '8px' }}>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Utiliza tabela de preço"
					name="utilizaTabelaPreco"
					value={values.parametrizacao.vendas?.utilizaTabelaPreco}
					onChange={onChangeUtilizaTabelaPreco}
					checked={values.parametrizacao.vendas?.utilizaTabelaPreco}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={Checkbox}
					label="Utiliza tabela de preço promocional"
					name="utilizaTabelaPrecoPromocional"
					value={values.parametrizacao.vendas?.utilizaTabelaPrecoPromocional}
					onChange={(e) => setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoPromocional', e.checked)}
					checked={values.parametrizacao.vendas?.utilizaTabelaPrecoPromocional}
					disabled={!podeEditarEmpresa}
				/>
			</Grid>

			<If test={values.parametrizacao.vendas?.utilizaTabelaPreco}>
				<Grid style={{ width: '100%', padding: '8px' }}>
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={Checkbox}
						label="Utiliza tabela de preço por região"
						name="utilizaTabelaPrecoPorRegiao"
						value={values.parametrizacao.vendas?.utilizaTabelaPrecoPorRegiao}
						onChange={(e) => setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoPorRegiao', e.checked)}
						checked={values.parametrizacao.vendas?.utilizaTabelaPrecoPorRegiao}
						disabled={!podeEditarEmpresa}
					/>
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={Checkbox}
						label="Utiliza tabela de preço para serviços"
						name="utilizaTabelaPrecoParaServico"
						value={values.parametrizacao.vendas?.utilizaTabelaPrecoParaServico}
						onChange={(e) => setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoParaServico', e.checked)}
						checked={values.parametrizacao.vendas?.utilizaTabelaPrecoParaServico}
						disabled={!podeEditarEmpresa}
					/>
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={Checkbox}
						label="Utiliza desconto por quantidade na tabela de preço"
						name="utilizaTabelaPrecoDescontoQuantidade"
						value={values.parametrizacao.vendas?.utilizaTabelaPrecoDescontoQuantidade}
						onChange={(e) => setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoDescontoQuantidade', e.checked)}
						checked={values.parametrizacao.vendas?.utilizaTabelaPrecoDescontoQuantidade}
						disabled={!podeEditarEmpresa}
					/>
					<Field
						sm="12"
						md="12"
						lg="6"
						xl="6"
						component={Checkbox}
						label="Utiliza acréscimo ou desconto por condição na tabela"
						name="utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao"
						value={values.parametrizacao.vendas?.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao}
						onChange={(e) =>
							setFieldValue('parametrizacao.vendas.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao', e.checked)
						}
						checked={values.parametrizacao.vendas?.utilizaTabelaPrecoAcrescimoOuDescontoPorCondicao}
						disabled={!podeEditarEmpresa}
					/>
				</Grid>
				<Field
					sm="12"
					md="12"
					lg="6"
					xl="6"
					component={SingleSelectTabelaPreco}
					label="Tabela de preço favorita da filial"
					name="tabelaPrecoPadrao"
					url={`${services.GESTOR}/v1/empresa/relacoes/tabela_preco`}
					value={values.parametrizacao.vendas?.tabelaPrecoPadrao}
					onChange={(e) => setFieldValue('parametrizacao.vendas.tabelaPrecoPadrao', e)}
					checked={values.parametrizacao.vendas?.tabelaPrecoPadrao}
					disabled={!podeEditarEmpresa}
				/>
			</If>

			<Subtitle subtitle="Barras com informação" />
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Campo"
				name="campoBarras"
				value={values.parametrizacao.vendas?.campoBarras}
				onChange={(e) => setFieldValue('parametrizacao.vendas.campoBarras', e.value)}
				options={[
					{ label: 'Código', value: 'CODIGO' },
					{ label: 'Código de barras', value: 'CODIGO_BARRAS' },
					{ label: 'Referência', value: 'REFERENCIA' },
				]}
				disabled={!podeEditarEmpresa}
				showClear={false}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				decimalScale={0}
				label="Dígito(s) inicial(is)"
				name="iniciaisBarrasBarras"
				value={values.parametrizacao.vendas?.iniciaisBarras}
				onChange={(e) => setFieldValue('parametrizacao.vendas.iniciaisBarras', e.target.value)}
				disabled={!podeEditarEmpresa}
				thousandSeparator=""
				size={5}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				decimalScale={0}
				label="Posição"
				name="posicaoBarras"
				value={values.parametrizacao.vendas?.posicaoBarras}
				onChange={(e) => setFieldValue('parametrizacao.vendas.posicaoBarras', e.target.value)}
				thousandSeparator=""
				disabled={!podeEditarEmpresa}
				size={3}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={InputDouble}
				decimalScale={0}
				thousandSeparator=""
				label="Tamanho"
				name="tamanhoBarras"
				value={values.parametrizacao.vendas?.tamanhoBarras}
				onChange={(e) => setFieldValue('parametrizacao.vendas.tamanhoBarras', e.target.value)}
				disabled={!podeEditarEmpresa}
				size={3}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Decimais"
				name="decimaisBarras"
				value={values.parametrizacao.vendas?.decimaisBarras}
				onChange={(e) => setFieldValue('parametrizacao.vendas.decimaisBarras', e.value)}
				options={gerarOptionsDecimais(2, 4)}
				disabled={!podeEditarEmpresa}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				component={Dropdown}
				label="Informação"
				name="informacaoBarras"
				value={values.parametrizacao.vendas?.informacaoBarras}
				onChange={(e) => setFieldValue('parametrizacao.vendas.informacaoBarras', e.value)}
				options={[
					{ label: 'Peso', value: 'PESO' },
					{ label: 'Preço', value: 'PRECO' },
				]}
				disabled={!podeEditarEmpresa}
			/>
		</Grid>
	);
}

export { TabVendas };
