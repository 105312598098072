import { useEffect, useRef } from 'react';
import {
	buscarDadosLoginLocalStorage,
	colors,
	copiarObjeto,
	gerarUUID,
	permissoes,
	recursos,
	usuarioPossuiModulos,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonFinalizarENovo,
	ButtonNovo,
	ButtonSalvar,
	NormalButton,
	ToastTypes,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	notify,
} from 'components';
import { NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';
import { rateioParcelas } from 'components/body/Vendas/components/Parcelas/Util/functions';
import { addMonths, formatISO, isValid, parseISO } from 'date-fns';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { SITUACAO_PARAMETROS_TIPO_FISCAL } from 'views/cadastros/vendas/Situacoes/Form/Utils/constantes';
import { SITUACAO_TIPO } from 'views/cadastros/vendas/Situacoes/Utils/constantes';
import { atualizarUrl, validarFormulario, voltarParaAPesquisa } from '../../../../../../Util';
import {
	asyncCreateVenda,
	asyncDeleteVenda,
	asyncEstornarPedido,
	asyncFinalizarPedido,
	asyncUpdateVenda,
	estornarDevolucaoDoPedidoDeVenda,
	estornarEntregaDoPedidoDeVenda,
} from '../../../Requests';
import { Operacao, SITUACAO_DEVOLUCAO, SITUACAO_ENTREGA, Status, Tipo } from '../../../Util/constantes';
import { buscarPermissãoPorTipoSituacao, montarMensagemToast } from '../../../Util/funcoes';
import {
	converterSituacaoParaFormulario,
	converterVendaParaApi,
	converterVendaParaFormulario,
} from '../../Util/PedidovendasOrcamentosConverter';
import { Condicoes, TypeErrors } from '../../Util/constantes';

export default function ActionButtons(props) {
	const {
		match,
		history,
		duplicarVenda,
		asyncSelectRegistro,
		novo,
		acessarNfe,
		acessarNfce,
		podeInserir,
		setTabPagamentoSelecionada,
		setModalHistoricoVisible,
		setModalMotivoCancelamentoVisible,
		setModalDevolverItens,
		setModalItensDevolvidos,
		setModalEntregaParcial,
		setModalItensEntregues,
		setModalDevolucaoCondicional,
		setEtapaCanceladaModalCancelamento,
		informacoesPermissoes,
		existeParcelaRecebida,
		urlVendaOuOrcamento,
		moduloUtilizado,
		setAtualizarImpostosProdutos,
		isVenda,
		totalizadores,
		setPodeEditar,
		setPodeInserir,
		setPodeExcluir,
		generateVenda,
		credencial,
		erroProdutoInativo,
		atualizarParcelas,
		isMobile,
		setAtualizarTotal,
		tipoSituacao,
	} = props;

	const { values, dirty, resetForm, handleSubmit, validateForm, setValues, initialValues } = useFormikContext();

	const menuOpcoes = useRef(null);
	const menuNotas = useRef(null);

	const hasErroFinalizarENovo = useRef(false);

	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const itensOpcoes = buscaritensOpcoes();
	const itensOpcoesNotas = buscaritensOpcoesNotas();
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;

	useEffect(() => {
		if (validarUUID(match.params.id) && hasErroFinalizarENovo.current) {
			asyncSelectRegistro(match.params.id);
			hasErroFinalizarENovo.current = false;
		}
	}, [match.params]);

	function buscaritensOpcoes() {
		let itensOpcoes = [];

		if (values.id && !dirty) {
			const duplicar = {
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => duplicarVenda(values.id),
			};
			itensOpcoes.push(duplicar);
		}

		if (values.id) {
			const historico = {
				label: 'Histórico',
				icon: 'fa fa-history',
				command: () => setModalHistoricoVisible(true),
			};
			itensOpcoes.push(historico);
		}

		if (values.tipo === Tipo.PEDIDO_VENDA && tipoSituacao !== Status.CANCELADO && tipoSituacao !== Status.FINALIZADO) {
			itensOpcoes.push({
				label: 'Recalcular pedido',
				icon: 'fa fa-calculator',
				command: () => {
					recalcularPedido();
				},
			});
		}

		if (values.id) {
			itensOpcoes = [...itensOpcoes, ...buscarAcoesDaSituacaoAtual()];
			itensOpcoes = [...itensOpcoes, ...buscarAcoesPermitidasDaSituacaoAtual()];
		}

		return itensOpcoes;
	}

	function buscarAcoesDaSituacaoAtual() {
		const itensOpcoes = [];

		if (values.situacao.registro.etapasVinculadas?.length > 0) {
			values.situacao?.registro?.etapasVinculadas?.forEach((etapa) => {
				if (
					isVenda()
						? !etapa.favorito || !etapa.situacao?.tipoFiscal === SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL
						: etapa.situacao?.tipo !== SITUACAO_TIPO.FINALIZADO && !values.pedido
				) {
					itensOpcoes.push({
						label: etapa.situacao?.textoBotao,
						icon: etapa.situacao?.icone,
						command: () => {
							buscarComandoSituacao(etapa?.situacao);
						},
						disabled:
							!usuarioPossuiPermissao(
								isVenda() ? recursos.VENDAS_PEDIDOS : recursos.VENDAS_ORCAMENTOS,
								isVenda() ? buscarPermissãoPorTipoSituacao(etapa.situacao?.tipo) : permissoes.EDITAR
							) || dirty,
					});
				}
			});

			if (itensOpcoes.length > 0) {
				itensOpcoes.unshift({
					separator: true,
					visible: true,
				});
			}
		}

		return itensOpcoes;
	}

	function buscarAcoesPermitidasDaSituacaoAtual() {
		const itensOpcoes = [];
		const isSalvo = values.id && !dirty;
		const isPedidoSalvo = isSalvo && values.tipo === Tipo.PEDIDO_VENDA;
		const permiteDevolucao = values.situacao?.registro?.parametros?.permiteDevolucao;
		const permiteDevolucaoCondicional = values.situacao?.registro?.parametros?.permiteDevolucaoCondicional;
		const permiteEntregaParcial = values.situacao?.registro?.parametros?.permiteEntregaParcial;

		if (isSalvo && tipoSituacao === 'CANCELADO') {
			itensOpcoes.push({
				label: 'Motivo do cancelamento',
				icon: 'fa fa-eye',
				command: () => setModalMotivoCancelamentoVisible(true),
			});
		}

		if (isPedidoSalvo) {
			if (permiteDevolucaoCondicional) {
				if (tipoSituacao === Status.PENDENTE) {
					itensOpcoes.push({
						label: 'Devolução condicional',
						icon: 'fa fa-cart-arrow-down',
						command: () => setModalDevolucaoCondicional(true),
						disabled:
							!usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.EDITAR) ||
							values.situacaoEntrega !== SITUACAO_ENTREGA.NAO_ENTREGUE,
					});
				}
			}

			if (permiteDevolucao) {
				if (values.situacaoDevolucao !== SITUACAO_DEVOLUCAO.DEVOLVIDO) {
					itensOpcoes.push({
						label: 'Devolver pedido',
						icon: 'fa fa-reply-all',
						command: () => setModalDevolverItens(true),
						disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.DEVOLVER),
					});
				}
				if (values.situacaoDevolucao === SITUACAO_DEVOLUCAO.DEVOLVIDO) {
					itensOpcoes.push({
						label: 'Estornar devolução',
						icon: 'fa fa-reply-all',
						command: () => {
							estornarDevolucaoDoPedidoDeVenda(values.id, () => {
								asyncSelectRegistro(match.params.id);
								notify('Estorno da devolucão concluído', ToastTypes.SUCCESS);
							});
						},
						disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.DEVOLVER),
					});
				}
				if (values.situacaoDevolucao !== SITUACAO_DEVOLUCAO.NAO_DEVOLVIDO) {
					itensOpcoes.push({
						label: 'Itens devolvidos',
						icon: 'fa fa-list',
						command: () => setModalItensDevolvidos(true),
					});
				}
			}

			if (permiteEntregaParcial) {
				if (values.situacaoEntrega !== SITUACAO_ENTREGA.ENTREGUE) {
					itensOpcoes.push({
						label: 'Entrega parcial',
						icon: 'fa fa-check-square-o',
						command: () => setModalEntregaParcial(true),
						disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.ENTREGAR),
					});
				}
				if (values.situacaoEntrega === SITUACAO_ENTREGA.ENTREGUE) {
					itensOpcoes.push({
						label: 'Estornar Entrega parcial',
						icon: 'fa fa-reply-all',
						command: () => {
							estornarEntregaDoPedidoDeVenda(values.id, () => {
								asyncSelectRegistro(match.params.id);
								notify('Estorno da entrega parcial concluída', ToastTypes.SUCCESS);
							});
						},
						disabled: !usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.ENTREGAR),
					});
				}
				if (values.situacaoEntrega !== SITUACAO_ENTREGA.NAO_ENTREGUE) {
					itensOpcoes.push({
						label: 'Itens Entregues',
						icon: 'fa fa-list',
						command: () => setModalItensEntregues(true),
					});
				}
			}

			if (!values.nfe && !values.nfce && tipoSituacao === Status.FINALIZADO) {
				itensOpcoes.push({
					label: values.documentoAssinaturaId ? 'Acessar assinatura' : 'Gerar assinatura',
					icon: 'fa fa-file-text-o',
					command: () => (values.documentoAssinaturaId ? acessarAssinatura() : gerarDocumento()),
					disabled: !usuarioPossuiModulos(['DOCUMENTOS']),
					title: values.documentoAssinaturaId ? 'Acessar assinatura' : 'Gerar assinatura',
				});
			}
		}

		if (itensOpcoes.length > 0) {
			itensOpcoes.unshift({
				separator: true,
				visible: true,
			});
		}

		return itensOpcoes;
	}

	function buscaritensOpcoesNotas() {
		const itensOpcoesNotas = [];
		itensOpcoesNotas.push({
			label: 'Gerar NF-e',
			icon: 'fa fa-file-text-o',
			command: () => {
				gerarNfe();
			},
		});
		itensOpcoesNotas.push({
			label: 'Gerar NFC-e',
			icon: 'fa fa-file-text-o',
			command: () => {
				gerarNfce();
			},
		});
		return itensOpcoesNotas;
	}

	function buscarComandoSituacao(situacao) {
		if (situacao) {
			if (situacao?.tipo === Status.FINALIZADO) {
				finalizarPedido(values.id, situacao, null, null);
			} else if (situacao?.tipo === Status.CANCELADO) {
				setModalMotivoCancelamentoVisible(true);
				setEtapaCanceladaModalCancelamento(situacao);
			} else if (situacao?.estornaMovimentacoes) {
				estornarPedido();
			} else {
				updateVenda({ ...values, situacao: converterSituacaoParaFormulario(situacao) }, (data) => {
					asyncSelectRegistro(data.id);
				});
			}
		}
	}

	async function cancelar() {
		if (dirty) {
			if (values.id) {
				resetForm({
					values: {
						...copiarObjeto(initialValues),
						tipo: moduloUtilizado === 'PEDIDO_VENDA' ? 'PEDIDO_VENDA' : 'ORCAMENTO',
					},
				});
				setAtualizarTotal(true);
			} else {
				novo({ isCancelarVenda: true });
			}
		} else {
			voltarParaAPesquisa(history, `/${moduloUtilizado === 'PEDIDO_VENDA' ? 'pedido_venda' : 'orcamento'}`);
		}

		if (initialValues.condicao === Condicoes.A_VISTA) {
			setTabPagamentoSelecionada(0);
		}
	}

	async function calcularTotalParcelas() {
		let totalParcelas = 0;
		await values.parcelas?.forEach((parcela) => {
			totalParcelas += parcela.valor;
		});

		return totalParcelas;
	}

	async function recalcularParcelas() {
		const parcelasTemp = [];

		const rateio = rateioParcelas(totalizadores.totalLiquido, values.quantidadeParcelas);

		for (let i = 0; i < values.quantidadeParcelas; i++) {
			const oldParcela = values.parcelas && values.parcelas[i];
			let valorParcela = rateio.valorPorParcela;

			if (values.quantidadeParcelas - 1 === i) {
				valorParcela = rateio.valorUltimaParcela;
			}

			const idParcela = oldParcela ? oldParcela.id : null;
			const vencimento = formatISO(
				isValid(new Date(oldParcela?.vencimento))
					? parseISO(oldParcela.vencimento)
					: addMonths(parseISO(values.dataEmissao), i + 1)
			);
			const descricao = oldParcela ? oldParcela.descricao : '';
			const forma = oldParcela ? oldParcela.forma : 'DINHEIRO';

			parcelasTemp.push({
				id: idParcela,
				vencimento: vencimento,
				valor: valorParcela,
				descricao: descricao,
				forma: forma,
			});
		}

		await atualizarParcelas(parcelasTemp);
		return parcelasTemp;
	}

	async function salvar(e, novoOnSuccess, vendaAtualizada) {
		const valuesVenda = { ...values, totalizadores: totalizadores };
		if (values.condicao === Condicoes.A_PRAZO && (await calcularTotalParcelas()) !== totalizadores.totalLiquido) {
			valuesVenda.parcelas = await recalcularParcelas();
		}

		if (valuesVenda.produtos?.length > 0) {
			valuesVenda.produtos = valuesVenda.produtos.filter((produto) => produto.produto);

			if (valuesVenda.produtos?.length === 0) {
				valuesVenda.produtos = [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }];
			}

			for (let i = 0; i < valuesVenda.produtos.length; i++) {
				valuesVenda.produtos[i].item = i + 1;
			}
		}

		const comissaoVenda = calcularComissaoVenda(valuesVenda);
		valuesVenda.comissaoTotalPercentual = comissaoVenda.percentual;
		valuesVenda.comissaoTotalValor = comissaoVenda.valor;

		setValues(valuesVenda);

		if (
			await validarFormulario({
				validateForm,
				values: valuesVenda,
			})
		) {
			if (valuesVenda.id) {
				updateVenda(
					{
						...valuesVenda,
						totalizadores: totalizadores,
						motivoCancelamento: vendaAtualizada?.motivoCancelamento,
						novoOnSuccess,
					},
					await novoOnSuccess
				);
			} else {
				createVenda({ ...valuesVenda, totalizadores: totalizadores }, await novoOnSuccess);
			}
		} else {
			handleSubmit();
		}
	}

	function calcularComissaoVenda(venda) {
		const comissao = { percentual: 0, valor: 0 };

		if (venda.produtos?.length > 0) {
			const percentualTotal =
				venda.produtos.reduce((total, item) => total + item.comissaoPercentual || 0, 0) / venda.produtos.length;

			comissao.percentual = parseFloat(percentualTotal.toFixed(2));
			comissao.valor = parseFloat((venda.totalizadores.totalLiquido * (percentualTotal / 100)).toFixed(2));
		}

		return comissao;
	}

	async function onFinalizarENovo() {
		const valuesVenda = { ...values, totalizadores: totalizadores };
		if (values.condicao === Condicoes.A_PRAZO && (await calcularTotalParcelas()) !== totalizadores.totalLiquido) {
			valuesVenda.parcelas = await recalcularParcelas();
		}

		if (valuesVenda.produtos?.length > 0) {
			valuesVenda.produtos = valuesVenda.produtos.filter((produto) => produto.produto);

			if (valuesVenda.produtos?.length === 0) {
				valuesVenda.produtos = [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }];
			}

			for (let i = 0; i < valuesVenda.produtos.length; i++) {
				valuesVenda.produtos[i].item = i + 1;
			}
		}

		const comissaoVenda = calcularComissaoVenda(valuesVenda);
		valuesVenda.comissaoTotalPercentual = comissaoVenda.percentual;
		valuesVenda.comissaoTotalValor = comissaoVenda.valor;

		setValues(valuesVenda);

		if (await validarFormulario({ validateForm, values: valuesVenda })) {
			const idSituacaoFinalFinalizado = values.situacao?.registro?.parametros?.situacaoFinalFinalizado?.id;

			if (values.id) {
				const mensagem = montarMensagemToast(initialValues.tipo, Operacao.EDITAR);
				await asyncUpdateVenda(
					urlVendaOuOrcamento,
					converterVendaParaApi({ ...values, totalizadores: totalizadores }),
					mensagem,
					({ data: venda }) => {
						asyncFinalizarPedido(venda.id, idSituacaoFinalFinalizado, () => {
							novo();
						});
					}
				);
			} else {
				const mensagem = montarMensagemToast(initialValues.tipo, Operacao.INSERIR);

				await asyncCreateVenda(
					urlVendaOuOrcamento,
					converterVendaParaApi({ ...values, totalizadores: totalizadores }),
					mensagem,
					({ data: venda }) => {
						if (urlVendaOuOrcamento === 'orcamentos') {
							atualizarUrl(history, `/orcamento/cadastro`, venda.id);
						} else {
							atualizarUrl(history, `/pedido_venda/cadastro`, venda.id);
						}
						asyncFinalizarPedido(
							venda.id,
							idSituacaoFinalFinalizado,
							() => {
								novo();
							},
							() => {
								hasErroFinalizarENovo.current = true;
							}
						);
					}
				);
			}
		}
	}

	async function updateVenda(valuesVenda, novoOnSuccess) {
		if (valuesVenda) {
			const mensagem = montarMensagemToast(initialValues.tipo, Operacao.EDITAR);
			await asyncUpdateVenda(urlVendaOuOrcamento, converterVendaParaApi(valuesVenda), mensagem, ({ data: venda }) => {
				if (novoOnSuccess) {
					novoOnSuccess(venda);
				} else {
					resetForm({
						values: {
							...valuesVenda,
							id: venda.id,
							numero: venda.numero,
						},
					});
				}
			});
		}
	}

	async function createVenda(values, novoOnSuccess) {
		if (values) {
			const mensagem = montarMensagemToast(initialValues.tipo, Operacao.INSERIR);

			await asyncCreateVenda(urlVendaOuOrcamento, converterVendaParaApi(values), mensagem, ({ data: venda }) => {
				if (novoOnSuccess) {
					novoOnSuccess(venda);
				} else {
					if (urlVendaOuOrcamento === 'orcamentos') {
						atualizarUrl(history, `/orcamento/cadastro`, venda.id);
					} else {
						atualizarUrl(history, `/pedido_venda/cadastro`, venda.id);
					}

					resetForm({
						values: {
							...converterVendaParaFormulario(venda),
						},
					});
				}
			});
		}
	}

	function recalcularPedido() {
		setAtualizarImpostosProdutos(true);
	}

	async function finalizarPedido(idVenda, novaSituacao, onSuccess, onError) {
		try {
			const produtosInativos = [];
			values.produtos?.forEach((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
			});
			if (produtosInativos?.length > 0) {
				erroProdutoInativo(produtosInativos);
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}

			await asyncFinalizarPedido(
				values.id || idVenda,
				novaSituacao.id,
				async ({ data }) => {
					await asyncSelectRegistro(data.id);

					setPodeEditar(false);
					setPodeInserir(false);
					setPodeExcluir(false);

					if (typeof onSuccess === 'function') {
						await onSuccess();
					}
				},
				async () => {
					if (typeof onError === 'function') {
						await onError();
					}
				}
			);

			setTabPagamentoSelecionada(!credencial.filialConectada?.parametrosFinancas?.gerarFinanceiroNaNfe ? 1 : 0);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, pedido de venda contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function estornarPedido() {
		try {
			const produtosInativos = [];
			values.produtos?.forEach((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
			});
			if (produtosInativos?.length > 0) {
				erroProdutoInativo(produtosInativos);
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}

			asyncEstornarPedido(urlVendaOuOrcamento, values.id, ({ data }) => {
				asyncSelectRegistro(data.id);
			});
			setPodeEditar(true);
			setPodeInserir(true);
			setPodeExcluir(true);
			setTabPagamentoSelecionada(0);
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, pedido de venda contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function excluir() {
		const mensagem = montarMensagemToast(initialValues.tipo, Operacao.EXCLUIR);
		confirm('Atenção', 'Tem certeza que deseja excluir?', () => {
			asyncDeleteVenda(urlVendaOuOrcamento, values.id, mensagem, () => {
				voltarParaAPesquisa(history, isVenda() ? '/pedido_venda' : '/orcamento');
			});
		});
	}

	function renderBotaoFavorito() {
		const style = { margin: '5px' };
		let etapaFavorita;
		values.situacao?.registro?.etapasVinculadas?.forEach((etapa) => {
			if (etapa.favorito) {
				etapaFavorita = etapa.situacao;
			}
		});

		if (etapaFavorita && moduloUtilizado === 'PEDIDO_VENDA') {
			return (
				<Button
					label={etapaFavorita.textoBotao}
					className={buscarClassNameBotaoFavorito(etapaFavorita.tipo)}
					icon={etapaFavorita.icone}
					disabled={
						dirty ||
						!values.id ||
						!usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, buscarPermissãoPorTipoSituacao(etapaFavorita?.tipo))
					}
					onClick={() => buscarComandoSituacao(etapaFavorita)}
					style={style}
				/>
			);
		}

		return null;
	}

	function buscarClassNameBotaoFavorito(tipo) {
		let className;
		switch (tipo) {
			case SITUACAO_TIPO.PENDENTE:
				className = 'p-button-info';
				break;
			case SITUACAO_TIPO.CANCELADO:
				className = 'p-button-danger';
				break;
			case SITUACAO_TIPO.FINALIZADO:
				className = 'p-button-success';
				break;
			default:
				className = 'p-button-info';
				break;
		}

		return className;
	}

	function renderBotaoNotaFiscal() {
		const style = {
			margin: '5px',
		};

		if (values.nfce) {
			return (
				<Button
					label="Acessar NFC-e"
					className="p-button-success"
					icon="fa fa-link"
					hidden={dirty || values.tipo !== Tipo.PEDIDO_VENDA || !values.id}
					onClick={() => acessarNfce()}
					style={style}
				/>
			);
		} else if (values.nfe) {
			return (
				<Button
					label="Acessar NF-e"
					className="p-button-success"
					icon="fa fa-link"
					hidden={dirty || values.tipo !== Tipo.PEDIDO_VENDA || !values.id}
					onClick={() => acessarNfe()}
					style={style}
				/>
			);
		} else if (
			values.situacao?.registro?.parametros?.tipoFiscal ===
				SITUACAO_PARAMETROS_TIPO_FISCAL.PERMITE_GERAR_DOCUMENTO_FISCAL ||
			(values.situacao?.registro?.parametros?.tipoFiscal === SITUACAO_PARAMETROS_TIPO_FISCAL.GERA_DOCUMENTO_FISCAL &&
				!values.nfe &&
				!values.nfce)
		) {
			return (
				<Button
					label={
						buscarDadosLoginLocalStorage()?.filialConectada?.parametrosFiscalNFCe &&
						buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFCe.serieNfce
							? 'Gerar fiscal'
							: 'Gerar NF-e'
					}
					className="p-button-success"
					icon="fa fa-file-text-o"
					hidden={
						dirty ||
						values.tipo !== Tipo.PEDIDO_VENDA ||
						tipoSituacao === (Status.CANCELADO || Status.FINALIZADO) ||
						!values.id
					}
					onClick={(event) => {
						buscarDadosLoginLocalStorage()?.filialConectada?.parametrosFiscalNFCe &&
						buscarDadosLoginLocalStorage().filialConectada.parametrosFiscalNFCe.serieNfce
							? menuNotas.current.toggle(event)
							: gerarNfe();
					}}
					disabled={!podeInserir}
					title={!podeInserir && !values.nfe ? 'Você não possui permissão para executar essa ação' : ''}
					style={style}
				/>
			);
		} else {
			return null;
		}
	}

	async function gerarNfe() {
		handleSubmit();
		await validateForm();

		try {
			const produtosInativos = [];
			values.produtos?.forEach((produto) => {
				if (
					produto.produto?.registro?.situacao === 'INATIVO' &&
					!produtosInativos.some((e) => e.id === produto.produto.registro.id)
				) {
					produtosInativos.push(produto.produto.registro);
				}
			});
			if (produtosInativos?.length > 0) {
				erroProdutoInativo(produtosInativos);
				throw new Error(TypeErrors.PRODUTO_INATIVO);
			}

			if (isValid) {
				atualizarUrl(history, `/nfes/importar_pedido/${values.id}`);
			}
		} catch (error) {
			if (error.message === TypeErrors.PRODUTO_INATIVO) {
				notify('Processo cancelado, pedido de venda contém produtos inativos!', ToastTypes.ERROR);
			} else {
				console.error(error);
			}
		}
	}

	function gerarNfce() {
		atualizarUrl(history, `/nfces/importar_pedido/${values.id}`);
	}

	function gerarDocumento() {
		atualizarUrl(history, `/documentos/assinatura/importar_pedido/${values.id}`);
	}

	function acessarAssinatura() {
		atualizarUrl(history, `/documentos/assinatura/cadastro/${values.documentoAssinaturaId}`);
	}

	async function gerarPedidoVenda() {
		const pedidoVenda = copiarObjeto(values);
		generateVenda(pedidoVenda);
	}

	return (
		<>
			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menuOpcoes} />
			<Menu model={itensOpcoesNotas} style={{ minWidth: '230px' }} popup ref={menuNotas} />
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={cancelar}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar disabled={!dirty || existeParcelaRecebida} onClick={salvar} {...informacoesPermissoes} />
			<ButtonNovo
				onClick={onClickNovo}
				hidden={!dirty && !values.id}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonFinalizarENovo
				hidden={
					tipoSituacao !== 'PENDENTE' ||
					(!values.id && !dirty) ||
					!values.situacao?.registro?.parametros?.permiteFinalizarENovo ||
					moduloUtilizado !== 'PEDIDO_VENDA'
				}
				onClick={onFinalizarENovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id} onClick={excluir} {...informacoesPermissoes} />

			<NormalButton
				className="p-button-primary"
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={itensOpcoes.length === 0}
				onClick={(event) => menuOpcoes.current.toggle(event)}
			/>
			{renderBotaoFavorito()}
			{renderBotaoNotaFiscal()}
			<NormalButton
				hidden={
					!values.id ||
					values.tipo === 'PEDIDO_VENDA' ||
					tipoSituacao === 'FINALIZADO' ||
					tipoSituacao === 'CANCELADO' ||
					!values.situacao?.registro?.parametros?.permiteGerarPedidoVenda
				}
				type="button"
				className="p-button-success"
				label={isMobile ? 'Gerar pedido' : 'Gerar pedido de venda'}
				icon="fa fa-shopping-cart"
				style={{
					backgroundColor: colors.verdeButton,
					margin: '5px',
					borderColor: colors.verdeButton,
				}}
				onClick={gerarPedidoVenda}
				{...informacoesPermissoes}
			/>
		</>
	);
}
