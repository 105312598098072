import { validarUUID } from 'Common';
import Col from 'components/Col';
import Grid from 'components/Grid';
import If from 'components/If';
import { FINANCEIRO_TIPO, TABS_PARCELAS } from 'components/Pagamentos/Util/constantes';
import TabView from 'components/TabView';
import { TabPanel } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { Parcelas } from '../Parcelas';
import { TabelaContaReceber } from '../TabelaContaReceber';
import { TabelaContaPagar } from '../TabelaContaPagar';

function TabViewParcelas({
	values,
	generateParcelas,
	calculateValorParcelas,
	onChangeVencimentoParcelas,
	onChangeValorParcelas,
	onBlurValorParcelas,
	onChangeFormaPagamento,
	onChangeConta,
	onChangeDescricao,
	onBlurDescricaoParcelas,
	onRemoveParcela,
	indexPagamento,
}) {
	const { classNameTabView, hideTabFinanceiro, financeiroTipo } = useContextPagamentos();

	const [tabParcelasSelecionada, setTabParcelasSelecionada] = useState(TABS_PARCELAS.PARCELAS);

	const isContaReceber = financeiroTipo === FINANCEIRO_TIPO.CONTA_RECEBER;
	const isContaPagar = financeiroTipo === FINANCEIRO_TIPO.CONTA_PAGAR;
	const hasFinanceiroGerado = checkHasFinanceiroGerado();

	useEffect(() => {
		if (hideTabFinanceiro || !hasFinanceiroGerado) {
			setTabParcelasSelecionada(TABS_PARCELAS.PARCELAS);
		} else {
			setTabParcelasSelecionada(TABS_PARCELAS.FINANCEIRO);
		}
	}, [hideTabFinanceiro]);

	function handleChangeTabParcelas(e) {
		setTabParcelasSelecionada(e.index);
	}

	function checkHasFinanceiroGerado() {
		let hasFinanceiroGerado = false;

		for (const parcela of values.parcelas) {
			if (validarUUID(parcela.financeiroId)) {
				hasFinanceiroGerado = true;
			}
		}

		return hasFinanceiroGerado;
	}

	return (
		<Col style={{ paddingTop: '0px', marginTop: '-5px' }}>
			<TabView
				className={`tab-view-no-border ${classNameTabView}`}
				activeIndex={tabParcelasSelecionada}
				onTabChange={handleChangeTabParcelas}
			>
				<TabPanel 
					header="Parcelas" 
					headerStyle={{marginBottom: '0.7em'}}
				>
					<Grid>
						<Parcelas
							values={values}
							generateParcelas={generateParcelas}
							calculateValorParcelas={calculateValorParcelas}
							onChangeVencimentoParcelas={onChangeVencimentoParcelas}
							onChangeValorParcelas={onChangeValorParcelas}
							onBlurValorParcelas={onBlurValorParcelas}
							onChangeFormaPagamento={onChangeFormaPagamento}
							onChangeConta={onChangeConta}
							onChangeDescricao={onChangeDescricao}
							onBlurDescricaoParcelas={onBlurDescricaoParcelas}
							onRemoveParcela={onRemoveParcela}
							indexPagamento={indexPagamento}
						/>
					</Grid>
				</TabPanel>
				<TabPanel
					header={isContaReceber ? 'Conta a receber' : 'Conta a pagar'}
					headerStyle={{
						display: hideTabFinanceiro || !hasFinanceiroGerado ? 'none' : 'unset',
						marginBottom: '0.7em'
					}}
				>
					<If test={isContaReceber}>
						<TabelaContaReceber
							parcelas={values.parcelas}
						/>
					</If>
					<If test={isContaPagar}>
						<TabelaContaPagar
							parcelas={values.parcelas}
						/>
					</If>
				</TabPanel>
			</TabView>
		</Col>
	);
}

export { TabViewParcelas };
