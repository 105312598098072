import { Field, useFormikContext } from 'formik';

import { Checkbox, Col, Grid, If, InputField } from 'components';
import { buscarDescricaoTamanhoArquivo } from 'Common';

import { helpPlanosForm } from '../../Help';
import { INITIAL_VALUES, STYLE_WARNINGS } from '../../../Util/constantes';

function PlanosLimites({ planoIlimitado, fieldCheckboxes, changeCheckboxesFunction, informacoesPermissoes }) {
	const { setFieldValue, values } = useFormikContext();

	function onChangeCampoIlimitado(field, value) {
		if (value === true) {
			if (field === 'armazenamento') {
				setFieldValue([field], Number.parseInt('99999999999999999', 10));
				changeCheckboxesFunction(field, value);
			} else {
				setFieldValue([field], 999999999);
				changeCheckboxesFunction(field, value);
			}
		} else {
			setFieldValue([field], INITIAL_VALUES[field]);
			changeCheckboxesFunction(field, value);
		}
	}

	return (
		<Grid>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Usuários ilimitados?"
					disabled={planoIlimitado}
					name="usuarios"
					helpMessage={helpPlanosForm.usuarios}
					onChange={(e) => onChangeCampoIlimitado('usuarios', e.checked)}
					checked={fieldCheckboxes.usuarios}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="usuarios"
					disabled={planoIlimitado || fieldCheckboxes.usuarios}
					type="number"
					size={9}
					min={0}
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Armazenamento ilimitado?"
					disabled={planoIlimitado}
					name="armazenamento"
					helpMessage={helpPlanosForm.armazenamento}
					onChange={(e) => onChangeCampoIlimitado('armazenamento', e.checked)}
					checked={fieldCheckboxes.armazenamento}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="armazenamento"
					disabled={planoIlimitado || fieldCheckboxes.armazenamento}
					type="number"
					size={18}
					min={0}
					{...informacoesPermissoes}
				/>
				<If test={values.armazenamento && values.armazenamento >= 0 && !fieldCheckboxes.armazenamento}>
					<div style={STYLE_WARNINGS}> {buscarDescricaoTamanhoArquivo(values.armazenamento)} </div>
				</If>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Pessoas ilimitadas?"
					disabled={planoIlimitado}
					name="cadastrosPessoas"
					helpMessage={helpPlanosForm.cadastrosPessoas}
					onChange={(e) => onChangeCampoIlimitado('cadastrosPessoas', e.checked)}
					checked={fieldCheckboxes.cadastrosPessoas}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="cadastrosPessoas"
					disabled={planoIlimitado || fieldCheckboxes.cadastrosPessoas}
					type="number"
					size={9}
					min={0}
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Produtos ilimitados?"
					disabled={planoIlimitado}
					name="cadastrosProdutos"
					helpMessage={helpPlanosForm.cadastrosProdutos}
					onChange={(e) => onChangeCampoIlimitado('cadastrosProdutos', e.checked)}
					checked={fieldCheckboxes.cadastrosProdutos}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="cadastrosProdutos"
					disabled={planoIlimitado || fieldCheckboxes.cadastrosProdutos}
					type="number"
					size={9}
					min={0}
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Documentos ilimitados?"
					name="documentosMensais"
					disabled={planoIlimitado}
					helpMessage={helpPlanosForm.documentosMensais}
					onChange={(e) => onChangeCampoIlimitado('documentosMensais', e.checked)}
					checked={fieldCheckboxes.documentosMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="documentosMensais"
					disabled={planoIlimitado || fieldCheckboxes.documentosMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Compras ilimitadas?"
					disabled={planoIlimitado}
					name="comprasMensais"
					helpMessage={helpPlanosForm.comprasMensais}
					onChange={(e) => onChangeCampoIlimitado('comprasMensais', e.checked)}
					checked={fieldCheckboxes.comprasMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="comprasMensais"
					disabled={planoIlimitado || fieldCheckboxes.comprasMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Orçamentos ilimitados?"
					disabled={planoIlimitado}
					name="orcamentosMensais"
					helpMessage={helpPlanosForm.orcamentosMensais}
					onChange={(e) => onChangeCampoIlimitado('orcamentosMensais', e.checked)}
					checked={fieldCheckboxes.orcamentosMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="orcamentosMensais"
					disabled={planoIlimitado || fieldCheckboxes.orcamentosMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Pedidos ilimitados?"
					disabled={planoIlimitado}
					name="pedidosMensais"
					helpMessage={helpPlanosForm.pedidosMensais}
					onChange={(e) => onChangeCampoIlimitado('pedidosMensais', e.checked)}
					checked={fieldCheckboxes.pedidosMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="pedidosMensais"
					disabled={planoIlimitado || fieldCheckboxes.pedidosMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Notas fiscais ilimitadas?"
					disabled={planoIlimitado}
					name="nfesMensais"
					helpMessage={helpPlanosForm.nfesMensais}
					onChange={(e) => onChangeCampoIlimitado('nfesMensais', e.checked)}
					checked={fieldCheckboxes.nfesMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="nfesMensais"
					disabled={planoIlimitado || fieldCheckboxes.nfesMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Notas fiscais consumidor ilimitadas?"
					disabled={planoIlimitado}
					name="nfcesMensais"
					helpMessage={helpPlanosForm.nfcesMensais}
					onChange={(e) => onChangeCampoIlimitado('nfcesMensais', e.checked)}
					checked={fieldCheckboxes.nfcesMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="nfcesMensais"
					disabled={planoIlimitado || fieldCheckboxes.nfcesMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>

			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Ordens de serviço ilimitadas?"
					disabled={planoIlimitado}
					name="ordensMensais"
					helpMessage={helpPlanosForm.ordensMensais}
					onChange={(e) => onChangeCampoIlimitado('ordensMensais', e.checked)}
					checked={fieldCheckboxes.ordensMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="ordensMensais"
					disabled={planoIlimitado || fieldCheckboxes.ordensMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>

			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Notas fiscais de serviço ilimitadas?"
					disabled={planoIlimitado}
					name="nfsesMensais"
					helpMessage={helpPlanosForm.nfsesMensais}
					onChange={(e) => onChangeCampoIlimitado('nfsesMensais', e.checked)}
					checked={fieldCheckboxes.nfsesMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="nfsesMensais"
					disabled={planoIlimitado || fieldCheckboxes.nfsesMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>

			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Manifestos de documentos fiscais eletrônicos ilimitados?"
					disabled={planoIlimitado}
					name="mdfesMensais"
					helpMessage={helpPlanosForm.mdfesMensais}
					onChange={(e) => onChangeCampoIlimitado('mdfesMensais', e.checked)}
					checked={fieldCheckboxes.mdfesMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="mdfesMensais"
					disabled={planoIlimitado || fieldCheckboxes.mdfesMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>

			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Conhecimentos de transporte eletrônicos ilimitados?"
					disabled={planoIlimitado}
					name="ctesMensais"
					helpMessage={helpPlanosForm.ctesMensais}
					onChange={(e) => onChangeCampoIlimitado('ctesMensais', e.checked)}
					checked={fieldCheckboxes.ctesMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="ctesMensais"
					disabled={planoIlimitado || fieldCheckboxes.ctesMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>

			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Contas financeiras ilimitadas?"
					disabled={planoIlimitado}
					name="contasFinanceiras"
					helpMessage={helpPlanosForm.contasFinanceiras}
					onChange={(e) => onChangeCampoIlimitado('contasFinanceiras', e.checked)}
					checked={fieldCheckboxes.contasFinanceiras}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="contasFinanceiras"
					disabled={planoIlimitado || fieldCheckboxes.contasFinanceiras}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>
			<Col sm="6" md="6" lg="6" xl="6">
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					colStyle={{ padding: '0px 0px 3px 1px' }}
					label="Boletos ilimitados?"
					disabled={planoIlimitado}
					name="boletosMensais"
					helpMessage={helpPlanosForm.boletosMensais}
					onChange={(e) => onChangeCampoIlimitado('boletosMensais', e.checked)}
					checked={fieldCheckboxes.boletosMensais}
					{...informacoesPermissoes}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={InputField}
					colStyle={{ padding: '0px' }}
					name="boletosMensais"
					disabled={planoIlimitado || fieldCheckboxes.boletosMensais}
					size={9}
					min={0}
					type="number"
					{...informacoesPermissoes}
				/>
			</Col>
		</Grid>
	);
}

export { PlanosLimites };
