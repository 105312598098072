import Button from 'components/Button';
import Col from 'components/Col';
import If from 'components/If';
import { ModalLoadingTransmissao } from 'components/ModalLoadingTransmissao';
import {
	createEmitirBoletos,
	createGerarPixCobrancas,
	createImprimirBoletos,
	createImprimirPixCobrancas,
	readContaComConvenio,
} from 'components/Pagamentos/Requests';
import { TIPO_COBRANCA, TIPO_COBRANCA_OPTIONS } from 'components/Pagamentos/Util/constantes';
import Dropdown from 'components/select/Dropdown';
import SingleSelectConta from 'components/select/SingleSelectConta';
import SingleSelectContaComConvenio from 'components/select/SingleSelectContaComConvenio';
import { Field } from 'formik';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef, useState } from 'react';
import ModalEnviarCobrancasPorEmail from 'views/financas/PixCobranca/components/ModalEnviarCobrancasPorEmail';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { ModalEnviarBoletosPorEmail } from './components/ModalEnviarBoletosPorEmail';

function ActionButtonsBoletos({
	parcelas,
	podeEditarContaReceber,
	podeVisualizarContaReceber,
	refreshParcelasComBoleto,
	refreshParcelasComPix,
}) {
	const { urls, isMobile } = useContextPagamentos();
	const [cobranca, setCobranca] = useState(TIPO_COBRANCA_OPTIONS.find((e) => e.value === TIPO_COBRANCA.BOLETO));
	const [conta, setConta] = useState(null);
	const [convenio, setConvenio] = useState(null);
	const [optionContaConvenios, setOptionContaConvenios] = useState([]);
	const [exibirLoading, setExibirLoading] = useState(false);
	const [messageLoading, setMessageLoading] = useState('Loading...');
	const [dadosPessoaParaEmail, setDadosPessoaParaEmail] = useState(null);
	const [exibirModalEnviarPorEmail, setExibirModalEnviarPorEmail] = useState(false);

	const panelEmissaoBoletos = useRef(null);

	const TITLE_NAO_POSSUI_PERMISSAO = 'Você não possui permissão para executar essa função';

	useEffect(() => {
		fetchContaFavorita(true);
	}, []);

	function todasParcelasTemBoletoOuRecebimento() {
		let result = true;

		if (parcelas.length > 0) {
			parcelas.forEach((parcela) => {
				if (parcela.valor === parcela.valorRestante && !parcela.temBoleto && !parcela.temPix) {
					result = false;
				}
			});
		}

		return result;
	}

	function existemBoletoGerado() {
		const result = {
			value: false,
			count: 0,
		};

		if (parcelas.length > 0) {
			parcelas.forEach((parcela) => {
				if (parcela.temBoleto) {
					result.value = true;
					result.count++;
				}
			});
		}

		return result;
	}

	function existemPixGerado() {
		const result = {
			value: false,
			count: 0,
		};

		if (parcelas.length > 0) {
			parcelas.forEach((parcela) => {
				if (parcela.temPix) {
					result.value = true;
					result.count++;
				}
			});
		}

		return result;
	}

	function getContasReceberIds() {
		const idBoletos = [];
		if (parcelas.length > 0) {
			parcelas.forEach((parcela) => {
				if (!parcela.temBoleto && !parcela.temPix && parcela.valor === parcela.valorRestante) {
					idBoletos.push({ id: parcela.financeiroId });
				}
			});
		}
		return idBoletos;
	}

	function getContasReceberIdsComPix() {
		const idPixs = [];
		if (parcelas.length > 0) {
			parcelas.forEach((parcela) => {
				if (parcela.temPix) {
					idPixs.push({ id: parcela.financeiroId });
				}
			});
		}
		return idPixs;
	}

	function getContasReceberIdsComBoletos() {
		const idBoletos = [];
		if (parcelas.length > 0) {
			parcelas.forEach((parcela) => {
				if (parcela.temBoleto) {
					idBoletos.push({ id: parcela.financeiroId });
				}
			});
		}
		return idBoletos;
	}

	function handleClickGerarBoletos(e) {
		panelEmissaoBoletos.current?.toggle(e);

		setTimeout(() => {
			document.getElementById('select-conta-emissao-boletos')?.getElementsByTagName('input')[0]?.focus();
		}, 250);
	}

	async function emitirBoletos() {
		const contasReceberIds = getContasReceberIds();

		setExibirLoading(true);
		setMessageLoading(`Gerando ${contasReceberIds.length === 1 ? 'boleto' : 'boletos'}...`);

		await createEmitirBoletos(
			convenio.value,
			contasReceberIds,
			async ({ data: pdf }) => {
				const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const boleto = window.open(arquivoURL);
				if (boleto) {
					boleto.onload = () => {
						setTimeout(() => {
							boleto.document.title = contasReceberIds.length === 1 ? 'Boleto' : 'Boletos';
						}, 250);
					};
				}
				setExibirLoading(false);
				refreshParcelasComBoleto(contasReceberIds);
				document
					.getElementById('overlayPanel-emitir-boletos')
					?.getElementsByClassName('p-overlaypanel-close p-link')[0]
					?.click();
			},
			() => setExibirLoading(false)
		);
	}

	async function emitirPixCobranca() {
		const contasReceberIds = getContasReceberIds();

		setExibirLoading(true);
		setMessageLoading(`Gerando ${contasReceberIds.length === 1 ? 'cobrança' : 'cobranças'}...`);

		await createGerarPixCobrancas(
			conta.value,
			contasReceberIds,
			async ({ data: pdf }) => {
				const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const boleto = window.open(arquivoURL);
				if (boleto) {
					boleto.onload = () => {
						setTimeout(() => {
							boleto.document.title = contasReceberIds.length === 1 ? 'Cobrança' : 'Cobranças';
						}, 250);
					};
				}
				setExibirLoading(false);
				refreshParcelasComPix(contasReceberIds);
				document
					.getElementById('overlayPanel-emitir-boletos')
					?.getElementsByClassName('p-overlaypanel-close p-link')[0]
					?.click();
			},
			() => setExibirLoading(false)
		);
	}

	async function handleClickImprimirBoletos() {
		const contasReceberIds = getContasReceberIdsComBoletos();

		setExibirLoading(true);
		setMessageLoading(`Gerando ${contasReceberIds.length === 1 ? 'impressão' : 'impressões'}...`);

		await createImprimirBoletos(
			contasReceberIds,
			({ data: pdf }) => {
				const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const boleto = window.open(arquivoURL);
				if (boleto) {
					boleto.onload = () => {
						setTimeout(() => {
							boleto.document.title = contasReceberIds.length === 1 ? 'Boleto' : 'Boletos';
						}, 250);
					};
				}
				setExibirLoading(false);
			},
			() => setExibirLoading(false)
		);
	}

	async function handleClickImprimirPixCobranca() {
		const contasReceberIds = getContasReceberIdsComPix();

		setExibirLoading(true);
		setMessageLoading(`Gerando ${contasReceberIds.length === 1 ? 'impressão' : 'impressões'}...`);

		await createImprimirPixCobrancas(
			contasReceberIds,
			({ data: pdf }) => {
				const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const cobranca = window.open(arquivoURL);
				if (cobranca) {
					cobranca.onload = () => {
						setTimeout(() => {
							cobranca.document.title = contasReceberIds.length === 1 ? 'Cobrança' : 'Cobranças';
						}, 250);
					};
				}
				setExibirLoading(false);
			},
			() => setExibirLoading(false)
		);
	}

	function handleChangeConta(e) {
		setConta(e);
		mountConveniosOptions(e?.registro?.convenios ?? null);
	}

	async function mountConveniosOptions(convenios) {
		let convenioOptions = [];
		let primeiroConvenio = {};

		if (convenios?.length > 0) {
			convenioOptions = await convenios
				?.filter((convenio) => convenio.situacao === 'ATIVA')
				?.map((convenio) => ({
					label: `${convenio.descricao} - ${convenio.numero}`,
					registro: convenio,
					value: convenio.id,
				}))
				?.sort((a, b) => a.label.localeCompare(b.label));

			if (convenioOptions.length > 0) {
				primeiroConvenio = {
					label: `${convenioOptions[0]?.registro?.descricao} - ${convenioOptions[0]?.registro?.numero}`,
					registro: convenioOptions[0]?.registro,
					value: convenioOptions[0]?.registro?.id,
				};
			}
		}

		setOptionContaConvenios(convenioOptions);
		setConvenio(Object.keys(primeiroConvenio).length > 0 ? primeiroConvenio : null);
	}

	function handleChangeConvenio(e) {
		Object.keys(e)?.length > 0 ? setConvenio(e) : setConvenio({});
	}

	async function fetchContaFavorita(emiteBoleto) {
		await readContaComConvenio(urls.conta, emiteBoleto, async ({ data }) => {
			if (data.page.totalElements > 0) {
				const contaFavorita = {
					label: await montarLabel(data.content[0]),
					registro: data.content[0],
					value: data.content[0].id,
				};
				let primeiroConvenio = null;

				if (data.content[0].convenios?.length > 0) {
					const convenios = await data.content[0].convenios
						?.filter((convenio) => convenio.situacao === 'ATIVA')
						?.sort((a, b) => a.numero.localeCompare(b.numero));

					if (convenios.length > 0) {
						primeiroConvenio = {
							label: `${convenios[0].descricao} - ${convenios[0].numero}`,
							registro: convenios[0],
							value: convenios[0].id,
						};
						mountConveniosOptions(data.content[0].convenios);
					}
				}
				setConta(contaFavorita);
				setConvenio(primeiroConvenio);
			}
		});
	}

	function montarLabel(conta) {
		return conta.nome;
	}

	function handleClickEnviarEmail() {
		setDadosPessoaParaEmail({
			pessoaNome: parcelas[0]?.pessoaNome,
			pessoaCodigo: parcelas[0]?.pessoaCodigo,
			pessoaEmail: parcelas[0]?.pessoaEmail,
		});
		setExibirModalEnviarPorEmail(true);
	}

	function montarLabelImprimirCobranca() {
		if (existemBoletoGerado().value) {
			return existemBoletoGerado().count > 1 ? 'Imprimir boletos' : 'Imprimir boleto';
		} else {
			return 'Imprimir cobrança';
		}
	}

	return (
		<>
			<Button
				label="Gerar cobrança"
				icon="fa fa-plus"
				className="p-button p-button-primary"
				onClick={handleClickGerarBoletos}
				disabled={todasParcelasTemBoletoOuRecebimento() || !podeEditarContaReceber}
				style={{ marginBottom: isMobile ? '5px' : '0px' }}
				title={!podeEditarContaReceber ? TITLE_NAO_POSSUI_PERMISSAO : ''}
			/>
			<Button
				label={montarLabelImprimirCobranca()}
				color="success"
				icon="fa fa-print"
				disabled={
					!todasParcelasTemBoletoOuRecebimento() ||
					(!existemBoletoGerado().value && !existemPixGerado().value) ||
					!podeVisualizarContaReceber
				}
				title={!podeVisualizarContaReceber ? TITLE_NAO_POSSUI_PERMISSAO : ''}
				onClick={existemBoletoGerado().value ? handleClickImprimirBoletos : handleClickImprimirPixCobranca}
				style={{
					marginLeft: isMobile ? '0px' : '5px',
					marginBottom: isMobile ? '5px' : '0px',
				}}
			/>
			<Button
				className="step-listagem-novo"
				label="Enviar por e-mail "
				color="info"
				icon="fa fa-paper-plane"
				disabled={
					!todasParcelasTemBoletoOuRecebimento() ||
					(!existemBoletoGerado().value && !existemPixGerado().value) ||
					!podeVisualizarContaReceber
				}
				title={!podeVisualizarContaReceber ? TITLE_NAO_POSSUI_PERMISSAO : ''}
				onClick={handleClickEnviarEmail}
				style={{
					marginLeft: isMobile ? '0px' : '5px',
				}}
			/>
			<OverlayPanel
				ref={panelEmissaoBoletos}
				id="overlayPanel-emitir-boletos"
				style={{
					width: isMobile ? '90%' : '100%',
					maxWidth: '30.5rem',
					marginLeft: isMobile ? '1rem' : null,
					marginTop: '0px',
				}}
				onHide={() => {}}
				showCloseIcon
			>
				<div className="p-inputgroup" style={{ flexDirection: 'column' }}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="cobranca"
						label="Tipo cobrança"
						component={Dropdown}
						options={TIPO_COBRANCA_OPTIONS}
						style={{ flexDirection: 'row', width: '100%' }}
						showClear={false}
						value={cobranca}
						onChange={(e) => {
							setConta(null);
							setCobranca(e);
							fetchContaFavorita(e.value === 'BOLETO');
						}}
					/>
					<If test={cobranca.value === TIPO_COBRANCA.PIX}>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							name="conta"
							label="Conta"
							component={SingleSelectConta}
							style={{ flexDirection: 'row', width: '100%' }}
							isClearable={false}
							obrigatorio
							onlyRecebePix
							value={conta}
							onChange={handleChangeConta}
							url={urls.conta}
						/>
					</If>
					<If test={cobranca.value === TIPO_COBRANCA.BOLETO}>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							name="conta"
							label="Conta"
							component={SingleSelectContaComConvenio}
							style={{ flexDirection: 'row', width: '100%' }}
							isClearable={false}
							obrigatorio
							value={conta}
							onChange={handleChangeConta}
							url={urls.conta}
						/>
					</If>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="convenio"
						label="Convênio"
						component={Dropdown}
						showClear={false}
						obrigatorio={cobranca.value === TIPO_COBRANCA.BOLETO}
						options={optionContaConvenios}
						value={convenio}
						disabled={cobranca.value !== TIPO_COBRANCA.BOLETO}
						onChange={handleChangeConvenio}
					/>
					<Col sm="12" md="12" lg="12" xl="12">
						<Field
							style={{ width: '100%' }}
							label={cobranca.value === TIPO_COBRANCA.BOLETO ? 'Emitir boletos' : 'Gerar pix cobrança'}
							color="success"
							component={Button}
							disabled={
								cobranca.value === TIPO_COBRANCA.BOLETO
									? !podeEditarContaReceber || conta === null || convenio === null
									: !podeEditarContaReceber || conta === null
							}
							onClick={() => (cobranca.value === TIPO_COBRANCA.BOLETO ? emitirBoletos() : emitirPixCobranca())}
						/>
					</Col>
				</div>
			</OverlayPanel>
			<If test={exibirLoading}>
				<ModalLoadingTransmissao visible={exibirLoading} message={messageLoading} onHide={() => {}} />
			</If>
			<If test={exibirModalEnviarPorEmail && existemBoletoGerado().value}>
				<ModalEnviarBoletosPorEmail
					visible={exibirModalEnviarPorEmail}
					onHide={() => setExibirModalEnviarPorEmail(false)}
					boletos={parcelas}
					boletosIds={getContasReceberIdsComBoletos()}
					dadosPessoaParaEmail={dadosPessoaParaEmail}
				/>
			</If>
			<If test={exibirModalEnviarPorEmail && !existemBoletoGerado().value}>
				<ModalEnviarCobrancasPorEmail
					visible={exibirModalEnviarPorEmail}
					onHide={() => setExibirModalEnviarPorEmail(false)}
					cobrancas={parcelas}
					cobrancasIds={getContasReceberIdsComPix()}
					dadosPessoaParaEmail={dadosPessoaParaEmail}
				/>
			</If>
		</>
	);
}

export { ActionButtonsBoletos };
