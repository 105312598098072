import { useEffect, useState } from 'react';
import Col from 'components/Col';

import './index.css';
import { buscarDisabledDeAcordoComAsPermissoes } from 'Common';

function ColorPicker({
	disabled,
	podeInserir,
	podeEditar,
	podeExcluir,
	estadoCadastro,
	label,
	value,
	onChange,
	onBlur,
	style,
	sm,
	md,
	lg,
	xl,
	...props
}) {
	const [cor, setCor] = useState(value || '#000000');

	useEffect(() => {
		setCor(value || '#000000');
	}, [value]);

	function internalOnChange(event) {
		setCor(event.target.value);
		if (typeof onChange === 'function') {
			onChange(event);
		}
	}

	function internalOnBlur(event) {
		if (typeof onBlur === 'function') {
			onBlur(event);
		}
	}

	return (
		<Col sm={sm} md={md} lg={lg} xl={xl}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{label}</span>
				<input
					{...props}
					id="inputColor"
					type="color"
					disabled={buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled)}
					value={cor}
					onChange={internalOnChange}
					onBlur={internalOnBlur}
					style={{
						...style,
					}}
				/>
			</div>
		</Col>
	);
}

export default ColorPicker;
