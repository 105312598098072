import { useRef } from 'react';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import {
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
	NormalButton,
} from 'components';
import { converterPlanoParaApi } from 'views/administracao/Planos/Util/converter';
import { atualizarUrl, metodosAtualizarUrl, validarFormulario, voltarParaAPesquisa } from 'views/Util';
import { CADASTROURL, INITIAL_VALUES, PESQUISAURL } from '../../../Util/constantes';
import { asyncCreateAdmPlano, asyncDeleteAdmPlano, asyncUpdateAdmPlano } from '../../Requests';

function ActionButtons({ informacoesPermissoes, handleIlimitado, isModal, hideModal, estruturaTree, history }) {
	const { dirty, resetForm, values, handleSubmit, validateForm, setFieldValue } = useFormikContext();
	const menuOpcoes = useRef(null);

	const itensOpcoes = buscaritensOpcoes();
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;

	function cancelar() {
		if (dirty) {
			handleIlimitado(false);
			resetForm({ values: INITIAL_VALUES });
		} else if (isModal) {
			fecharModal();
		} else {
			voltarParaAPesquisa(history, PESQUISAURL);
		}
	}

	function novo() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: INITIAL_VALUES });
		handleIlimitado(false);
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (values.id) {
				await asyncUpdateRegistro(converterPlanoParaApi(validarValor(values), estruturaTree), novoOnSuccess);
			} else {
				await asyncCreateRegistro(converterPlanoParaApi(validarValor(values), estruturaTree), novoOnSuccess);
			}
			if (hideModal) {
				fecharModal();
			}
		}
	}

	function validarValor(plano) {
		if (plano.valor === null || plano.valor < 0) {
			return {
				...plano,
				valor: 0,
			};
		}
		return plano;
	}

	async function asyncUpdateRegistro(plano, novoOnSuccess) {
		await asyncUpdateAdmPlano(plano, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				resetForm({ values: values });
			}
		});
	}

	async function asyncCreateRegistro(plano, novoOnSuccess) {
		if (plano) {
			await asyncCreateAdmPlano(plano, async ({ data: plano }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({ values: { ...values, id: plano.id } });
					if (!isModal) {
						atualizarUrl(history, CADASTROURL, plano.id, metodosAtualizarUrl.POP);
					}
				}
			});
		}
	}

	function excluir() {
		confirm('Confirmação', 'Tem certeza que deseja excluir o registro?', async () => {
			await asyncDeleteRegistro();
		});
	}

	async function asyncDeleteRegistro() {
		await asyncDeleteAdmPlano(values.id, () => {
			resetForm();
			voltarParaAPesquisa(history, PESQUISAURL);
		});
	}

	function buscaritensOpcoes() {
		const itens = [];
		if (values.id) {
			itens.push({
				label: 'Duplicar',
				icon: 'fa fa-copy',
				command: () => {
					handleClickDuplicar();
				},
			});
		}
		return itens;
	}

	function handleClickDuplicar() {
		atualizarUrl(history, CADASTROURL, null, metodosAtualizarUrl.POP);
		resetForm({ values: { ...values, id: null } });
		setFieldValue('descricao', '');
		setTimeout(() => {
			document.getElementById('PlanosInputFieldDescricao')?.focus();
		}, 500);
	}

	function fecharModal() {
		if (values.id) {
			hideModal(values);
		} else {
			hideModal();
		}
	}

	return (
		<>
			<ButtonCancelar
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
				{...informacoesPermissoes}
			/>
			<ButtonSalvar
				estadoBotao={estadosBotaoSalvar.SALVAR}
				disabled={!dirty}
				onClick={() => salvar()}
				{...informacoesPermissoes}
			/>
			<ButtonNovo
				onClick={onClickNovo}
				hidden={(!dirty && !values.id) || isModal}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir hidden={!values.id} onClick={() => excluir()} {...informacoesPermissoes} />
			<NormalButton
				className="p-button-primary"
				type="button"
				label="Opções"
				icon="fa fa-list"
				style={{ margin: '5px' }}
				hidden={itensOpcoes.length === 0}
				onClick={(event) => menuOpcoes.current?.toggle(event)}
			/>
			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menuOpcoes} />
		</>
	);
}

export { ActionButtons };
