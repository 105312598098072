import { TabPanel } from 'primereact/tabview';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import {
	DADOSLOCALSTORAGE,
	buscarDadosLoginLocalStorage,
	buscarItemLocalStorage,
	permissoes,
	recursos,
	salvarItemLocalStorage,
	sincronizarInformacoesLogin,
	usePrevious,
	usuarioPossuiPermissao,
} from 'Common';
import { DateInterval, Grid, TabView } from 'components';
import { asyncGetInformacoesGeraisPlano } from '../../configuracao/Planos/Requests';
import { MessageDiasRestantes } from '../../configuracao/Planos/components/MessageDiasRestantes';
import { MessageOrganizacaoBloqueada } from '../../configuracao/Planos/components/MessageOrganizacaoBloqueada';
import { MessagePagamentoNaoEfetuado } from '../../configuracao/Planos/components/MessagePagamentoNaoEfetuado';
import { MessagePlanoExcedido } from '../../configuracao/Planos/components/MessagePlanoExpirado';

import { endOfMonth, startOfMonth } from 'date-fns';
import GraficoSemPermissaoDashboard from './Images/GraficoSemPermissaoDashboard.svg';
import './Styles/index.css';
import DashboardCompras from './components/DashboardCompras';
import DashboardDocumentosDigitais from './components/DashboardDocumentosDigitais';
import DashboardEstoque from './components/DashboardEstoque';
import DashboardFinancas from './components/DashboardFinancas';
import { DashboardServicos } from './components/DashboardServicos';
import { DashboardTransportes } from './components/DashboardTransportes';
import { DashboardVendas } from './components/DashboardVendas';

function Dashboard(props) {
	const { dataAtualizacaoMenuLateral, isMobile } = props;

	const [dataInicial, setDataInicial] = useState(startOfMonth(new Date()));
	const [dataFinal, setDataFinal] = useState(endOfMonth(new Date()));
	const [renderizarMessageDiasRestantes, setRenderizarMessageDiasRestantes] = useState(false);
	const [organizacaoBloqueada, setOrganizacaoBloqueada] = useState(
		buscarDadosLoginLocalStorage()?.organizacao?.bloqueada
	);
	const [meioPagamentoOrganizacao, setMeioPagamentoOrganizacao] = useState(
		buscarDadosLoginLocalStorage()?.organizacao?.meioPagamento
	);
	const podeVisualizarFinancas = usuarioPossuiPermissao(recursos.FINANCAS_DASHBOARD, permissoes.VISUALIZAR);
	const podeVisualizarDocumentos = usuarioPossuiPermissao(recursos.DOCUMENTOS_DASHBOARD, permissoes.VISUALIZAR);
	const podeVisualizarCompras = usuarioPossuiPermissao(recursos.COMPRAS_DASHBOARD, permissoes.VISUALIZAR);
	const podeVisualizarVendas =
		usuarioPossuiPermissao(recursos.VENDAS_DASHBOARD, permissoes.VISUALIZAR) &&
		(usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.VISUALIZAR) ||
			usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR) ||
			usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR) ||
			usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR));
	const podeVisualizarEstoque = usuarioPossuiPermissao(recursos.ESTOQUE_DASHBOARD, permissoes.VISUALIZAR);
	const podeVisualizarServicos =
		usuarioPossuiPermissao(recursos.SERVICOS_DASHBOARD, permissoes.VISUALIZAR) &&
		(usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR) ||
			usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR));
	const podeVisualizarTransportes = usuarioPossuiPermissao(recursos.TRANSPORTE_DASHBOARD, permissoes.VISUALIZAR);	
	const [tabSelecionada, setTabSelecionada] = useState(null);
	const [firstRender, setFirstRender] = useState(true);		
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(async() => {		
		if (tabSelecionada === -1){					
			await buscaInformacoesIniciais();					
			setTabSelecionada(buscarTabInicial());								
		}
	}, [tabSelecionada]);
	
	useEffect(() => {						
		setFirstRender(false);				
		if (dataAtualizacaoMenuLateral){
			setTimeout(()=>{					
				setTabSelecionada(-1);		
			}, 1000);
		}else{			
			if (firstRender){
				setTabSelecionada(buscarTabInicial());								
				buscaInformacoesIniciais();					
			}			
		}		
	}, [firstRender]);

	useEffect(() => {		
		if (dataAtualizacaoMenuLateral){				
			setFirstRender(true);			
		}		
	}, [dataAtualizacaoMenuLateral]);

	async function buscaInformacoesIniciais(){
		await sincronizarInformacoesLogin();
		await asyncGetInformacoesGeraisPlano(({ data }) => {
			let MessageDiasRestantes = data.valorPlanoAtual > 0 && data.efetuarPagamentoAoAderir && !organizacaoBloqueada;
			setRenderizarMessageDiasRestantes(MessageDiasRestantes);
		});

		if (afterFirstRenderRef) {
			if (prevProps?.dataAtualizacaoMenuLateral !== dataAtualizacaoMenuLateral) {
				await asyncGetInformacoesGeraisPlano(({ data }) => {
					let MessageDiasRestantes = data.valorPlanoAtual > 0 && data.efetuarPagamentoAoAderir && !organizacaoBloqueada;
					setRenderizarMessageDiasRestantes(MessageDiasRestantes);
					setOrganizacaoBloqueada(buscarDadosLoginLocalStorage()?.organizacao?.bloqueada);
				});
			}
		} else afterFirstRenderRef = true;
	}

	function buscarTabInicial() {
		const tabViewSalva = Number(buscarItemLocalStorage(DADOSLOCALSTORAGE.tabviewDashboard, false) || 0);

		let tabsDisponiveis = [];
		if (podeVisualizarCompras) {
			tabsDisponiveis.push(0);
		}
		if (podeVisualizarEstoque) {
			tabsDisponiveis.push(1);
		}
		if (podeVisualizarVendas) {
			tabsDisponiveis.push(2);
		}
		if (podeVisualizarServicos) {
			tabsDisponiveis.push(3);
		}
		if (podeVisualizarTransportes) {
			tabsDisponiveis.push(4);
		}
		if (podeVisualizarFinancas) {
			tabsDisponiveis.push(5);
		}
		if (podeVisualizarDocumentos) {
			tabsDisponiveis.push(6);
		}

		if (tabsDisponiveis.length === 0) {
			return null;
		} else if (tabsDisponiveis.includes(tabViewSalva)) {
			return tabViewSalva;
		} else {
			return tabsDisponiveis[0];
		}
	}

	function onChangePeriodo(dataInicial, dataFinal) {
		setDataInicial(dataInicial);
		setDataFinal(dataFinal);
	}

	function renderValidationMessage() {
		if (meioPagamentoOrganizacao === 'SISTEMA_COBRANCA') {
			if (organizacaoBloqueada) {
				return <MessagePlanoExcedido />;
			} else if (renderizarMessageDiasRestantes) {
				return <MessageDiasRestantes />;
			}
		} else if (meioPagamentoOrganizacao === 'BOLETO_BANCARIO' && organizacaoBloqueada) {
			return <MessagePagamentoNaoEfetuado />;
		} else if (organizacaoBloqueada) {
			return <MessageOrganizacaoBloqueada />;
		}
	}

	function possuiDashboardParaMostrar() {			
		//acrescentar novos dashboards no if
		if (
			podeVisualizarCompras ||
			podeVisualizarDocumentos ||
			podeVisualizarFinancas ||
			podeVisualizarVendas ||
			podeVisualizarServicos ||
			podeVisualizarEstoque ||
			podeVisualizarTransportes
		) {
			return true;
		}
		return false;
	}

	function onChangeTabView(e) {
		salvarItemLocalStorage(DADOSLOCALSTORAGE.tabviewDashboard, e.index, false);
		setTabSelecionada(e.index);
	}

	if (possuiDashboardParaMostrar()) {
		return (
			<>
				{renderValidationMessage()}

				<Grid justifyCenter style={{ padding: '0px', margin: '0px' }}>
					<DateInterval 
						interval={{dataInicial: dataInicial, dataFinal: dataFinal}} 
						onChange={(interval) => onChangePeriodo(interval.dataInicial, interval.dataFinal)} 
					/>
				</Grid>

				<div
					style={{
						margin: '0px',
						padding: '0px',
						maxWidth: '100vw',
					}}
				>
					<TabView
						className={`${'tab-view-dashboard'} ${isMobile ? 'tab-view-dashboard-mobile' : null}`}
						activeIndex={tabSelecionada}
						onTabChange={onChangeTabView}
						renderActiveOnly
						style={{ padding: '0px' }}
					>
						<TabPanel header="Compras" headerStyle={!podeVisualizarCompras ? { display: 'none' } : null}>
							<DashboardCompras dataInicial={dataInicial} dataFinal={dataFinal} />
						</TabPanel>
						<TabPanel header="Estoque" headerStyle={!podeVisualizarEstoque ? { display: 'none' } : null}>
							<DashboardEstoque dataInicial={dataInicial} dataFinal={dataFinal} />
						</TabPanel>
						<TabPanel header="Vendas" headerStyle={!podeVisualizarVendas ? { display: 'none' } : null}>
							<DashboardVendas dataInicial={dataInicial} dataFinal={dataFinal} />
						</TabPanel>
						<TabPanel header="Serviços" headerStyle={!podeVisualizarServicos ? { display: 'none' } : null}>
							<DashboardServicos dataInicial={dataInicial} dataFinal={dataFinal} />
						</TabPanel>
						<TabPanel header="Transportes" headerStyle={!podeVisualizarTransportes ? { display: 'none' } : null}>
							<DashboardTransportes dataInicial={dataInicial} dataFinal={dataFinal} />
						</TabPanel>
						<TabPanel header="Finanças" headerStyle={!podeVisualizarFinancas ? { display: 'none' } : null}>
							<DashboardFinancas dataInicial={dataInicial} dataFinal={dataFinal} />
						</TabPanel>
						<TabPanel header="Documentos" headerStyle={!podeVisualizarDocumentos ? { display: 'none' } : null}>
							<DashboardDocumentosDigitais dataInicial={dataInicial} dataFinal={dataFinal} />
						</TabPanel>
					</TabView>
				</div>
			</>
		);
	} else {
		return (
			<>
				{renderValidationMessage()}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '20%',
					}}
				>
					<div>
						<img src={GraficoSemPermissaoDashboard} alt="Nenhuma informação a ser exibida" width="130" height="82" />
					</div>
					<div style={{ textAlign: 'center' }}>
						<span style={{ fontWeight: '600' }}>{'Nenhuma informação a ser exibida'}</span>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	dataAtualizacaoMenuLateral: state.menuLateral.dataAtualizacaoMenuLateral,
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Dashboard);
