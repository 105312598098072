function converterNovoTituloParaApi(titulo) {
	return {
		identificador: titulo.identificador ?? null,
		status: titulo.status,
		origem: titulo.origem ?? null,
		tipo: titulo.tipo ?? null,
		ocorrencia: titulo.ocorrencia ?? null,
		emissao: titulo.emissao ?? null,
		vencimento: titulo.vencimento ?? null,
		competencia: titulo.competencia ?? null,
		valor: titulo.valor ?? null,
		numeroDocumento: titulo.numeroDocumento ?? null,
		dataDocumento: titulo.dataDocumento ?? null,
		descricao: titulo.descricao ?? null,
		observacao: titulo.observacao ?? null,
		quantidadeRepeticoes: titulo.quantidadeRepeticoes ?? null,
		itemRepeticao: titulo.itemRepeticao ?? null,
		categorias: converterCategoriasParaApi(titulo.categoria),
		pessoa: titulo.pessoa ? { id: titulo.pessoa.value } : null,
		recebimentos: titulo.recebimentos ?? [],
		valorAReceber: titulo.valorTitulosNovos ?? null,
		boleto: titulo.boleto ?? null,
		pix: titulo.pix ?? null,
		formaPagamento: titulo.formaPagamento ? { id: titulo.formaPagamento.value } : null,
		conta: titulo.conta ? { id: titulo.conta.value } : null,
		compensarAutomaticamente: titulo.compensacaoAutomatica ?? false,
		dataCompensacao: titulo.dataCompensacao ?? null,
		taxaCategoria: titulo.taxaCategoria ? { id: titulo.taxaCategoria.value ?? titulo.taxaCategoria } : null,
		taxaValor: titulo.taxaValor ?? null,
		taxaAliquota: titulo.taxaAliquota ?? null,
		tipoCompensacao: titulo.tipoCompensacao ?? null,
		tipoTaxa: titulo.tipoTaxa ?? null,
	};
}

function converterCategoriasParaApi(categorias) {
	if (categorias) {
		return categorias.map((categoria) => ({
			id: null,
			categoria: { id: categoria.categoria?.value },
			percentual: categoria.percentual,
			valor: categoria.valor,
		}));
	}

	return [];
}

export { converterNovoTituloParaApi };
