import { format, formatISO, parseISO } from 'date-fns';
import { optionsTipoEmissao } from 'views/fiscal/Util/constantes';
import { converterQuantidadeParcelas, converterValueParaSelect } from 'components/body/Vendas/Util/functions';
import { buscarDadosLoginLocalStorage, gerarUUID } from '../../../../../../Common';
import { TipoDestinatario, idDest } from '../../../../../../components/header/Util/constantes';
import { montarLabelPessoa } from '../../../../../../components/select/SingleSelectPessoa/Util';
import { Status } from '../../Util/constantes';

const EMITENTE = buscarDadosLoginLocalStorage().filialConectada;

function converterNFeParaFormulario(values) {
	return {
		id: values.id,
		numero: values.nNF ?? null,
		serie: values.serie ?? null,
		chave: values.chNfe ?? null,
		status: values.status,
		tipo: 'NOTA_FISCAL',
		tipoOperacao: values.tpNF,
		naturezaOperacao: converterNaturezaOperacaoParaFormulario(values.natOp),
		finalidadeDocumento: converterFinalidadeDocumentoParaFormulario(values.finNfe),
		indicadorPresenca: values.indPres,
		pessoa: converterPessoaDestinatarioParaFormulario(values.dest, values.indFinal, values.idDest),
		dataSaida: values.dhSaiEnt,
		dataEmissao: values.dhEmi,
		operacaoFiscal: converterSelectParaFormulario(values.operacaoFiscal),
		emitente: EMITENTE,
		previsaoEntrega: values.previsaoEntrega ?? null,
		tipoDesconto: values.tipoDesconto === 'NAO_APLICADO' ? 'VALOR' : values.tipoDesconto,
		pedidoId: values.venda?.id ?? null,
		gerouFinanceiro: !(values.venda && values.venda.gerouFinanceiro),
		pedidoNumero: values.venda?.numero ?? null,
		NFref: converterDocumentosReferenciadosParaFormulario(values.NFref),
		comissaoTotalPercentual: values.comissaoTotalPercentual,
		comissaoTotalValor: values.comissaoTotalValor,

		produtos: converterDetalhesParaFormulario(values.det),

		totalizadores: {
			totalProdutos: values.total.ICMSTot.vProd,
			totalTributos: values.total.ICMSTot.vIPI + values.total.ICMSTot.vST ?? 0,
			totalFrete: values.total.ICMSTot.vFrete ?? 0,
			totalSeguro: values.total.ICMSTot.vSeg ?? 0,
			totalAcessorias: values.total.ICMSTot.vOutro ?? 0,
			percentualDesconto: 0,
			totalDesconto: values.total.ICMSTot.vDesc ?? 0,
			totalLiquido: values.total.ICMSTot.vNF,
			totalIpiDevolvido: values.total.ICMSTot.vIPIDevol ?? 0,
		},

		condicao: values.cobr ? values.cobr.condicao : null,
		idCobranca: values.cobr ? values.cobr.id : null,
		idFatura: values.cobr ? values.cobr.fat?.id : null,
		quantidadeParcelas: values.cobr ? values.cobr.quantidadeParcelas : null,
		conta: values.cobr && converterContaParaFormulario(values.cobr),
		pagamentos: converterPagamentosParaFormulario(values.pagamentos, values.dest),
		transporte: {
			modalidadeFrete: values.transp.modFrete,
			transportador: converterTransportadoraParaFormulario(values.transp?.pessoa, values.transp?.transporta),
			veiculo: {
				placa: values.transp?.veicTransp?.placa,
				uf: converterEstadoVeiculoParaFormulario(values.transp?.veicTransp),
				RNTC: values.transp?.veicTransp?.RNTC,
			},
			volumes: converterVolumeTransporteParaFormulario(values.transp?.vol),
		},

		informacoesComplementares: {
			vendedor: converterVendedorParaFormulario(values.vendedor),
			tabelaPreco: converterSelectParaFormulario(values.tabelaPreco),
			informacaoComplementar: values.infAdic.infAdicFisco ?? null,
			observacaoInterna: values.infAdic.infCpl ?? null,
			setor: converterSetorParaFormulario(values.setor),
		},
		historico: values.historico,

		correcoes: converterCorrecoesParaFormulario(values?.correcoes),

		xJustCancelamento: values.xJustCancelamento ?? null,
		dataCancelamento: values.dhCancelamento ?? null,

		erros: values?.erros ?? [],

		validacaoRecarregaProdutoPeloCliente: false,

		exportacao: {
			ufEmbarque: values.exporta?.ufSaidaPais ? converterEstadoParaFormulario(values.exporta?.ufSaidaPais) : null,
			localEmbarque: values.exporta?.xLocDespacho ?? null,
			localExportacao: values.exporta?.xLocExporta ?? null,
		},
	};
}

function converterSetorParaFormulario(setor) {
	if (setor) {
		return {
			label: setor.nome,
			value: setor.id,
		};
	}
	return null;
}

function converterNaturezaOperacaoParaFormulario(natOp) {
	if (natOp) {
		return {
			label: natOp.descricao,
			value: natOp.id,
		};
	}
	return null;
}

function converterFinalidadeDocumentoParaFormulario(finNfe) {
	let finalidadeDocumento = {};
	optionsTipoEmissao.forEach((optionFinNfe) => {
		if (optionFinNfe.value === finNfe) {
			finalidadeDocumento = optionFinNfe;
		}
	});
	return finalidadeDocumento;
}

function converterPagamentosParaFormulario(pagamentos, cliente) {
	const pagamentosFormulario = pagamentos.map((pagamento) => ({
			id: pagamento.id,
			tempKey: gerarUUID(),
			sequencial: pagamento.sequencial,
			condicaoPagamento: converterValueParaSelect(pagamento.condicaoPagamento),
			quantidadeParcelas: converterQuantidadeParcelas(pagamento.quantidadeParcelas),
			categorias: converterPagamentoCategoriasParaFormulario(pagamento.categorias),
			formaPagamento: converterValueParaSelect(pagamento.formaPagamento),
			conta: converterValueParaSelect(pagamento.conta),
			valor: pagamento.valor,
			parcelas: converterParcelasParaFormulario(pagamento.parcelas, cliente),
		}));
	return pagamentosFormulario;
}

function converterPagamentoCategoriasParaFormulario(values){
	const categorias = [];
	if (values) {
		values?.forEach(registro =>{
			const nfeCategoria = {
				categoria: converterCategoriaParaFormulario(registro.categoria),
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(nfeCategoria);
		});
	}
	return categorias;
}

function converterCategoriaParaFormulario(categoriaApi) {
	if (categoriaApi) {
		return {
			label: categoriaApi.nome,
			value: categoriaApi.id,
			registro: categoriaApi,
		};
	}
	return null;
}

function converterPessoaParaFormulario(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoaFormulario(pessoaApi),
			value: pessoaApi.id,
			registro: pessoaApi,
		};
	}

	return null;
}

function montarLabelPessoaFormulario(pessoa) {
	let complemento = '';
	if (pessoa.pessoaJuridica?.cnpj) {
		complemento = ` - ${pessoa.pessoaJuridica?.cnpj}`;
	} else if (pessoa.pessoaFisica?.cpf) {
		complemento = ` - ${pessoa.pessoaFisica?.cpf}`;
	} else if (pessoa.pessoaEstrangeira?.identificacao) {
		complemento = ` - ${pessoa.pessoaEstrangeira?.identificacao}`;
	}

	return `${pessoa.nome}${complemento}`;
}

function converterPessoaDestinatarioParaFormulario(pessoaApi, indFinal, idDest) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoaDestinatarioFormulario(pessoaApi),
			value: pessoaApi.idDestinatario,
			registro: {
				id: pessoaApi.idDestinatario,
				codigo: pessoaApi.codigoDestinatario,
				nome: pessoaApi.xNome,
				cliente: pessoaApi.cliente ?? null,
				fornecedor: pessoaApi.fornecedor ?? null,
				pessoaJuridica: pessoaApi?.CNPJ ? { cnpj: pessoaApi.CNPJ } : null,
				pessoaFisica: pessoaApi?.CPF ? { cpf: pessoaApi.CPF } : null,
				consumidorFinal: indFinal === 'CONSUMIDOR_FINAL',
				email: [pessoaApi.email ?? null],
				idEstrangeiro: pessoaApi?.idEstrangeiro,
				enderecos: [
					{
						bairro: pessoaApi.enderDest?.xBairro,
						cep: pessoaApi.enderDest?.CEP,
						complemento: pessoaApi.enderDest?.xCpl,
						favorito: true,
						logradouro: pessoaApi.enderDest?.xLgr,
						numero: pessoaApi.enderDest?.nro,
						municipio: {
							codigo: pessoaApi.enderDest?.cMun,
							estadoSigla: pessoaApi.enderDest?.UF,
							id: pessoaApi.enderDest?.idMun,
							nome: pessoaApi.enderDest?.xMun,
							ufId: pessoaApi.enderDest?.idEst,
						},
						pais: {
							codigo: pessoaApi.enderDest?.cPais,
							id: pessoaApi.enderDest?.idPais,
							nome: pessoaApi.enderDest?.xPais,
						},
					},
				],
				telefone: pessoaApi.telefone,
				tipo: pessoaApi.tipo,
				indicadorInscricaoEstadual: pessoaApi.indIEDest,
				inscricaoEstadual: pessoaApi.IE,
				destinoOperacao: idDest,
			},
		};
	}
	return null;
}

function montarLabelPessoaDestinatarioFormulario(pessoa) {
	let complemento = '';
	if (pessoa.CNPJ) {
		complemento = ` - ${pessoa.CNPJ}`;
	} else if (pessoa.CPF) {
		complemento = ` - ${pessoa.CPF}`;
	} else if (pessoa.idEstrangeiro) {
		complemento = ` - ${pessoa.idEstrangeiro}`;
	}

	if (pessoa.codigo) {
		return `${pessoa.codigoDestinatario} - ${pessoa.xNome}${complemento}`;
	}
	return `${pessoa.xNome}${complemento}`;
}

function converterContaParaFormulario(cobr) {
	if (cobr) {
		return {
			label: cobr.conta?.nome,
			registro: cobr.conta,
			value: cobr.conta?.id,
		};
	}
	return null;
}

function converterParcelasParaFormulario(parcelas, cliente) {
	if (parcelas?.length > 0) {
		const parcelasConvertidas = [];
		parcelas.forEach((parcela) => {
			parcelasConvertidas.push({
				id: parcela.id,
				tempKey: gerarUUID(),
				sequencial: parcela.sequencial,
				vencimento: parcela.vencimento,
				formaPagamento: converterValueParaSelect(parcela.formaPagamento),
				conta: converterValueParaSelect(parcela.conta),
				descricao: parcela.descricao,
				valor: parcela.valor,
				temBoleto: parcela.temBoleto,
				temPix: parcela.temPix,
				financeiroIdentificador: parcela.financeiroIdentificador,
				financeiroId: parcela.financeiroId,
				financeiroStatus: parcela.financeiroStatus,
				valorRestante: parcela.valorRestante,
				pessoaNome: cliente.xNome,
				pessoaCodigo: cliente.codigoDestinatario,
				pessoaEmail: cliente.email,
			});
		});
		return parcelasConvertidas;
	}
	return [];
}

function converterDocumentosReferenciadosParaFormulario(notasReferenciadas) {
	const NFref = [];
	notasReferenciadas?.forEach((nota) => {
		NFref.push({
			caixa: nota.caixa,
			cnpjCpf: nota.cnpjCpf,
			dataEmissao: nota.emissao,
			inscricaoEstadual: nota.ie,
			modelo: nota.mod,
			numeroCoo: nota.nCoo,
			numeroEcf: nota.nEcf,
			numeroNF: nota.nNF,
			chaveNFe: nota.refNFe,
			serie: nota.serie,
			uf: nota.uf,
		});
	});
	return NFref;
}

function converterDetalhesParaFormulario(itensApi) {
	const itensFormulario = itensApi?.map((item) => ({
			id: item.id,
			item: item.nItem,
			produto: converterProdutoParaFormulario(item),
			descricao: item?.infAdProd ?? null,

			cfop: converterSelectParaFormulario({
				id: item.prod?.idCFOP,
				codigo: item.prod?.CFOP,
				descricao: item.prod?.xCFOP,
			}),
			operacaoFiscal: converterSelectParaFormulario(item.operacaoFiscal),
			ncm: converterSelectParaFormulario({
				id: item.prod?.idNCM,
				codigo: item.prod?.NCM,
				descricao: item.prod?.xNCM,
			}),
			unidadeMedida: converterSelectParaFormulario({
				id: item.prod?.idCom,
				codigo: item.prod?.uCom,
				descricao: item.prod?.xCom,
			}),
			cest:
				converterSelectParaFormulario({
					id: item.prod?.idCEST,
					codigo: item.prod?.CEST,
					descricao: item.prod?.xCEST,
				}) ?? null,
			codigoBeneficiario: item.prod?.codigoBeneficiario ?? null,
			numeroPedidoCompra: item.prod?.xPed ?? null,
			itemPedidoCompra: item.prod?.nItemPed ?? null,

			quantidade: item.prod?.qCom ?? 0,
			desconto: item.prod?.vDesc ?? 0,
			valor: item.prod?.vUnCom ?? 0,
			valorOriginal: item.prod?.valorOriginal ?? 0,

			acessorias: item.prod?.vOutro ?? 0,
			seguro: item.prod?.vSeg ?? 0,
			frete: item.prod?.vFrete ?? 0,
			descontoMaximoPercentual: item.prod?.descontoMaximoPercentual ?? 0,
			descontoMaximoVendedor: item.descontoMaximoVendedor ?? 0,
			descontoMaximoTabela: item.descontoMaximoVendedor ?? 0,
			comissaoValor: item.prod?.comissaoValor ?? 0,
			comissaoPercentual: item.prod?.comissaoPercentual ?? 0,
			utilizouTabelaPromocional: item.prod?.utilizouTabelaPromocional,
			adicionalTabelaPreco: {
				percentual: item.prod?.percentualAdicionalTabelaPreco,
				tipo: item.prod?.tipoPercentualAdicionalTabelaPreco,
			},

			tributos: {
				icms: {
					id: item.imposto.icms?.id ?? null,
					cstCsosn: converterSelectParaFormulario({
						id: item.imposto.icms?.cstCsosn?.id,
						codigo: item.imposto.icms?.cstCsosn?.codigo,
						descricao: item.imposto.icms?.cstCsosn?.descricao,
					}),
					origemProduto: item.imposto.icms?.origem,
					percentualCreditoSimplesNacional: item.imposto.icms?.aliquotaCreditoSimplesNacional ?? 0,
					valorCreditoSimplesNacional: item.imposto.icms?.valorCreditoSimplesNacional ?? 0,
					percentualBaseIcms: item.imposto.icms?.percentualBaseCalculo ?? 0,
					percentualReducaoBaseIcms: item.imposto.icms?.percentualReducaobaseCalculo ?? 0,
					modalidadeCalculo: item.imposto.icms?.modalidadeCalculo ?? 0,
					baseIcms: item.imposto.icms?.valorBaseCalculo ?? 0,
					percentualIcms: item.imposto.icms?.aliquota ?? 0,
					valorIcms: item.imposto.icms?.valor ?? 0,
					valorIcmsSubstituto: item.imposto.icms?.valorSubstituto ?? 0,
					modalidadeCalculoSt: item.imposto.icms?.modalidadeCalculoSt ?? 0,
					percentualMva: item.imposto.icms?.percentualMva ?? 0,
					valorPauta: item.imposto.icms?.valorPauta ?? 0,
					percentualBaseIcmsSt: item.imposto.icms?.percentualBaseCalculoSt ?? 0,
					percentualReducaoBaseIcmsSt: item.imposto.icms?.percentualReducaoBaseCalculoSt ?? 0,
					baseIcmsSt: item.imposto.icms?.valorBaseCalculoSt ?? 0,
					percentualBaseIcmsStRetido: item.imposto.icms?.percentualBaseCalculoStRetido ?? 0,
					percentualIcmsStRetido: item.imposto.icms?.aliquotaStRetido ?? 0,
					percentualIcmsSt: item.imposto.icms?.aliquotaSt ?? 0,
					valorIcmsSt: item.imposto.icms?.valorSt ?? 0,
					baseRetidoSt: item.imposto.icms?.valorBaseCalculoStRetido ?? 0,
					valorRetidoSt: item.imposto.icms?.valorStRetido ?? 0,
					percentualDiferimento: item.imposto.icms?.percentualDiferido ?? 0,
					valorDiferimento: item.imposto.icms?.valorDiferido ?? 0,
					baseFundoCombatePobreza: item.imposto.icms?.valorBaseCalculoFcPobreza ?? 0,
					percentualFundoCombatePobreza: item.imposto.icms?.percentualFcPobreza ?? 0,
					valorFundoCombatePobreza: item.imposto.icms?.valorFcPobreza ?? 0,
					quantidadeBaseIcmsMonofasicoRetido: item.imposto.icms?.quantidadeBaseMonofasicoRetido ?? 0,
					percentualIcmsMonofasicoRetido: item.imposto.icms?.percentualMonofasicoRetido ?? 0,
					valorIcmsMonofasicoRetido: item.imposto.icms?.valorMonofasicoRetido ?? 0,
				},
				ipi: {
					id: item.imposto.ipi?.id ?? null,
					enquadramentoIpi: converterSelectParaFormulario({
						id: item.imposto.ipi?.enquadramento?.id,
						codigo: item.imposto.ipi?.enquadramento?.codigo,
						descricao: item.imposto.ipi?.enquadramento?.descricao,
					}),
					cst: converterSelectParaFormulario({
						id: item.imposto.ipi?.cst?.id,
						codigo: item.imposto.ipi?.cst?.codigo,
						descricao: item.imposto.ipi?.cst?.descricao,
					}),
					percentualBaseIpi: item.imposto.ipi?.percentualBaseCalculo ?? 0,
					baseIpi: item.imposto.ipi?.valorBaseCalculo ?? 0,
					percentualIpi: item.imposto.ipi?.aliquota ?? 0,
					valorIpi: item.imposto.ipi?.valor ?? 0,
				},
				pis: {
					id: item.imposto.pis?.id ?? null,
					cst: converterSelectParaFormulario({
						id: item.imposto.pis?.cst?.id,
						codigo: item.imposto.pis?.cst?.codigo,
						descricao: item.imposto.pis?.cst?.descricao,
					}),
					percentualBasePis: item.imposto.pis?.percentualBaseCalculo ?? 0,
					basePis: item.imposto.pis?.valorBaseCalculo ?? 0,
					percentualPis: item.imposto.pis?.aliquota ?? 0,
					valorPis: item.imposto.pis?.valor ?? 0,
					percentualBasePisSt: item.imposto.pis?.percentualBaseCalculoSt ?? 0,
					basePisSt: item.imposto.pis?.valorBaseCalculoSt ?? 0,
					percentualPisSt: item.imposto.pis?.aliquotaSt ?? 0,
					valorPisSt: item.imposto.pis?.valorSt ?? 0,
				},
				cofins: {
					id: item.imposto.cofins?.id ?? null,
					cst: converterSelectParaFormulario({
						id: item.imposto.cofins?.cst?.id,
						codigo: item.imposto.cofins?.cst?.codigo,
						descricao: item.imposto.cofins?.cst?.descricao,
					}),
					percentualBaseCofins: item.imposto.cofins?.percentualBaseCalculo ?? 0,
					baseCofins: item.imposto.cofins?.valorBaseCalculo ?? 0,
					percentualCofins: item.imposto.cofins?.aliquota ?? 0,
					valorCofins: item.imposto.cofins?.valor ?? 0,
					percentualBaseCofinsSt: item.imposto.cofins?.percentualBaseCalculoSt ?? 0,
					baseCofinsSt: item.imposto.cofins?.valorBaseCalculoSt ?? 0,
					percentualCofinsSt: item.imposto.cofins?.aliquotaSt ?? 0,
					valorCofinsSt: item.imposto.cofins?.valorSt ?? 0,
				},
				devolvido: {
					percentualDevolvido: item.impostoDevolvido?.percentualDevolvido ?? 100,
					valorIpiDevolvido: item.impostoDevolvido?.ipi?.valor ?? 0,
				},
			},

			importacao: {
				numeroDocumentoImportacao: item.prod?.importacao?.numeroDocumentoImportacao ?? null,
				dataRegistro: item.prod?.importacao?.dataRegistro ?? null,
				dataDesembaraco: item.prod?.importacao?.dataDesembaraco ?? null,
				localDesembaraco: item.prod?.importacao?.localDesembaraco ?? null,
				estadoDesembaraco: converterEstadoParaFormulario(item.prod?.importacao?.estadoDesembaraco) ?? null,
				exportador: converterPessoaParaFormulario(item.prod?.importacao?.exportador) ?? null,
				fabricante: converterPessoaParaFormulario(item.prod?.importacao?.fabricante) ?? null,
			},

			baseCalculo: item.prod.vProd + item.prod.vSeg + item.prod.vFrete + item.prod.vOutro - item.prod.vDesc ?? 0,
			subtotal:
				item.prod.vProd +
					item.prod.vSeg +
					item.prod.vFrete +
					item.prod.vOutro +
					item.imposto?.icms?.valorSt +
					item.imposto?.ipi?.valor -
					item.prod.vDesc ?? 0,
		}));
	return itensFormulario;
}

function converterProdutoParaFormulario(produtoApi) {
	if (produtoApi) {
		return {
			value: produtoApi.prod.idProd,
			label: `${produtoApi.prod.produto?.codigo  } - ${  produtoApi.prod.xProd}`,
			registro: {
				id: produtoApi.prod.idProd,
				codigo: produtoApi.prod.produto?.codigo,
				sku: produtoApi.prod.produto?.sku,
				codigoBarras: produtoApi.prod.produto?.codigoBarras,
				ncm: {
					id: produtoApi.prod.idNCM,
					codigo: produtoApi.prod.NCM,
					descricao: produtoApi.prod.xNCM,
				},
				nome: produtoApi.prod.xProd,
				origem: produtoApi.imposto.icms.origem,
				unidadeMedida: {
					id: produtoApi.prod.idCom,
					unidade: produtoApi.prod.uCom,
					descricao: produtoApi.prod.xCom,
				},
				preco: produtoApi.prod?.produto?.preco,
				precoAtacado: produtoApi.prod.produto?.precoAtacado,
				situacao: produtoApi.prod.produto.situacao,
				codigoAnp: produtoApi.prod.produto.codigoAnp,
			},
		};
	}
	return null;
}

function converterVendedorParaFormulario(vendedor) {
	return {
		label: `${vendedor.codigo} - ${vendedor.nome}`,
		registro: vendedor,
		value: vendedor.id,
	};
}

function converterCorrecoesParaFormulario(correcoesApi) {
	const correcoes = correcoesApi.map((correcao) => ({
			id: correcao.id,
			dataHoraEvento: correcao.dhEvento,
			numeroSequenciaEvento: correcao.nSeqEvento,
			descricao: correcao.xCorrecao,
		}));

	return correcoes;
}

function converterEstadoVeiculoParaFormulario(veicTransp) {
	if (Object.keys(veicTransp).length > 0) {
		if (veicTransp?.UF) {
			return {
				label: `${veicTransp?.xUf} - ${veicTransp?.UF}`,
				registro: veicTransp,
				value: veicTransp?.idUF,
			};
		}
	}
	return null;
}

function converterTransportadoraParaFormulario(pessoaTransportadora, dadosTransportadora) {
    let transportador = {};

    if (pessoaTransportadora) {
        transportador = {
            label: `${pessoaTransportadora.codigo} - ${pessoaTransportadora.nome}`,
            registro: {
                nome: pessoaTransportadora.nome,
                pessoaFisica: pessoaTransportadora.cpf ? { cpf: pessoaTransportadora.cpf } : null,
                pessoaJuridica: pessoaTransportadora.cnpj ? { cnpj: pessoaTransportadora.cnpj } : null,
                inscricaoEstadual: pessoaTransportadora.inscricaoEstadual,
            },
            value: pessoaTransportadora.id,
        };
    }

    if (dadosTransportadora) {
        transportador = {
            ...transportador,
            endereco: {
                enderecoString: dadosTransportadora.xEnder ? dadosTransportadora.xEnder.substring(0, 60) : '',
                municipio: {
                    label: `${dadosTransportadora.xMun} - ${dadosTransportadora.UF}`,
                    registro: dadosTransportadora,
                    value: dadosTransportadora.idMun,
                },
            },
        };
    }

    return Object.keys(transportador).length > 0 ? transportador : null;
}

function converterVolumeTransporteParaFormulario(volumes) {
	if (volumes.length > 0) {
		return {
			quantidade: volumes[0].qVol,
			tipo: volumes[0].esp,
			marca: volumes[0].marca,
			numeracao: volumes[0].nVol,
			pesoBruto: volumes[0].pesoB,
			pesoLiquido: volumes[0].pesoL,
		};
	}
	return null;
}

function converterEstadoParaFormulario(estado) {
	if (estado && estado?.id) {
		return {
			label: `${estado.nome} - ${estado.sigla}`,
			value: estado.id,
			registro: estado,
		};
	} else {
		return null;
	}
}

function converterNFeParaApi(values) {
	const nfe = {
		id: values.id,
		status: values.status,
		venda: values.pedidoId ? { id: values?.pedidoId } : null,
		natOp: converterNaturezaOperacaoParaApi(values.naturezaOperacao),
		indPag: converterIndPagParaApi(values.condicao),
		mod: 'NFE',
		serie: values.serie,
		nNF: values.numero,
		dhEmi: `${format(parseISO(values.dataEmissao), 'yyyy-MM-dd')  }T${  format(new Date(), 'HH:mm:ss')}`,
		dhSaiEnt: `${format(parseISO(values.dataSaida), 'yyyy-MM-dd')  }T${  format(new Date(), 'HH:mm:ss')}`,
		tpNF: values.tipoOperacao,
		idDest:
			values.pessoa.registro?.tipo === TipoDestinatario.ESTRANGEIRO
				? idDest.OPERACAO_COM_EXTERIOR
				: values.pessoa?.registro.destinoOperacao,
		finNfe: values.finalidadeDocumento?.value,
		tpImp: converterTipoImpressaoParaApi(),
		indFinal: values.pessoa?.registro.consumidorFinal ? 'CONSUMIDOR_FINAL' : 'NORMAL',
		indPres: values.indicadorPresenca,
		chNfe: values.chave,
		dhCancelamento: values.dataCancelamento,
		xJustCancelamento: values.JustificativaCancelamento,
		NFref: converterDocumentosReferenciadosParaApi(values.NFref),
		comissaoTotalPercentual: values.comissaoTotalPercentual,
		comissaoTotalValor: values.comissaoTotalValor,
		dest: converterPessoaParaApi(values.pessoa),
		det: converterProdutosParaApi(
			values.produtos,
			values.finalidadeDocumento?.value,
			values.pessoa,
			values.tipoOperacao
		),
		total: converterTotalParaApi(values.totalizadores, values.produtos, values.finalidadeDocumento?.value),
		transp: converterTransportadoraParaApi(values.transporte),
		pagamentos: converterFinanceiroParaApi(values),
		infAdic: converterInfAdicParaApi(values.informacoesComplementares),
		vendedor: { id: values.informacoesComplementares.vendedor?.value },
		tabelaPreco: values.informacoesComplementares.tabelaPreco
			? { id: values.informacoesComplementares.tabelaPreco?.value }
			: null,
		operacaoFiscal: { id: values.operacaoFiscal.value },
		setor: values.informacoesComplementares.setor?.value?.id
			? { id: values.informacoesComplementares.setor?.value.id }
			: values.informacoesComplementares.setor?.value
				? { id: values.informacoesComplementares.setor?.value }
				: null,
		exporta:
			values.pessoa.registro?.tipo === 'ESTRANGEIRO' && values.exportacao?.ufEmbarque
				? {
						ufSaidaPais: values.exportacao?.ufEmbarque ? { id: values.exportacao?.ufEmbarque.value } : null,
						xLocDespacho: values.exportacao?.localEmbarque ?? '',
						xLocExporta: values.exportacao?.localExportacao ?? '',
					}
				: null,
	};
	return nfe;
}

function converterNaturezaOperacaoParaApi(natOp) {
	return {
		id: natOp.value,
		descricao: natOp.label,
	}
}

function converterIndPagParaApi(condicao) {
	switch (condicao) {
		case 'A_VISTA':
			return 'A_VISTA';
		case 'A_PRAZO':
			return 'A_PRAZO';
		case 'SEM_PAGAMENTO':
			return 'OUTROS';
		default:
			return 'A_VISTA';
	}
}

function converterTipoImpressaoParaApi() {
	const { filialConectada } = buscarDadosLoginLocalStorage();
	return filialConectada?.parametrosFiscalNFe?.tipoImpressaoDanfeNfe ?? 'NORMAL_RETRATO';
}

function converterDocumentosReferenciadosParaApi(notasReferenciadas) {
	const NFref = [];
	notasReferenciadas?.forEach((nota) => {
		NFref.push({
			caixa: nota.caixa,
			cnpjCpf: nota.cnpjCpf,
			emissao: nota.dataEmissao,
			ie: nota.inscricaoEstadual,
			mod: nota.modelo,
			nCoo: nota.numeroCoo,
			nEcf: nota.numeroEcf,
			nNF: nota.numeroNF,
			refNFe: nota.chaveNFe,
			serie: nota.serie,
			uf: nota.uf?.registro,
		});
	});

	return NFref;
}

function converterPessoaParaApi(pessoa) {
	return {
		idDest: pessoa.value,
		idDestinatario: pessoa.registro.id,
		tipo: pessoa.registro.tipo,
		CNPJ: pessoa.registro.pessoaJuridica?.cnpj ?? null,
		CPF: pessoa.registro.pessoaFisica?.cpf ?? null,
		idEstrangeiro: pessoa.registro.idEstrangeiro ?? null,
		xNome: pessoa.registro.nome,
		enderDest: converterEnderecoPessoaParaApi(pessoa.registro?.enderecos, pessoa.registro?.telefones) ?? null,
		indIEDest: pessoa.registro.indicadorInscricaoEstadual ?? null,
		IE: pessoa.registro.inscricaoEstadual ?? null,
		email: converterEmailPessoaParaApi(pessoa.registro.emails) ?? null,
		ISUF: pessoa.registro.pessoaJuridica?.inscricaoSuframa ?? null,
		IM: pessoa.registro.pessoaJuridica?.inscricaoMunicipal ?? null,
		simplesNacional: pessoa.registro.pessoaJuridica?.simplesNacional ?? null,
	};
}

function converterEnderecoPessoaParaApi(enderecos, telefones) {
	let telefoneUtilizado = null;
	let foundFavorite = false;

	telefones?.forEach((telefone) => {
		if (telefone.favorito === true) {
			telefoneUtilizado = telefone.numero;
			foundFavorite = true;
			
		}
	});

	if (!foundFavorite && telefones?.length > 0) {
		telefoneUtilizado = telefones[0].numero;
	}

	const endereco = enderecos.map((endereco) => endereco.favorito === true
			? {
					xLgr: endereco.logradouro,
					nro: endereco.numero,
					xCpl: endereco.complemento,
					xBairro: endereco.bairro,
					idMun: endereco.municipio.id,
					idEst: endereco.municipio.ufId,
					cMun: endereco.municipio.codigo,
					xMun: endereco.municipio.nome,
					UF: endereco.municipio.estadoSigla,
					CEP: endereco.cep,
					idPais: endereco.pais.id,
					cPais: endereco.pais.codigo,
					xPais: endereco.pais.nome,
					fone: telefoneUtilizado,
				}
			: null);
	return endereco[0]?.length > 0
		? {
				xLgr: endereco[0]?.logradouro,
				nro: endereco[0]?.numero,
				xCpl: endereco[0]?.complemento,
				xBairro: endereco[0]?.bairro,
				idMun: endereco[0]?.municipio.id,
				idEst: endereco[0]?.municipio.ufId,
				cMun: endereco[0]?.municipio.codigo,
				xMun: endereco[0]?.municipio.nome,
				UF: endereco[0]?.municipio.estadoSigla,
				CEP: endereco[0]?.cep,
				idPais: endereco[0]?.pais.id,
				cPais: endereco[0]?.pais.codigo,
				xPais: endereco[0]?.pais.nome,
				fone: telefoneUtilizado,
			}
		: {
				xLgr: enderecos[0].logradouro,
				nro: enderecos[0].numero,
				xCpl: enderecos[0].complemento,
				xBairro: enderecos[0].bairro,
				idMun: enderecos[0].municipio.id,
				idEst: enderecos[0].municipio.ufId,
				cMun: enderecos[0].municipio.codigo,
				xMun: enderecos[0].municipio.nome,
				UF: enderecos[0].municipio.estadoSigla,
				CEP: enderecos[0].cep,
				idPais: enderecos[0].pais.id,
				cPais: enderecos[0].pais.codigo,
				xPais: enderecos[0].pais.nome,
				fone: telefoneUtilizado,
			};
}

function converterEmailPessoaParaApi(emails) {
	let emailPessoa = '';

	emails?.forEach((email) => {
		email.favorito === true ? (emailPessoa = email.email) : null;
	});

	if (emailPessoa) {
		return emailPessoa;
	} else if (emails?.length > 0) {
		return emails[0].email;
	} else {
		return null;
	}
}

function converterProdutosParaApi(produtos, finalidade, pessoa, tipoOperacao) {
	const codigoNaNfe = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.codigoNaNfe;
	const det = [];
	produtos.forEach((produto) => {
		if (produto.produto) {
			det.push({
				id: produto.id,
				nItem: produto.item,
				infAdProd: produto?.descricao ?? null,
				prod: {
					idProd: produto.produto.value,
					produto: { id: produto.produto.value },
					cProd:
						codigoNaNfe === 'REFERENCIA' &&
						produto.produto.registro.sku &&
						produto.produto.registro.sku.toString().trim() !== ''
							? produto.produto.registro.sku
							: codigoNaNfe === 'CODIGO_BARRAS' &&
								  produto.produto.registro.codigoBarras &&
								  produto.produto.registro.codigoBarras.toString().trim() !== ''
								? produto.produto.registro.codigoBarras
								: produto.produto.registro.codigo,
					cEAN:
						produto.produto.registro.codigoBarras && produto.produto.registro.codigoBarras.toString().length > 7
							? produto.produto.registro.codigoBarras
							: 'SEM GTIN',
					xProd: produto.produto.registro.nome,
					idNCM: produto.ncm?.value,
					xNCM: produto.ncm?.registro.descricao,
					NCM: produto.ncm?.registro.codigo,
					idCFOP: produto.cfop?.value,
					xCFOP: produto.cfop?.registro.descricao,
					CFOP: produto.cfop?.registro.codigo,
					idCom: produto.unidadeMedida?.value,
					uCom: produto.unidadeMedida?.registro.unidade,
					xCom: produto.unidadeMedida?.registro.descricao,
					qCom: produto.quantidade,
					vUnCom: produto.valor,
					vProd: parseFloat((produto.quantidade * produto.valor).toFixed(2)),
					vFrete: produto.frete,
					vSeg: produto.seguro,
					vDesc: produto.desconto,
					vOutro: produto.acessorias,
					indTot: 'COMPOE_TOTAL',
					xPed: produto.numeroPedidoCompra,
					nItemPed: produto.itemPedidoCompra,
					idCEST: produto.cest?.value,
					xCEST: produto.cest?.registro.descricao,
					CEST: produto.cest?.registro.codigo,
					codigoBeneficiario: produto.codigoBeneficiario,
					cProdANP: produto.produto.registro.codigoAnp,
					descontoMaximoPercentual: produto.descontoMaximoPercentual ?? 0,
					descontoMaximoVendedor: produto.descontoMaximoVendedor ?? 0,
					descontoMaximoTabela: produto.descontoMaximoVendedor ?? 0,
					comissaoValor: produto.comissaoValor ?? 0,
					comissaoPercentual: produto.comissaoPercentual ?? 0,
					utilizouTabelaPromocional: produto.utilizouTabelaPromocional,
					valorOriginal: produto.valorOriginal,
					percentualAdicionalTabelaPreco: produto.adicionalTabelaPreco?.percentual,
					tipoPercentualAdicionalTabelaPreco: produto.adicionalTabelaPreco?.tipo,
					importacao:
						pessoa.registro?.tipo === 'ESTRANGEIRO' && tipoOperacao === 'ENTRADA'
							? {
									numeroDocumentoImportacao: produto.importacao?.numeroDocumentoImportacao ?? null,
									dataRegistro: produto.importacao?.dataRegistro ?? null,
									dataDesembaraco: produto.importacao?.dataDesembaraco ?? null,
									localDesembaraco: produto.importacao?.localDesembaraco ?? null,
									estadoDesembaraco: produto.importacao?.estadoDesembaraco
										? { id: produto.importacao?.estadoDesembaraco.value }
										: null,
									exportador: produto.importacao?.exportador ? { id: produto.importacao?.exportador.value } : null,
									fabricante: produto.importacao?.fabricante ? { id: produto.importacao?.fabricante.value } : null,
								}
							: null,
				},
				imposto: {
					vTotTrib: 0,
					icms: {
						id: produto.tributos.icms.id ?? null,
						cstCsosn: { id: produto.tributos.icms.cstCsosn.value },
						origem: produto.tributos.icms.origemProduto,
						aliquotaCreditoSimplesNacional: produto.tributos.icms.percentualCreditoSimplesNacional ?? 0,
						valorCreditoSimplesNacional: produto.tributos.icms.valorCreditoSimplesNacional ?? 0,
						percentualBaseCalculo: produto.tributos.icms.percentualBaseIcms ?? 0,
						percentualReducaoBaseCalculo: parseFloat(
							(produto.tributos.icms.percentualBaseIcms < 100
								? 100 - produto.tributos.icms.percentualBaseIcms
								: 0
							).toFixed(2)
						),
						modalidadeCalculo: produto.tributos.icms.modalidadeCalculo,
						valorBaseCalculo: produto.tributos.icms.baseIcms ?? 0,
						aliquota: produto.tributos.icms.percentualIcms ?? 0,
						valor: produto.tributos.icms.valorIcms ?? 0,
						valorSubstituto: produto.tributos.icms.valorIcmsSubstituto ?? 0,
						modalidadeCalculoSt: produto.tributos.icms.modalidadeCalculoSt,
						percentualMva: produto.tributos.icms.percentualMva ?? 0,
						valorPauta: produto.tributos.icms.valorPauta ?? 0,
						percentualBaseCalculoSt: produto.tributos.icms.percentualBaseIcmsSt ?? 0,
						percentualReducaoBaseCalculoSt: produto.tributos.icms.percentualReducaoBaseIcmsSt ?? 0,
						valorBaseCalculoSt: produto.tributos.icms.baseIcmsSt ?? 0,
						aliquotaSt: produto.tributos.icms.percentualIcmsSt ?? 0,
						valorSt: produto.tributos.icms.valorIcmsSt ?? 0,
						percentualBaseCalculoStRetido: produto.tributos.icms.percentualBaseIcmsStRetido ?? 0,
						valorBaseCalculoStRetido: produto.tributos.icms.baseRetidoSt ?? 0,
						aliquotaStRetido: produto.tributos.icms.percentualIcmsStRetido ?? 0,
						valorStRetido: produto.tributos.icms.valorRetidoSt ?? 0,
						percentualDiferido: produto.tributos.icms.percentualDiferimento ?? 0,
						valorDiferido: produto.tributos.icms.valorDiferimento ?? 0,
						valorBaseCalculoFcPobreza: produto.tributos.icms.baseFundoCombatePobreza ?? 0,
						percentualFcPobreza: produto.tributos.icms.percentualFundoCombatePobreza ?? 0,
						valorFcPobreza: produto.tributos.icms.valorFundoCombatePobreza ?? 0,
						quantidadeBaseMonofasicoRetido: produto.tributos.icms.quantidadeBaseIcmsMonofasicoRetido ?? 0,
						percentualMonofasicoRetido: produto.tributos.icms.percentualIcmsMonofasicoRetido ?? 0,
						valorMonofasicoRetido: produto.tributos.icms.valorIcmsMonofasicoRetido ?? 0,
					},
					ipi: {
						id: produto.tributos.ipi.id ?? null,
						cst: { id: produto.tributos.ipi.cst.value },
						enquadramento: { id: produto.tributos.ipi.enquadramentoIpi.value },
						percentualBaseCalculo: produto.tributos.ipi.percentualBaseIpi ?? 0,
						valorBaseCalculo: produto.tributos.ipi.baseIpi ?? 0,
						aliquota: produto.tributos.ipi.percentualIpi ?? 0,
						valor: produto.tributos.ipi.valorIpi ?? 0,
					},
					pis: {
						id: produto.tributos.pis.id ?? null,
						cst: { id: produto.tributos.pis.cst.value },
						percentualBaseCalculo: produto.tributos.pis.percentualBasePis ?? 0,
						valorBaseCalculo: produto.tributos.pis.basePis ?? 0,
						aliquota: produto.tributos.pis.percentualPis ?? 0,
						valor: produto.tributos.pis.valorPis ?? 0,
						percentualBaseCalculoSt: produto.tributos.pis.percentualBasePisSt ?? 0,
						valorBaseCalculoSt: produto.tributos.pis.basePisSt ?? 0,
						aliquotaSt: produto.tributos.pis.percentualPisSt ?? 0,
						valorSt: produto.tributos.pis.valorPisSt ?? 0,
					},
					cofins: {
						id: produto.tributos.cofins.id ?? null,
						cst: { id: produto.tributos.cofins.cst.value },
						percentualBaseCalculo: produto.tributos.cofins.percentualBaseCofins ?? 0,
						valorBaseCalculo: produto.tributos.cofins.baseCofins ?? 0,
						aliquota: produto.tributos.cofins.percentualCofins ?? 0,
						valor: produto.tributos.cofins.valorCofins ?? 0,
						percentualBaseCalculoSt: produto.tributos.cofins.percentualBaseCofinsSt ?? 0,
						valorBaseCalculoSt: produto.tributos.cofins.baseCofinsSt ?? 0,
						aliquotaSt: produto.tributos.cofins.percentualCofinsSt ?? 0,
						valorSt: produto.tributos.cofins.valorCofinsSt ?? 0,
					},
				},
				impostoDevolvido:
					finalidade === 'DEVOLUCAO_MERCADORIA'
						? {
								percentualDevolvido: produto.tributos?.devolvido?.percentualDevolvido ?? 100,
								ipi: {
									valor: produto.tributos?.devolvido?.valorIpiDevolvido ?? 0,
								},
							}
						: null,
				operacaoFiscal: { id: produto.operacaoFiscal.value },
			});
		}
	});

	return det;
}

function converterTotalParaApi(totalizadores, produtos, finalidade) {
	function converterFCPParaApi(produtos) {
		let fcp = 0;
		produtos.forEach((produto) => {
			fcp += produto.tributos?.icms?.valorFundoCombatePobreza;
		});
		return fcp;
	}

	function validateValue(value) {
		return value > 0 && !isNaN(value) ? value : 0;
	}

	return {
		ICMSTot: {
			vBC: validateValue(totalizadores.totalBaseCalculoIcms),
			vICMS: validateValue(totalizadores.totalIcms),
			vBCST: validateValue(totalizadores.totalBaseCalculoIcmsSt),
			vST: validateValue(totalizadores.totalIcmsSt),
			vProd: validateValue(totalizadores.totalProdutos),
			vFrete: validateValue(totalizadores.totalFrete),
			vSeg: validateValue(totalizadores.totalSeguro),
			vDesc: validateValue(totalizadores.totalDesconto),
			vOutro: validateValue(totalizadores.totalAcessorias),
			vNF: validateValue(totalizadores.totalLiquido),
			vTotTrib: 0,
			vFCPUFDest: null,
			vICMSUFDest: null,
			vICMSUFRemet: null,
			vFCP: converterFCPParaApi(produtos),
			vIPI: validateValue(totalizadores.totalIpi),
			vPIS: validateValue(totalizadores.totalPis),
			vCOFINS: validateValue(totalizadores.totalCofins),
			vIPIDevol: finalidade === 'DEVOLUCAO_MERCADORIA' ? validateValue(totalizadores.totalIpiDevolvido) : 0,
		},
	};
}

function converterTransportadoraParaApi(transporte) {
	return {
		modFrete: transporte.modalidadeFrete?.value ? transporte.modalidadeFrete.value : transporte.modalidadeFrete ?? null,
		transporta: transporte.transportador
			? {
					CNPJ: transporte.transportador.registro?.pessoaJuridica?.cnpj ?? null,
					CPF: transporte.transportador.registro?.pessoaFisica?.cpf ?? null,
					xNome: transporte.transportador.registro?.nome ?? null,
					IE: transporte.transportador.registro?.inscricaoEstadual ?? null,
					xEnder: transporte.transportador.endereco?.enderecoString.substring(0, 60) ?? null,
					idMun: transporte.transportador.endereco?.municipio.value ?? null,
					xMun: transporte.transportador.endereco?.municipio.registro.nome ?? null,
					UF: transporte.transportador.endereco?.municipio.registro.estadoSigla ?? null,
				}
			: null,
		pessoa: transporte.transportador ? { id: transporte.transportador.value ?? null } : null,
		veicTransp: {
			placa: transporte.veiculo.placa ?? null,
			idUf: transporte.veiculo.uf?.value ?? null,
			xUf: transporte.veiculo.uf?.registro.nome ?? null,
			UF: transporte.veiculo.uf?.registro.sigla ?? null,
			RNTC: transporte.veiculo.RNTC ?? null,
		},
		vol: [
			{
				qVol: transporte.volumes?.quantidade ?? null,
				esp: transporte.volumes?.tipo ?? null,
				marca: transporte.volumes?.marca ?? null,
				nVol: transporte.volumes?.numeracao ?? null,
				pesoL: transporte.volumes?.pesoLiquido ?? null,
				pesoB: transporte.volumes?.pesoBruto ?? null,
			},
		],
	};
}

function converterPagamentoCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const nfeCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(nfeCategoria);
		});
	}
	return categorias;
}

function converterFinanceiroParaApi(values) {
	const pagamentosApi = values.pagamentos.map((pagamento) => ({
			id: pagamento.id,
			sequencial: pagamento.sequencial,
			condicaoPagamento: { id: pagamento.condicaoPagamento.value },
			quantidadeParcelas: pagamento.quantidadeParcelas ? pagamento.quantidadeParcelas.value : null,
			categorias: converterPagamentoCategoriasParaApi(pagamento.categorias),
			formaPagamento: pagamento.formaPagamento ? { id: pagamento.formaPagamento.value } : null,
			conta: pagamento.conta ? { id: pagamento.conta.value } : null,
			valor: pagamento.valor,
			parcelas: converterDuplicatasParaApi(pagamento.parcelas),
		}));
	return pagamentosApi;
}

function converterDuplicatasParaApi(parcelas) {
	const pagamentoParcelasApi = parcelas.map((parcela) => ({
			id: parcela.id,
			sequencial: parcela.sequencial,
			vencimento: parcela.vencimento,
			formaPagamento: parcela.formaPagamento ? { id: parcela.formaPagamento.value } : null,
			conta: parcela.conta ? { id: parcela.conta.value } : null,
			valor: parcela.valor,
			descricao: parcela.descricao,
		}));
	return pagamentoParcelasApi;
}

function converterInfAdicParaApi(informacoesComplementares) {
	return {
		infCpl: informacoesComplementares.informacaoComplementar ?? null,
		observacoes: informacoesComplementares.observacaoInterna ?? null,
	};
}

function converterParaDuplicar(values) {
	return {
		...values,
		id: null,
		numero: null,
		chave: null,
		pedidoId: null,
		status: Status.NAO_ENVIADA,
		dataSaida: formatISO(new Date()),
		dataEmissao: formatISO(new Date()),
		NFref: values.NFref ?? [],
		xJustCancelamento: '',
		dataCancelamento: null,
		idCobranca: null,
		idFatura: null,
		produtos: converterProdutosParaDuplicar(values.produtos),
		pagamentos: converterPagamentoParaDuplicar(values.pagamentos),
		validacaoRecarregaProdutoPeloCliente: false,
		comissaoTotalPercentual: values.comissaoTotalPercentual,
		comissaoTotalValor: values.comissaoTotalValor,

		erros: [],
	};
}

function converterProdutosParaDuplicar(produtosDuplicados) {
	const produtos = [];

	produtosDuplicados?.forEach((produto) => {
		produtos.push({
			...produto,
			id: gerarUUID(),
			tributos: {
				icms: { ...produto.tributos.icms, id: null },
				ipi: { ...produto.tributos.ipi, id: null },
				pis: { ...produto.tributos.pis, id: null },
				cofins: { ...produto.tributos.cofins, id: null },
			},
		});
	});

	return produtos;
}

function converterPagamentoParaDuplicar(pagamentosApi) {
	const pagamentos = [];
	pagamentosApi?.forEach((pag) => {
		pagamentos.push({
			...pag,
			id: null,
			parcelas: pag.parcelas.map((e) => ({ ...e, id: null })),
		});
	});

	return pagamentos;
}

function converterNFeDePedido(values) {
	const nfe = {
		id: null,
		numero: null,
		serie: null,
		chave: null,
		status: Status.NAO_ENVIADA,
		tipo: 'NOTA_FISCAL',
		tipoOperacao: 'SAIDA',
		naturezaOperacao: null,
		finalidadeDocumento: {
			label: '1 - Normal',
			value: 'NORMAL',
		},
		indicadorPresenca: 'NAO_SE_APLICA',
		pessoa: converterPessoaDePedido(values.cliente),
		dataSaida: formatISO(new Date()),
		dataEmissao: formatISO(new Date()),
		operacaoFiscal: converterSelectParaFormulario(values.operacaoFiscal),
		tipoDesconto: values.tipoDesconto === 'NAO_APLICADO' ? 'VALOR' : values.tipoDesconto,
		emitente: EMITENTE,
		NFref: [],
		comissaoTotalPercentual: values.comissaoTotalPercentual,
		comissaoTotalValor: values.comissaoTotalValor,

		produtos: converterItensDePedido(values.itens),

		totalizadores: {
			totalProdutos: values.totalItens,
			totalTributos: values.totalIpi + values.totalIcmsSt ?? 0,
			totalFrete: values.valorFrete ?? 0,
			totalSeguro: values.valorSeguro ?? 0,
			totalAcessorias: values.valorDespesasAcessorias ?? 0,
			percentualDesconto: values.tipoDesconto === 'PERCENTUAL' ? values.percentualDesconto : 0,
			totalDesconto: values.tipoDesconto === 'VALOR' ? values.desconto : 0,
			totalLiquido: values.totalLiquido,
		},
		pagamentos: converterPagamentosDePedido(values.pagamentos, values.cliente),
		transporte: {
			modalidadeFrete: {
				label: '0 - Por conta do emitente',
				value: 'EMITENTE',
			},
			transportador: null,
			veiculo: {
				placa: null,
				uf: null,
				RNTC: null,
			},
			volumes: {
				quantidade: null,
				tipo: '',
				Marca: '',
				Numeracao: '',
				pesoBruto: null,
				pesoLiquido: null,
				especie: null,
			},
		},

		informacoesComplementares: {
			vendedor: converterVendedorDePedido(values.vendedor),
			tabelaPreco: converterTabelaPrecoDePedido(values.tabelaPreco),
			informacaoComplementar: concatenarInformacaoComplementarPadraoEPedido(values.informacaoComplementar) ?? null,
			observacaoInterna: values.observacaoInterna ?? null,
			setor: converterSetorParaFormulario(values.setor),
		},

		xJustCancelamento: '',
		dataCancelamento: null,

		validacaoRecarregaProdutoPeloCliente: false,

		erros: [],

		pedidoId: values.id,
		pedidoNumero: values.numero,
	};

	return nfe;
}

function concatenarInformacaoComplementarPadraoEPedido(informacaoComplementar) {
	const informacaoComplementarPadrao =
		buscarDadosLoginLocalStorage().filialConectada?.parametrosVendas?.observacaoPadraoNfe;

	if (informacaoComplementarPadrao) {
		return informacaoComplementar
			? `${informacaoComplementarPadrao}\n${informacaoComplementar}`
			: informacaoComplementarPadrao;
	}

	return informacaoComplementar || '';
}

function converterPagamentosDePedido(pagamentos, cliente) {
	const pagamentosFormulario = pagamentos.map((pagamento) => ({
			tempKey: gerarUUID(),
			sequencial: pagamento.sequencial,
			condicaoPagamento: converterValueParaSelect(pagamento.condicaoPagamento),
			quantidadeParcelas: converterQuantidadeParcelas(pagamento.quantidadeParcelas),
			categorias: converterPagamentoCategoriasParaFormulario(pagamento.categorias),
			formaPagamento: converterValueParaSelect(pagamento.formaPagamento),
			conta: converterValueParaSelect(pagamento.conta),
			valor: pagamento.valor,
			parcelas: converterParcelasDePedido(pagamento.parcelas, cliente),
		}));
	return pagamentosFormulario;
}

function converterParcelasDePedido(parcelas, cliente) {
	if (parcelas?.length > 0) {
		const parcelasConvertidas = [];
		parcelas.forEach((parcela) => {
			parcelasConvertidas.push({
				tempKey: gerarUUID(),
				sequencial: parcela.sequencial,
				vencimento: parcela.vencimento,
				formaPagamento: converterValueParaSelect(parcela.formaPagamento),
				conta: converterValueParaSelect(parcela.conta),
				descricao: parcela.descricao,
				valor: parcela.valor,
				temBoleto: parcela.temBoleto,
				temPix: parcela.temPix,
				financeiroIdentificador: parcela.financeiroIdentificador,
				financeiroId: parcela.financeiroId,
				financeiroStatus: parcela.financeiroStatus,
				valorRestante: parcela.valorRestante,
				pessoaNome: cliente.xNome,
				pessoaCodigo: cliente.codigoDestinatario,
				pessoaEmail: cliente.email,
			});
		});
		return parcelasConvertidas;
	}
	return [];
}

function converterPessoaDePedido(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoa(pessoaApi),
			value: pessoaApi.id,
			registro: {
				id: pessoaApi.id,
				codigo: pessoaApi.codigo,
				nome: pessoaApi.nome,
				cliente: pessoaApi.cliente,
				fornecedor: pessoaApi.fornecedor,
				pessoaJuridica: pessoaApi?.cnpj ? { cnpj: pessoaApi.cnpj } : null,
				pessoaFisica: pessoaApi?.cpf ? { cpf: pessoaApi.cpf } : null,
				consumidorFinal: pessoaApi.consumidorFinal ?? false,
				configPrecoPraticado: pessoaApi.configPrecoPraticado,
				emails: pessoaApi.emails ?? null,
				enderecos: pessoaApi.enderecos ?? null,
				tipo: pessoaApi.tipo,
				indicadorInscricaoEstadual: pessoaApi.indicadorInscricaoEstadual,
				inscricaoEstadual: pessoaApi.inscricaoEstadual,
				destinoOperacao: converterDestinoOperacao(pessoaApi),
			},
		};
	}
	return null;
}

function converterDestinoOperacao(destinatario) {
	const enderecoDestinatario = [];
	if (Array.isArray(destinatario.enderecos)) {
	  destinatario.enderecos.forEach((endereco) => {
		if (endereco.favorito === true) {
		  enderecoDestinatario.push(endereco);
		}
	  });
	}
	if (enderecoDestinatario.length === 0 && Array.isArray(destinatario.enderecos)) {
	  enderecoDestinatario.push(destinatario.enderecos[0]);
	}
	const municipioDestino = enderecoDestinatario[0]?.municipio;
	const ufIdDestino = municipioDestino?.ufId;
	const ufIdEmitente = EMITENTE.endereco?.municipio?.estado.id;
  
	if (ufIdDestino === ufIdEmitente) {
	  return idDest.OPERACAO_INTERNA;
	} else if (destinatario.tipo === 'ESTRANGEIRO') {
	  return idDest.OPERACAO_COM_EXTERIOR;
	} else if (ufIdDestino) {
	  return idDest.OPERACAO_INTERESTADUAL;
	} else {
	  return null;
	}
  }
  

function converterItensDePedido(itensApi) {
	const itensFormulario = itensApi.map((item) => ({
			id: item.id,
			produto: converterProdutoDePedido(item.produto),
			descricao: item.descricao,

			cfop: converterSelectParaFormulario(item.cfop),
			operacaoFiscal: converterSelectParaFormulario(item.operacaoFiscal),
			ncm: converterSelectParaFormulario(item.ncm),
			unidadeMedida: converterSelectParaFormulario(item.unidadeMedida),
			cest: converterSelectParaFormulario(item.cest) ?? null,
			codigoBarras: item.codigoBarras ?? null,
			codigoBeneficiario: item.codigoBeneficiario ?? null,
			numeroPedidoCompra: item.numeroPedidoComrpa ?? null,
			itemPedidoCompra: item.itemPedidoCompra ?? null,

			descontoMaximoPercentual: item.descontoMaximoPercentual ?? 0,
			descontoMaximoVendedor: item.descontoMaximoVendedor ?? 0,
			descontoMaximoTabela: item.descontoMaximoVendedor ?? 0,
			comissaoValor: item.comissaoValor ?? 0,
			comissaoPercentual: item.comissaoPercentual ?? 0,
			utilizouTabelaPromocional: item.utilizouTabelaPromocional,
			valorOriginal: item.valorOriginal,
			adicionalTabelaPreco: item.adicionalTabelaPreco,

			quantidade: item.quantidade,
			desconto: item.desconto ?? 0,
			valor: item.valor ?? 0,

			acessorias: item.acessorias ?? 0,
			seguro: item.seguro ?? 0,
			frete: item.frete ?? 0,

			tributos: {
				icms: {
					cstCsosn: converterSelectParaFormulario(item.icms?.cstCsosn),
					origemProduto: item.icms?.origem,
					percentualCreditoSimplesNacional: item.icms?.aliquotaCreditoSimplesNacional ?? 0,
					valorCreditoSimplesNacional: item.icms?.valorCreditoSimplesNacional ?? 0,
					percentualBaseIcms: item.icms?.percentualBaseCalculo ?? 0,
					percentualReducaoBaseIcms: item.icms?.percentualReducaobaseCalculo ?? 0,
					modalidadeCalculo: item.icms?.modalidadeCalculo ?? 0,
					baseIcms: item.icms?.valorBaseCalculo ?? 0,
					percentualIcms: item.icms?.aliquota ?? 0,
					valorIcms: item.icms?.valor ?? 0,
					valorIcmsSubstituto: item.icms?.valorSubstituto ?? 0,
					modalidadeCalculoSt: item.icms?.modalidadeCalculoSt ?? 0,
					percentualMva: item.icms?.percentualMva ?? 0,
					valorPauta: item.icms?.valorPauta ?? 0,
					percentualBaseIcmsSt: item.icms?.percentualBaseCalculoSt ?? 0,
					percentualReducaoBaseIcmsSt: item.icms?.percentualReducaoBaseCalculoSt ?? 0,
					baseIcmsSt: item.icms?.valorBaseCalculoSt ?? 0,
					percentualBaseIcmsStRetido: item.icms?.percentualBaseCalculoStRetido ?? 0,
					percentualIcmsStRetido: item.icms?.aliquotaStRetido ?? 0,
					percentualIcmsSt: item.icms?.aliquotaSt ?? 0,
					valorIcmsSt: item.icms?.valorSt ?? 0,
					baseRetidoSt: item.icms?.valorBaseCalculoStRetido ?? 0,
					valorRetidoSt: item.icms?.valorStRetido ?? 0,
					percentualDiferimento: item.icms?.percentualDiferido ?? 0,
					valorDiferimento: item.icms?.valorDiferido ?? 0,
					baseFundoCombatePobreza: item.icms?.valorBaseCalculoFcPobreza ?? 0,
					percentualFundoCombatePobreza: item.icms?.percentualFcPobreza ?? 0,
					valorFundoCombatePobreza: item.icms?.valorFcPobreza ?? 0,
					quantidadeBaseIcmsMonofasicoRetido: item.icms?.quantidadeBaseMonofasicoRetido ?? 0,
					percentualIcmsMonofasicoRetido: item.icms?.percentualMonofasicoRetido ?? 0,
					valorIcmsMonofasicoRetido: item.icms?.valorMonofasicoRetido ?? 0,
				},
				ipi: {
					enquadramentoIpi: converterSelectParaFormulario(item.ipi?.enquadramento),
					cst: converterSelectParaFormulario(item.ipi?.cst),
					percentualBaseIpi: item.ipi?.percentualBaseCalculo ?? 0,
					baseIpi: item.ipi?.valorBaseCalculo ?? 0,
					percentualIpi: item.ipi?.aliquota ?? 0,
					valorIpi: item.ipi?.valor ?? 0,
				},
				pis: {
					cst: converterSelectParaFormulario(item.pis?.cst),
					percentualBasePis: item.pis?.percentualBaseCalculo ?? 0,
					basePis: item.pis?.valorBaseCalculo ?? 0,
					percentualPis: item.pis?.aliquota ?? 0,
					valorPis: item.pis?.valor ?? 0,
					percentualBasePisSt: item.pis?.percentualBaseCalculoSt ?? 0,
					basePisSt: item.pis?.valorBaseCalculoSt ?? 0,
					percentualPisSt: item.pis?.aliquotaSt ?? 0,
					valorPisSt: item.pis?.valorSt ?? 0,
				},
				cofins: {
					cst: converterSelectParaFormulario(item.cofins?.cst),
					percentualBaseCofins: item.cofins?.percentualBaseCalculo ?? 0,
					baseCofins: item.cofins?.valorBaseCalculo ?? 0,
					percentualCofins: item.cofins?.aliquota ?? 0,
					valorCofins: item.cofins?.valor ?? 0,
					percentualBaseCofinsSt: item.cofins?.percentualBaseCalculoSt ?? 0,
					baseCofinsSt: item.cofins?.valorBaseCalculoSt ?? 0,
					percentualCofinsSt: item.cofins?.aliquotaSt ?? 0,
					valorCofinsSt: item.cofins?.valorSt ?? 0,
				},
			},

			subtotal: item.subtotal ?? 0,
		}));
	return itensFormulario;
}

function converterProdutoDePedido(produtoApi) {
	if (produtoApi) {
		return {
			value: produtoApi.id,
			label: `${produtoApi.codigo  } - ${  produtoApi.nome}`,
			registro: produtoApi,
		};
	}
	return null;
}

function converterVendedorDePedido(vendedor) {
	return { label: vendedor.nome, registro: vendedor, value: vendedor.id };
}

function converterTabelaPrecoDePedido(tabelaPreco) {
	if (tabelaPreco) {
		return { label: `${tabelaPreco.codigo} - ${tabelaPreco.nome}`, registro: tabelaPreco, value: tabelaPreco.id };
	}
	return null;
}

function converterSelectParaFormulario(selectApi) {
	if (selectApi) {
		return {
			label: montarLabelSelect(selectApi),
			value: selectApi.id,
			registro: selectApi,
		};
	}
	return null;
}

function montarLabelSelect(select) {
	let texto = '';
	if (select.codigo) {
		texto = `${select.codigo  } - ${  select.descricao}`;
	} else if (select.unidade) {
		texto = `${select.unidade  } - ${  select.descricao}`;
	} else {
		texto = select.descricao;
	}
	return texto;
}

export { converterNFeDePedido, converterNFeParaApi, converterNFeParaFormulario, converterParaDuplicar };
