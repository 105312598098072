import { useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';
import Grid from '../../../../../components/Grid';
import InputDate from '../../../../../components/input/InputDate';
import { helpMessage } from './util/constantes';
import { isValid, parseISO, format, startOfMonth, formatISO } from 'date-fns';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import { asyncBaixarRelatorioMovimentacoesPorPeriodo } from '../../../../cadastros/produtos/Produtos/Requests';
import Checkbox from '../../../../../components/input/Checkbox';
import If from '../../../../../components/If';
import MultiSelectProdutos from '../../../../../components/select/MultiSelectProdutos';
import { services } from '../../../../../Common/Constantes/api';
import ModalRelatorio from '../../../../../components/ModalRelatorio';
import { SingleSelectMarca, SingleSelectProdutoGrupo } from 'components';

const initialValue = {
	dataInicial: formatISO(startOfMonth(new Date())),
	dataFinal: new Date().toISOString(),
	codigo: 'CODIGO',
	produtos: null,
	filtrarProdutos: false,
	grupo: null,
	marca: null,	
};

function MovimentacaoEstoque(props) {
	const { visible, onHide, values, handleSubmit, validateForm, isValid, setFieldValue } = props;

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('InputDataInicial')?.focus();
		}, 500);
	}, []);

	async function imprimir() {
		await handleSubmit();
		await validateForm();

		let valorPesquisa = values.produtos;
		let dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
		let dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');

		if (isValid) {
			await asyncBaixarRelatorioMovimentacoesPorPeriodo(
				valorPesquisa,
				dataInicialFormatada,
				dataFinalFormatada,
				values.grupo == null ? '' : values.grupo.value,
				values.marca == null ? '' : values.marca.value,
				({ data: pdf }) => {
					let arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
					let arquivoURL = URL.createObjectURL(arquivoPDF);
					let relatorio = window.open(arquivoURL);
					if (relatorio) {
						relatorio.onload = () => {
							setTimeout(() => {
								relatorio.document.title = `Relatório movimentações de estoque - ${dataInicialFormatada} até ${dataFinalFormatada}`;
							}, 250);
						};
					}
					onHide();
				}
			);
		}
	}

	const parametros = (
		<>
			<Grid>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputDate}
					label="Data inicial"
					name="dataInicial"
					obrigatorio
					helpMessage={helpMessage.dataInicial}
					value={values.dataInicial}
					touched={true}
					id="InputDataInicial"
				/>
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={InputDate}
					label="Data final"
					name="dataFinal"
					obrigatorio
					helpMessage={helpMessage.dataFinal}
					value={values.dataFinal}
					touched={true}
				/>
				<Field
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					label="Imprimir movimentação de produtos específicos"
					name="filtrarProdutos"
					onChange={(e) => {
						values.produtos = [];
						setFieldValue('filtrarProdutos', !values.filtrarProdutos);
					}}
					checked={values.filtrarProdutos}
				/>
			</Grid>
			<Grid>
				<If test={values.filtrarProdutos}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						name="produtos"
						label="Produtos"
						autofocus
						isMulti
						component={MultiSelectProdutos}
						value={values.produtos}
						helpMessage={helpMessage.produto}
						onChange={(e) => {
							setFieldValue('produtos', e);
							setFieldValue('saldo', 'TODOS');
						}}
						url={`${services.GESTOR}/v1/estoque/movimentacoes/relacoes/produtos`}
						parametros={';tipo!="SERVICO";controlarEstoque==true'}
					/>
				</If>
				<If test={!values.filtrarProdutos}>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectProdutoGrupo}
						label="Grupo"
						name="grupo"						
						url={`${services.GESTOR}/v1/grupos`}
						value={values.grupo}
						onChange={(e) => setFieldValue('grupo', e)}
						showClear={false}
						disabled={values.filtrarProdutos}
					/>
					<Field
						sm="12"
						md="6"
						lg="6"
						xl="6"
						component={SingleSelectMarca}
						label="Marca"
						name="marca"						
						url={`${services.GESTOR}/v1/produtos/relacoes/marcas`}
						value={values.marca}
						onChange={(e) => setFieldValue('marca', e)}
						showClear={false}
						disabled={values.filtrarProdutos}
						esconderBotao
					/>					
				</If>
			</Grid>
		</>
	);
	return (
		<ModalRelatorio
			header={'Relatório movimentações de estoque'}
			visible={visible}
			onHide={onHide}
			onImprimir={imprimir}
			parametros={parametros}
			isValid={isValid}
		/>
	);
}

const ModalMovimentacaoEstoque = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return initialValue;
	},

	validate(values) {
		let errors = {};

		if (!values.dataInicial || values.dataInicial === '') {
			errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.dataFinal || values.dataFinal === '') {
			errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
			errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
		}

		if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
			errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
	}),

	handleSubmit: () => {},
})(MovimentacaoEstoque);

export default ModalMovimentacaoEstoque;
