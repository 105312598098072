import { formatos, inserirMascara } from 'Common';
import { Button, Col } from 'components';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef, useState } from 'react';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { useContextNFCe } from '../../../../../Context';
import ModalCliente from './ModalCliente';

function Cliente(props) {
	const { isMobile, isTablet, isLessHd, isFullScreen, cliente, setFieldValue } = props;

	const { setModalCliente } = useContextNFCe();

	const [focusCliente, setFocusCliente] = useState(false);

	const panelDetalhes = useRef(null);

	useEffect(() => {
		let input = null;
		if (focusCliente) {
			if (document.getElementById('id-cliente-cadastrado-nfce')) {
				input = document.getElementById('id-cliente-cadastrado-nfce')?.getElementsByTagName('input')[0].focus();
			} else if (document.getElementById('id-nome-cliente-nfce')) {
				input = document.getElementById('id-nome-cliente-nfce');
				input?.focus();
			}
			setFocusCliente(false);
		}
	}, [focusCliente]);

	function montarLabelCliente() {
		const cpfCnpj =
			cliente.tipoCliente === TIPO_PESSOA.JURIDICA
				? `${inserirMascara(cliente.cnpj, formatos.CNPJ)}`
				: cliente.cpf
					? `${inserirMascara(cliente.cpf, formatos.CPF)}`
					: '';
		const { nome } = cliente;
		
		return (
			<span style={{ 
				display: 'inline-block',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			}}>
				{(cpfCnpj || nome) && <span style={{ fontWeight: 'bold', marginRight: '8px' }}>Cliente:</span>}
				{(cpfCnpj || nome) && <span>{cpfCnpj && `(${cpfCnpj})`}{(cpfCnpj && nome) && ` - `}{nome && nome}</span>}
			</span>
		)
	}

	return (
		<>
			<OverlayPanel
				id="id-cliente-overlay-panel-nfce"
				style={{ right: `${isMobile ? '12px' : null}`, maxWidth: '500px' }}
				ref={panelDetalhes}
				showCloseIcon
				appendTo={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				onHide={() => setModalCliente(false)}
				onShow={() => setModalCliente(true)}
			>
				<ModalCliente
					cliente={cliente}
					setFieldValue={setFieldValue}
					setFocusCliente={setFocusCliente}
					isFullScreen={isFullScreen}
				/>
			</OverlayPanel>
			<span
				style={{
					display: 'flex',
					flexDirection: isLessHd || isTablet || isMobile ? 'column' : 'row',
					width: '100%',
					alignItems: isLessHd || isTablet || isMobile ? 'center' : 'strech',
				}}
			>
				<Col
					sm={5}
					md={7}
					lg={6}
					xl={5}
					style={{
						padding: isMobile ? '0.5rem 0 0 0' : '0.5rem 0 0 0.5rem',
						alignSelf: isLessHd || isTablet ? 'flex-end' : isMobile ? 'center' : 'auto',
					}}
				>
					<Button
						id="id-cliente-nfce"
						className="p-button step-nfce-pessoa"
						label="Cliente [F3]"
						icon="fa fa-user-plus"
						style={{ width: '100%' }}
						onClick={(e) => panelDetalhes.current.toggle(e)}
					/>
				</Col>
				<Col
					sm={7}
					md={7}
					lg={12}
					xl={7}
					style={{
						width: isLessHd || isTablet || isMobile ? '100%' : null,
						padding: '0rem 0rem 0 0.5rem',
						paddingTop: montarLabelCliente()?.length > 56 || !isLessHd || !isTablet || !isMobile ? '0rem' : '0.5rem',
						paddingRigth: '0px',
						wordWrap: 'break-word',
						overflow: 'hidden',
						textOverflow: isLessHd || isTablet || isMobile ? 'ellipsis' : 'clip',
						wordBreak: isLessHd || isTablet || isMobile ? 'break-all' : 'normal',
						whiteSpace: isLessHd || isTablet || isMobile ? 'nowrap' : 'normal',
						textAlign: isTablet || isMobile ? 'center' : isLessHd ? 'end' : 'left',
						maxHeight: '3.2rem',
						display: 'flex',
						justifyContent: 'end',
					}}
					title={cliente.nome}
				>
					{montarLabelCliente()}
				</Col>
			</span>
		</>
	);
}

export default Cliente;
