import { useState } from 'react';
import { modulos } from '../../../../Common';
import { If } from '../../../../components';
import ListagemRelatorios from '../../../../components/ListagemRelatorios';
import ModalComissaoAnalitico from './components/ModalComissaoAnalitico';
import ModalComissaoSintetico from './components/ModalComissaoSintetico';
import ModalFaturamentoPorPeriodo from './components/ModalFaturamentoPorPeriodo';
import ModalFaturamentoPorNcm from './components/ModalFaturamentoPorNcm';
import ModalPedidosPorPeriodo from './components/ModalPedidosPorPeriodo';
import ModalProdutosPorPedido from './components/ModalProdutosPorPedido';
import ModalOrcamentosPorPeriodo from './components/ModalOrcamentosPorPeriodo';

function VendasRelatorios() {
	const [exibirModalFaturamentoPorPeriodo, setExibirModalFaturamentoPorPeriodo] = useState(false);
	const [exibirModalPedidosPorPeriodo, setExibirModalPedidosPorPeriodo] = useState(false);
	const [exibirModalProdutosPorPedido, setExibirModalProdutosPorPedido] = useState(false);
	const [exibirModalComissaoAnalitico, setExibirModalComissaoAnalitico] = useState(false);
	const [exibirModalComissaoSintetico, setExibirModalComissaoSintetico] = useState(false);
	const [exibirModalFaturamentoPorNcm, setExibirModalFaturamentoPorNcm] = useState(false);
	const [exibirModalOrcamentosPorPeriodo, setExibirModalOrcamentosPorPeriodo] = useState(false);

	const metodosImprimir = [
		{
			nome: 'FATURAMENTO_PERIODO',
			onImprimir: () => setExibirModalFaturamentoPorPeriodo(true),
		},
		{
			nome: 'PEDIDOS_PERIODO',
			onImprimir: () => setExibirModalPedidosPorPeriodo(true),
		},
		{
			nome: 'PRODUTOS_PEDIDOS_PERIODO',
			onImprimir: () => setExibirModalProdutosPorPedido(true),
		},
		{
			nome: 'COMISSAO_ANALITICO',
			onImprimir: () => setExibirModalComissaoAnalitico(true),
		},
		{
			nome: 'COMISSAO_SINTETICO',
			onImprimir: () => setExibirModalComissaoSintetico(true),
		},
		{
			nome: 'FATURAMENTO_POR_NCM',
			onImprimir: () => setExibirModalFaturamentoPorNcm(true),
		},
		{
			nome: 'ORCAMENTOS_PERIODO',
			onImprimir: () => setExibirModalOrcamentosPorPeriodo(true),
		},
	];

	return (
		<>
			<ListagemRelatorios header="Relatórios de vendas" modulo={modulos.VENDAS} metodosImprimir={metodosImprimir} />
			<If test={exibirModalFaturamentoPorPeriodo}>
				<ModalFaturamentoPorPeriodo
					visible={exibirModalFaturamentoPorPeriodo}
					onHide={() => setExibirModalFaturamentoPorPeriodo(false)}
				/>
			</If>
			<If test={exibirModalPedidosPorPeriodo}>
				<ModalPedidosPorPeriodo
					visible={exibirModalPedidosPorPeriodo}
					onHide={() => setExibirModalPedidosPorPeriodo(false)}
				/>
			</If>
			<If test={exibirModalProdutosPorPedido}>
				<ModalProdutosPorPedido
					visible={exibirModalProdutosPorPedido}
					onHide={() => setExibirModalProdutosPorPedido(false)}
				/>
			</If>
			<If test={exibirModalComissaoAnalitico}>
				<ModalComissaoAnalitico
					visible={exibirModalComissaoAnalitico}
					onHide={() => setExibirModalComissaoAnalitico(false)}
				/>
			</If>
			<If test={exibirModalComissaoSintetico}>
				<ModalComissaoSintetico
					visible={exibirModalComissaoSintetico}
					onHide={() => setExibirModalComissaoSintetico(false)}
				/>
			</If>
			<If test={exibirModalFaturamentoPorNcm}>
				<ModalFaturamentoPorNcm
					visible={exibirModalFaturamentoPorNcm}
					onHide={() => setExibirModalFaturamentoPorNcm(false)}
				/>
			</If>
			<If test={exibirModalOrcamentosPorPeriodo}>
				<ModalOrcamentosPorPeriodo
					visible={exibirModalOrcamentosPorPeriodo}
					onHide={() => setExibirModalOrcamentosPorPeriodo(false)}
				/>
			</If>
		</>
	);
}

export default VendasRelatorios;
