import { buscarDadosLoginLocalStorage, colors } from 'Common';

const DADOS_LOGIN = buscarDadosLoginLocalStorage();
export const CADASTROURL = '/administracao_planos/cadastro';
export const PESQUISAURL = '/administracao_planos';

export const PLANOS_TIPO = {
	GRATUITO: 'GRATUITO',
	NORMAL: 'NORMAL',
	EXCLUSIVO: 'EXCLUSIVO',
};

export const PLANOS_APLICACAO = {
	DOCS_DIGITAIS: 'DOCS_DIGITAIS',
	MSYS_GESTOR: 'MSYS_GESTOR',
	GESTOR_MEI: 'GESTOR_MEI',
};

export const PLANOS_TIPO_OPTIONS = [
	{ label: 'Gratuito', value: PLANOS_TIPO.GRATUITO },
	{ label: 'Normal', value: PLANOS_TIPO.NORMAL, isDisabled: DADOS_LOGIN.autoridade !== 'ADMIN' },
	{ label: 'Exclusivo', value: PLANOS_TIPO.EXCLUSIVO },
];

export const PLANOS_APLICACAO_OPTIONS = [
	{ label: 'DOCs Digitais', value: PLANOS_APLICACAO.DOCS_DIGITAIS },
	{ label: 'Msys Gestor', value: PLANOS_APLICACAO.MSYS_GESTOR },
	{ label: 'Gestor MEI', value: PLANOS_APLICACAO.GESTOR_MEI },
];

export const INITIAL_VALUES = {
	id: null,
	descricao: '',
	ilimitado: false,
	tipo: 'EXCLUSIVO',
	aplicacao: 'MSYS_GESTOR',
	suporteChat: false,
	valor: 0,
	recursos: [],

	usuarios: 0,
	armazenamento: 0,
	cadastrosPessoas: 0,
	cadastrosProdutos: 0,
	documentosMensais: 0,
	contasFinanceiras: 0,
	orcamentosMensais: 0,
	pedidosMensais: 0,
	nfesMensais: 0,
	nfcesMensais: 0,
	comprasMensais: 0,
	ordensMensais: 0,
	nfsesMensais: 0,
	mdfesMensais: 0,
	ctesMensais: 0,
	boletosMensais: 0,
};

export const STYLE_WARNINGS = {
	fontSize: '12px',
	color: colors.amarelo,
	height: '16px',
};
