import { useEffect, useState } from 'react';
import {
	buscarDadosLoginLocalStorage,
	calcularImpostosProduto,
	colors,
	copiarObjeto,
	formatarDecimais,
	formatarMonetarioDecimais,
	getSafeValue,
} from 'Common';
import { InputDouble, NenhumRegistroEncontrado } from 'components';
import { Field, useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {
	buscarPercentualAdicionalCondicaoPagamento,
	buscarPercentualAdicionalQuantidadeProduto,
} from '../../../../../../../../components/body/Vendas/Util/functions';
import { TooltipSubtotal } from '../../../../../../../../components/Produtos/components/TooltipSubtotal';
import InputProduto from '../../../../../../../../components/input/InputProduto';
import { Grid } from '../../../../../../../../components';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

const initialValue = {
	produtoSelecionado: null,
	quantidadeSelecionada: 1,
};

function ListaProdutosDevolucaoCondicional({
	utilizaTabelaPreco,
	valuesFormPedido,
	isMobile,
	isTablet,
	informacoesPermissoes,
}) {
	const { values, errors, setFieldValue } = useFormikContext();

	const [focoParaInputProduto, setFocoParaInputProduto] = useState(true);

	const { filialConectada } = buscarDadosLoginLocalStorage();
	const utilizaTabelaPrecoDescontoQuantidade = filialConectada?.parametrosVendas?.utilizaTabelaPrecoDescontoQuantidade;
	const decimaisQtd = filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;
	const hasErrorProduto = errors?.produtos?.length > 0;
	const { podeEditar } = informacoesPermissoes;

	useEffect(() => {
		document.addEventListener('keydown', keyDownWatched);

		return () => {
			document.removeEventListener('keydown', keyDownWatched);
		}
	},[])

	useEffect(() => {
		if (focoParaInputProduto) {
			document.getElementById('id-produto-devolucao-condicional').focus();
			setFocoParaInputProduto(false);
		}
	}, [focoParaInputProduto]);

	function keyDownWatched(event) {
		let input = '';

		if (event.ctrlKey && event.code === 'KeyP') {
			event.preventDefault();
			input = document.getElementById('id-produto-devolucao-condicional');
			input?.focus();
		}
		if (event.ctrlKey && event.code === 'KeyQ') {
			event.preventDefault();
			input = document.getElementById('id-quantidade-devolucao-condicional');
			input?.focus();
		}
	}

	async function calcularPercentualAdicionalTabelaPreco(quantidade) {
		const Promises = [
			buscarPercentualAdicionalCondicaoPagamento(
				valuesFormPedido.pagamentos,
				valuesFormPedido.informacoesComplementares?.tabelaPreco?.registro?.alteracoesPorCondicao
			),
			buscarPercentualAdicionalQuantidadeProduto(
				quantidade,
				valuesFormPedido.informacoesComplementares?.tabelaPreco?.registro?.descontosPorQuantidade
			),
		];

		return Promise.all(Promises).then((dados) => {
			const percentualAplicado = dados.reduce((percentual, dado) => {
				if (dado?.tipo === 'ACRESCIMO') {
					return percentual + (dado.percentual || 0);
				} else if (dado?.tipo === 'DESCONTO') {
					return percentual - (dado.percentual || 0);
				}
				return percentual;
			}, 0);

			return { percentual: Math.abs(percentualAplicado), tipo: percentualAplicado > 0 ? 'ACRESCIMO' : 'DESCONTO' };
		});
	}

	function calcularValorComTabelaPreco(valorAtual, adicionalTabelaPreco) {
		let valorFinal = valorAtual;
		const percentual = adicionalTabelaPreco.percentual / 100;

		if (adicionalTabelaPreco.tipo === 'ACRESCIMO') {
			valorFinal += valorAtual * percentual;
		} else if (adicionalTabelaPreco.tipo === 'DESCONTO') {
			valorFinal -= valorAtual * percentual;
			valorFinal = Math.max(valorFinal, 0);
		}

		return valorFinal;
	}

	async function atualizarProduto(produtoAtual, produtoAlterado, index) {
		const novoProduto = copiarObjeto(produtoAlterado);
		const quantidadeAntiga = novoProduto.foiAlterado ? produtoAtual.quantidadeInicial : produtoAtual.quantidade;
		const novaQuantidade = produtoAlterado.quantidade;

		if (novoProduto) {
			novoProduto.foiAlterado = novaQuantidade !== quantidadeAntiga;

			if (utilizaTabelaPreco && utilizaTabelaPrecoDescontoQuantidade) {
				const adicionalTabelaPreco = await calcularPercentualAdicionalTabelaPreco(novaQuantidade);

				novoProduto.adicionalTabelaPreco = {
					percentual: adicionalTabelaPreco.percentual,
					tipo: adicionalTabelaPreco.tipo,
				};

				novoProduto.valor = parseFloat(
					calcularValorComTabelaPreco(novoProduto.valorOriginalProduto, novoProduto.adicionalTabelaPreco).toFixed(
						decimaisPreco
					)
				);
			}

			calcularImpostosProduto(novoProduto);

			novoProduto.subtotal = parseFloat(
				(
					getSafeValue(novoProduto.baseCalculo) +
					getSafeValue(novoProduto.valorIcmsSt) +
					getSafeValue(novoProduto.tributos?.icms?.valorIcmsSt) +
					getSafeValue(novoProduto.tributos?.ipi?.valorIpi)
				).toFixed(2)
			);

			if (novoProduto.quantidade > 0) {
				setFieldValue(`produtos[${index}]`, novoProduto);
			} else {
				setFieldValue(
					`produtos`,
					values.produtos.filter((item) => item.id !== novoProduto.id)
				);
			}
		}
	}

	function renderLabelProduto(row) {
		return row.produto ?? ' - ';
	}

	function renderLabelQuantidade(row, options) {
		const color =
			hasErrorProduto && errors?.produtos[options.rowIndex]?.quantidade ? colors.vermelho : colors.cinzaText;
		return (
			<div
				className={`id-quantidade-produto-devolucao-condicional-listagem-${options.rowIndex}`}
				title={
					hasErrorProduto && errors?.produtos[options.rowIndex]?.quantidade
						? errors?.produtos[options.rowIndex]?.quantidade
						: ''
				}
				style={{
					color: color,
					fontWeight: hasErrorProduto && errors?.produtos[options.rowIndex]?.quantidade ? 'bold' : 'normal',
				}}
			>
				{formatarDecimais(row.quantidade, decimaisQtd)}
			</div>
		);
	}

	function renderLabelValorUnitario(row, options) {
		const color = hasErrorProduto && errors?.produtos[options.rowIndex]?.valor ? colors.vermelho : colors.cinzaText;
		return (
			<div
				className={`id-valor-unitario-produto-devolucao-condicional-listagem-${options.rowIndex}`}
				title={
					hasErrorProduto && errors?.produtos[options.rowIndex]?.valor ? errors?.produtos[options.rowIndex]?.valor : ''
				}
				style={{
					color: color,
					fontWeight: hasErrorProduto && errors?.produtos[options.rowIndex]?.valor ? 'bold' : 'normal',
				}}
			>
				{formatarMonetarioDecimais(row.valor, decimaisPreco)}
			</div>
		);
	}

	function renderLabelSubtotal(row, options) {
		const color = hasErrorProduto && errors?.produtos[options.rowIndex]?.subtotal ? colors.vermelho : colors.cinzaText;
		return (
			<>
				<TooltipSubtotal
					indexProduto={options.rowIndex}
					produto={row}
					target=".id-subtotal-produto-devolucao-condicional-listagem-"
				/>
				<div
					className={`id-subtotal-produto-devolucao-condicional-listagem-${options.rowIndex}`}
					title={
						hasErrorProduto && errors?.produtos[options.rowIndex]?.subtotal
							? errors?.produtos[options.rowIndex]?.subtotal
							: ''
					}
					style={{
						color: color,
						fontWeight: hasErrorProduto && errors?.produtos[options.rowIndex]?.subtotal ? 'bold' : 'normal',
					}}
				>
					{formatarMonetarioDecimais(row.subtotal, decimaisPreco)}
				</div>
			</>
		);
	}

	function renderEditQuantidade(options) {
		return (
			<InputDouble
				colStyle={{ padding: '0px', maxWidth: isMobile || isTablet ? '80%' : null }}
				label=""
				size={13}
				name="quantidade"
				value={options.value}
				onChange={(event) => options.editorCallback(event.target.value ?? 0)}
				decimalScale={decimaisQtd}
				allowNegative={false}
				errors={errors?.quantidade}
				{...informacoesPermissoes}
			/>
		);
	}

	async function onBlurColumn(e) {
		await atualizarProduto(e.rowData, e.newRowData, e.rowIndex);
		setFocoParaInputProduto(true);
	}

	async function removerProduto(produtoASerRemovido) {
		values.produtos.forEach((produto, index) => {
			if (produto.id === produtoASerRemovido.value) {
				const novoProduto = {
					...produto,
					quantidade: produto.quantidade - values.quantidadeSelecionada,
				};
				atualizarProduto(produto, novoProduto, index);
			}
		});
		setFieldValue('produtoSelecionado', initialValue.produtoSelecionado);
		setFieldValue('quantidadeSelecionada', initialValue.quantidadeSelecionada);
	}

	return (
		<>
			<Grid id="id-grid-produto-devolucao-condicional">
				<InputProduto
					id="id-produto-devolucao-condicional"
					sm="12"
					md="12"
					lg="8"
					xl="9"
					name="produto"
					label="Produto"
					shortcut="[Ctrl+P]"
					produtoSelecionado={values.produtoSelecionado}
					decimaisPreco={decimaisPreco}
					decimaisQtd={decimaisQtd}
					setFieldValue={setFieldValue}
					funcaoParaOProduto={removerProduto}
					disabled={!podeEditar}
					searchDisabled={!podeEditar}
					initialValue={initialValue}
					permiteOnchange={podeEditar}
					isMobile={isMobile}
					informacoesPermissoes={informacoesPermissoes}
					listaDeProdutos={values.produtos}
					exibeItem
				/>
				<Field
					sm="12"
					md="12"
					lg="4"
					xl="3"
					component={InputDouble}
					id="id-quantidade-devolucao-condicional"
					name="quantidade"
					label="Qtde. a ser devolvida"
					shortcut="[Ctrl+Q]"
					value={values.quantidadeSelecionada}
					onChange={(event) => setFieldValue('quantidadeSelecionada', event.target.value)}
					placeholder={formatarDecimais(0, decimaisQtd)}
					decimalScale={decimaisQtd}
					size={17}
					allowNegative={false}
					{...informacoesPermissoes}
				/>
			</Grid>
			<DataTable
				id="id-produtos-devolucao-condicional-listagem"
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				editMode="cell"
				value={values.produtos}
				style={{
					width: '100%',
					overflowY: values?.length > 6 ? 'scroll' : 'hidden',
					overflowX: 'hidden',
					maxHeight: isMobile ? '36.4rem' : '20.2rem',
				}}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					header="Item"
					field="item"
					body={(row) => row?.item}
					style={{
						...styleColumn,
						width: '40px',
					}}
				/>
				<Column
					header="Produto"
					field="produto"
					body={(row) => renderLabelProduto(row)}
					style={{
						...styleColumn,
					}}
				/>
				<Column
					header="Qtde. restante"
					field="quantidade"
					body={(row, options) => renderLabelQuantidade(row, options)}
					editor={(options) => renderEditQuantidade(options)}
					onCellEditComplete={onBlurColumn}
					style={{
						...styleColumn,
						width: '120px',
					}}
				/>
				<Column
					header="Valor unit."
					field="valor"
					body={(row, options) => renderLabelValorUnitario(row, options)}
					style={{
						...styleColumn,
						width: '140px',
					}}
				/>
				<Column
					header="Total produto"
					field="subtotal"
					body={(row, options) => renderLabelSubtotal(row, options)}
					style={{
						...styleColumn,
						width: '140px',
					}}
				/>
			</DataTable>
		</>
	);
}

export { ListaProdutosDevolucaoCondicional };
