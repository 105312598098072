import {
	DADOSLOCALSTORAGE,
	buscarDadosLoginLocalStorage,
	buscarItemLocalStorage,
	colors,
	copiarParaAreaTransferencia,
	services,
} from 'Common';
import { If, notify } from 'components';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { FaPrint, FaShareAlt } from 'react-icons/fa';
import { asyncImprimirVenda } from '../../../Requests';
import ModalCompartilharPorEmail from '../../../components/ModalCompartilharPorEmail';
import ModalCompartilharPorWhatsapp from '../../../components/ModalCompartilharPorWhatsapp';

const styleBotaoImprimir = {
	padding: '0px 10px',
	background: 'none',
	color: colors.cinzaDark,
	fontWeight: 'bold',
	fontSize: '15px',
	display: 'flex',
	alignItems: 'center',
	border: 'none',
};

export default function HeaderForm({ isVenda, isMobile, urlVendaOuOrcamento, tipoSituacao }) {
	const { values, dirty } = useFormikContext();
	const [organizacaoId] = useState(buscarItemLocalStorage(DADOSLOCALSTORAGE.dadosLogin).organizacao.id);
	const [modalEnviarPorEmailVisible, setModalEnviarPorEmailVisible] = useState(false);
	const [modalEnviarPorWhatsappVisible, setModalEnviarPorWhatsappVisible] = useState(false);

	const menuCompartilhar = useRef(null);

	const itensCompartilharMenu = buscarItensCompartilharMenu();

	const permiteImpressoes = values.situacao?.registro?.parametros?.permiteImpressoes;
	const permiteCompartilhamento = values.situacao?.registro?.parametros?.permiteCompartilhamento;

	async function imprimirVenda() {
		await asyncImprimirVenda(urlVendaOuOrcamento, values.id, organizacaoId, ({ data: pdf }) => {
			const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const relatorio = window.open(arquivoURL);
			if (relatorio) {
				relatorio.onload = () => {
					setTimeout(() => {
						relatorio.document.title = urlVendaOuOrcamento === 'orcamentos' ? 'Orçamento' : 'Pedido de Venda';
					}, 250);
				};
			}
		});
	}

	function copiarLinkCompartilhavel() {
		const organizacaoId = buscarDadosLoginLocalStorage().organizacao.id;
		copiarParaAreaTransferencia(
			`${services.GESTOR}/v1/${urlVendaOuOrcamento}/${values.id}/organizacoes/${organizacaoId}/imprimir`,
			() => {
				notify('Link copiado para área de transferência');
			}
		);
	}

	function buscarItensCompartilharMenu() {
		return [
			{
				label: 'Enviar por e-mail',
				icon: 'fa fa-send',
				command: () => setModalEnviarPorEmailVisible(true),
			},
			{
				label: 'Enviar por Whatsapp',
				icon: 'fa fa-whatsapp',
				command: () => setModalEnviarPorWhatsappVisible(true),
			},
			{
				label: 'Copiar link compartilhável',
				icon: 'fa fa-link',
				command: copiarLinkCompartilhavel,
			},
		];
	}

	function buscarTitle() {
		return <span>{isVenda() ? 'Pedido de venda ' : 'Orçamento de venda '}</span>;
	}

	function buscarBotoes() {
		return (
			<>
				<button
					type="button"
					style={{
						...styleBotaoImprimir,
						cursor: dirty || !permiteCompartilhamento ? 'default' : 'pointer',
						opacity: dirty || !permiteCompartilhamento ? 0.5 : 1,
					}}
					onClick={!dirty ? (event) => menuCompartilhar.current?.toggle(event) : undefined}
					title={!permiteCompartilhamento ? 'Situação sem permissão para compartilhamento' : ''}
					disabled={!permiteCompartilhamento}
				>
					<Menu
						style={{ width: '220px' }}
						model={itensCompartilharMenu}
						popup
						ref={menuCompartilhar}
						id="menu_compartilhar"
					/>
					<FaShareAlt size="15px" style={!isMobile ? { marginRight: '5px' } : {}} />
					{!isMobile && <span>Compartilhar</span>}
				</button>
				<button
					type="button"
					style={{
						...styleBotaoImprimir,
						cursor: dirty || !permiteImpressoes ? 'default' : 'pointer',
						opacity: dirty || !permiteImpressoes ? 0.5 : 1,
					}}
					title={
						dirty
							? 'Salve a venda ou orçamento para imprimir'
							: !permiteImpressoes
								? 'Situação sem permissão para impressões'
								: ''
					}
					onClick={!dirty ? imprimirVenda : undefined}
					disabled={!permiteImpressoes}
				>
					<FaPrint size="15px" style={!isMobile ? { marginRight: '5px' } : {}} />
					{!isMobile && <span>Imprimir</span>}
				</button>
			</>
		);
	}

	if (!values.id) {
		return <div>{isVenda() ? 'Novo pedido de venda ' : 'Novo orçamento de venda'}</div>;
	}

	return (
		<>
			<span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				{buscarTitle()}
				<span style={{ display: 'flex', alignItems: 'center', fontSize: '16px', cursor: 'default' }}>
					{buscarBotoes()}
				</span>
			</span>
			<If test={modalEnviarPorEmailVisible}>
				<ModalCompartilharPorEmail
					idVenda={values.id}
					idPessoa={values.pessoa && values.pessoa.value}
					numero={values.numero}
					statusVenda={tipoSituacao}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					visible={modalEnviarPorEmailVisible}
					onHide={() => setModalEnviarPorEmailVisible(false)}
				/>
			</If>
			<If test={modalEnviarPorWhatsappVisible}>
				<ModalCompartilharPorWhatsapp
					idVenda={values.id}
					idPessoa={values.pessoa && values.pessoa.value}
					cliente={values.pessoa}
					numero={values.numero}
					urlVendaOuOrcamento={urlVendaOuOrcamento}
					visible={modalEnviarPorWhatsappVisible}
					isVenda={isVenda()}
					onHide={() => setModalEnviarPorWhatsappVisible(false)}
				/>
			</If>
		</>
	);
}
