import { buscarDadosLoginLocalStorage } from 'Common';
import { modalidadeICMSST } from 'views/fiscal/Util/constantes';

export const NOVO_PRODUTO_VENDA = {
	id: null,
	item: 1,
	produto: null,
	descricao: null,

	cfop: {
		value: 'ea7b1a76-4705-854f-6291-f71b4d06c3f1',
		label: '5102',
		registro: {
			id: 'ea7b1a76-4705-854f-6291-f71b4d06c3f1',
			codigo: '5102',
			descricao: 'Venda de mercadoria adquirida ou recebida de terceiros',
		},
	},
	operacaoFiscal: null,
	ncm: null,
	unidadeMedida: null,
	cest: null,
	codigoBarras: null,
	codigoBeneficiario: null,
	numeroPedidoCompra: null,
	itemPedidoCompra: null,
	utilizouTabelaPromocional: false,
	adicionalTabelaPreco: {
		percentual: 0,
		tipo: 'DESCONTO',
	},

	quantidade: 1,
	desconto: 0,
	valor: 0,
	valorOriginal: 0,

	valorAntesAlteracao: null,
	alterouValorManualmente: false,

	acessorias: 0,
	seguro: 0,
	frete: 0,

	descontoMaximoPercentual: 100,
	comissaoPercentual: 0,
	comissaoValor: 0,

	tributos: {
		icms: {
			cstCsosn: {
				label:
					buscarDadosLoginLocalStorage().filialConectada?.regimeTributario === ' SIMPLES_NACIONAL'
						? '102 - Tributada pelo Simples Nacional sem permissão de crédito'
						: '00 - Tributada integralmente',
				value:
					buscarDadosLoginLocalStorage().filialConectada?.regimeTributario === ' SIMPLES_NACIONAL'
						? '3fffc6e3-866d-4a8b-8285-fa1fc406abfb'
						: 'e2a76bea-82cc-4ca8-9f56-848d9e6d2206',
			},
			origemProduto: null,
			percentualCreditoSimplesNacional: 0,
			valorCreditoSimplesNacional: 0,
			percentualBaseIcms: 0,
			percentualReducaoBaseIcms: 0,
			modalidadeCalculo: modalidadeICMSST.VALOR_OPERACAO,
			baseIcms: 0,
			percentualIcms: 0,
			valorIcms: 0,
			valorIcmsSubstituto: 0,
			modalidadeCalculoSt: modalidadeICMSST.MARGEM_VALOR_AGREGADO,
			percentualMva: 0,
			valorPauta: 0,
			percentualBaseIcmsSt: 0,
			percentualReducaoBaseIcmsSt: 0,
			baseIcmsSt: 0,
			percentualIcmsSt: 0,
			percentualBaseIcmsStRetido: 0,
			percentualIcmsStRetido: 0,
			valorIcmsSt: 0,
			baseRetidoSt: 0,
			valorRetidoSt: 0,
			percentualDiferimento: 0,
			valorDiferimento: 0,
			baseFundoCombatePobreza: 0,
			percentualFundoCombatePobreza: 0,
			valorFundoCombatePobreza: 0,
		},
		ipi: {
			enquadramentoIpi: {
				label: '999 - Tributação normal IPI; Outros;',
				registro: {
					codigo: '999',
					descricao: 'Tributação normal IPI; Outros;',
					id: '15c1cd1b-29ba-4769-83bf-e399e9e3cb18',
				},
				value: '15c1cd1b-29ba-4769-83bf-e399e9e3cb18',
			},
			cst: {
				label: '53 - Saída Não-Tributada',
				registro: {
					codigo: '53',
					descricao: 'Saída Não-Tributada',
					id: '63ca257c-fb24-4bcc-80b8-2b6f45ee0d2e',
				},
				value: '63ca257c-fb24-4bcc-80b8-2b6f45ee0d2e',
			},
			percentualBaseIpi: 0,
			baseIpi: 0,
			percentualIpi: 0,
			valorIpi: 0,
		},
		pis: {
			cst: {
				label: '07 - Operação Isenta da Contribuição',
				registro: {
					codigo: '07',
					descricao: 'Operação Isenta da Contribuição',
					id: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
				},
				value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
			},
			percentualBasePis: 0,
			basePis: 0,
			percentualPis: 0,
			valorPis: 0,
			percentualBasePisSt: 0,
			basePisSt: 0,
			percentualPisSt: 0,
			valorPisSt: 0,
		},
		cofins: {
			cst: {
				label: '07 - Operação Isenta da Contribuição',
				value: '314c4cd9-a8d9-4edc-80f9-2bac14234d2b',
			},
			percentualBaseCofins: 0,
			baseCofins: 0,
			percentualCofins: 0,
			valorCofins: 0,
			percentualBaseCofinsSt: 0,
			baseCofinsSt: 0,
			percentualCofinsSt: 0,
			valorCofinsSt: 0,
		},
		devolvido: {
			percentualDevolvido: 100,
			valorIpiDevolvido: 0,
		},
	},
	importacao: {
		numeroDocumentoImportacao: null,
		dataRegistro: null,
		dataDesembaraco: null,
		localDesembaraco: null,
		estadoDesembaraco: null,
		exportador: null,
		fabricante: null,
	},

	subtotal: 0,
	baseCalculo: 0,
	personalizado: false,
};

export const moduloUtilizado = {
	NOTA_FISCAL: 'NOTA_FISCAL',
	PEDIDO_VENDA: 'PEDIDO_VENDA',
	ORCAMENTO: 'ORCAMENTO',
	ORDEM_SERVICO: 'ORDEM_SERVICO',
	NOTA_FISCAL_SERVICO: 'NOTA_FISCAL_SERVICO',
};

export const StatusPedidoEOrcamento = {
	PENDENTE: 'PENDENTE',
	FINALIZADO: 'FINALIZADO',
	CANCELADO: 'CANCELADO',
	EXPIRADO: 'EXPIRADO',
};

export const StatusNota = {
	REJEITADA: 'REJEITADA',
	TRANSMITIDA: 'TRANSMITIDA',
	CORRIGIDA: 'CORRIGIDA',
	CANCELADO: 'CANCELADO',
	NAO_ENVIADA: 'NAO_ENVIADA',
	DENEGADA: 'DENEGADA',
	AGUARDANDO_AUTORIZACAO: 'AGUARDANDO_AUTORIZACAO',
};
