import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, useFormikContext, withFormik } from 'formik';

import { colors, copiarObjeto, formatarMonetarioDecimais, keyFilterConsultaRsql } from 'Common';
import {
	AutoProgressBar,
	ButtonAdicionarItem,
	ButtonCancelar,
	ButtonExcluirTable,
	ButtonSalvar,
	Divider,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputDouble,
	Message,
	Modal,
	SingleSelectCategoria,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
} from 'components';
import { useEffect } from 'react';
import { validarFormulario } from 'views/Util';
import { ratearValoresCategorias } from 'components/select/SingleSelectCategoria';

const NOVA_CATEGORIA = {
	categoria: '',
	percentual: 0,
	valor: 0,
};

function ModalMultiCategoriaView({
	visible,
	onHide,
	dirty,
	informacoesPermissoes,
	url,
	values,
	isMobile,
	isTablet,
	setValues,
	onSave,
	valueTotalList,
	disabledFields,
}) {
	const { resetForm, initialValues, errors, handleSubmit, validateForm } = useFormikContext();

	const prefixoHeader = 'Selecionar categorias';

	const buttonAdicionarStyle = {
		background: 'none',
		border: 'none',
		fontWeight: 'bold',
		boxShadow: 'none',
		color: colors.verde,
		marginTop: '0.7rem',
		paddingLeft: '0',
	};
	
	useEffect(() => {
		ratearValores(values, -1, true);
	}, [valueTotalList]);

	function ratearValores(values, indexInicial = -1, manterPercentual = false) {
		setValues(ratearValoresCategorias(values, valueTotalList, indexInicial, manterPercentual));
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function addCategoria() {
		const lista = copiarObjeto(values);
		lista.push(copiarObjeto(NOVA_CATEGORIA));
		 setValues(lista);
		const index = lista.length - 2; 
		ratearValores(lista, index, false);
	}

	function setValueOperacao(index, key, event) {
		const listaCategorias = values;
		listaCategorias[index][key] = event;
		setValues(Array.from(listaCategorias));
	}

	function valorTotalCategorias() {
		return values.reduce((acc, item) => acc + (item.valor || 0), 0);
	}

	async function handleClickSalvar() {
		handleSubmit();
		if (await validarFormulario({ values: values, validateForm: validateForm })) {
			onSave(values);
		}
	}

	function FormatarTextoWarning(errors) {
		const listaErrors = Object.keys(errors).filter((r) => r !== 'categorias');
		return (
			<span>
				{listaErrors.map((erro) => (
					<span key={erro}>
						{errors[erro]}
						<br />
					</span>
				))}
			</span>
		);
	}

	function onBlurValor(index) {
		const updatedValues = values;
		updatedValues[index].percentual = parseFloat(((updatedValues[index].valor * 100) / valueTotalList).toFixed(4));
		ratearValores(values, index);
	}

	function onBlurPercentual(index) {
		const updatedValues = values;
		updatedValues[index].valor = parseFloat(((valueTotalList * updatedValues[index].percentual) / 100).toFixed(2));
		ratearValores(values, index);
	}

	return (
		<Modal header={prefixoHeader} visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty}
						onClick={handleClickSalvar}
						{...informacoesPermissoes}
					/>
				</FormActions>
				<If test={errors.somaTotal || errors.somaPercentual}>
					<Message
						severity="error"
						text={errors ? FormatarTextoWarning(errors) : null}
						colStyle={{ padding: '5px 0px' }}
					/>
					<br />
				</If>
				<FormContent>
					<Grid className="grid-categorias" style={{ overflowY: 'scroll', maxHeight: '18.2rem' }}>
						{values?.length
							? values?.map((registro, index) => {
									const { categoria, percentual, valor } = registro;
									return (
										<div key={`${index}`} style={{ display: 'flex', width: '100%', paddingLeft: '0.5rem' }}>
											<Grid style={{ width: '97%' }}>
												<Field
													sm="12"
													md="5"
													lg="6"
													xl="6"
													label="Categoria"
													name="categoria"
													helpMessage="Categoria"
													placeholder="Pesquisar"
													component={SingleSelectCategoria}
													value={categoria}
													index={index}
													onChange={(e) => setValueOperacao(index, 'categoria', e)}
													url={url}
													disabled={disabledFields}
													esconderBotao={disabledFields}
													obrigatorio
													isClearable={false}
													touched
													errors={errors && errors.categorias?.length > 0 ? errors.categorias[index]?.categoria : null}
													useFormErrors={false}
													{...informacoesPermissoes}
												/>
												<Field
													sm="6"
													md="6"
													lg="3"
													xl="3"
													component={InputDouble}
													label="Percentual"
													autoFocus
													decimalScale={4}
													obrigatorio
													disabled={disabledFields}
													value={percentual}
													keyfilter={keyFilterConsultaRsql}
													onChange={(e) => setValueOperacao(index, 'percentual', e.target.value)}
													name="percentual"
													touched
													errors={errors && errors.categorias?.length > 0 ? errors.categorias[index]?.percentual : null}
													maxValue={100}
													onBlur={() => onBlurPercentual(index)}
													suffix="%"
													size={255}
													id="CategoriasInputFieldpercentual"
													{...informacoesPermissoes}
												/>
												<Field
													sm="6"
													md="6"
													lg="3"
													xl="3"
													component={InputDouble}
													label="Valor"
													disabled={disabledFields}
													value={valor}
													autoFocus
													obrigatorio
													keyfilter={keyFilterConsultaRsql}
													onChange={(e) => setValueOperacao(index, 'valor', e.target.value)}
													name="valor"
													touched
													errors={errors && errors.categorias?.length > 0 ? errors.categorias[index]?.valor : null}
													onBlur={() => onBlurValor(index)}
													maxValue={valueTotalList}
													prefix="R$ "
													size={255}
													id="CategoriasInputFieldValor"
													{...informacoesPermissoes}
												/>
											</Grid>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													paddingTop: '0.5rem',
													paddingLeft: '0.5rem',
													paddingRight: values?.length > 5 ? '0.5rem' : '0',
													marginBottom: isMobile && index !== values.length - 1 ? '2.5rem' : '0',
												}}
											>
												<Field
													sm="12"
													md="1"
													lg="1"
													xl="1"
													disabled={disabledFields}
													component={ButtonExcluirTable}
													onClick={() => {
														const updatedCategorias = [...values];
														updatedCategorias.splice(index, 1);
														setValues(updatedCategorias);
													}}
												/>
											</div>
										</div>
									);
								})
							: null}
					</Grid>
					<ButtonAdicionarItem
						label="Adicionar categoria"
						style={{ ...buttonAdicionarStyle }}
						onClick={addCategoria}
						disabled={disabledFields}
					/>
					<Divider />
					<br />
					<div
						style={{
							fontSize: '20px',
							fontWeight: 'bold',
							color: colors.azul,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
							paddingLeft: isMobile || isTablet ? '12px' : '0px',
							marginLeft: isMobile || isTablet ? '0px' : '8px',
						}}
						title={`Valor atingido: ${formatarMonetarioDecimais(valorTotalCategorias())}\nPercentual atingido: ${(
							(valorTotalCategorias() * 100) /
							valueTotalList
						).toFixed(2)} %`}
					>
						{'Total: '}
						{formatarMonetarioDecimais(valueTotalList)}
					</div>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalMultiCategoriaForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.values) {
			return props.values;
		} else {
			return [copiarObjeto(NOVA_CATEGORIA)];
		}
	},

	validate(values, props) {
		const errors = {
			categorias: [],
		};

		let somaCategorias = 0;
		let somaPorcentagem = 0;

		values.forEach((registro) => {
			const error = {};

			if (!registro.categoria) {
				error.categoria = 'Campo obrigatório';
			} else if (values.filter((r) => r.categoria.value === registro.categoria.value).length > 1) {
				error.categoria = 'Categoria duplicada';
			}
			if (!registro.valor || registro.valor === 0) {
				error.valor = 'Campo obrigatório';
			} else {
				somaCategorias += registro.valor;
			}
			if (!registro.percentual || registro.percentual === 0) {
				error.percentual = 'Campo obrigatório';
			} else {
				somaPorcentagem += registro.percentual;
			}
			errors.categorias.push(Object.keys(error).length > 0 ? error : undefined);
		});

		if (parseFloat(somaCategorias.toFixed(2)) !== props.valueTotalList) {
			errors.somaTotal = 'Soma dos valores das categorias diferente do valor do pagamento.';
		}

		if (parseFloat(somaPorcentagem.toFixed(4)) > 100.0000) {
			errors.somaPercentual = 'Soma dos percentuais maior que o permitido.';
		}

		let errorsWithoutUndefined = [];

		errorsWithoutUndefined = errors.categorias.filter((error) => typeof error !== 'undefined');

		if (errorsWithoutUndefined?.length > 0 || errors.somaTotal || errors.somaPercentual) {
			return errors;
		} else {
			return {};
		}
	},

	handleSubmit: () => {},
})(ModalMultiCategoriaView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(ModalMultiCategoriaForm));
