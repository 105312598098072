import {
	asyncGetOperacaoFiscalFavorita,
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	buscarItemLocalStorage,
	configuracoesUsuario,
	copiarObjeto,
	gerarUUID,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	salvarItemLocalStorage,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Form,
	FormActions,
	FormContent,
	Grid,
	INITIAL_VALUE_PAGAMENTO_NFCE,
	ModalLoadingImprimir,
	ModalLoadingTransmissao,
	Prompt,
	ToastTypes,
	Tutorial,
	notify,
	tutorialStepsNFCe,
	useGenerateParcelas,
	useRecalculatePagamentos,
} from 'components';
import { withFormik } from 'formik';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { asyncBuscarPedidoParaImportacao } from '../../../../../components/ModalPedidoImportacao/Requests';
import { atualizarUrl, metodosAtualizarUrl } from '../../../../Util';
import { useContextNFCe } from '../Context';
import {
	asyncGetCategoriaFavorita,
	asyncGetCondicaoPagamentoFavorita,
	asyncGetContaFavorita,
	asyncGetFormaPagamentoDinheiro,
	asyncGetNfce,
	asyncImprimirServicoLocal,
	buscarVendedorInicial,
	imprimirDANFE,
} from '../Requests';
import { converterNfceParaFormulario, converterPedidoParaNfce, converterValueParaSelect } from '../Util/NFCeConverter';
import { INITIAL_VALUES_NFCE, Status } from '../Util/constantes';
import CabecalhoCard from './components/CabecalhoCard';
import ItensCard from './components/ItensCard';
import { asyncGetConfiguracoesServicoLocal } from './components/ModalConfiguracoes/Requests';

import './Styles/index.css';

function NFCeFormView(props) {
	const { values, dirty, setFieldValue, isMobile, isTablet, isLessHd, isDesktop, isFullScreen, history, resetForm } =
		props;

	const {
		exibirLoadingTransmissao,
		exibirLoadingImprimir,
		messageLoadingTransmissao,
		setModalListagemVisible,
		setModalCancelamentoVisible,
		setModalFinalizacaoVisible,
		setModalMenuFiscalVisible,
		setJogarFocoParaProduto,
		setCloseAllModal,
		favoritos,
		setFavoritos,
		existeModalAberto,
		setPodeInserir,
		podeEditar,
		informacoesPermissoes,
		setModalPedidoImportacao,
		setDavImportado,
		setMessageLoadingTransmissao,
		setExibirLoadingImprimir,
		setExibirLoadingTransmissao,
		setPodeEditar,
		transmissaoFlag,
	} = useContextNFCe();

	const decimaisQtd = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisQtd ?? 3;
	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	const [credencial] = useState(buscarDadosLoginLocalStorage());
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [mostrarMenuFiscal, setMostrarMenuFiscal] = useState(false);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NFCE));
	const [atualizarTotal, setAtualizarTotal] = useState(false);

	const valuesKeyDown = useRef(values);

	const [generateParcelas] = useGenerateParcelas({
		valorPagamento: values.totalizadores?.totalLiquido,
		dataBaseParcela: values.emissao,
		favoritos,
	});

	useRecalculatePagamentos(
		{
			valorTotal: values.totalizadores?.totalLiquido,
			dataBaseParcela: values.emissao,
			favoritos,
		},
		[values.totalizadores?.totalLiquido]
	);

	useEffect(async () => {
		setaConfiguracoesLocais();
		document.addEventListener('keydown', keyDownWatched);
	}, []);

	useLayoutEffect(
		() => () => {
			document.removeEventListener('keydown', keyDownWatched);
			valuesKeyDown.current;
		},
		[]
	);

	useEffect(async () => {
		const idNFCe = props.match.params.id;

		getFavoritosPagamento();

		if (validarUUID(idNFCe) && !props.match.path.includes('/nfces/importar_pedido')) {
			await asyncGetNfce(idNFCe, async (nfce) => {
				const dadosFormulario = converterNfceParaFormulario(nfce.data);
				await resetForm({ values: dadosFormulario });

				setPodeInserir(
					usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.INSERIR) &&
						dadosFormulario?.status === Status.NAO_ENVIADA
				);
			});
		} else {
			await novaNFCe();
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_NFCE, false, null, false);
		}

		informacoesPermissoes.podeEditar = podeEditar && values.status === Status.NAO_ENVIADA;

		setTimeout(() => {
			setJogarFocoParaProduto(true);
		}, 100);
	}, []);

	useEffect(() => {
		if (props.match.path.includes('/nfces/importar_pedido')) {
			asyncBuscarPedidoParaImportacao(props.match.params.id, async ({ data: venda }) => {
				setDavImportado(true);
				setPodeInserir(false);

				const nfce = converterPedidoParaNfce(venda);
				nfce.serie = credencial.filialConectada.parametrosFiscalNFCe?.serieNfce;
				await props.setValues(nfce);
				atualizarUrl(props.history, '/nfces/cadastro', null, metodosAtualizarUrl.POP);
			});
		}
	}, [props.match.path]);

	useEffect(() => {
		setCloseAllModal(true);
	}, [isFullScreen]);

	useEffect(() => {
		if (atualizarTotal) {
			let totalProdutos = 0;
			let totalDesconto = 0;
			let totalAcessorias = 0;

			values.produtos.forEach((produto) => {
				totalProdutos += parseFloat((produto.quantidade * produto.valor)?.toFixed(2));
				totalDesconto += parseFloat(produto.desconto?.toFixed(2)) ?? 0;
				totalAcessorias += parseFloat(produto.acessorias?.toFixed(2)) ?? 0;
			});

			totalProdutos = parseFloat(totalProdutos.toFixed(2));
			totalDesconto = parseFloat(totalDesconto.toFixed(2)) ?? 0;
			totalAcessorias = parseFloat(totalAcessorias.toFixed(2)) ?? 0;

			const totalLiquido = parseFloat((totalProdutos - totalDesconto + totalAcessorias)?.toFixed(2));

			if (totalProdutos !== values.totalizadores.totalProdutos) {
				props.setFieldValue('totalizadores.totalProdutos', totalProdutos);
			}

			if (totalDesconto !== values.totalizadores.totalDesconto) {
				props.setFieldValue('totalizadores.totalDesconto', totalDesconto);
			}

			if (totalAcessorias !== values.totalizadores.totalAcessorias) {
				props.setFieldValue('totalizadores.totalAcessorias', totalAcessorias);
			}

			if (totalLiquido !== values.totalizadores.totalLiquido) {
				props.setFieldValue('totalizadores.totalLiquido', totalLiquido);
			}
		}
		setAtualizarTotal(false);
	}, [atualizarTotal]);

	useEffect(() => {
		valuesKeyDown.current = values;
	}, [values]);

	async function keyDownWatched(event) {
		preventKeyDownDefault(event);
		if (!existeModalAberto.current) {
			readerShortcuts(event);
		}

		defaultShortcuts(event);
		shortcutsToClose(event);
	}

	async function preventKeyDownDefault(event) {
		const keyboardhelper = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11'];
		let applyPreventDefault = false;

		if (keyboardhelper.includes(event.code)) {
			applyPreventDefault = true;
		} else if (event.ctrlKey) {
			switch (event.code) {
				case 'KeyZ':
					applyPreventDefault = false;
					break;
				case 'KeyX':
					applyPreventDefault = false;
					break;
				case 'KeyC':
					applyPreventDefault = false;
					break;
				case 'KeyV':
					applyPreventDefault = false;
					break;
				case 'KeyF':
					applyPreventDefault = false;
					break;
				case 'KeyA':
					applyPreventDefault = false;
					break;
				default:
					applyPreventDefault = true;
			}
		}

		if (applyPreventDefault) {
			await event.preventDefault();
		}
	}

	function readerShortcuts(event) {
		let input = '';

		if (event.ctrlKey && event.code === 'KeyP') {
			input = document.getElementById('id-produto-nfce');
			setTimeout(() => {
				input?.focus();
			}, 200);
		}

		if (event.ctrlKey && event.code === 'KeyQ') {
			input = document.getElementById('id-quantidade-nfce');
			input?.focus();
			input?.select();
		}

		if (event.code === 'F2') {
			document.getElementById('id-button-cancelar-nfce')?.click();
		}

		if (event.code === 'F3') {
			document.getElementById('id-cliente-nfce').click();

			if (document.getElementById('id-cliente-cadastrado-nfce')) {
				input = document.getElementById('id-cliente-cadastrado-nfce')?.getElementsByTagName('input')[0].focus();
			} else if (document.getElementById('id-nome-cliente-nfce')) {
				input = document.getElementById('id-nome-cliente-nfce');
				input?.focus();
			}
		}

		if (event.code === 'F4') {
			if (valuesKeyDown.current.produtos.length > 0) {
				setModalFinalizacaoVisible(true);
			}
		}

		if (event.code === 'F5') {
			setModalListagemVisible(true);
		}

		if (event.code === 'F6') {
			if (valuesKeyDown.current.id) {
				setModalCancelamentoVisible(true);
			}
		}

		if (event.code === 'F7') {
			if (valuesKeyDown.current.id) {
				document.getElementById('id-button-novo-nfce')?.click();
			}
		}

		if (event.code === 'F8') {
			if (!valuesKeyDown.current.id && !valuesKeyDown.current.venda) {
				setModalPedidoImportacao(true);
			}
		}

		if (event.code === 'F9') {
			setModalMenuFiscalVisible(true);
		}

		if (event.key === 'Enter') {
			if (document.activeElement?.id === 'id-quantidade-nfce') {
				input = document.getElementById('id-produto-nfce');
				input?.focus();
			}
		}
	}

	function defaultShortcuts(event) {
		if (event.ctrlKey && event.shiftKey && event.code === 'KeyP') {
			window.print();
		}
	}

	function shortcutsToClose(event) {
		if (event.code === 'Escape') {
			if (document.getElementById('id-cliente-overlay-panel-nfce')) {
				document
					.getElementById('id-cliente-overlay-panel-nfce')
					.getElementsByClassName('p-overlaypanel-close p-link')[0]
					?.click();
			}

			if (document.getElementById('id-input-produto-overlay-panel')) {
				const input = document.getElementById('id-produto-nfce');
				input?.click();
				input?.select();
			}
		}
	}

	function setaConfiguracoesLocais() {
		salvarItemLocalStorage('mostrarPreviewNFCe', true, true);
		salvarItemLocalStorage('balancaConfigurada', false, true);
		asyncGetConfiguracoesServicoLocal(({ data: configuracoes }) => {
			if (configuracoes.impressao && configuracoes.impressao.preview === false) {
				salvarItemLocalStorage('mostrarPreviewNFCe', false, true);
			}
			if (configuracoes.balanca && configuracoes.balanca.modelo !== 'Nenhum') {
				salvarItemLocalStorage('balancaConfigurada', true, true);
			}
		});

		if (
			buscarDadosLoginLocalStorage()?.filialConectada?.endereco?.municipio?.estado?.id ===
			'e453945e-5ef9-424d-46aa-2d9456bdb94a'
		) {
			setMostrarMenuFiscal(true);
		}
	}

	async function getFavoritosPagamento(callback) {
		const pagamentos = [
			{
				...INITIAL_VALUE_PAGAMENTO_NFCE,
				tempKey: gerarUUID(),
				sequencial: 1,
				conta: null,
				categorias: [
					{
						categoria: null,
						percentual: 100,
						valor: 0,
					},
				],
				condicaoPagamento: null,
				formaPagamento: null,
			},
		];

		const favoritos = {};

		const promises = [
			asyncGetContaFavorita(({ data: response }) => {
				const contaFavorita = converterValueParaSelect(response.content[0]);
				pagamentos[0].conta = contaFavorita;
				favoritos.conta = contaFavorita;
			}),
			asyncGetCategoriaFavorita(({ data: response }) => {
				const categoria = converterValueParaSelect(response.content[0]);
				pagamentos[0].categorias[0].categoria = categoria;
				favoritos.categoria = categoria;
			}),
			asyncGetCondicaoPagamentoFavorita(async ({ data: response }) => {
				const condicaoPagamentoFavorita = converterValueParaSelect(response.content[0]);
				const isAPrazo = condicaoPagamentoFavorita?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
				const parcelaPadraoCondicaoPagamento =
					condicaoPagamentoFavorita?.registro?.parcelaPadrao ?? condicaoPagamentoFavorita?.registro?.parcelaMinima ?? 1;

				pagamentos[0].condicaoPagamento = condicaoPagamentoFavorita;
				favoritos.condicaoPagamento = condicaoPagamentoFavorita;

				function setFormaPagamento(formaPagamento) {
					const formaPagamentoConvertida = converterValueParaSelect(formaPagamento);
					pagamentos[0].formaPagamento = formaPagamentoConvertida;
					favoritos.formaPagamento = formaPagamentoConvertida;

					if (formaPagamento.conta) {
						const contaConvertida = converterValueParaSelect(formaPagamento.conta);
						pagamentos[0].conta = contaConvertida;
						favoritos.conta = contaConvertida;
					}

					if (formaPagamento.categoriaReceita) {
						const categoriaConvertida = converterValueParaSelect(formaPagamento.categoriaReceita);
						pagamentos[0].categorias[0].categoria = categoriaConvertida;
						favoritos.categoria = categoriaConvertida;
					}
				}

				if (condicaoPagamentoFavorita.registro.formaPagamento) {
					setFormaPagamento(condicaoPagamentoFavorita.registro.formaPagamento);
				} else {
					await asyncGetFormaPagamentoDinheiro(({ data: response }) => {
						setFormaPagamento(response.content[0]);
					});
				}

				if (isAPrazo) {
					pagamentos[0].quantidadeParcelas = {
						value: parcelaPadraoCondicaoPagamento,
						label: `${parcelaPadraoCondicaoPagamento}x`,
					};
					pagamentos[0].parcelas = generateParcelas({
						qtdParcelas: parcelaPadraoCondicaoPagamento,
						favoritos: favoritos,
						dataBaseParcela: values.emissao,
						valorPagamento: 0,
					});
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFavoritos(favoritos);
		});

		if (typeof callback === 'function') {
			callback(pagamentos);
		}
	}

	async function novaNFCe() {
		let valoresGet = {};
		if (credencial.filialConectada.parametrosFiscalNFCe?.operacaoFiscalNfce) {
			valoresGet = {
				...valoresGet,
				operacaoFiscal: {
					label: `${credencial.filialConectada.parametrosFiscalNFCe?.operacaoFiscalNfce} - ${credencial.filialConectada.parametrosFiscalNFCe?.operacaoFiscal?.descricao}`,
					registro: { id: credencial.filialConectada.parametrosFiscalNFCe?.operacaoFiscalNfce },
					value: credencial.filialConectada.parametrosFiscalNFCe?.operacaoFiscalNfce,
				},
			};
		} else {
			await asyncGetOperacaoFiscalFavorita('SAIDA', ({ data }) => {
				const operacaoFiscal = data;
				valoresGet = {
					...valoresGet,
					operacaoFiscal: operacaoFiscal
						? {
								label: `${operacaoFiscal.codigo} - ${operacaoFiscal.descricao}`,
								registro: operacaoFiscal,
								value: operacaoFiscal.id,
							}
						: null,
				};
			});
		}

		await getFavoritosPagamento((favoritosPagamentos) => {
			valoresGet.pagamentos = favoritosPagamentos;
		});

		await buscarVendedorInicial((vendedor) => {
			valoresGet = { ...valoresGet, vendedor: vendedor };
		});

		valoresGet = {
			...valoresGet,
			serie: credencial.filialConectada.parametrosFiscalNFCe?.serieNfce,
		};

		await resetForm({ values: { ...copiarObjeto(INITIAL_VALUES_NFCE), ...valoresGet } });
		setJogarFocoParaProduto(true);

		setPodeInserir(usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.INSERIR));

		setDavImportado(false);

		setTimeout(() => {
			document.getElementById('id-produto-nfce').focus();
		}, 200);
	}

	async function imprimirNFCe(nfceId) {
		setMessageLoadingTransmissao('Imprimindo NFC-e...');
		setExibirLoadingTransmissao(false);
		setExibirLoadingImprimir(true);
		const mostrarPreview = buscarItemLocalStorage('mostrarPreviewNFCe', true);
		await imprimirDANFE(nfceId, !mostrarPreview, async ({ data: arquivo }) => {
			if (mostrarPreview) {
				const arquivoPDF = new Blob([arquivo], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const danfce = window.open(arquivoURL);
				if (danfce) {
					danfce.onload = () => {
						setTimeout(() => {
							danfce.document.title = 'Preview NFC-e';
						}, 250);
					};
				}
			} else {
				const formData = new FormData();
				const blob = new File([arquivo], { type: 'application/pdf' });
				formData.append('arquivo', blob, `${nfceId}.pdf`);

				await asyncImprimirServicoLocal(
					formData,
					isFullScreen,
					() => {
						notify('NFC-e criada com sucesso', ToastTypes.SUCCESS);
					},
					() => {
						notify('Erro ao imprimir a NFC-e', ToastTypes.ERROR);
					}
				);
			}

			const layoutFullscreen = document.getElementsByClassName('layout-fullscreen');
			const alertifyNotifier = document.getElementsByClassName('alertify-notifier');

			if (isFullScreen) {
				layoutFullscreen[0].append(alertifyNotifier[0]);
			}

			setModalFinalizacaoVisible(false);
			setExibirLoadingImprimir(false);
			setDavImportado(false);
			transmissaoFlag.current = false;
			setPodeEditar(usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.EDITAR));

			await novaNFCe();
			setJogarFocoParaProduto(true);
		});
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsNFCe}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<ModalLoadingTransmissao
				visible={exibirLoadingTransmissao}
				message={messageLoadingTransmissao}
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				onHide={() => {}}
			/>
			<ModalLoadingImprimir
				visible={exibirLoadingImprimir}
				message={messageLoadingTransmissao}
				container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
				onHide={() => {}}
			/>
			<Form>
				<FormActions />
				<FormContent>
					<Grid>
						<CabecalhoCard
							sm={12}
							md={6}
							lg={6}
							xl={6}
							values={values}
							setFieldValue={setFieldValue}
							dirty={dirty}
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
							isDesktop={isDesktop}
							isFullScreen={isFullScreen}
							history={history}
							resetForm={resetForm}
							credencial={credencial}
							setAtualizarTotal={setAtualizarTotal}
							novaNFCe={novaNFCe}
							decimaisQtd={decimaisQtd}
							decimaisPreco={decimaisPreco}
							pathname={props.match.path}
							mostrarMenuFiscal={mostrarMenuFiscal}
						/>
						<ItensCard
							sm={12}
							md={6}
							lg={6}
							xl={6}
							values={values}
							setFieldValue={setFieldValue}
							setAtualizarTotal={setAtualizarTotal}
							novaNFCe={() => novaNFCe()}
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
							isDesktop={isDesktop}
							isFullScreen={isFullScreen}
							dirty={dirty}
							credencial={credencial}
							decimaisQtd={decimaisQtd}
							decimaisPreco={decimaisPreco}
							resetFormPrincipal={resetForm}
							imprimirNFCe={imprimirNFCe}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const NFCeForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES_NFCE;
	},

	validate() {
		const errors = {};
		return errors;
	},

	handleSubmit: () => {},
})(NFCeFormView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
	isDesktop: state.dispositivo.isDesktop,
	isFullScreen: state.dispositivo.isFullScreen,
});

export default connect(mapStateToProps)(NFCeForm);
