import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';

import { TabView } from 'components';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { CLASSNAME_TABVIEW, CTE_FINALIDADE, CTE_TIPO } from '../../Util/constantes';
import { TabDocumentosFiscais } from './components/TabDocumentosFiscais';
import { TabDocumentosAnterior } from './components/TabDocumentosAnterior';
import { TabDocumentosSubstituicao } from './components/TabDocumentosSubstituicao';
import { TabDocumentosComplementarValor } from './components/TabDocumentosComplementarValor';

function TabsDocumentos() {
	const { activeTabDocumentos, setActiveTabDocumentos } = useContextCTe();
	const { values } = useFormikContext();

	return (
		<TabView
			className={`tab-view ${CLASSNAME_TABVIEW[values.situacao]}`}
			activeIndex={activeTabDocumentos}
			onTabChange={({ index }) => setActiveTabDocumentos(index)}
			renderActiveOnly={false}
		>
			<TabPanel header="Documentos">
				<TabDocumentosFiscais />
			</TabPanel>
			<TabPanel header="Documentos transporte anterior" disabled={CTE_FINALIDADE.NORMAL === values.finalidade}>
				<TabDocumentosAnterior />
			</TabPanel>
			<TabPanel header="CT-e complementar de valores" disabled={values.tipo !== CTE_TIPO.COMPLEMENTO_VALORES}>
				<TabDocumentosComplementarValor />
			</TabPanel>
			<TabPanel header="CT-e de substituição" disabled={values.tipo !== CTE_TIPO.SUBSTITUICAO}>
				<TabDocumentosSubstituicao />
			</TabPanel>
		</TabView>
	);
}

export { TabsDocumentos };
