import { permissoes, recursos } from 'Common';
import { Switch, withRouter } from 'react-router-dom';
import PrivateRoute from 'components/Route';

import AgentesAutorizados from 'views/administracao/AgentesAutorizados';
import AgentesAutorizadosForm from 'views/administracao/AgentesAutorizados/Form';

import AdministracaoPlanos from 'views/administracao/Planos';
import { AdministracaoPlanosForm } from 'views/administracao/Planos/Form';

import Organizacoes from 'views/administracao/Organizacoes';
import OrganizacoesForm from 'views/administracao/Organizacoes/Form';

import AdministracaoRelatorios from 'views/administracao/Relatorios';

export const pathAdministracaoRoutes = [
	'/agentes_autorizados',
	'/agentes_autorizados/cadastro',
	'/agentes_autorizados/cadastro/:id',
	'/administracao_planos',
	'/administracao_planos/cadastro',
	'/administracao_planos/cadastro/:id',
	'/organizacoes',
	'/organizacoes/cadastro',
	'/organizacoes/cadastro/:id',
	'/relatorios_administracao',
];

function AdministracaoRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path={['/agentes_autorizados']}
				render={(props) => <AgentesAutorizados {...props} />}
				recurso={recursos.AGENTES_AUTORIZADOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/agentes_autorizados/cadastro', '/agentes_autorizados/cadastro/:id']}
				render={(props) => <AgentesAutorizadosForm {...props} />}
				recurso={recursos.AGENTES_AUTORIZADOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/administracao_planos']}
				render={(props) => <AdministracaoPlanos {...props} />}
				recurso={recursos.PLANOS_ADMINISTRACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/administracao_planos/cadastro', '/administracao_planos/cadastro/:id']}
				render={(props) => <AdministracaoPlanosForm {...props} />}
				recurso={recursos.PLANOS_ADMINISTRACAO}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/organizacoes"
				render={(props) => <Organizacoes {...props} />}
				recurso={recursos.ORGANIZACOES}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/organizacoes/cadastro', '/organizacoes/cadastro/:id']}
				render={(props) => <OrganizacoesForm {...props} />}
				recurso={recursos.ORGANIZACOES}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path={['/relatorios_administracao']}
				render={(props) => <AdministracaoRelatorios {...props} />}
				recurso={recursos.RELATORIOS_ADMINISTRACAO}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(AdministracaoRoutes);
