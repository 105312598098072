import { useEffect, useRef, useState } from 'react';
import { useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';
import { ratearValoresCategorias } from 'components/select/SingleSelectCategoria';
import { copiarObjeto, gerarUUID } from 'Common';
import { ButtonAdicionarItem, Col, Grid, If } from 'components';
import {	CONDICAO_PAGAMENTO_TIPO } from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { ModalDetalhesPix } from 'components/ModalDetalhesPix';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { useGenerateParcelas } from '../../Hooks/useGenerateParcelas';
import { FINANCEIRO_TIPO, INITIAL_VALUE_PAGAMENTO } from '../../Util/constantes';
import { divideValorPorQuantidade } from '../../Util/functions';
import { Pagamento } from '../Pagamento';

function PagamentosImpl({
	idOrigem,
	pagamentos,
	urls,
	favoritos,
	informacoesPermissoes,
	disabledFields,
	dataBaseParcela,
	hideTabFinanceiro,
	valorTotal,
	financeiroTipo = FINANCEIRO_TIPO.CONTA_RECEBER,
	onChangePagamentos,
	onChangePagamento,
	onChangePagamentoField,
	isMobile,
	isTablet,
	isLessHd,
	isFullScreen,
	classNameTabView,
	hiddenButtonAdd,
	color,
	setRecalcularTodosOsProdutosComTabelaPreco,
	setRecalcularTodosOsServicosComTabelaPreco,
	stylePagamento,
	isNfce,
	disabledValorRecebido,
}) {
	const { 
		handleIdOrigem,
		handleValuesPagamentos,
		handleValorTotalPagamentos,
		handleQuantidadeDePagamentos,
		handleUrls,
		handleFuncaoSetValues,
		handleFuncaoSetFieldValue, 
		handleErrorsFormulario, 
		isFormModal,
		handleIsNfce,
		favoritosContext,
		handleFavoritosContext,
		handleDataBaseParcela,
		handleDisabledFields,
		handleInformacoesPermissoes,
		handleClassNameTabView,
		handleColor,
		handleHideTabFinanceiro,
		handleFinanceiroTipo,
		handleStylePagamento,
		handleDisabledValorRecebido,
		handleIsMobile,
		handleIsTablet,
		handleIsLessHd,
		handleIsFullScreen,
		renderMessage,
	} = useContextPagamentos();

	const { values, errors, setValues, setFieldValue } = useFormikContext();

	const [recalcularValoresPagamentos, setRecalcularValoresPagamentos] = useState(false);
	const [modalDetalhesPixVisible, setModalDetalhesPixVisible] = useState(false);
	const [indexPagamentoPix, setIndexPagamentoPix] = useState(0);
	const prevPagamentos = useRef(pagamentos);
	const disabledButtonAdd = pagamentos[0]?.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO;

	useEffect(() => {
		handleIdOrigem(idOrigem);
		handleValorTotalPagamentos(valorTotal);
		handleUrls(urls);
		handleFuncaoSetValues(() => setValues);
		handleIsNfce(isNfce);
		handleFavoritosContext(favoritos);
		handleDataBaseParcela(dataBaseParcela);
		handleDisabledFields(disabledFields);
		handleInformacoesPermissoes(informacoesPermissoes);
		handleClassNameTabView(classNameTabView);
		handleColor(color);
		handleHideTabFinanceiro(hideTabFinanceiro);
		handleFinanceiroTipo(financeiroTipo);
		handleStylePagamento(stylePagamento);
		handleDisabledValorRecebido(disabledValorRecebido);
	}, []);

	useEffect(() => {
		handleValuesPagamentos(values);
	}, [values]);

	useEffect(() => {
		if (prevPagamentos.current !== values) {
			onChangePagamentos(values);
		}
		handleQuantidadeDePagamentos(values.length);
	}, [values.length]);
	
	useEffect(() => {
		if (recalcularValoresPagamentos) {
			const { valorNaoRateado, quantidadePagamentosNaoRateados } = buscarValoresNaoRateado();

			ratearNovoValorPagamentos(valorNaoRateado, quantidadePagamentosNaoRateados);

			setRecalcularValoresPagamentos(false);
		}
	}, [recalcularValoresPagamentos]);

	useEffect(() => {
		handleErrorsFormulario(errors);
	}, [errors])

	useEffect(() => {
		if (!isFormModal) {
			handleFuncaoSetFieldValue(() => setFieldValue);
		}
	}, [isFormModal]);

	useEffect(() => {
		handleIsMobile(isMobile);
		handleIsTablet(isTablet);
		handleIsLessHd(isLessHd);
		handleIsFullScreen(isFullScreen);
	}, [isMobile, isTablet, isLessHd, isFullScreen]);

	const [generateParcelas] = useGenerateParcelas({
		dataBaseParcela,
		favoritosContext,
	});

	function handleClickAdicionarFormaPagamento() {
		const hasCondicaoAPrazo =
			values.filter((e) => e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO).length > 0;
		const isCondicaoFavoritaAPrazo = favoritosContext.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
		const parcelaPadraoCondicaoPagamento =
			favoritosContext.condicaoPagamento?.registro?.parcelaPadrao ?? favoritosContext.condicaoPagamento?.registro?.parcelaMinima ?? 1;
		const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(valorTotal, values.length + 1);
		const formasPagamentoComValorAjustado = [];

		const newFormaPagamento = {
			...INITIAL_VALUE_PAGAMENTO,
			tempKey: gerarUUID(),
			sequencial: values.length + 1,
			condicaoPagamento: hasCondicaoAPrazo && isCondicaoFavoritaAPrazo ? null : favoritosContext.condicaoPagamento,
			quantidadeParcelas: {
				value: parcelaPadraoCondicaoPagamento,
				label: `${parcelaPadraoCondicaoPagamento}x`,
			},
			conta: favoritosContext.conta,
			formaPagamento: favoritosContext.formaPagamento,
			categorias: [{ categoria: favoritosContext.categoria, valor: valorUltimaQuantidade, percentual: 100 }],
			valor: valorUltimaQuantidade,
			valorRecebido: valorUltimaQuantidade,
		};

		newFormaPagamento.parcelas = getParcelas(newFormaPagamento, valorUltimaQuantidade);

		values.forEach((e) => {
			formasPagamentoComValorAjustado.push({
				...e,
				valor: valorPorQuantidade,
				valorRecebido: valorPorQuantidade,
				categorias: ratearValoresCategorias(e.categorias, valorPorQuantidade, -1, true),
				parcelas: getParcelas(e, valorPorQuantidade),
			});
		});

		setValues([...formasPagamentoComValorAjustado, newFormaPagamento]);
	}

	function getParcelas(e, valorPagamento) {
		const isAPrazo = e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
		return isAPrazo
			? generateParcelas({
					qtdParcelas: e.quantidadeParcelas?.value,
					parcelas: e.parcelas,
					valorPagamento: valorPagamento,
				})
			: [];
	}

	function buscarValoresNaoRateado() {
		let valorNaoRateado = valorTotal;
		let quantidadePagamentosNaoRateados = 0;

		values?.forEach((pagamento) => {
			if (pagamento.alterouValor) {
				valorNaoRateado -= pagamento.valor;
			} else {
				quantidadePagamentosNaoRateados++;
			}
		});

		return { valorNaoRateado, quantidadePagamentosNaoRateados };
	}

	function ratearNovoValorPagamentos(valorNaoRateado, quantidadePagamentosNaoRateados) {
		const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(
			valorNaoRateado,
			quantidadePagamentosNaoRateados
		);
		const pagamentos = copiarObjeto(values);

		pagamentos?.forEach((pagamento, index) => {
			if (!pagamento.alterouValor) {
				if (index === pagamentos.length - 1) {
					pagamentos[index].valor = valorNaoRateado <= 0 ? 0 : valorUltimaQuantidade;
					pagamentos[index].valorRecebido = valorNaoRateado <= 0 ? 0 : valorUltimaQuantidade;
				} else {
					pagamentos[index].valor = valorNaoRateado <= 0 ? 0 : valorPorQuantidade;
					pagamentos[index].valorRecebido = valorNaoRateado <= 0 ? 0 : valorPorQuantidade;
				}
			}
		});

		setValues(pagamentos);
		onChangePagamentos(pagamentos);
	}

	return (
		<>
			{renderMessage()}
			<div style={{ marginTop: '4px' }}>
				{values.length > 0 &&
					values.map((value, index) => (
						<Pagamento
							key={value.tempKey}
							value={value}
							indexPagamento={index}
							onChangePagamento={onChangePagamento}
							onChangePagamentoField={onChangePagamentoField}
							setRecalcularValoresPagamentos={setRecalcularValoresPagamentos}
							setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
							setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
							setModalDetalhesPixVisible={setModalDetalhesPixVisible}
							setIndexPagamentoPix={setIndexPagamentoPix}
						/>
					))}
			</div>
			<Grid style={{ paddingTop: '10px' }} hidden={hiddenButtonAdd}>
				<Col sm="8" md="8" lg="8" xl="8" style={{ padding: '8px 0px 0px 0px' }}>
					<ButtonAdicionarItem
						label="Adicionar forma de pagamento"
						style={{
							background: 'none',
							border: 'none',
							fontWeight: 'bold',
							boxShadow: 'none',
							padding: '0rem 1.2rem',
							color: color,
						}}
						onClick={handleClickAdicionarFormaPagamento}
						disabled={!informacoesPermissoes?.podeInserir || disabledButtonAdd}
					/>
				</Col>
			</Grid>
			<If test={modalDetalhesPixVisible}>
				<ModalDetalhesPix
					visible={modalDetalhesPixVisible}
					lancamentoId={values[indexPagamentoPix]?.lancamento?.id}
					pixId={values[indexPagamentoPix]?.lancamento?.pixId}
					valor={values[indexPagamentoPix]?.lancamento?.valor}
					container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
					onHide={() => {
						setModalDetalhesPixVisible(false);
					}}
				/>
			</If>
		</>
	);
}

const PagamentosFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues({ pagamentos }) {
		return pagamentos;
	},

	mapPropsToErrors({ errors }) {
		return errors ?? [];
	},

	handleSubmit: () => {},
})(PagamentosImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
		isTablet: state.dispositivo.isTablet,
		isLessHd: state.dispositivo.isLessHd,
	};
}

export const PagamentosFormPrincipal = connect(mapStateToProps)(PagamentosFormik);
