import { copiarObjeto, TiposPercentualValor } from 'Common';
import { Produtos } from 'components/Produtos';
import { calcularTributacaoTodosProdutos } from 'components/Produtos/Util/util';
import { useFormikContext } from 'formik';
import { TabPanel } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { aplicarPercentual } from 'views/Util';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { Divider, Grid, If, Message, TabView } from '../..';
import { moduloUtilizado as modulo, tipoOperacao } from '../../header/Util/constantes';
import { colorSituacaoTipoVenda, colorsStatusNfe } from '../Util/constantes';
import './Styles/index.css';
import { CAMPO } from './Util/constantes';
import {
	buscarPercentualAdicionalCondicaoPagamento,
	buscarPercentualAdicionalQuantidadeProduto,
	rateioValorEntreProdutos,
} from './Util/functions';
import InformacoesComplementares from './components/Complementos';
import InformacoesExportacao from './components/Exportacao';
import { InputsTotalizadores } from './components/InputsTotalizadores';
import { TabPagamentos } from './components/Parcelas';
import Totalizadores from './components/Totalizadores';
import Transporte from './components/Transporte';

function BodyVendas({
	moduloUtilizado,
	informacoesPermissoes,
	precoVendaOuAtacado,
	setAtualizarTotal,
	atualizarTotal,
	existeParcelaRecebida,
	controlMessageProdutos,
	setControlMessageProdutos,
	urlModuloUtilizado,
	operacaoFiscalHeader,
	comissaoHeader,
	descontoMaximoVendedor,
	descontoMaximoSuperior,
	isFirstRender,
	isMobile,
	favoritosPagamento,
	podeEditar,
	totalizadores,
	setTotalizadores,
	buscarTabelaPreco,
	buscarMunicipioIdPessoa,
}) {
	const { values, errors, setFieldValue, submitCount } = useFormikContext();
	const [classNameMessage, setClassNameMessage] = useState('');
	const [showMessage, setShowMessage] = useState(
		moduloUtilizado !== modulo.ORCAMENTO && (!values.operacaoFiscal?.registro || !values.pessoa?.registro)
	);
	const [disabledFields, setdisabledFields] = useState(showMessage);
	const [tabViewIndex, setTabViewIndex] = useState(0);
	const [recalcularTodosOsProdutosComTabelaPreco, setRecalcularTodosOsProdutosComTabelaPreco] = useState(false);

	const [totalLiquido, setTotalLiquido] = useState(0);
	const [totalProdutos, setTotalProdutos] = useState(0);
	const [totalFrete, setTotalFrete] = useState(0);
	const [totalSeguro, setTotalSeguro] = useState(0);
	const [totalAcessorias, setTotalAcessorias] = useState(0);
	const [totalDesconto, setTotalDesconto] = useState(0);
	const [totalIcmsSt, setTotalIcmsSt] = useState(0);
	const [totalIpi, setTotalIpi] = useState(0);
	const [totalIpiDevolvido, setTotalIpiDevolvido] = useState(0);
	const [totalPis, setTotalPis] = useState(0);
	const [totalCofins, setTotalCofins] = useState(0);
	const [totalIcms, setTotalIcms] = useState(0);
	const [totalBaseCalculoIcmsSt, setTotalBaseCalculoIcmsSt] = useState(0);
	const [totalBaseCalculoIcms, setTotalBaseCalculoIcms] = useState(0);
	const [totalBaseCalculoIpi, setTotalBaseCalculoIpi] = useState(0);
	const [totalBaseCalculoPis, setTotalBaseCalculoPis] = useState(0);
	const [totalBaseCalculoCofins, setTotalBaseCalculoCofins] = useState(0);

	const isVenda = [modulo.PEDIDO_VENDA, modulo.ORCAMENTO].includes(moduloUtilizado);

	let camposVaziosMessage = '';

	useEffect(() => {
		if (
			moduloUtilizado === modulo.NOTA_FISCAL &&
			tabViewIndex === 4 &&
			(values?.pessoa?.registro?.tipo !== TIPO_PESSOA.ESTRANGEIRO || values?.tipoOperacao !== tipoOperacao.SAIDA)
		) {
			setTabViewIndex(0);
		}
	}, [values.pessoa, values.tipoOperacao]);

	useEffect(() => {
		if (atualizarTotal) {
			let totalProdutos = 0;
			let totalFrete = 0;
			let totalSeguro = 0;
			let totalAcessorias = 0;
			let totalDesconto = 0;

			let totalBaseCalculoIcms = 0;
			let totalIcms = 0;
			let totalBaseCalculoIcmsSt = 0;
			let totalIcmsSt = 0;

			let totalBaseCalculoIpi = 0;
			let totalIpi = 0;
			let totalIpiDevolvido = 0;

			let totalBaseCalculoPis = 0;
			let totalPis = 0;

			let totalBaseCalculoCofins = 0;
			let totalCofins = 0;

			const produtosLocal = copiarObjeto(values.produtos);

			produtosLocal.forEach((produto) => {
				totalProdutos += parseFloat((produto.quantidade * produto.valor)?.toFixed(2));
				totalFrete += parseFloat(produto.frete?.toFixed(2)) ?? 0;
				totalSeguro += parseFloat(produto.seguro?.toFixed(2)) ?? 0;
				totalAcessorias += parseFloat(produto.acessorias?.toFixed(2)) ?? 0;
				totalDesconto += parseFloat(produto.desconto?.toFixed(2)) ?? 0;

				totalBaseCalculoIcms += parseFloat(produto?.tributos?.icms?.baseIcms?.toFixed(2)) ?? 0;
				totalIcms += parseFloat(produto?.tributos?.icms?.valorIcms?.toFixed(2)) ?? 0;
				totalBaseCalculoIcmsSt += parseFloat(produto?.tributos?.icms?.baseIcmsSt?.toFixed(2)) ?? 0;
				totalIcmsSt += parseFloat(produto?.tributos?.icms?.valorIcmsSt?.toFixed(2)) ?? 0;

				totalBaseCalculoIpi += parseFloat(produto?.tributos?.ipi?.baseIpi?.toFixed(2)) ?? 0;
				totalIpi += parseFloat(produto?.tributos?.ipi?.valorIpi?.toFixed(2)) ?? 0;
				totalIpiDevolvido += produto?.tributos?.devolvido
					? parseFloat(produto?.tributos?.devolvido?.valorIpiDevolvido?.toFixed(2)) ?? 0
					: 0;

				totalBaseCalculoPis += parseFloat(produto?.tributos?.pis?.basePis?.toFixed(2)) ?? 0;
				totalPis += parseFloat(produto?.tributos?.pis?.valorPis?.toFixed(2)) ?? 0;

				totalBaseCalculoCofins += parseFloat(produto?.tributos?.cofins?.baseCofins?.toFixed(2)) ?? 0;
				totalCofins += parseFloat(produto?.tributos?.cofins?.valorCofins?.toFixed(2)) ?? 0;
			});

			totalProdutos = parseFloat(totalProdutos.toFixed(2));
			totalFrete = parseFloat(totalFrete.toFixed(2)) ?? 0;
			totalSeguro = parseFloat(totalSeguro.toFixed(2)) ?? 0;
			totalAcessorias = parseFloat(totalAcessorias.toFixed(2)) ?? 0;
			totalDesconto = parseFloat(totalDesconto.toFixed(2)) ?? 0;

			totalBaseCalculoIcms = parseFloat(totalBaseCalculoIcms.toFixed(2)) ?? 0;
			totalIcms = parseFloat(totalIcms.toFixed(2)) ?? 0;
			totalBaseCalculoIcmsSt = parseFloat(totalBaseCalculoIcmsSt.toFixed(2)) ?? 0;
			totalIcmsSt = parseFloat(totalIcmsSt.toFixed(2)) ?? 0;

			totalBaseCalculoIpi = parseFloat(totalBaseCalculoIpi.toFixed(2)) ?? 0;
			totalIpi = parseFloat(totalIpi.toFixed(2)) ?? 0;
			totalIpiDevolvido = parseFloat(totalIpiDevolvido.toFixed(2)) ?? 0;

			totalBaseCalculoPis = parseFloat(totalBaseCalculoPis.toFixed(2)) ?? 0;
			totalPis = parseFloat(totalPis.toFixed(2)) ?? 0;

			totalBaseCalculoCofins = parseFloat(totalBaseCalculoCofins.toFixed(2)) ?? 0;
			totalCofins = parseFloat(totalCofins.toFixed(2)) ?? 0;

			const totalLiquido = parseFloat(
				(
					(totalProdutos || 0) +
					(totalFrete || 0) +
					(totalSeguro || 0) +
					(totalIcmsSt || 0) +
					(totalIpi || 0) +
					(totalIpiDevolvido || 0) +
					(totalAcessorias || 0) -
					(totalDesconto || 0)
				)?.toFixed(2)
			);

			setTotalProdutos(totalProdutos);
			setTotalFrete(totalFrete);
			setTotalSeguro(totalSeguro);
			setTotalAcessorias(totalAcessorias);
			setTotalDesconto(totalDesconto);

			setTotalBaseCalculoIcms(totalBaseCalculoIcms);
			setTotalIcms(totalIcms);
			setTotalBaseCalculoIcmsSt(totalBaseCalculoIcmsSt);
			setTotalIcmsSt(totalIcmsSt);

			setTotalBaseCalculoIpi(totalBaseCalculoIpi);
			setTotalIpi(totalIpi);
			setTotalIpiDevolvido(totalIpiDevolvido);

			setTotalBaseCalculoPis(totalBaseCalculoPis);
			setTotalPis(totalPis);

			setTotalBaseCalculoCofins(totalBaseCalculoCofins);
			setTotalCofins(totalCofins);

			setTotalLiquido(totalLiquido);

			if (totalFrete !== totalizadores.totalFrete) {
				setFieldValue('totalizadores.totalFrete', totalFrete);
			}
			if (totalSeguro !== totalizadores.totalSeguro) {
				setFieldValue('totalizadores.totalSeguro', totalSeguro);
			}
			if (totalAcessorias !== totalizadores.totalAcessorias) {
				setFieldValue('totalizadores.totalAcessorias', totalAcessorias);
			}
			if (totalDesconto !== totalizadores.totalDesconto) {
				setFieldValue('totalizadores.totalDesconto', totalDesconto);
			}

			setTotalizadores({
				totalProdutos: totalProdutos,
				totalFrete: totalFrete,
				totalSeguro: totalSeguro,
				totalAcessorias: totalAcessorias,
				totalDesconto: totalDesconto,
				totalBaseCalculoIcms: totalBaseCalculoIcms,
				totalIcms: totalIcms,
				totalBaseCalculoIcmsSt: totalBaseCalculoIcmsSt,
				totalIcmsSt: totalIcmsSt,
				totalBaseCalculoIpi: totalBaseCalculoIpi,
				totalIpi: totalIpi,
				totalIpiDevolvido: totalIpiDevolvido,
				totalBaseCalculoPis: totalBaseCalculoPis,
				totalPis: totalPis,
				totalCofins: totalCofins,
				totalBaseCalculoCofins: totalBaseCalculoCofins,
				totalLiquido: totalLiquido,
			});
			setAtualizarTotal(false);
		}
	}, [atualizarTotal]);

	useEffect(() => {
		if (moduloUtilizado !== modulo.ORCAMENTO && (!values.operacaoFiscal?.registro || !values.pessoa?.registro)) {
			setShowMessage(true);
			setdisabledFields(true);
			setClassNameMessage('message-produto-venda-effect');
		} else {
			setShowMessage(false);
			setdisabledFields(false);
			setClassNameMessage('');
		}

		setControlMessageProdutos(false);
	}, [controlMessageProdutos, values.operacaoFiscal, values.pessoa, values.tipo]);

	useEffect(() => {
		if (errors.pagamentos) {
			setTabViewIndex(1);
		}
		if (errors.informacoesComplementares) {
			setTabViewIndex(3);
		}
	}, [errors, submitCount]);

	function montarInformacaoMessage() {
		if (!values.pessoa) {
			moduloUtilizado !== modulo.NOTA_FISCAL
				? (camposVaziosMessage += 'cliente')
				: (camposVaziosMessage += 'destinatário');
		}

		if (!values.operacaoFiscal) {
			camposVaziosMessage !== '' ? (camposVaziosMessage += ' e ') : null;
			camposVaziosMessage += 'operação fiscal';
		}

		if (camposVaziosMessage.split(' ').length <= 2) {
			return (
				<span>
					{`O campo `}
					<b>{`${camposVaziosMessage}`}</b>
					{` deve ser preenchido antes de adicionar um produto.`}
				</span>
			);
		} else {
			return (
				<span>
					{`Os campos `}
					<b>{`${camposVaziosMessage}`}</b>
					{` devem ser preenchidos antes de adicionar um produto.`}
				</span>
			);
		}
	}

	function defineColorStatus(situacao) {
		return isVenda
			? colorSituacaoTipoVenda[values.situacao?.registro?.tipo]
			: colorsStatusNfe[situacao]
				? colorsStatusNfe[situacao]?.strongColor
				: colorsStatusNfe.PENDENTE?.strongColor;
	}

	function defineClassNameTabView(situacao) {
		switch (situacao) {
			case 'NAO_ENVIADA': {
				return 'tab-view-venda-nota-padrao';
			}
			case 'PENDENTE': {
				return 'tab-view-venda-nota-padrao';
			}
			case 'FINALIZADO': {
				return 'tab-view-venda-nota-finalizada';
			}
			case 'TRANSMITIDA': {
				return 'tab-view-venda-nota-finalizada';
			}
			case 'REJEITADA': {
				return 'tab-view-venda-nota-rejeitada';
			}
			case 'CANCELADO': {
				return 'tab-view-venda-nota-cancelada';
			}
			case 'DENEGADA': {
				return 'tab-view-venda-nota-denegada';
			}
			case 'AGUARDANDO_AUTORIZACAO': {
				return 'tab-view-venda-nota-aguardando';
			}
			case 'CORRIGIDA': {
				return 'tab-view-venda-nota-finalizada';
			}
			default: {
				return 'tab-view-venda-nota-padrao';
			}
		}
	}

	function calcularFrete(frete) {
		if (frete.tipoFrete === TiposPercentualValor.PERCENTUAL) {
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(
					values.produtos,
					totalProdutos,
					CAMPO.FRETE,
					aplicarPercentual(totalProdutos, frete.totalFrete)
				),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			setFieldValue('totalizadores.totalFrete', aplicarPercentual(totalProdutos, frete.totalFrete));
		} else {
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(values.produtos, totalProdutos, CAMPO.FRETE, frete.totalFrete),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			setFieldValue('totalizadores.totalFrete', frete.totalFrete);
		}
	}

	function calcularSeguro(seguro) {
		if (seguro.tipoSeguro === TiposPercentualValor.PERCENTUAL) {
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(
					values.produtos,
					totalProdutos,
					CAMPO.SEGURO,
					aplicarPercentual(totalProdutos, seguro.totalSeguro)
				),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			setFieldValue('totalizadores.totalSeguro', aplicarPercentual(totalProdutos, seguro.totalSeguro));
		} else {
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(values.produtos, totalProdutos, CAMPO.SEGURO, seguro.totalSeguro),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			setFieldValue('totalizadores.totalSeguro', seguro.totalSeguro);
		}
	}

	function calcularDesconto(desconto) {
		const produtosTemp = copiarObjeto(values.produtos);
		if (descontoMaximoSuperior) {
			produtosTemp.forEach((produto, index) => {
				if (descontoMaximoSuperior > produto.descontoMaximoPercentual) {
					produtosTemp[index].descontoMaximoVendedor = descontoMaximoSuperior;
					produtosTemp[index].descontoMaximoPercentual = descontoMaximoSuperior;
				}
			});
		}
		if (desconto.tipoDesconto === TiposPercentualValor.PERCENTUAL) {
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(
					produtosTemp,
					totalProdutos,
					CAMPO.DESCONTO,
					aplicarPercentual(totalProdutos, desconto.totalDesconto)
				),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			setFieldValue('totalizadores.totalDesconto', aplicarPercentual(totalProdutos, desconto.totalDesconto));
		} else {
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(produtosTemp, totalProdutos, CAMPO.DESCONTO, desconto.totalDesconto),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			setFieldValue('totalizadores.totalDesconto', desconto.totalDesconto);
		}
	}

	function calcularAcessorias(acessorias) {
		if (acessorias.tipoAcessorias === TiposPercentualValor.PERCENTUAL) {
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(
					values.produtos,
					totalProdutos,
					CAMPO.ACESSORIAS,
					aplicarPercentual(totalProdutos, acessorias.totalAcessorias)
				),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			const valorAcessorias = aplicarPercentual(totalProdutos, acessorias.totalAcessorias);

			setFieldValue('totalizadores.totalAcessorias', valorAcessorias);
			setTotalAcessorias(valorAcessorias);
		} else {
			const valorAcessorias = aplicarPercentual(totalProdutos, acessorias.totalAcessorias);
			calcularTributacaoTodosProdutos(
				rateioValorEntreProdutos(values.produtos, totalProdutos, CAMPO.ACESSORIAS, acessorias.totalAcessorias),
				(produtos) => {
					setFieldValue('produtos', produtos);
				}
			);
			setTotalAcessorias(valorAcessorias);
			setFieldValue('totalizadores.totalAcessorias', acessorias.totalAcessorias);
		}
	}

	function onBlurTotalFrete(tipo, value, percentual) {
		if (!isFirstRender && value !== values.totalizadores.totalFrete) {
			const frete = {
				tipoFrete: tipo,
				totalFrete: tipo === TiposPercentualValor.PERCENTUAL ? percentual : value,
			};
			calcularFrete(frete);
			if (!isFirstRender) {
				setTimeout(() => {
					setAtualizarTotal(true);
				}, 500);
			}
		}
	}

	function onBlurTotalSeguro(tipo, value, percentual) {
		if (!isFirstRender && value !== values.totalizadores.totalSeguro) {
			const seguro = {
				tipoSeguro: tipo,
				totalSeguro: tipo === TiposPercentualValor.PERCENTUAL ? percentual : value,
			};
			calcularSeguro(seguro);
			if (!isFirstRender) {
				setTimeout(() => {
					setAtualizarTotal(true);
				}, 500);
			}
		}
	}

	function onBlurTotalAcessorias(tipo, value, percentual) {
		if (!isFirstRender && value !== values.totalizadores.totalAcessorias) {
			const acessorias = {
				tipoAcessorias: tipo,
				totalAcessorias: tipo === TiposPercentualValor.PERCENTUAL ? percentual : value,
			};
			calcularAcessorias(acessorias);
			if (!isFirstRender) {
				setTimeout(() => {
					setAtualizarTotal(true);
				}, 500);
			}
		}
	}

	function onBlurTotalDesconto(tipo, value, percentual) {
		if (!isFirstRender && value !== values.totalizadores.totalDesconto) {
			const desconto = {
				tipoDesconto: tipo,
				totalDesconto: tipo === TiposPercentualValor.PERCENTUAL ? percentual : value,
			};
			calcularDesconto(desconto);
			if (!isFirstRender) {
				setTimeout(() => {
					setAtualizarTotal(true);
				}, 500);
			}
		}
	}

	function onChangeProdutos(produtos) {
		setFieldValue('produtos', produtos);
		setAtualizarTotal(true);
	}

	function onChangeProduto(index, value) {
		setFieldValue(`produtos[${index}]`, value);
		setAtualizarTotal(true);
	}

	async function calcularPercentualAdicionalTabelaPreco(quantidade) {
		const Promises = [
			buscarPercentualAdicionalCondicaoPagamento(
				values.pagamentos,
				values.informacoesComplementares?.tabelaPreco?.registro?.alteracoesPorCondicao
			),
			buscarPercentualAdicionalQuantidadeProduto(
				quantidade,
				values.informacoesComplementares?.tabelaPreco?.registro?.descontosPorQuantidade
			),
		];

		return Promise.all(Promises).then((dados) => {
			const percentualAplicado = dados.reduce((percentual, dado) => {
				if (dado?.tipo === 'ACRESCIMO') {
					return percentual + (dado.percentual || 0);
				} else if (dado?.tipo === 'DESCONTO') {
					return percentual - (dado.percentual || 0);
				}

				return percentual;
			}, 0);

			return { percentual: Math.abs(percentualAplicado), tipo: percentualAplicado > 0 ? 'ACRESCIMO' : 'DESCONTO' };
		});
	}

	return (
		<>
			<If test={showMessage}>
				<Message className={classNameMessage} severity="warn" text={montarInformacaoMessage()} />
			</If>
			<div
				className={
					moduloUtilizado === modulo.NOTA_FISCAL
						? 'step-nfe-produtos grid-Produtos'
						: 'step-venda-e-orcamento-produtos grid-Produtos'
				}
				style={{
					paddingLeft: '0.4rem',
					paddingRight: '0.5rem',
					paddingTop: '0.3rem',
				}}
			>
				<Produtos
					produtos={values.produtos}
					pessoa={values.pessoa}
					pagamentos={values.pagamentos}
					tabelaPreco={values.informacoesComplementares?.tabelaPreco}
					operacaoFiscalHeader={operacaoFiscalHeader}
					comissaoHeader={comissaoHeader}
					descontoMaximoVendedor={descontoMaximoVendedor}
					descontoMaximoSuperior={descontoMaximoSuperior}
					utilizaPrecoAtacado={precoVendaOuAtacado === 'PRECO_ATACADO'}
					onChangeProdutos={onChangeProdutos}
					onChangeProduto={onChangeProduto}
					calcularPercentualAdicionalTabelaPreco={calcularPercentualAdicionalTabelaPreco}
					recalcularTodosOsProdutosComTabelaPreco={recalcularTodosOsProdutosComTabelaPreco}
					setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
					atualizarTotalizadores={() => setAtualizarTotal(true)}
					color={defineColorStatus(values.status)}
					disabledButtonAdd={!informacoesPermissoes.podeEditar || existeParcelaRecebida || disabledFields}
					informacoesPermissoes={informacoesPermissoes}
					modulo={moduloUtilizado}
					disabled={disabledFields}
					tipoOperacao={values.tipoOperacao}
					consumidorFinal={values.pessoa?.registro?.consumidorFinal}
					finalidadeDocumento={values.finalidadeDocumento?.value}
					errors={errors.produtos}
				/>
			</div>
			<Divider styleContainer={{ margin: '0.5rem 0' }} styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }} />
			<Grid
				style={{ width: '100%', margin: '0px' }}
				className={
					moduloUtilizado === modulo.NOTA_FISCAL ? 'step-nfe-totalizadores' : 'step-venda-e-orcamento-totalizadores'
				}
			>
				<InputsTotalizadores
					disabledFields={disabledFields}
					informacoesPermissoes={informacoesPermissoes}
					onBlurTotalFrete={onBlurTotalFrete}
					onBlurTotalSeguro={onBlurTotalSeguro}
					onBlurTotalAcessorias={onBlurTotalAcessorias}
					onBlurTotalDesconto={onBlurTotalDesconto}
				/>
				<TabView
					className={`tab-view ${defineClassNameTabView(isVenda ? values.situacao?.registro?.tipo : values.status)}`}
					activeIndex={tabViewIndex}
					onTabChange={(event) => {
						event.index === 0 && setAtualizarTotal(true);
						setTabViewIndex(event.index);
					}}
					{...informacoesPermissoes}
				>
					<TabPanel header="Totalizadores">
						<Totalizadores
							moduloUtilizado={moduloUtilizado}
							isMobile={isMobile}
							colorStatus={defineColorStatus(values.status)}
							totalLiquido={totalLiquido}
							totalProdutos={totalProdutos}
							totalFrete={totalFrete}
							totalSeguro={totalSeguro}
							totalAcessorias={totalAcessorias}
							totalDesconto={totalDesconto}
							totalIcmsSt={totalIcmsSt}
							totalIpi={totalIpi}
							totalIpiDevolvido={totalIpiDevolvido}
							totalPis={totalPis}
							totalCofins={totalCofins}
							totalIcms={totalIcms}
							totalBaseCalculoIcmsSt={totalBaseCalculoIcmsSt}
							totalBaseCalculoIcms={totalBaseCalculoIcms}
							totalBaseCalculoIpi={totalBaseCalculoIpi}
							totalBaseCalculoPis={totalBaseCalculoPis}
							totalBaseCalculoCofins={totalBaseCalculoCofins}
						/>
					</TabPanel>
					<TabPanel header="Pagamentos">
						<TabPagamentos
							isVenda={isVenda}
							informacoesPermissoes={informacoesPermissoes}
							favoritos={favoritosPagamento}
							urlModuloUtilizado={urlModuloUtilizado}
							totalLiquido={totalLiquido}
							classNameTabView={defineClassNameTabView}
							podeEditar={podeEditar}
							moduloUtilizado={moduloUtilizado}
							setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
						/>
					</TabPanel>
					<TabPanel
						header="Transporte"
						headerStyle={moduloUtilizado !== modulo.NOTA_FISCAL ? { display: 'none' } : null}
					>
						<Transporte
							transporte={values.transporte}
							setFieldValue={setFieldValue}
							informacoesPermissoes={informacoesPermissoes}
						/>
					</TabPanel>
					<TabPanel header="Informações complementares">
						<InformacoesComplementares
							moduloUtilizado={moduloUtilizado}
							existeParcelaRecebida={existeParcelaRecebida}
							informacoesPermissoes={informacoesPermissoes}
							setFieldValue={setFieldValue}
							buscarTabelaPreco={buscarTabelaPreco}
							buscarMunicipioIdPessoa={buscarMunicipioIdPessoa}
						/>
					</TabPanel>
					<TabPanel
						header="Exportação"
						headerStyle={
							moduloUtilizado === modulo.NOTA_FISCAL &&
							values.pessoa?.registro?.tipo === TIPO_PESSOA.ESTRANGEIRO &&
							values.tipoOperacao === tipoOperacao.SAIDA
								? null
								: { display: 'none' }
						}
					>
						<InformacoesExportacao
							exportacao={values.exportacao}
							informacoesPermissoes={informacoesPermissoes}
							setFieldValue={setFieldValue}
						/>
					</TabPanel>
				</TabView>
			</Grid>
		</>
	);
}

export default BodyVendas;
