import { formatarMonetario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import Button from 'components/Button';
import Col from 'components/Col';
import Grid from 'components/Grid';
import If from 'components/If';
import { ModalDetalhesPix } from 'components/ModalDetalhesPix';
import NenhumRegistroEncontrado from 'components/NenhumRegistroEncontrado';
import { createEstornarRecebimento, readContasReceberPorId } from 'components/Pagamentos/Requests';
import { FINANCEIRO_STATUS_CONTA_RECEBER } from 'components/Pagamentos/Util/constantes';
import { converterRecebimentoToForm } from 'components/Pagamentos/Util/converter';
import { format, parseISO } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useContextPagamentos } from 'components/Pagamentos/Context';
import { ActionButtonsBoletos } from './components/ActionButtonsBoletos';
import { ModalRecebimento } from './components/ModalRecebimento';

function TabelaContaReceber({ parcelas }) {
	const { idOrigem, urls, favoritos, informacoesPermissoes, color, isMobile } = useContextPagamentos();
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [visibleModalRecebimento, setVisibleModalRecebimento] = useState(false);
	const [listParcelas, setListParcelas] = useState(parcelas);
	const [totalRestante, setTotalRestante] = useState(calcularTotalRestante());
	const [totalRecebido, setTotalRecebido] = useState(calcularTotalRecebido());
	const [exibirModalDetalhesPix, setExibirModalDetalhesPix] = useState(false);
	const [openModalCobranca, setOpenModalCobranca] = useState(false);
	const [contaReceber, setContaReceber] = useState(null);

	const [podeVisualizarContaReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.VISUALIZAR)
	);
	const [podeEditarContaReceber] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_RECEBER, permissoes.EDITAR)
	);

	useEffect(() => {
		setTotalRestante(calcularTotalRestante());
		setTotalRecebido(calcularTotalRecebido());
	}, [listParcelas]);

	function renderIdentificadorField(element) {
		return (
			<span
				title={element.financeiroIdentificador}
				style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex', fontWeight: '500' }}
			>
				{element.financeiroIdentificador}
			</span>
		);
	}

	function renderVencimentoField(element) {
		return <span>{format(parseISO(element.vencimento), 'dd/MM/yyyy')}</span>;
	}

	function renderValorField(element) {
		const valorFormatado = formatarMonetario(element.valor);
		return <span title={valorFormatado}>{valorFormatado}</span>;
	}

	function renderValorAReceberField(element) {
		const valorFormatado = formatarMonetario(element.valorRestante);
		return <span title={valorFormatado}>{valorFormatado}</span>;
	}

	function renderFormaPagamentoField(element) {
		const label = element.formaPagamento?.label;
		return (
			<span title={label} style={{ maxHeight: '60px', overflow: 'hidden', display: 'inline-flex' }}>
				{label}
			</span>
		);
	}

	function renderAcoesField(element) {
		const recebida = Boolean(element.financeiroStatus === FINANCEIRO_STATUS_CONTA_RECEBER.RECEBIDA);
		const naoRecebida = Boolean(element.financeiroStatus === FINANCEIRO_STATUS_CONTA_RECEBER.NAO_RECEBIDA);
		const parcialmenteRecebida = Boolean(
			element.financeiroStatus === FINANCEIRO_STATUS_CONTA_RECEBER.PARCIALMENTE_RECEBIDA
		);
		const hasBoleto = Boolean(element.temBoleto);
		const hasPix = Boolean(element.temPix);

		const disableBotoes = !informacoesPermissoes.podeEditar || !podeEditarContaReceber;

		return (
			<span style={!isMobile ? { display: 'flex', justifyContent: 'flex-end' } : null}>
				<If test={!hasBoleto && !hasPix}>
					<If test={recebida || parcialmenteRecebida}>
						<Button
							className="p-button p-button-secondary"
							style={{ borderRadius: '20px', marginRight: '5px', padding: '5px 0px' }}
							disabled={disableBotoes}
							title={
								disableBotoes
									? 'Você não possui permissão ou o módulo habilitado para executar essa ação'
									: 'Estornar recebimentos'
							}
							onClick={() => handleClickEstornar(element)}
							icon="pi pi-replay"
						/>
					</If>
					<If test={recebida}>
						<Button
							className="p-button p-button-success"
							style={{ borderRadius: '20px', width: '105px', padding: '4px 7px' }}
							disabled
							label="Recebido"
							icon="pi pi-check-circle"
						/>
					</If>
					<If test={naoRecebida || parcialmenteRecebida}>
						<Button
							id="btn-contas-receber-receber"
							className="p-button p-button-secondary"
							style={{ borderRadius: '20px', width: '105px' }}
							disabled={disableBotoes}
							title={
								disableBotoes
									? 'Você não possui permissão ou o módulo habilitado para executar essa ação'
									: 'Efetuar o recebimento'
							}
							onClick={() => handleClickEfetuarBaixa(element)}
							icon="pi pi-check"
							label="Receber"
						/>
					</If>
				</If>
				<If test={hasBoleto}>
					<Button
						className="p-button p-button-success"
						style={{ borderRadius: '20px', width: '145px', padding: '4px 7px' }}
						disabled
						label="Boleto gerado"
						icon="pi pi-check-circle"
					/>
				</If>
				<If test={hasPix}>
					<Button
						className="p-button p-button-success"
						style={{ borderRadius: '20px', width: '145px', padding: '4px 7px' }}
						disabled
						label="Pix gerado"
						icon="pi pi-check-circle"
					/>
				</If>
			</span>
		);
	}

	function handleClickEfetuarBaixa(e) {
		setRegistroSelecionado({ ...e });
		setVisibleModalRecebimento(true);
	}

	async function handleClickEstornar(e) {
		await createEstornarRecebimento(urls.urlBase, idOrigem, e.id, () => {
			setListParcelas((state) =>
				state.map((parcela) => {
					if (parcela.id === e.id) {
						return {
							...parcela,
							valor: e.valor,
							valorRestante: e.valor,
							financeiroStatus: FINANCEIRO_STATUS_CONTA_RECEBER.NAO_RECEBIDA,
						};
					}
					return parcela;
				})
			);
			setRegistroSelecionado(null);
		});
	}

	function onHideModalRecebimento(values) {
		if (values) {
			setListParcelas((state) =>
				state.map((parcela) => {
					if (parcela.id === registroSelecionado.id) {
						return converterRecebimentoToForm(registroSelecionado, values);
					}
					return parcela;
				})
			);
			setVisibleModalRecebimento(false);
			setRegistroSelecionado(null);
		} else {
			setVisibleModalRecebimento(false);
			setRegistroSelecionado(null);
		}
	}

	function calcularTotalRestante() {
		return (
			listParcelas.reduce(
				(accumulator, currentValue) =>
					Number(parseFloat(accumulator) + parseFloat(currentValue.valorRestante)).toFixed(2),
				0
			) ?? 0
		);
	}

	function calcularTotalRecebido() {
		return (
			listParcelas.reduce(
				(accumulator, currentValue) =>
					Number(parseFloat(accumulator) + parseFloat(currentValue.valor - currentValue.valorRestante)).toFixed(2),
				0
			) ?? 0
		);
	}

	function refreshParcelasComBoleto(boletos) {
		const newParcelas = [];
		if (listParcelas.length > 0) {
			listParcelas.forEach((parcela) => {
				let temBoleto = parcela.temBoleto ?? false;
				boletos.forEach((boleto) => {
					if (parcela.financeiroId === boleto.id) {
						temBoleto = true;
					}
				});
				newParcelas.push({ ...parcela, temBoleto });
			});
		}
		setListParcelas(newParcelas);
	}

	function refreshParcelasComPix(pixs) {
		const newParcelas = [];
		if (listParcelas.length > 0) {
			listParcelas.forEach((parcela) => {
				let temPix = parcela.temPix ?? false;
				pixs.forEach((pix) => {
					if (parcela.financeiroId === pix.id) {
						temPix = true;
					}
				});
				newParcelas.push({ ...parcela, temPix });
			});
		}
		setListParcelas(newParcelas);
	}

	async function onHideRecebimentoPix(registro) {
		setVisibleModalRecebimento(false);
		setOpenModalCobranca(true);
		await readContasReceberPorId(registro.financeiroId, ({ data: contaReceber }) => {
			setContaReceber({
				...contaReceber,
			});
			setExibirModalDetalhesPix(true);
		});
	}

	function onHideModalDetalhesPix() {
		setOpenModalCobranca(false);
		setExibirModalDetalhesPix(false);
		if (contaReceber?.pix) {
			refreshParcelasComPix([{ id: contaReceber?.id }]);
		}
	}

	return (
		<>
			<Grid>
				<Col>
					<DataTable
						style={{ width: '100%', maxHeight: '222.31px', overflowY: 'auto' }}
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						value={listParcelas}
						responsive
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							header={isMobile ? 'Iden.' : 'Identificador'}
							field="financeiroIdentificador"
							sortable
							body={renderIdentificadorField}
							style={{ wordBreak: 'break-all', width: '12%' }}
						/>
						<Column header="Data" field="vencimento" sortable body={renderVencimentoField} style={{ width: '110px' }} />
						<Column
							header="Valor"
							field="valor"
							sortable
							body={renderValorField}
							style={{ width: '105px', textOverflow: 'ellipsis', overflow: 'hidden' }}
						/>
						<Column
							header={isMobile ? 'A receb.' : 'A receber'}
							field="valorRestante"
							sortable
							body={renderValorAReceberField}
							style={{ width: '120px', textOverflow: 'ellipsis', overflow: 'hidden' }}
						/>
						<Column
							header={isMobile ? 'Forma pgto.' : 'Forma de pagamento'}
							field="forma"
							sortable
							body={renderFormaPagamentoField}
							style={{ wordBreak: 'break-all' }}
						/>
						<Column header={isMobile ? 'Ações' : ''} body={renderAcoesField} />
					</DataTable>
				</Col>
				<Col style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Col col="9">
						<ActionButtonsBoletos
							parcelas={listParcelas}
							podeEditarContaReceber={podeEditarContaReceber}
							podeVisualizarContaReceber={podeVisualizarContaReceber}
							refreshParcelasComBoleto={refreshParcelasComBoleto}
							refreshParcelasComPix={refreshParcelasComPix}
						/>
					</Col>
					<Col col="3" style={{ textAlign: 'end' }}>
						<div>
							{'A receber: '}
							<b style={{ color }}>{formatarMonetario(totalRestante)}</b>
						</div>
						<div>
							{'Recebido: '}
							<b style={{ color }}>{formatarMonetario(totalRecebido)}</b>
						</div>
					</Col>
				</Col>
			</Grid>
			<If test={visibleModalRecebimento}>
				<ModalRecebimento
					visible={visibleModalRecebimento}
					onHide={onHideModalRecebimento}
					onHideRecebimentoPix={onHideRecebimentoPix}
					parcela={registroSelecionado}
					idOrigem={idOrigem}
					favoritos={favoritos}
				/>
			</If>
			<If test={exibirModalDetalhesPix}>
				<ModalDetalhesPix
					visible={exibirModalDetalhesPix}
					pixId={contaReceber?.pix?.id}
					valor={contaReceber?.valor}
					openModalCobranca={openModalCobranca}
					onHide={onHideModalDetalhesPix}
				/>
			</If>
		</>
	);
}

export { TabelaContaReceber };
