import { del, get, post, put, services, exibirToast } from 'Common';

export async function readNaturezasOperacao(url, onSuccess, onError) {
	return get(url, null, onSuccess, onError, true);
}

export async function readNaturezaOperacao(idNatureza, onSuccess, onError) {
	return get(`${services.GESTOR}/v1/vendas/natureza_operacao/${idNatureza}`, null, onSuccess, onError);
}

export async function createNaturezaOperacao(naturezaOperacao, onSuccess, onError) {
	return post(
		`${services.GESTOR}/v1/vendas/natureza_operacao`,
		naturezaOperacao,
		null,
		exibirToast(onSuccess, 'Registro criado com sucesso'),
		onError
	);
}

export async function updateNaturezaOperacao(naturezaOperacao, onSuccess, onError) {
	return put(
		`${services.GESTOR}/v1/vendas/natureza_operacao/${naturezaOperacao.id}`,
		naturezaOperacao,
		null,
		exibirToast(onSuccess, 'Registro atualizado com sucesso'),
		onError
	);
}

export async function deleteNaturezaOperacao(idNaturezaOperacao, onSuccess, onError) {
	return del(
		`${services.GESTOR}/v1/vendas/natureza_operacao/${idNaturezaOperacao}`,
		null,
		exibirToast(onSuccess, 'Registro excluído'),
		onError
	);
}